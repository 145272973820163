import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FontComponentService} from '../../../../../services/component-service/font-component.service';
import {CanvasComponentService} from '../../../../../services/component-service/canvas-component.service';
import {UtilService} from '../../../../../services/util.service';
import {Style} from 'src/app/models/style';
import {StyleService} from 'src/app/services/style.service';
import {ToolAndGridComponentService} from "../../../../../services/component-service/tool-and-grid-component.service";

@Component({
    selector: 'app-fonts',
    templateUrl: './fonts.component.html',
    styleUrls: ['./fonts.component.scss']
})
export class FontsComponent implements OnInit, AfterViewInit {

    @ViewChild('fontFamilyDiv', {static: false}) fontFamilyDivElement: ElementRef;

    public fontSectionDisabled = true;
    public activeStyle: Style = null;
    public fonts;

    private selectedFontFamily: string = null;
    private fontFamilyChangedCanvasObject: any = null;

    @ViewChild('font_upload', {static: false}) fontUpload: ElementRef;

    constructor(public fontComponentService: FontComponentService,
                public canvasComponentService: CanvasComponentService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public utilService: UtilService,
                public styleService: StyleService)
    {
        fontComponentService.fontComponent = this;
    }

    ngOnInit()
    {
        this.getFonts();
        this.refreshStyles();
    }

    ngAfterViewInit()
    {
        $('#font-family-dropdown')?.on({
            "show.bs.dropdown": () =>
            {
                this.selectedFontFamily = this.activeStyle.fontFamily;
                this.fontFamilyChangedCanvasObject = this.canvasComponentService.getActiveObject();
            },
            "hide.bs.dropdown": () =>
            {
                if (!this.fontFamilyChangedCanvasObject || !this.fontFamilyChangedCanvasObject.customStyles || this.fontFamilyChangedCanvasObject.customStyles.fontFamily == this.selectedFontFamily) return;

                this.fontFamilyChangedCanvasObject.customStyles.fontFamily = this.selectedFontFamily;
                this.canvasComponentService.setStyleValuesForObject('family', this.fontFamilyChangedCanvasObject);
            }
        });
    }

    public refreshStyles()
    {
        this.activeStyle = this.styleService.getActiveObjectStyle();
    }

    public updateCanvas(styleType)
    {
        this.canvasComponentService.setStyleValuesForObject(styleType);
        this.canvasComponentService.saveCanvasState();
    }

    public fontBoldToggle(isBold): void
    {
        this.activeStyle.fontWeight = (isBold ? 800 : 400);
        this.updateCanvas('font-weight');
    }

    public fontWeightChange(): void
    {
        let isBold = this.activeStyle.fontWeight > 550;
        let tempStyles = [];
        for (let style of this.activeStyle.textStyles)
        {
            if (style != "bold") tempStyles.push(style);
        }
        if (isBold) tempStyles.push("bold");

        this.activeStyle.textStyles = tempStyles;
        this.updateCanvas('font-weight');
    }

    public getFonts(): void
    {
        this.fonts = Style.FONT_LIST;
    }

    public openFileDialog()
    {
        if (!this.fontUpload || !this.fontUpload.nativeElement) return;
        this.fontUpload.nativeElement.click();
    }

    public fontFamilySelect(selectedFontFamily: string)
    {
        this.selectedFontFamily = selectedFontFamily;
        this.activeStyle.fontFamily = selectedFontFamily;
        this.updateCanvas('family');
    }

    public fontFamilyPreview(hoveredFontFamily: string)
    {
        this.activeStyle.fontFamily = hoveredFontFamily;
        this.canvasComponentService.setStyleValuesForObject('family');
    }

    public uploadFont(event)
    {
        if (event && event.target && event.target.files && event.target.files[0])
        {
            let file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (readerEvent) =>
            {
                let data = {
                    name: file.name,
                    fileData: readerEvent.target['result']
                }
                //TODO
            };
            reader.readAsDataURL(file);
        }
    }

    public setFontSectionDisabled(state): void
    {
        this.fontSectionDisabled = state;
    }
}
