import {Component} from '@angular/core';

@Component({
    selector: 'app-admin-console',
    templateUrl: './admin-console.component.html',
    styleUrls: ['./admin-console.component.scss']
})
export class AdminConsoleComponent {

}
