import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ClipArtService {

    constructor(public http: HttpClient) { }

    public saveClipArt(clipArt: any)
    {
        return this.http.post(environment.endpoint + "clip_art/save", clipArt);
    }

    public deleteClipArt(clipArt: any)
    {
        return this.http.post(environment.endpoint + "clip_art/delete", clipArt);
    }

    public getAllClipArts()
    {
        return this.http.get(environment.endpoint + "clip_art/all");
    }
}
