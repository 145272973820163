import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SearchEngineOptimizationService } from 'src/app/services/search-engine-optimization.service';

@Component({
    selector: "app-about-us",
    templateUrl: "./about-us.component.html",
    styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit {

    constructor(
        public searchEngineOptimizationService: SearchEngineOptimizationService,
        public router: Router
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Scroll to the top of the page on navigation end
                window.scrollTo(0, 0);
            }
        });
    }

    ngOnInit(): void {
        this.searchEngineOptimizationService.updatePageTitle("About Us - ThumbzStudio");
        this.searchEngineOptimizationService.updateMetaDescription("About Us for ThumbzStudio, Learn more about our company, our mission, vision, and conclusion.");
    }

}
