import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Template} from '../../models/template';
import {ActivatedRoute} from "@angular/router";
import {TemplateService} from "../../services/temaplate.service";
import {ToastrService} from "ngx-toastr";
import {SearchEngineOptimizationService} from "../../services/search-engine-optimization.service";
import {SignInDialogComponent} from "../sign-in/sign-in-dialog.component";
import {SIGN_IN_DIALOG_HEIGHT, SIGN_IN_DIALOG_WIDTH} from "../../util/app-consts";
import {AuthService} from "../../services/auth.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
	selector: 'app-template-preview',
	templateUrl: './template-preview.component.html',
	styleUrls: ['./template-preview.component.scss']
})
export class TemplatePreviewComponent implements AfterViewInit {

	public template: Template;
	public templateType: string;
	public templateTags: string;

	constructor(private activatedRoute: ActivatedRoute,
	            private templateService: TemplateService,
	            private notification: ToastrService,
				private authService: AuthService,
				private dialog: MatDialog,
				private searchEngineOptimizationService: SearchEngineOptimizationService)
	{}

	ngAfterViewInit()
	{
		let shareableCode = this.activatedRoute.snapshot.paramMap.get("shareable-code");
		this.templateService.getTemplateFromShareableCode(shareableCode).subscribe((res: Template) =>
		{
			if (res)
			{
				this.template = res;

				this.createTypeStr();
				this.createTagsStr();

				this.searchEngineOptimizationService.updatePageTitle('Pre-designed Template: ' + this.template.name);

				let metaDescription = this.templateType + " - " +  this.template.category + " - " + this.template.description + " - " + this.templateTags;
				this.searchEngineOptimizationService.updateMetaDescription(metaDescription);
			}
			else
			{
				this.notification.success("Incorrect Shareable Code", 'Error');
			}
		}, (error =>
		{
			if (error.error) this.notification.error(error.error['message'], 'Error');
		}));
	}

	public createTypeStr()
	{
		this.templateType = "";
		try
		{
			let tempStr = this.template.canvasType.replace(/-/g, " ").trim();

			tempStr.split(' ').forEach((str)=>{
				this.templateType += str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() + " ";
			});

			this.templateType += this.template?.isPremium ? "(Premium)" : "(Free)"
		}
		catch (e: any)  {
			console.error(e.toString())
		}
	}

	public createTagsStr()
	{
		this.templateTags = "";
		try
		{
			let tagsArray: string[] = JSON.parse(this.template.tags);
			for (let i = 0; i < tagsArray.length; i++)
			{
				let tempStr = tagsArray[i].trim();
				this.templateTags += tempStr.charAt(0).toUpperCase() + tempStr.slice(1).toLowerCase();

				if (i < tagsArray.length - 1) this.templateTags += ", ";
			}
		}
		catch (e: any)  {
			console.error(e.toString())
		}
	}

	GotoTool() {
		if (!this.authService.isUserLoggedIn())
		{
			this.notification.warning("Please sign in to perform this action...", 'Warning');
			this.dialog.open(SignInDialogComponent, {
				width: SIGN_IN_DIALOG_WIDTH + 'px',
				height: SIGN_IN_DIALOG_HEIGHT + 'px',
				maxWidth: SIGN_IN_DIALOG_WIDTH,
				maxHeight: SIGN_IN_DIALOG_HEIGHT
			})

			return;
		}
		document.location.href = this.template.shareableLink;
	}
}
