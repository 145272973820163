import {Component, Input, OnInit} from '@angular/core';
import {Template} from '../../../models/template';

@Component({
    selector: 'app-grid-cell',
    templateUrl: './grid-cell.component.html',
    styleUrls: ['./grid-cell.component.scss']
})
export class GridCellComponent implements OnInit {

    @Input() template: Template;
    @Input() type;

    public showTemplateItemCountDetails = false;

    constructor() { }

    ngOnInit(): void
    {
    }

}
