<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSourceUserStatsCount" matSort #sortUserStatsCountTab="matSort" (matSortChange)="announceSortChangeForUserStatsCountTable($event)">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"> Name</th>
            <td mat-cell *matCellDef="let user"> {{ user.name }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email"> Email</th>
            <td mat-cell *matCellDef="let user"> {{ user.email }}</td>
        </ng-container>

        <ng-container matColumnDef="loginCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Login Count"> Login Count</th>
            <td mat-cell *matCellDef="let user"> {{ user.loginCount }}</td>
        </ng-container>

        <ng-container matColumnDef="downloadImageCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Download Image Count"> Download Image Count</th>
            <td mat-cell *matCellDef="let user"> {{ user.downloadImageCount }}</td>
        </ng-container>

        <ng-container matColumnDef="keywordSearchCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Keyword Search Count"> Keyword Search Count</th>
            <td mat-cell *matCellDef="let user"> {{ user.keywordSearchCount }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsUserStatsCountTab"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsUserStatsCountTab;"></tr>
    </table>

    <mat-paginator #paginatorUserStatsCountTab [pageSizeOptions]="[25, 50, 100, 250, 500, 1000]" showFirstLastButtons></mat-paginator>
</div>
