<div>
    <div class="modal-header">
        <label class="modal-title pull-left">PREVIEW</label>
        <button type="button" class="close pull-right" aria-label="Close" (click)="dialogRef.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <img [src]=downloadedImage.imageUrl>
        <div class="info-div">
            <span>Email: {{ downloadedImage.userEmail }}</span>
            <span>Date: {{ convertDateToLocalDate(downloadedImage.downloadedDate) }}</span>
        </div>
    </div>

    <div class="modal-footer">
        <mat-dialog-actions>
            <button class="btn btn-primary btn-sm btn-cancel" mat-button (click)="dialogRef.close()">Cancel</button>
        </mat-dialog-actions>
    </div>
</div>



