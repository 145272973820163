<div [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()" [appDivDisable]="fontSectionDisabled">
    <div class="font-family-div row">
        <div class="label col-4 d-flex align-items-center">
            <label>Family</label>
        </div>
        <div class="col-8 d-flex justify-content-start align-items-center font-family-div" #fontFamilyDiv>
            <div class="font-family-dropdown-div">
                <div id="font-family-dropdown" class="font-family-dropdown">
                    <button class="btn dropdown-toggle font-family-dropdown-btn tool-btn" type="button" data-toggle="dropdown">
                        {{ activeStyle.fontFamily }}
                    </button>
                    <div class="dropdown-menu font-family-dropdown-menu">
                        <div *ngFor="let font of fonts" class="font-family-dropdown-option" (click)="fontFamilySelect(font)" (mouseover)="fontFamilyPreview(font)" [ngStyle]="{'font-family' : font}" [ngClass]="{'font-family-option-selected': activeStyle.fontFamily == font}">
                            <label class="form-check-label font-family-dropdown-option-label">{{ font }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="font-align-div row">
        <div class="label col-4 d-flex align-items-center">
            <label>Text Align</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="activeStyle.textAlign" (click)="updateCanvas('align')">
                <mat-button-toggle class="left-btn" value="left" aria-label="Text align left" matTooltip="left align" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-left-align" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="center-btn" value="center" aria-label="Text align center" matTooltip="center align" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-center-align" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="right-btn" value="right" aria-label="Text align right" matTooltip="right align" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-right-align" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="justify-btn" value="justify" aria-label="Text align justify" matTooltip="justify" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-justify" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <div class="font-style-div row">
        <div class="label col-4 d-flex align-items-center">
            <label>Text Styles</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-button-toggle-group #group="matButtonToggleGroup" [multiple]="true" [(ngModel)]="activeStyle.textStyles">
                <mat-button-toggle class="bold-btn" value="bold" aria-label="bold" (change)="fontBoldToggle($event.source['_checked'])" matTooltip="blod" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-bold" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="italic-btn" value="italic" aria-label="italic" (change)="updateCanvas('styles')" matTooltip="italic" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-italic" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="underline-btn" value="underline" aria-label="underline" (change)="updateCanvas('styles')" matTooltip="underline" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-underline" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="overline-btn" value="overline" aria-label="overline" (change)="updateCanvas('styles')" matTooltip="overline" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-overline" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="linethrough-btn" value="linethrough" aria-label="strikethrough" (change)="updateCanvas('styles')" matTooltip="strikethrough" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-strikethrough" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <div class="font-style2-div row">
        <div class="label col-4 d-flex align-items-center">
            <label>Other Styles</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-button-toggle-group #group="matButtonToggleGroup">
                <mat-button-toggle class="all-capital-btn" id="all-capital" value="all-capital" (change)="activeStyle.allCapital=true; updateCanvas('all-capital')" aria-label="all-capital" matTooltip="all capital" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-all-capital" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="all-simple-btn" id="all-simple" value="all-simple" (change)="activeStyle.allSimple=true; updateCanvas('all-simple')" aria-label="all-simple" matTooltip="all simple" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-all-simple" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="superscript-btn" value="superscript" (change)="activeStyle.superscript=true; updateCanvas('superscript')" aria-label="superscript" matTooltip="superscript" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-superscript" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="subscript-btn" value="subscript" (change)="activeStyle.subscript=true; updateCanvas('subscript')" aria-label="subscript" matTooltip="subscript" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-subscript" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle class="reset-btn" value="reset" (change)="updateCanvas('reset-other-styles')" aria-label="reset" matTooltip="reset superscript/subscript" [matTooltipPosition]="'above'">
                    <mat-icon svgIcon="svg-icon-reset-superscript-subscript" aria-hidden="false"></mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <div class="font-weight-div row font-weight-row">
        <div class="label col-4 d-flex align-items-center">
            <label>Font Weight</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-slider thumbLabel tickInterval="1" min="100" max="900" [(ngModel)]="activeStyle.fontWeight" (change)="fontWeightChange()"></mat-slider>
        </div>
    </div>

    <div class="char-spacing-div row">
        <div class="label col-4 d-flex align-items-center">
            <label>Char Spacing</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-slider thumbLabel [displayWith]="utilService.formatSliderValuePx" tickInterval="1" min="1" max="300" [(ngModel)]="activeStyle.textCharSpacing" (change)="updateCanvas('char-spacing')"></mat-slider>
        </div>
    </div>
</div>
