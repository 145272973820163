import {AfterViewInit, Component, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {AuthService} from './services/auth.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SignInDialogComponent} from './pages/sign-in/sign-in-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CanvasComponentService} from './services/component-service/canvas-component.service';
import {ToolAndGridComponentService} from "./services/component-service/tool-and-grid-component.service";
import {UtilService} from "./services/util.service";
import {
    CANVAS_TYPES_AND_SIZES,
    CONFIRMATION_DIALOG_HEIGHT,
    CONFIRMATION_DIALOG_WIDTH,
    MESSAGE_BAR_TYPE_ERROR_MESSAGES,
    MESSAGE_BAR_TYPE_WARNING_MESSAGES,
    SIGN_IN_DIALOG_HEIGHT,
    SIGN_IN_DIALOG_WIDTH
} from "./util/app-consts";
import {environment} from "../environments/environment";
import {ConfirmationBoxComponent} from "./pages/common-components/confirmation-box/confirmation-box.component";
import {LocalStorageService} from "./services/local-storage.service";
import {NotificationService} from "./services/notification.service";
import {SearchEngineOptimizationService} from "./services/search-engine-optimization.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

    public title = 'thumbaa';
    public version = environment.version;
    public isDarkThemeEnable = false;

    public MESSAGE_BAR_TYPE_WARNING = MESSAGE_BAR_TYPE_WARNING_MESSAGES;
    public MESSAGE_BAR_TYPE_ERROR = MESSAGE_BAR_TYPE_ERROR_MESSAGES;

    constructor(public authService: AuthService,
                public router: Router,
                public renderer: Renderer2,
                public dialog: MatDialog,
                public vcRef: ViewContainerRef, // color-picker popup uses [cpUseRootViewContainer]="true"
                public canvasComponentService: CanvasComponentService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public localStorageService: LocalStorageService,
                public notificationService: NotificationService,
                public activatedRoute: ActivatedRoute,
                public notification: ToastrService,
                public searchEngineOptimizationService: SearchEngineOptimizationService,
                public utilService: UtilService)
    {
        this.loadSavedThemeFromLocalStorage();
        this.utilService.registerSvgIcons();
        this.setDefaultCanvasSizeAndTypeToLocalStorage();
    }

    ngOnInit()
    {
        this.subscribeToNetworkStatusChange();

        this.router.events.subscribe(event =>
        {
            if (event instanceof NavigationEnd)
            {
                let activatedRoute = this.activatedRoute.firstChild;
                while (!activatedRoute)
                {
                    activatedRoute = activatedRoute.firstChild;
                }

                let authToken: string = this.activatedRoute.snapshot.queryParamMap.get("token");
                let error: string = this.activatedRoute.snapshot.queryParamMap.get("error");

                if (authToken || error)
                {
                    const queryParams = {};
                    this.router.navigate([], {queryParams, replaceUrl: true, relativeTo: this.activatedRoute});
                }

                if (error)
                {
                    this.notification.error(error.toString(), 'Error');
                }
                else if (authToken && authToken.length > 0)
                {
                    this.authService.getUserDataByAuthToken(authToken); // refresh published, saved, favourite templates after user data is fetched
                }
                else
                {
                    this.authService.getUserDataByAuthTokenInLocalStorage(); // refresh published, saved, favourite templates after user data is fetched
                }
            }
        });
    }

    ngAfterViewInit()
    {
        this.searchEngineOptimizationService.initService(this.renderer);
    }

    private subscribeToNetworkStatusChange()
    {
        window.addEventListener("online", () =>
        {
            this.notificationService.showNetworkConnectedSuccessMessage();
        });

        window.addEventListener("offline", () =>
        {
            this.notificationService.showNetworkDisconnectedErrorMessage();
        });
    }

    private setDefaultCanvasSizeAndTypeToLocalStorage()
    {
        let canvasTypeAndSize = this.localStorageService.loadCanvasTypeAndSize();

        if (!canvasTypeAndSize)
        {
            canvasTypeAndSize = CANVAS_TYPES_AND_SIZES[0];

            this.localStorageService.saveCanvasTypeAndSize(canvasTypeAndSize);
        }
    }

    public goToHome()
    {
        this.router.navigate(['/']);
    }

    public goToTool()
    {
        this.router.navigate(['/tool']);
    }

    public goToTemplates()
    {
        this.router.navigate(['/templates']);
    }
  
    public goToAboutUs()
    {
        this.router.navigate(['/about-us']);
    }

    public goToTutorials()
    {
        this.router.navigate(['/tutorials']);
    }

    public goToHowToUse()
    {
        this.router.navigate(['/how-to-use']);
    }

    public goToTermsOfUse()
    {
        this.router.navigate(["/terms-of-use"]);
    }

    public goToPrivacyPolicy()
    {
        this.router.navigate(["/privacy-policy"]);
    }

    public goToPricing()
    {
        this.router.navigate(['/pricing']);
    }

    public goToAdminConsole()
    {
        this.router.navigate(['/admin-console']);
    }

    public goToUserStats()
    {
        this.router.navigate(['/user-stats']);
    }

    public showHowToUseDialog()
    {

    }

    public signIn()
    {
        this.dialog.open(SignInDialogComponent, {
            width: SIGN_IN_DIALOG_WIDTH + 'px',
            height: SIGN_IN_DIALOG_HEIGHT + 'px',
            maxWidth: SIGN_IN_DIALOG_WIDTH,
            maxHeight: SIGN_IN_DIALOG_HEIGHT
        })
    }

    public signOut()
    {
        this.authService.signOut();
    }

    public showProfile()
    {

    }

    toggleTheme()
    {
        this.isDarkThemeEnable = !this.isDarkThemeEnable;
        this.toolAndGridComponentService.setIsDarkThemeEnable(this.isDarkThemeEnable);
    }

    public routeToHomeAndRefresh()
    {
        let homeUrl = environment.baseUrl;
        if (document.location.href == homeUrl) return;

        if (this.canvasComponentService.isCanvasDirty())
        {
            const initialState = {
                title: 'Canvas will be reset... Are you sure?'
            };
            const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
                data: initialState,
                width: CONFIRMATION_DIALOG_WIDTH + 'px',
                height: CONFIRMATION_DIALOG_HEIGHT + 'px',
                maxWidth: CONFIRMATION_DIALOG_WIDTH,
                maxHeight: CONFIRMATION_DIALOG_HEIGHT
            });
            dialogRef.afterClosed().subscribe(result =>
            {
                if (result == "yes")
                {
                    this.router.navigate(['']);
                    this.canvasComponentService.reset();
                }
            });
        }
        else
        {
            this.router.navigate(['']);
            this.canvasComponentService.reset();
        }
    }

    private loadSavedThemeFromLocalStorage()
    {
        let theme = this.localStorageService.loadTheme();
        if (theme && theme == 'dark') this.toggleTheme();
    }

    public openSocialMediaLink(type:string)
    {
        let url = "";
        if(type == 'facebook')
        {
            url = "https://www.facebook.com/ThumbzStudio";
        }
        else if(type == 'youtube')
        {
            url = "https://www.youtube.com/@thumbzstudio/videos";
        }
        else if(type == 'instagram')
        {
            url = "https://www.instagram.com/thumbzstudio";
        }
        else
        {
            return;
        }
        window.open(url,'_blank');
    }
}
