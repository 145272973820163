import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    public isScreenIsSmall = false;

    constructor(public matIconRegistry: MatIconRegistry,
                public domSanitizer: DomSanitizer)
    {}

    public validateForm(formGroup: FormGroup)
    {
        Object.keys(formGroup.controls).forEach(field =>
        {
            const control = formGroup.get(field);
            if (control instanceof FormControl)
            {
                control.markAsDirty({onlySelf: true});
            }
            else if (control instanceof FormGroup)
            {
                this.validateForm(control);
            }
        });
    };

    public formatSliderValue100(value: number): string
    {
        return value + "%";
    }

    public formatSliderValuePx(value: number): string
    {
        return value + "px";
    }

    public formatSliderValueDeg(value: number): string
    {
        return value + "deg";
    }

    public registerSvgIcons()
    {
        this.matIconRegistry.addSvgIcon("svg-icon-welcome-page-templates-backward", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/welcome-page-templates-backward.svg"),);
        this.matIconRegistry.addSvgIcon("svg-icon-welcome-page-templates-auto-refresh", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/welcome-page-templates-auto-refresh.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-welcome-page-templates-forward", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/welcome-page-templates-forward.svg"));

        this.matIconRegistry.addSvgIcon("svg-icon-reset", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/action-section-reset.svg"),);
        this.matIconRegistry.addSvgIcon("svg-icon-undo", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/action-section-undo.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-redo", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/action-section-redo.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-preview", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/action-section-preview.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-download", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/action-section-download.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-publish", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/action-section-publish.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-save", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/action-section-save.svg"));

        this.matIconRegistry.addSvgIcon("svg-icon-add-image", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/add-new-section-add-image.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-add-text", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/add-new-section-add-text.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-add-clipart", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/add-new-section-add-image.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-add-shape", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/add-new-section-add-shape.svg"));

        this.matIconRegistry.addSvgIcon("svg-icon-left-align", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-left-align.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-center-align", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-center-align.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-right-align", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-right-align.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-justify", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-justify.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-bold", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-bold.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-italic", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-italic.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-underline", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-underline.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-overline", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-overline.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-strikethrough", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-strikethrough.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-all-capital", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-all-capital.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-all-simple", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-all-simple.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-superscript", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-superscript.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-subscript", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-subscript.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-reset-superscript-subscript", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/font-section-reset-superscript-subscript.svg"));

        this.matIconRegistry.addSvgIcon("svg-icon-move-up", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/layer-move-up.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-move-down", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/layer-move-down.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-duplicate", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/layer-duplicate.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-lock", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/layer-lock.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-delete", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/layer-delete.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-hide", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/layer-hide.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-unhide", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/layer-unhide.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-search", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/layer-search.svg"));

        this.matIconRegistry.addSvgIcon("svg-icon-download-count", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/grid-download-count.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-view-count", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/grid-view-count.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-like-count", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/grid-like-count.svg"));

        this.matIconRegistry.addSvgIcon("svg-icon-google-login", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/images/signin-google-icon.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-facebook-login", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/images/signin-facebook-icon.svg"));

        this.matIconRegistry.addSvgIcon("svg-icon-social-media-link-facebook", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/social-media-link-facebook.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-social-media-link-instagram", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/social-media-link-instagram.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-social-media-link-linkedin", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/social-media-link-linkedin.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-social-media-link-tiktok", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/social-media-link-tiktok.svg"));
        this.matIconRegistry.addSvgIcon("svg-icon-social-media-link-youtube", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg-icons/social-media-link-youtube.svg"));
    }
}
