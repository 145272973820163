import {Component, OnInit} from '@angular/core';
import {UserStatsDownloadedImage} from "../../models/user-stats-downloaded-image";
import {UserStatsService} from "../../services/user-stats.service";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {UserStatsCounts} from "../../models/user-stats-counts";

@Component({
    selector: 'app-user-stats',
    templateUrl: './user-stats.component.html',
    styleUrls: ['./user-stats.component.scss']
})
export class UserStatsComponent implements OnInit {

    public dateRanges = [
        {
            label: "Today", value: "TODAY"
        },
        {
            label: "Last Week", value: "LAST_WEEK"
        },
        {
            label: "Last Month", value: "LAST_MONTH"
        },
        {
            label: "Last 3 Months", value: "LAST_3_MONTHS"
        },
        {
            label: "Last 6 Months", value: "LAST_6_MONTHS"
        },
        {
            label: "Last Year", value: "LAST_YEAR"
        },
        {
            label: "All", value: "ALL"
        }
    ];

    public selectedDateRange = this.dateRanges[6];
    public downloadedImages: UserStatsDownloadedImage[] = [];
    public userStatsCounts: UserStatsCounts[] = [];

    constructor(public authService: AuthService,
                public router: Router,
                public userStatsService: UserStatsService) {}

    ngOnInit(): void
    {
        if (this.authService.getCurrentUser() && this.authService.getCurrentUserType() == 'ADMIN')
        {
            this.getUserStatsCounts();
            this.getAllDownloadedImages();
        }
        else
        {
            this.router.navigate(['']);
        }
    }

    public dateRangeChanged(dateRange: {label:string, value:string})
    {
        this.selectedDateRange = dateRange;
        this.getUserStatsCounts();
        this.getAllDownloadedImages();
    }

    public getAllDownloadedImages()
    {
        this.userStatsService.getAllDownloadedImages(this.selectedDateRange.value).subscribe((res: UserStatsDownloadedImage[]) =>
        {
            this.downloadedImages = res.reverse();
        }, (error) =>
        {
            console.error(error);
        })
    }

    private getUserStatsCounts()
    {
        this.userStatsService.getUserStatsCounts(this.selectedDateRange.value).subscribe((res: UserStatsCounts[]) =>
        {
            this.userStatsCounts = res;

        }, (error) =>
        {
            console.error(error);
        })
    }
}
