import {Injectable} from '@angular/core';
import {CanvasComponentService} from "./component-service/canvas-component.service";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor(public canvasComponentService: CanvasComponentService)
    {
    }

    public saveAuthToken(authToken: string)
    {
        localStorage.setItem('auth-token', authToken);
    }

    public loadAuthToken()
    {
        let authToken = localStorage.getItem('auth-token');
        return authToken;
    }

    public saveTheme(theme: string)
    {
        localStorage.setItem('theme', theme);
    }

    public loadTheme()
    {
        let theme = localStorage.getItem('theme');
        return theme;
    }

    public saveCanvasTypeAndSize(canvasTypeAndSize: {})
    {
        localStorage.setItem('canvas-type-and-size', JSON.stringify(canvasTypeAndSize));
    }

    public loadCanvasTypeAndSize()
    {
        let canvasTypeAndSize = localStorage.getItem('canvas-type-and-size');
        return canvasTypeAndSize ? JSON.parse(canvasTypeAndSize) : null;
    }
}
