import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
    selector: '[appDecimal]', //usage : [appDecimal] = "<number of decimal places>"
    providers: [{provide: NG_VALIDATORS, useExisting: DecimalDirective, multi: true}]
})
export class DecimalDirective implements Validator {

    @Input('appDecimal') decimalPlaces: number;
    @Input('allowZero') allowZero: boolean;

    constructor(
        private el: ElementRef
    )
    { }

    validate(control: AbstractControl): ValidationErrors | null // gives control.errors.valueZero for value 0
    {
        if (this.allowZero) return null;
        if (control.value == null)
        {
            return null;
        }
        else if (control.value == "")
        {
            control.setValue(null);
            return null;
        }
        else if (isNaN(parseFloat(control.value)) || parseFloat(control.value) == 0)
        {
            return {valueZero: true};
        }
        else
        {
            return null;
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent)
    {

        let stringToGoIntoTheRegex = this.decimalPlaces > 0 ? "\^\\d\*\\.\?\\d\{0," + this.decimalPlaces + "\}\$" : "\^\\d\*\$";

        let regex: RegExp = new RegExp(stringToGoIntoTheRegex, "g"); //    /^\d*\.?\d{0,2}$/g
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del',
                                          'Delete'];
        let ctrlKeys: Array<string> = ['c', 'C', 'v', 'V', 'a', 'A'];

        //Allow c,v,a Keys with Ctrl and Meta
        if ((event.metaKey == true || event.ctrlKey == true) && ctrlKeys.indexOf(event.key) >= 0)
        {
            return true;
        }
        // Allow Special Keys
        if (specialKeys.indexOf(event.key) >= 0)
        {
            return true;
        }

        let current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key,
                              current.slice(position)].join('');

        if (next && !String(next).match(regex))
        {
            event.preventDefault();
        }
    }
}

