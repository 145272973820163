<div class="tool-section" [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <div class="header">
        <label class="header-text">LAYERS</label>
    </div>

    <div class="scroll-area tool-section-overlay">
        <div cdkDropList class="layer-list d-flex flex-column" (cdkDropListDropped)="drop($event)">
            <div class="first-level-item" *ngFor="let layer of layers?.slice()?.reverse(); index as i" cdkDrag (click)="canvasComponentService.clearAndSelect(layer, !($event['ctrlKey']||$event['metaKey']));">

                <!-- Normal Objects -->
                <div [ngClass]="{'layer-selected':selectedLayers.indexOf(i)>=0}" class="layer row" *ngIf="layer.type != 'group' && !layer.excludeFromLayers">
                    <div class="layer-image col-2 d-flex align-items-center">
                        <img src="{{layer.image}}" class="img-fluid" alt="">
                    </div>
                    <div class="layer-text col-4 d-flex  align-items-center">
                        <input type="text" [readOnly]="!layer['isTextEditable']" [(ngModel)]="layer.text" [ngClass]="{'layer-text-editable':layer['isTextEditable']}" (dblclick)="makeTextEditable(layer, true)" (blur)="makeTextEditable(layer, false)"/>
                    </div>
                    <div class="layer-buttons col-6 d-flex justify-content-end align-items-center">
                        <div class="layer-button-div move-down-btn-div d-flex justify-content-end align-items-center">
                            <mat-button-toggle [disabled]="i==layers?.slice()?.length-1" class="layer-btn move-down-btn" aria-label="move-down" (click)="moveDownLayer(i)" matTooltip="move down" [matTooltipPosition]="'above'">
                                <mat-icon class="layer-image" svgIcon="svg-icon-move-down" aria-hidden="false"></mat-icon>
                            </mat-button-toggle>
                        </div>
                        <div class="layer-button-div move-up-btn-div d-flex justify-content-end align-items-center">
                            <mat-button-toggle [disabled]="i==0" class="layer-btn move-up-btn" aria-label="move-up" (change)="moveUpLayer(i)" matTooltip="move up" [matTooltipPosition]="'above'">
                                <mat-icon class="layer-image" svgIcon="svg-icon-move-up" aria-hidden="false"></mat-icon>
                            </mat-button-toggle>
                        </div>
                        <div class="layer-button-div duplicate-btn-div d-flex justify-content-end align-items-center">
                            <mat-button-toggle class="layer-btn duplicate-btn" [checked]="false" aria-label="duplicate" (click)="duplicateLayer(i)" matTooltip="create duplicate" [matTooltipPosition]="'above'">
                                <mat-icon class="layer-image" svgIcon="svg-icon-duplicate" aria-hidden="false"></mat-icon>
                            </mat-button-toggle>
                        </div>
                        <div class="layer-button-div lock-btn-div d-flex justify-content-end align-items-center">
                            <mat-button-toggle class="layer-btn lock-btn" aria-label="lock" (change)="lockObject(layer, !$event.source['_checked'])" matTooltip="layer lock" [matTooltipPosition]="'above'">
                                <mat-icon class="layer-image" svgIcon="svg-icon-lock" aria-hidden="false"></mat-icon>
                            </mat-button-toggle>
                        </div>
                        <div class="layer-button-div delete-btn-div d-flex justify-content-end align-items-center">
                            <mat-button-toggle class="layer-btn delete-btn" aria-label="delete" (click)="deleteLayer(i)" matTooltip="layer delete" [matTooltipPosition]="'above'">
                                <mat-icon class="layer-image" svgIcon="svg-icon-delete" aria-hidden="false"></mat-icon>
                            </mat-button-toggle>
                        </div>
                    </div>
                </div>

                <!-- Group Objects -->
                <div (click)="toggleGroup(layer, $event)" [ngClass]="{'layer-selected':selectedLayers.indexOf(i)>=0}" class="group-layer row" *ngIf="layer.type == 'group'">

                    <div class="layer-image col-2 d-flex align-items-center">
                        <img src="{{layer.image}}" class="img-fluid" alt="">
                    </div>
                    <div class="layer-text col-4 d-flex  align-items-center">
                        <input type="text" [readOnly]="!layer['isTextEditable']" [(ngModel)]="layer.text" [ngClass]="{'layer-text-editable':layer['isTextEditable']}" (dblclick)="makeTextEditable(layer, true)" (blur)="makeTextEditable(layer, false)"/>
                    </div>

                    <div class="layer-buttons col-6 d-flex justify-content-end align-items-center">
                        <div class="layer-button-div move-down-btn-div d-flex justify-content-end align-items-center">
                            <mat-button-toggle [disabled]="true" class="layer-btn move-down-btn" aria-label="move-down" (click)="moveDownLayer(i)" matTooltip="move down" [matTooltipPosition]="'above'">
                                <mat-icon class="layer-image" svgIcon="svg-icon-move-down" aria-hidden="false"></mat-icon>
                            </mat-button-toggle>
                        </div>
                        <div class="layer-button-div move-up-btn-div d-flex justify-content-end align-items-center">
                            <mat-button-toggle [disabled]="i==0" class="layer-btn move-up-btn" aria-label="move-up" (change)="moveUpLayer(i)" matTooltip="move up" [matTooltipPosition]="'above'">
                                <mat-icon class="layer-image" svgIcon="svg-icon-move-up" aria-hidden="false"></mat-icon>
                            </mat-button-toggle>
                        </div>
                        <div class="layer-button-div duplicate-btn-div d-flex justify-content-end align-items-center">
                            <mat-button-toggle class="layer-btn duplicate-btn" [checked]="false" aria-label="duplicate" (click)="duplicateLayer(i)" matTooltip="create duplicate" [matTooltipPosition]="'above'">
                                <mat-icon class="layer-image" svgIcon="svg-icon-duplicate" aria-hidden="false"></mat-icon>
                            </mat-button-toggle>
                        </div>
                        <div class="layer-button-div lock-btn-div d-flex justify-content-end align-items-center">
                            <mat-button-toggle class="layer-btn lock-btn" aria-label="lock" (change)="lockObject(layer, !$event.source['_checked'])" matTooltip="layer lock" [matTooltipPosition]="'above'">
                                <mat-icon class="layer-image" svgIcon="svg-icon-lock" aria-hidden="false"></mat-icon>
                            </mat-button-toggle>
                        </div>
                        <div class="layer-button-div delete-btn-div d-flex justify-content-end align-items-center">
                            <mat-button-toggle class="layer-btn delete-btn" aria-label="delete" (click)="deleteLayer(i)" matTooltip="layer delete" [matTooltipPosition]="'above'">
                                <mat-icon class="layer-image" svgIcon="svg-icon-delete" aria-hidden="false"></mat-icon>
                            </mat-button-toggle>
                        </div>
                    </div>
                </div>

                <!-- 2nd Level -->
                <div *ngIf="layer.type == 'group' && layer.groupExapnded">
                    <div class="second-level-item" *ngFor="let child of layer.getObjects().slice()?.reverse()">

                        <!-- Normal Objects -->
                        <div class="child-layer row" *ngIf="child.type != 'group'">
                            <div class="child-image col-2 d-flex align-items-center">
                                <img src="{{child.image}}" class="img-fluid" alt="">
                            </div>
                            <div class="child-text col-10 d-flex  align-items-center">
                                <input type="text" [readOnly]="true" [(ngModel)]="child.text"/>
                            </div>
                        </div>

                        <!-- Group Objects -->
                        <div (click)="toggleGroup(child, $event)" class="child-group-layer row" *ngIf="child.type == 'group'">
                            <div class="child-image col-2 d-flex align-items-center">
                                <img src="{{child.image}}" class="img-fluid" alt="">
                            </div>
                            <div class="child-text col-10 d-flex  align-items-center">
                                <input type="text" [readOnly]="true" [(ngModel)]="child.text"/>
                            </div>
                        </div>

                        <!-- 3rd Level -->
                        <div *ngIf="child.type == 'group' && child.groupExapnded">
                            <div class="third-level-item" *ngFor="let grandChild of child.getObjects().slice()?.reverse()">

                                <!-- All Objects -->
                                <div class="child-layer row">
                                    <div class="child-image col-2 d-flex align-items-center">
                                        <img src="{{grandChild.image}}" class="img-fluid" alt="">
                                    </div>
                                    <div class="child-text col-10 d-flex  align-items-center">
                                        <input type="text" [readOnly]="true" [(ngModel)]="grandChild.text"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
