import {Component, OnInit} from '@angular/core';
import {CANVAS_TYPES_AND_SIZES} from "../../util/app-consts";
import {Template} from "../../models/template";
import {TemplateService} from "../../services/temaplate.service";
import {environment} from "../../../environments/environment";
import {SearchEngineOptimizationService} from "../../services/search-engine-optimization.service";

@Component({
	selector: 'app-templates',
	templateUrl: './templates.component.html',
	styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit{

	public templates: Template[] = [];
	public setIndex = 0;
	public canvasTypes = CANVAS_TYPES_AND_SIZES;
	public selectedCanvasType = CANVAS_TYPES_AND_SIZES[0];
	public isProgressSpinnerVisible: boolean = false;

	constructor(public templateService: TemplateService,
	            public searchEngineOptimizationService: SearchEngineOptimizationService)
	{}

	public ngOnInit(): void
	{
		this.loadTemplates();
		this.searchEngineOptimizationService.updatePageTitle('Templates - ThumbzStudio');
		this.searchEngineOptimizationService.updateMetaDescription('Browse free pre-designed templates and load for easy editing and downloading with ThumbzStudio Templates...');
	}

	public loadTemplates()
	{
		this.isProgressSpinnerVisible = true;
		this.templateService.getAllGeneralTemplatesByCanvasType(this.selectedCanvasType.type, this.setIndex).subscribe((res: Template[]) =>
		{
			this.templates.push(...res);
			this.isProgressSpinnerVisible = false;
		}, (error =>
		{
			console.warn(error);
			this.isProgressSpinnerVisible = false;
		}));
	}

	public canvasTypeChanged(canvasType:any) {
		this.selectedCanvasType  = canvasType;
		this.setIndex = 0;
		this.templates = [];

		this.loadTemplates();
	}

	public loadMore()
	{
		this.setIndex++;
		this.loadTemplates();
	}

	public showPreview(template:Template){
		window.open(environment.baseUrl + 'template-preview/' + template.id, "_blank");
	}


}
