import {Injectable} from '@angular/core';
import {LayersComponent} from '../../pages/tool-and-grid/tool/layers/layers.component';

@Injectable({
    providedIn: 'root'
})
export class LayerComponentService {

    public layerComponent: LayersComponent;

    constructor() { }

    public addLayerData(object, name, type): void
    {
        if (!this.layerComponent || !object || !name || !type) return;
        this.layerComponent.addLayerData(object, name, type);
    }

    public selectLayers(indexes: number[]): void
    {
        if (!this.layerComponent) return;

        this.layerComponent.selectLayer(-1);
        indexes.forEach((index) =>
        {
            this.layerComponent.selectLayer(index, false);
        })
    }

    public selectLayer(index: number, clearAndSelect: boolean = true)
    {
        if (!this.layerComponent) return;
        this.layerComponent.selectLayer(index, clearAndSelect);
    }

    public refreshLayers(): void
    {
        this.layerComponent.refreshLayers();
    }
}
