<div class="modal-header">
    <label class="modal-title pull-left">{{ title }}</label>
    <button type="button" class="close pull-right" aria-label="Close" (click)="dialogRef.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-footer">
    <mat-dialog-actions>
        <button *ngIf="yesBtnVisible" class="btn btn-primary btn-sm btn-ok" mat-button [mat-dialog-close]="'yes'" cdkFocusInitial>{{ yesBtnName }}</button>
        <button *ngIf="noBtnVisible" class="btn btn-primary btn-sm btn-cancel" mat-button [mat-dialog-close]="'no'">{{ noBtnName }}</button>
    </mat-dialog-actions>
</div>
