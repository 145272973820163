export class Template {

    public id: string;
    public ownerId: number;
    public fabricTemplateId: number;
    public fabricTemplate: string; // original fabric template
    public fabricTemplateUrl: string;
    public originalTemplate: string;
    public name: string;
    public description: string;
    public category: any; // sends Category object while save and publish, all other places use category name
    public tags: string;
    public dialogPreviewUrl: string;
    public gridPreviewUrl: string;
    public dialogPreviewBase64Url: string;
    public gridPreviewBase64Url: string;
    public shareableLink: string;
    public isSaved: boolean;
    public isPublished: boolean;
    public isPremium: boolean;
    public isApproved: boolean;
    public price: number;
    public viewCount: number;
    public downloadCount: number;
    public likeCount: number;
    public isViewed: boolean;
    public isDownloaded: boolean;
    public isLiked: boolean;
    public isFavourite: boolean;
    public isPurchased: boolean;
    public canvasWidth: number;
    public canvasHeight: number;
    public canvasType: string;
    public createdDate: string;

}
