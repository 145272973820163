import {Injectable} from '@angular/core';
import {ConfirmationBoxComponent} from "../pages/common-components/confirmation-box/confirmation-box.component";
import {
    CONFIRMATION_DIALOG_HEIGHT,
    CONFIRMATION_DIALOG_WIDTH,
    MESSAGE_BAR_MESSAGE_ASK_TO_LOGIN,
    MESSAGE_BAR_MESSAGE_NETWORK_CONNECTED,
    MESSAGE_BAR_MESSAGE_NETWORK_DISCONNECTED,
    MESSAGE_BAR_TYPE_ERROR_MESSAGES,
    MESSAGE_BAR_TYPE_SUCCESS_MESSAGES,
    MESSAGE_BAR_TYPE_WARNING_MESSAGES
} from "../util/app-consts";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public showMessageBar = false;
    public messageBarText = "";
    public messageBarType = "";

    constructor(public dialog: MatDialog) { }

    public alert(title)
    {
        const initialState = {
            title: title,
            yesBtnName: "OK",
            noBtnVisible: false
        };
        this.dialog.open(ConfirmationBoxComponent, {
            data: initialState,
            width: CONFIRMATION_DIALOG_WIDTH + 'px',
            height: CONFIRMATION_DIALOG_HEIGHT + 'px',
            maxWidth: CONFIRMATION_DIALOG_WIDTH,
            maxHeight: CONFIRMATION_DIALOG_HEIGHT
        });
    }

    public showAskToLoginWarningMessage()
    {
        setTimeout(() =>
        {
            this.messageBarType = MESSAGE_BAR_TYPE_WARNING_MESSAGES;
            this.messageBarText = MESSAGE_BAR_MESSAGE_ASK_TO_LOGIN;
            this.showMessageBar = true;
        }, 3000)
    }

    public showNetworkDisconnectedErrorMessage()
    {
        setTimeout(() =>
        {
            this.messageBarType = MESSAGE_BAR_TYPE_ERROR_MESSAGES;
            this.messageBarText = MESSAGE_BAR_MESSAGE_NETWORK_DISCONNECTED;
            this.showMessageBar = true;
        }, 0)
    }

    public showNetworkConnectedSuccessMessage()
    {
        setTimeout(() =>
        {
            this.messageBarType = MESSAGE_BAR_TYPE_SUCCESS_MESSAGES;
            this.messageBarText = MESSAGE_BAR_MESSAGE_NETWORK_CONNECTED;
            this.showMessageBar = true;

            setTimeout(() =>
            {
                this.showMessageBar = false;
            }, 5000)
        }, 0)
    }
}
