import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "../../material.module";
import {ConfirmationBoxComponent} from "./confirmation-box/confirmation-box.component";
import {InputBoxComponent} from "./input-box/input-box.component";
import {TemplatePreviewDialogComponent} from "./template-preview-dialog/template-preview-dialog.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DirectivesModule} from "../../directives/directives.module";


@NgModule({
    declarations: [
	    ConfirmationBoxComponent,
	    InputBoxComponent,
	    TemplatePreviewDialogComponent,
    ],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        DirectivesModule,
        MaterialModule
    ]
})
export class CommonComponentsModule {}
