import {Injectable} from '@angular/core';
import {ImageComponent} from '../../pages/tool-and-grid/tool/styles/image/image.component';

@Injectable({
    providedIn: 'root'
})
export class ImageComponentService {

    public imageComponent: ImageComponent;

    constructor() { }

    public setImageSectionDisabled(state): void
    {
        if (!this.imageComponent) return;
        this.imageComponent.setImageSectionDisabled(state);
    }

    public refreshStyles()
    {
        if (!this.imageComponent) return;
        this.imageComponent.refreshStyles();
    }
}
