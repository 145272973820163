<div #dialog [appDivDisable]="loading">
    <div class="modal-header">
        <label class="modal-title pull-left">{{ title }}</label>
        <button type="button" class="close pull-right" aria-label="Close" (click)="dialogRef.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body row">
        <div class="preview col-sm-6">
            <img [src]="dialogPreviewBase64Url" alt="preview"/>
            <div class="row shareable-link-div d-flex justify-content-start">
                <span class="col-sm-3 shareable-link-lbl">Shareable Link:&nbsp; </span>
                <span *ngIf="shareableLink" class="col-sm-9 shareable-link">{{ shareableLink }}</span>
                <span *ngIf="!shareableLink" class="col-sm-9 shareable-link">~save template to generate the shareable link~</span>
            </div>
        </div>
        <div class="details col-sm-6">
            <form class="save-publish-form" [formGroup]="saveAndPublishForm" (ngSubmit)="dialogType=='publish' ? publishTemplate() : saveTemplate()">

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label name-label-div">Name:</label>
                    <div class="name-input-div col-sm-9 d-flex justify-content-start">
                        <input type="text" class="name-input form-control" formControlName="name" appMandatoryField>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label category-label-div">Category:</label>
                    <div class="col-sm-9 category-dropdown-div d-flex justify-content-start">
                        <button #categoryDropDownButton class="btn dropdown-toggle category-dropdown-button form-control" type="button" data-toggle="dropdown" appMandatoryField>
                            {{ saveAndPublishForm.controls['category'].value }}
                        </button>
                        <div class="dropdown-menu category-dropdown-menu" [ngStyle]="{'width.px': categoryDropDownMenuWidth}">
                            <a *ngFor="let category of templateCategories" (click)="categoryChanged(category)" class="dropdown-item category-dropdown-item">{{ category.name }}</a>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label tags-label-div">Tags:</label>
                    <div class="col-sm-9 tags-input-div">
                        <div class="d-flex justify-content-start">
                            <input type="text" class="form-control tags-input" [(ngModel)]="tagText" [ngModelOptions]="{standalone: true}" (keyup.enter)="addTag()">
                            <button [disabled]="tagText == '' || selectedTags.length >= 20" class="btn btn-primary btn-sm tags-add-btn btn-ok" type="button" (click)="addTag()" mat-button>Add</button>
                        </div>
                        <div class="form-group row tags-array-div d-flex align-content-start flex-wrap">
                            <div *ngFor="let tag of selectedTags; let index = index" class="tag-item-div">
                                <span class="tag-item-span">{{ tag }}</span><span aria-hidden="true" class="tag-item-close" (click)="removeTag(index)">&nbsp;&times;</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label description-label-div">Description:</label>
                    <div class="col-sm-9 description-textarea-div">
                        <textarea class="form-control description-textarea" formControlName="description"></textarea>
                    </div>
                </div>

                <!--                <div class="form-group row" *ngIf="dialogType=='publish' || template && template.isPublished && template.ownerId == authService.getCurrentUser()['id']">-->
                <!--                    <label class="col-sm-3 col-form-label price-label">Price:</label>-->
                <!--                    <div class="col-sm-9 price-input-div d-flex justify-content-start">-->
                <!--                        <input [required]="saveAndPublishForm.controls['isPremium'].value" type="text" class="form-control price-input" formControlName="price" [appDecimal]="0" [allowZero]="true" appMandatoryField>-->
                <!--                        <label class="col-sm-3 col-form-label usd-label">USD</label>-->
                <!--                        <div class="premium-chk-div form-check d-flex align-items-center">-->
                <!--                            <input class="premium-chk form-check-input" type="checkbox" formControlName="isPremium" id="premium-chk" (change)="isPremiumCheckBoxChange()">-->
                <!--                            <label class="premium-chk-lbl col-form-label form-check-label" for="premium-chk"> Premium </label>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->

            </form>
        </div>
    </div>
    <div class="modal-footer" [appDivDisable]="spinnerPosition.top == 0 || spinnerPosition.left == 0">
        <mat-dialog-actions>
            <button class="btn btn-primary btn-sm btn-cancel" mat-button [mat-dialog-close]="'cancel'">Cancel</button>
            <button class="btn btn-primary btn-sm btn-ok" [disabled]="saveAndPublishForm.invalid" type="submit" *ngIf="dialogType=='save'" mat-button (click)="saveTemplate()">Save</button>
            <button class="btn btn-primary btn-sm btn-ok" [disabled]="saveAndPublishForm.invalid" type="submit" *ngIf="dialogType=='publish'" mat-button (click)="publishTemplate()">Publish</button>
        </mat-dialog-actions>
    </div>
</div>

<div *ngIf="loading" class="progress-spinner" [ngStyle]="{'top.px': spinnerPosition.top, 'left.px': spinnerPosition.left}">
    <mat-progress-spinner class="progress-spinner" [color]="'accent'" [mode]="'indeterminate'"></mat-progress-spinner>
</div>
