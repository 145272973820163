import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Template} from '../../models/template';
import {Location} from '@angular/common';
import {TemplateService} from '../../services/temaplate.service';
import {TemplatePreviewDialogComponent} from '../common-components/template-preview-dialog/template-preview-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CanvasComponentService} from '../../services/component-service/canvas-component.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../services/auth.service';
import {ToolAndGridComponentService} from '../../services/component-service/tool-and-grid-component.service';
import {SelectCanvasSizeComponent} from "../select-canvas-size/select-canvas-size.component";
import {
    ADMIN,
    CANVAS_TYPES_AND_SIZES,
    CONFIRMATION_DIALOG_HEIGHT,
    CONFIRMATION_DIALOG_WIDTH,
    DESIGNER,
    GRID_SORT_TYPES
} from "../../util/app-consts";
import {LocalStorageService} from "../../services/local-storage.service";
import {NotificationService} from "../../services/notification.service";
import {ConfirmationBoxComponent} from "../common-components/confirmation-box/confirmation-box.component";
import {SearchEngineOptimizationService} from "../../services/search-engine-optimization.service";

@Component({
    selector: 'app-tool-and-grid',
    templateUrl: './tool-and-grid.component.html',
    styleUrls: ['./tool-and-grid.component.scss']
})
export class ToolAndGridComponent implements OnInit, AfterViewInit {

    @ViewChild('tool', {static: false}) toolElement: ElementRef;
    @ViewChild('filter_div_marker', {static: false}) filterDivMarkerElement: ElementRef;

    public readonly SEARCH_KEYUP_WAIT_TIME = 2000; //2 seconds
    public readonly MAX_PAGINATE_NUMBER = 7;
    public readonly DEFAULT_PAGE_COUNT: number = 1;
    public readonly DEFAULT_CURRENT_PAGE: number = 1;
    public readonly DEFAULT_PAGE_SIZE: number = 24;
    public readonly DEFAULT_PAGE_SIZE_INPUT_VALUE = this.DEFAULT_PAGE_SIZE;

    public searchString: string = "";
    public tabsPageCountMap: any = new Map();
    public tabsCurrentPageMap: any = new Map();
    public tabsPageSizeMap: any = new Map();
    public tabsPageSizeInputValueMap: any = new Map();

    public gridFilterPosition = {top: 0, left: 0, width: 0};
    public currentTab = "General";
    public gridVisible = false;
    public templateCategories: any[] = [];
    public sortTypes: any[] = [];
    public selectedSortType = null;
    public selectedSortDirection = 'DESC';
    private waitingForSearch = false;

    public generalTemplates: Template[] = [];
    public publishedTemplates: Template[] = [];
    public savedTemplates: Template[] = [];
    public purchasedTemplates: Template[] = [];
    public favouriteTemplates: Template[] = [];

    constructor(public templateService: TemplateService,
                public canvasComponentService: CanvasComponentService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public dialog: MatDialog,
                public router: Router,
                public location: Location,
                public notification: ToastrService,
                public activatedRoute: ActivatedRoute,
                public authService: AuthService,
                public localStorageService: LocalStorageService,
                public searchEngineOptimizationService: SearchEngineOptimizationService,
                public notificationService: NotificationService)
    {
        this.toolAndGridComponentService.toolAndGridComponent = this;
    }

    ngOnInit()
    {
        this.getTemplateCategories();
        this.getSortTypes();
        this.initTabPageData();

        this.searchEngineOptimizationService.updatePageTitle('Tool - ThumbzStudio');
        this.searchEngineOptimizationService.updateMetaDescription('Unlock effortless thumbnail enhancements with ThumbzStudio\'s Tool. Elevate your visuals like a pro in seconds...');
    }

    ngAfterViewInit()
    {
        this.loadTemplateByShareableCodeOrCanvasDraft();
        this.loadGridByQueryParams();

        setTimeout(() =>
        {
            this.setSpinnerPosition();
            this.setFilterPosition();
        }, 0);
    }

    private loadTemplateByShareableCodeOrCanvasDraft()
    {
        let shareableCode = this.activatedRoute.snapshot.paramMap.get("shareable-code");

        setTimeout(() =>
        {
            if (shareableCode != null && shareableCode.length == 36)
            {
                this.loadExistingTemplateByShareableCode(shareableCode);
            }
            else
            {
                this.loadCanvasTypeAndSize();

                this.canvasComponentService.loadCanvasDraft().then((fabricTemplate) =>
                {
                    if (fabricTemplate && ((fabricTemplate['background']) || (fabricTemplate['objects'] && fabricTemplate['objects'].length > 0)))
                    {
                        const initialState = {
                            title: 'An unsaved canvas draft found.. Do you want to load it?'
                        };
                        const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
                            data: initialState,
                            width: CONFIRMATION_DIALOG_WIDTH + 'px',
                            height: CONFIRMATION_DIALOG_HEIGHT + 'px',
                            maxWidth: CONFIRMATION_DIALOG_WIDTH,
                            maxHeight: CONFIRMATION_DIALOG_HEIGHT
                        });
                        dialogRef.afterClosed().subscribe(result =>
                        {
                            if (result == "yes")
                            {
                                this.canvasComponentService.loadCanvasByFabricTemplate(fabricTemplate).then((loaded: boolean) =>
                                {
                                    if (loaded)
                                    {
                                        this.notification.success("Template loaded...", 'Success');
                                    }
                                    else
                                    {
                                        this.notification.error("Fabric template error... Template not loaded...", 'Error');
                                    }
                                }).catch((error) =>
                                {
                                    this.notification.error("Template not loaded... Please login and try again...", 'Error');
                                });
                            }
                            else
                            {
                                this.canvasComponentService.clearCanvasDraft();
                            }
                        });
                    }
                }).catch((error) =>
                {
                    console.warn(error);
                });
            }
        }, 0);
    }

    private loadGridByQueryParams()
    {
        let canvasType: string = this.activatedRoute.snapshot.queryParamMap.get("canvas-type");
        let gridSortBy: string = this.activatedRoute.snapshot.queryParamMap.get("grid-sort-by");
        let gridSortDirection: string = this.activatedRoute.snapshot.queryParamMap.get("grid-sort-direction");
        let gridSearchText: string = this.activatedRoute.snapshot.queryParamMap.get("grid-search-text");
        let gridPageSize: string = this.activatedRoute.snapshot.queryParamMap.get("grid-page-size");
        let gridCurrentPage: string = this.activatedRoute.snapshot.queryParamMap.get("grid-current-page");

        if (canvasType == null && gridSortBy == null && gridSortDirection == null && gridSearchText == null && gridPageSize == null && gridCurrentPage == null) return;

        setTimeout(() =>
        {
            if (canvasType != null && canvasType.length > 0)
            {
                for (let canvasTypeAndSize of CANVAS_TYPES_AND_SIZES)
                {
                    if (canvasTypeAndSize.text.toLowerCase().includes(canvasType))
                    {
                        this.templateService.setCurrentCanvasTypeAndSize(canvasTypeAndSize)
                        break;
                    }
                }
            }

            if (gridSortBy != null && gridSortBy.length > 0)
            {
                for (let sortType of this.sortTypes)
                {
                    if (sortType['text'].toLowerCase() == gridSortBy.toLowerCase())
                    {
                        this.selectedSortType = sortType;
                        break;
                    }
                }
            }

            if (gridSortDirection != null && gridSortDirection.length > 0)
            {
                if (gridSortDirection == 'ascending')
                {
                    this.selectedSortDirection = 'ASC';
                }
                else if (gridSortDirection == 'descending')
                {
                    this.selectedSortDirection = 'DESC';
                }
            }

            if (gridSearchText != null && gridSearchText.length > 0)
            {
                this.searchString = gridSearchText;
            }

            if (gridPageSize != null && !isNaN(+gridPageSize))
            {
                this.tabsPageSizeMap[this.currentTab] = +gridPageSize;
                this.tabsPageSizeInputValueMap[this.currentTab] = +gridPageSize;
            }

            if (gridCurrentPage != null && !isNaN(+gridCurrentPage))
            {
                this.tabsCurrentPageMap[this.currentTab] = +gridCurrentPage;
            }

            if ((gridSortBy != null && gridSortBy.length > 0) || (gridSortDirection != null && gridSortDirection.length > 0) || (gridSearchText != null && gridSearchText.length > 0) || (gridPageSize != null && !isNaN(+gridPageSize)) || (gridCurrentPage != null && !isNaN(+gridCurrentPage)))
            {
                this.refreshAllTemplates();
            }

        }, 0)
    }

    public gridTabChanged(currentTab)
    {
        this.currentTab = currentTab;
        this.searchString = "";

        if (currentTab != 'General')
        {
            this.sortTypes = this.sortTypes.slice(0, 2);
            this.selectedSortType = this.sortTypes[1];
        }
        else
        {
            this.sortTypes = GRID_SORT_TYPES;
        }
    }

    public refreshAllTemplates()
    {
        setTimeout(() =>
        {
            this.getAllGeneralTemplates();
        }, 0);

        setTimeout(() =>
        {
            this.getAllPublishedTemplates();
        }, 0);

        setTimeout(() =>
        {
            this.getAllSavedTemplates();
        }, 0);

        setTimeout(() =>
        {
            this.getAllPurchasedTemplates();
        }, 0);

        setTimeout(() =>
        {
            this.getAllFavouriteTemplates();
        }, 0);
    }

    public getAllGeneralTemplates()
    {
        this.gridVisible = false;
        let data = this.getRequestDataForTab('General');
        this.templateService.getAllGeneralTemplates(data).subscribe((res: Template[]) =>
        {
            this.tabsPageCountMap['General'] = res['totalPages'];
            this.generalTemplates = res['content'];
            this.gridVisible = true;
        }, (error =>
        {
            console.warn(error);
        }));
    }

    public getAllPublishedTemplates()
    {
        if (this.authService.isUserLoggedIn() == false) return;

        let data = this.getRequestDataForTab('Published');
        this.templateService.getAllPublishedTemplates(data).subscribe((res: Template[]) =>
        {
            this.tabsPageCountMap['Published'] = res['totalPages'];
            this.publishedTemplates = res['content'];
        }, (error =>
        {
            console.warn(error)
        }));
    }

    public getAllSavedTemplates()
    {
        if (this.authService.isUserLoggedIn() == false) return;

        let data = this.getRequestDataForTab('Saved');
        this.templateService.getAllSavedTemplates(data).subscribe((res: Template[]) =>
        {
            this.tabsPageCountMap['Saved'] = res['totalPages'];
            this.savedTemplates = res['content'];
        }, (error =>
        {
            console.warn(error)
        }));
    }

    public getAllPurchasedTemplates()
    {
        if (this.authService.isUserLoggedIn() == false) return;

        let data = this.getRequestDataForTab('Purchased');
        this.templateService.getAllPurchasedTemplates(data).subscribe((res: Template[]) =>
        {
            this.tabsPageCountMap['Purchased'] = res['totalPages'];
            this.purchasedTemplates = res['content'];
        }, (error =>
        {
            console.warn(error)
        }));
    }

    public getAllFavouriteTemplates()
    {
        if (this.authService.isUserLoggedIn() == false) return;

        let data = this.getRequestDataForTab('Favourite');
        this.templateService.getAllFavouriteTemplates(data).subscribe((res: Template[]) =>
        {
            this.tabsPageCountMap['Favourite'] = res['totalPages'];
            this.favouriteTemplates = res['content'];
        }, (error =>
        {
            console.warn(error)
        }));
    }

    private getRequestDataForTab(tab: string)
    {
        let params = {};
        if (this.tabsCurrentPageMap[tab]) params['currentPage'] = this.tabsCurrentPageMap[tab] - 1;
        if (this.tabsPageSizeMap[tab]) params['pageSize'] = this.tabsPageSizeMap[tab];
        if (this.selectedSortType) params['sortType'] = this.selectedSortType?.value;
        if (this.selectedSortDirection) params['sortDirection'] = this.selectedSortDirection;
        if (this.templateCategories) params['categories'] = this.templateCategories.filter(category => category.selected);
        params['search'] = this.searchString;

        return params;
    }

    private getTemplateCategories()
    {
        this.templateService.getTemplateCategories().subscribe((res: any[]) =>
        {
            this.templateCategories = res;
        });
    }

    private loadCanvasTypeAndSize()
    {
        let canvasTypeAndSize = this.localStorageService.loadCanvasTypeAndSize();

        if (!canvasTypeAndSize)
        {
            this.openSelectCanvasSizeComponent(true);
        }
        else
        {
            this.templateService.setCurrentCanvasTypeAndSize(canvasTypeAndSize);
            this.canvasComponentService.setCanvasTypeAndSize(canvasTypeAndSize['type'], canvasTypeAndSize['size']);
            setTimeout(() =>
            {
                this.refreshAllTemplates();
            }, 0);
        }
    }

    public openSelectCanvasSizeComponent(isMandatory = false)
    {
        let dialogRef = this.dialog.open(SelectCanvasSizeComponent);

        dialogRef.disableClose = isMandatory;
        dialogRef.afterClosed().subscribe((result: string) =>
        {
            if (result != 'load') return;

            setTimeout(() =>
            {
                this.initTabPageData();
                this.refreshAllTemplates();
            }, 0);

            setTimeout(() =>
            {
                window.scrollTo(0, window.outerHeight - 80);
            }, 500);
        });
    }

    private getSortTypes()
    {
        this.sortTypes = GRID_SORT_TYPES;
        this.selectedSortType = this.sortTypes[1];
    }

    public showPreview(template: Template)
    {
        let buttons = [];

        if ((this.authService.getCurrentUserType() == ADMIN || this.authService.getCurrentUserType() == DESIGNER) && (this.currentTab == 'General' || this.currentTab == 'Published' || this.currentTab == 'Saved'))
        {
            buttons.push("CreateDuplicate");
        }

        if (this.currentTab == 'General' && this.authService.getCurrentUserType() == ADMIN)
        {
            buttons.push('UnPublish');
        }

        if (this.currentTab == 'Published')
        {
            if (template.isPublished) buttons.push('UnPublish');
        }

        if (this.currentTab == 'Saved')
        {
            if (template.isSaved) buttons.push('RemoveFromSave');
        }

        if (this.currentTab == 'Favourite')
        {
            buttons.push('RemoveFromFavourite');
        }
        else
        {
            if (!template.isFavourite) buttons.push('AddToFavourite');
        }

        buttons.push("Load");

        let dialogPreviewUrl = template.dialogPreviewUrl;
        const initialState = {
            template: template,
            dialogPreviewBase64Url: dialogPreviewUrl,
            buttons: buttons
        };
        let dialogRef = this.dialog.open(TemplatePreviewDialogComponent, {data: initialState});
        dialogRef.afterClosed().subscribe((result: string) =>
        {
            if (result != null && result == "load-template")
            {
                this.loadTemplateToCanvas(template);
                window.scrollTo(0, 0);
            }
        });
    }

    public categoryChanged(category)
    {
        category.selected = !category.selected;
        this.refreshAllTemplates();
    }

    public sortChanged(sortType)
    {
        this.selectedSortType = sortType;

        if (this.selectedSortType.text == 'NAME')
        {
            this.selectedSortDirection = 'ASC';
        }
        else
        {
            this.selectedSortDirection = 'DESC';
        }

        this.refreshAllTemplates();
    }

    public toggleSortDirection(checked)
    {
        if (checked)
        {
            this.selectedSortDirection = 'DESC';
        }
        else
        {
            this.selectedSortDirection = 'ASC';
        }

        this.refreshAllTemplates();
    }

    public searchInGrid()
    {
        if (this.waitingForSearch) return;

        this.waitingForSearch = true;
        setTimeout(() =>
        {
            this.refreshCurrentTab();
            this.waitingForSearch = false;
        }, this.SEARCH_KEYUP_WAIT_TIME);
    }

    public clearSearchString()
    {
        if (this.searchString == '') return;

        this.searchString = '';
        this.refreshCurrentTab();
    }

    public gotoTop()
    {
        window.scrollTo(0, 0);
    }

    private loadExistingTemplateByShareableCode(shareableCode) // load template by shareable code
    {
        this.toolAndGridComponentService.isProgressSpinnerVisible = true;
        this.templateService.getTemplateFromShareableCode(shareableCode).subscribe((res: Template) =>
        {
            if (res)
            {
                let template: Template = res;

                let canvasType = template.canvasType;
                let canvasSize = {width: template.canvasWidth, height: template.canvasHeight};

                let loadedCanvasTypeAndSize = null;

                if (canvasType == 'custom')
                {
                    loadedCanvasTypeAndSize = {
                        text: 'Custom Size',
                        type: 'custom',
                        size: canvasSize
                    }
                }
                else
                {
                    for (let canvasTypeAndSize of CANVAS_TYPES_AND_SIZES)
                    {
                        if (canvasTypeAndSize['type'] == canvasType)
                        {
                            loadedCanvasTypeAndSize = canvasTypeAndSize;
                        }
                    }
                }

                if (loadedCanvasTypeAndSize == null) return;

                this.canvasComponentService.setCanvasTypeAndSize(loadedCanvasTypeAndSize['type'], loadedCanvasTypeAndSize['size']);

                this.localStorageService.saveCanvasTypeAndSize(loadedCanvasTypeAndSize);
                this.templateService.setCurrentCanvasTypeAndSize(loadedCanvasTypeAndSize);

                this.templateService.setCurrentTemplate(template);

                this.refreshAllTemplates();
                this.loadTemplateToCanvas(template);
            }
            else
            {
                this.toolAndGridComponentService.isProgressSpinnerVisible = false;
                this.notification.success("Incorrect Shareable Code", 'Error');
            }
        }, (error =>
        {
            this.toolAndGridComponentService.isProgressSpinnerVisible = false;
            if (error.error) this.notification.error(error.error['message'], 'Error');
        }));
    }

    private loadTemplateToCanvas(template: Template)
    {
        this.toolAndGridComponentService.progressSpinnerValue = 0;
        this.toolAndGridComponentService.progressSpinnerMode = "determinate";
        this.toolAndGridComponentService.progressSpinnerText = "Downloading the Template";

        this.toolAndGridComponentService.isProgressSpinnerVisible = true;

        this.loadCanvasByFabricTemplate(template);
    }

    private loadCanvasByFabricTemplate(template)
    {
        this.templateService.getFabricTemplate(template.fabricTemplateUrl).subscribe((fabricTemplate: any) =>
        {
            if (fabricTemplate == null) return;

            if (typeof fabricTemplate == 'string')
            {
                try
                {
                    fabricTemplate = JSON.parse(fabricTemplate);
                }
                catch (error)
                {
                    this.notification.error("Fabric template error... Template not loaded...", 'Error');
                    return;
                }
            }

            this.canvasComponentService.loadCanvasByFabricTemplate(fabricTemplate).then((loaded: boolean) =>
            {
                if (loaded)
                {
                    this.notification.success("Template loaded...", 'Success');
                }
                else
                {
                    this.notification.error("Fabric template error... Template not loaded...", 'Error');
                }
            }).catch((error) =>
            {
                this.notification.error("Template not loaded... Please login and try again...", 'Error');
            }).finally(() =>
            {
                this.toolAndGridComponentService.isProgressSpinnerVisible = false;
            });
        }, error =>
        {
            this.toolAndGridComponentService.isProgressSpinnerVisible = false;

            if (!navigator.onLine)
            {
                this.notification.warning("To load templates that you haven't loaded before, you need to go online...", "Warning");
                return;
            }

            this.notification.error("Template not loaded...", 'Error');
        })
    }

    private setSpinnerPosition() // set loading spinner position
    {
        if (!this.toolElement || !this.toolElement.nativeElement || !this.toolElement.nativeElement.getBoundingClientRect()) return;

        let height = this.toolElement.nativeElement.getBoundingClientRect().height;
        let width = this.toolElement.nativeElement.getBoundingClientRect().width;

        this.toolAndGridComponentService.progressSpinnerPosition.top = (height - 150) / 2;
        this.toolAndGridComponentService.progressSpinnerPosition.left = (width - 800) / 2;
    }

    private setFilterPosition()
    {
        if (!this.toolElement || !this.toolElement.nativeElement || !this.toolElement.nativeElement.getBoundingClientRect()) return;
        if (!this.filterDivMarkerElement || !this.filterDivMarkerElement.nativeElement || !this.filterDivMarkerElement.nativeElement.getBoundingClientRect()) return;

        this.gridFilterPosition.top = this.toolElement.nativeElement.getBoundingClientRect().height + 150;
        this.gridFilterPosition.left = this.filterDivMarkerElement.nativeElement.getBoundingClientRect().left;
        this.gridFilterPosition.width = this.filterDivMarkerElement.nativeElement.getBoundingClientRect().width;
    }

    public canvasSizeChanged(event)
    {
        this.setSpinnerPosition();
        this.setFilterPosition();
    }

    public initTabPageData()
    {
        this.tabsPageCountMap['General'] = this.DEFAULT_PAGE_COUNT;
        this.tabsPageCountMap['Published'] = this.DEFAULT_PAGE_COUNT;
        this.tabsPageCountMap['Saved'] = this.DEFAULT_PAGE_COUNT;
        this.tabsPageCountMap['Purchased'] = this.DEFAULT_PAGE_COUNT;
        this.tabsPageCountMap['Favourite'] = this.DEFAULT_PAGE_COUNT;

        this.tabsCurrentPageMap['General'] = this.DEFAULT_CURRENT_PAGE;
        this.tabsCurrentPageMap['Published'] = this.DEFAULT_CURRENT_PAGE;
        this.tabsCurrentPageMap['Saved'] = this.DEFAULT_CURRENT_PAGE;
        this.tabsCurrentPageMap['Purchased'] = this.DEFAULT_CURRENT_PAGE;
        this.tabsCurrentPageMap['Favourite'] = this.DEFAULT_CURRENT_PAGE;

        this.tabsPageSizeMap['General'] = this.DEFAULT_PAGE_SIZE;
        this.tabsPageSizeMap['Published'] = this.DEFAULT_PAGE_SIZE;
        this.tabsPageSizeMap['Saved'] = this.DEFAULT_PAGE_SIZE;
        this.tabsPageSizeMap['Purchased'] = this.DEFAULT_PAGE_SIZE;
        this.tabsPageSizeMap['Favourite'] = this.DEFAULT_PAGE_SIZE;

        this.tabsPageSizeInputValueMap['General'] = this.DEFAULT_PAGE_SIZE_INPUT_VALUE;
        this.tabsPageSizeInputValueMap['Published'] = this.DEFAULT_PAGE_SIZE_INPUT_VALUE;
        this.tabsPageSizeInputValueMap['Saved'] = this.DEFAULT_PAGE_SIZE_INPUT_VALUE;
        this.tabsPageSizeInputValueMap['Purchased'] = this.DEFAULT_PAGE_SIZE_INPUT_VALUE;
        this.tabsPageSizeInputValueMap['Favourite'] = this.DEFAULT_PAGE_SIZE_INPUT_VALUE;
    }

    public gotoFirst()
    {
        this.tabsCurrentPageMap[this.currentTab] = 1;
        this.refreshCurrentTab();

    }

    public gotoPrevious()
    {
        this.tabsCurrentPageMap[this.currentTab]--;
        this.refreshCurrentTab();
    }

    public gotoNext()
    {
        this.tabsCurrentPageMap[this.currentTab]++;
        this.refreshCurrentTab();
    }

    public gotoLast()
    {
        this.tabsCurrentPageMap[this.currentTab] = this.tabsPageCountMap[this.currentTab];
        this.refreshCurrentTab();
    }

    public gotoPage(page)
    {
        this.tabsCurrentPageMap[this.currentTab] = page;
        this.refreshCurrentTab();
    }

    public changePageSize()
    {
        this.tabsPageSizeMap[this.currentTab] = this.tabsPageSizeInputValueMap[this.currentTab];
        this.refreshCurrentTab();
    }

    public refreshCurrentTab()
    {
        if (this.currentTab == 'General')
        {
            this.getAllGeneralTemplates();
        }
        else if (this.currentTab == 'Published')
        {
            this.getAllPublishedTemplates();
        }
        else if (this.currentTab == 'Saved')
        {
            this.getAllSavedTemplates();
        }
        else if (this.currentTab == 'Purchased')
        {
            this.getAllPurchasedTemplates();
        }
        else if (this.currentTab == 'Favourite') this.getAllFavouriteTemplates();
    }

    public getPaginationSequence(): Array<number>
    {
        let array = [];
        let start;
        let end;
        if (this.tabsPageCountMap[this.currentTab] > this.MAX_PAGINATE_NUMBER)
        {
            if (this.tabsCurrentPageMap[this.currentTab] > this.tabsPageCountMap[this.currentTab] - (this.MAX_PAGINATE_NUMBER - 1) / 2)
            {
                start = this.tabsPageCountMap[this.currentTab] - (this.MAX_PAGINATE_NUMBER - 1);
            }
            else if (this.tabsCurrentPageMap[this.currentTab] > (this.MAX_PAGINATE_NUMBER - 1) / 2)
            {
                start = this.tabsCurrentPageMap[this.currentTab] - 3
            }
            else
            {
                start = 1;
            }
            end = start + this.MAX_PAGINATE_NUMBER - 1;
        }
        else
        {
            start = 1;
            end = this.tabsPageCountMap[this.currentTab];
        }

        for (let i = start; i < end + 1; i++)
        {
            array.push(i);
        }

        return array;
    }
}
