import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class CacheManagementInterceptor implements HttpInterceptor {

    constructor()
    {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const clonedRequest = request.clone({
            headers: request.headers.append('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate')
        });

        return next.handle(clonedRequest);
    }
}
