import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {CanvasComponentService} from '../../../services/component-service/canvas-component.service';
import {Template} from '../../../models/template';
import {ActionComponentService} from '../../../services/component-service/action-component.service';
import {TemplateService} from '../../../services/temaplate.service';
import {AuthService} from '../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ToolAndGridComponentService} from '../../../services/component-service/tool-and-grid-component.service';
import {FormControl, FormGroup} from '@angular/forms';
import {AdminConsoleService} from "../../../services/admin-console.service";
import {SignInDialogComponent} from "../../sign-in/sign-in-dialog.component";
import {SIGN_IN_DIALOG_HEIGHT, SIGN_IN_DIALOG_WIDTH} from "../../../util/app-consts";

@Component({
    selector: 'app-template-preview-dialog',
    templateUrl: './template-preview-dialog.component.html',
    styleUrls: ['./template-preview-dialog.component.scss']
})
export class TemplatePreviewDialogComponent implements OnInit, AfterViewInit {

    @ViewChild('dialog', {static: false}) dialogElement: ElementRef;

    public showAddToFavouriteBtn: boolean;
    public showRemoveFromFavouriteBtn: boolean;
    public showSaveBtn: boolean;
    public showCreateDuplicateBtn: boolean;
    public showRemoveFromSaveBtn: boolean;
    public showPublishBtn: boolean;
    public showUnPublishBtn: boolean;
    public showDownloadBtn: boolean;
    public showLoadBtn: boolean;
    public showApproveBtn: boolean;
    public showDeclineBtn: boolean;
    public loading: boolean = false;
    public spinnerPosition = {top: 0, left: 0};

    public selectedTags = [];
    public dialogPreviewBase64Url: string;
    public template: Template;
    private buttons: any[];

    public previewForm = new FormGroup({
        name: new FormControl({value: '', disabled: true}),
        category: new FormControl({value: '', disabled: true}),
        description: new FormControl({value: '', disabled: true}),
        isPremium: new FormControl({value: false, disabled: true}),
        price: new FormControl({value: 0, disabled: true}),
    });

    constructor(public dialogRef: MatDialogRef<TemplatePreviewDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialog: MatDialog,
                public notification: ToastrService,
                public canvasComponentService: CanvasComponentService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public actionComponentService: ActionComponentService,
                public templateService: TemplateService,
                public adminConsoleService: AdminConsoleService,
                public authService: AuthService)
    { }

    ngOnInit(): void
    {
        if (!this.data) return;

        if (this.data['dialogPreviewBase64Url']) this.dialogPreviewBase64Url = this.data['dialogPreviewBase64Url'];
        if (this.data['template']) this.template = this.data['template'];
        if (this.data['buttons']) this.buttons = this.data['buttons'];

        this.showButtons();
        this.loadTemplateDataToDialog();

        if (this.template && this.authService.getCurrentUser())
        {
            this.templateService.changViewCount(this.template.id).subscribe((template: Template) =>
            {
                this.template.viewCount = template.viewCount;
                this.template.isViewed = template.isViewed;
            })
        }
    }

    ngAfterViewInit()
    {
        if (this.toolAndGridComponentService.getIsDarkThemeEnable())
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.add("dark-theme");
        }
        else
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.remove("dark-theme");
        }
        setTimeout(() =>
        {
            this.setSpinnerPosition();
        }, 0);
    }

    private loadTemplateDataToDialog() // load template data to the dialog
    {
        if (!this.template) return;

        this.previewForm.controls['name'].patchValue(this.template.name);
        this.previewForm.controls['category'].patchValue(this.template.category);
        this.previewForm.controls['description'].patchValue(this.template.description);
        this.previewForm.controls['isPremium'].patchValue(this.template.isPremium);
        this.previewForm.controls['price'].patchValue(this.template.price);

        if (this.template.tags && this.template.tags != "")
        {
            this.selectedTags = JSON.parse(this.template.tags);
        }

        if (!this.template || !this.template.shareableLink || this.template.shareableLink == "")
        {
            this.getShareableLink();
        }
    }

    private getShareableLink()
    {
        if (!this.template) return;
        this.templateService.getShareableCodeForTemplate(this.template.id).subscribe((res: string) =>
        {
            this.template.shareableLink = res['message'];
        })
    }

    private showButtons()
    {
        this.showAddToFavouriteBtn = this.buttons.indexOf('AddToFavourite') >= 0;
        this.showRemoveFromFavouriteBtn = this.buttons.indexOf('RemoveFromFavourite') >= 0;
        this.showSaveBtn = this.buttons.indexOf('Save') >= 0;
        this.showCreateDuplicateBtn = this.buttons.indexOf('CreateDuplicate') >= 0;
        this.showRemoveFromSaveBtn = this.buttons.indexOf('RemoveFromSave') >= 0;
        this.showPublishBtn = this.buttons.indexOf('Publish') >= 0;
        this.showUnPublishBtn = this.buttons.indexOf('UnPublish') >= 0;
        this.showDownloadBtn = this.buttons.indexOf('Download') >= 0;
        this.showLoadBtn = this.buttons.indexOf('Load') >= 0;
        this.showApproveBtn = this.buttons.indexOf('Approve') >= 0;
        this.showDeclineBtn = this.buttons.indexOf('Decline') >= 0;
    }

    public addToFavourite()
    {
        if (!this.template || !this.template.id)
        {
            this.notification.error("Please Save template first", 'Error');
            return;
        }
        this.templateService.addToFavourite(this.template.id).subscribe((res) =>
        {
            this.dialogRef.close();
            this.toolAndGridComponentService.refreshAllFavouriteTemplates();

            this.notification.success(res['message'], 'Success');
        }, (error =>
        {
            if (!navigator.onLine)
            {
                this.notification.warning("Data will be synced when you back online...", "Warning");
                return;
            }

            if (error.error) this.notification.error(error.error['message'], 'Error');
        }))
    }

    public removeFromFavourite()
    {
        if (!this.template || !this.template.id) return;

        this.templateService.removeFromFavourite(this.template.id).subscribe((res) =>
        {
            this.dialogRef.close();
            this.toolAndGridComponentService.refreshAllFavouriteTemplates();

            this.notification.success(res['message'], 'Success');
        }, (error =>
        {
            if (!navigator.onLine)
            {
                this.notification.warning("Data will be synced when you back online...", "Warning");
                return;
            }

            if (error.error) this.notification.error(error.error['message'], 'Error');
        }))
    }

    public save()
    {
        this.actionComponentService.saveTemplate();
        this.dialogRef.close();
    }

    public removeFromSave()
    {
        if (!this.template || !this.template.id) return;

        this.templateService.removeTemplate(this.template.id).subscribe((res) =>
        {
            this.dialogRef.close();
            this.toolAndGridComponentService.refreshAllSavedTemplates();
            this.toolAndGridComponentService.refreshAllFavouriteTemplates();

            this.notification.success(res['message'], 'Success');
        }, (error =>
        {
            if (!navigator.onLine)
            {
                this.notification.warning("Data will be synced when you back online...", "Warning");
                return;
            }

            if (error.error) this.notification.error(error.error['message'], 'Error');
        }))
    }

    public publish()
    {
        this.actionComponentService.publishTemplate();
        this.dialogRef.close();
    }

    public unpublish()
    {
        if (!this.template || !this.template.id) return;

        this.templateService.unpublishTemplate(this.template.id).subscribe((res) =>
        {
            this.dialogRef.close();
            this.toolAndGridComponentService.refreshAllGeneralTemplates();
            this.toolAndGridComponentService.refreshAllPublishedTemplates();
            this.toolAndGridComponentService.refreshAllSavedTemplates();

            this.notification.success(res['message'], 'Success');
        }, (error =>
        {
            if (!navigator.onLine)
            {
                this.notification.warning("Data will be synced when you back online...", "Warning");
                return;
            }

            if (error.error) this.notification.error(error.error['message'], 'Error');
        }))
    }

    public download()
    {
        this.canvasComponentService.downloadThumbnail();

        if (this.template && this.template.isPublished)
        {
            if (this.authService.getCurrentUser() == null) return;

            this.templateService.changeDownloadCount(this.template.id).subscribe();
        }
    }

    public createDuplicate()
    {
        this.loading = true;
        this.templateService.createDuplicate(this.template.id).subscribe((res) =>
        {
            this.loading = false;

            this.dialogRef.close();
            this.toolAndGridComponentService.refreshAllGeneralTemplates();
            this.toolAndGridComponentService.refreshAllPublishedTemplates();
            this.toolAndGridComponentService.refreshAllSavedTemplates();

            this.notification.success(res['message'], 'Success');
        }, (error =>
        {
            this.loading = false;

            if (!navigator.onLine)
            {
                this.notification.warning("Data will be synced when you back online...", "Warning");
                return;
            }

            if (error.error) this.notification.error(error.error['message'], 'Error');
        }))
    }

    public loadTemplate()
    {
        if (!this.authService.isUserLoggedIn())
        {
            this.notification.warning("Please sign in to perform this action...", 'Warning');
            this.dialog.open(SignInDialogComponent, {
                width: SIGN_IN_DIALOG_WIDTH + 'px',
                height: SIGN_IN_DIALOG_HEIGHT + 'px',
                maxWidth: SIGN_IN_DIALOG_WIDTH,
                maxHeight: SIGN_IN_DIALOG_HEIGHT
            })

            return;
        }

        this.templateService.setCurrentTemplate(this.template);
        this.dialogRef.close('load-template');
    }

    public approveTemplate()
    {
        this.loading = true;
        this.adminConsoleService.approveTemplate(this.template.id).subscribe((res: any) =>
        {
            this.loading = false;
            this.notification.success(res['message'], 'Success');
            this.dialogRef.close();
        }, (error) =>
        {
            this.loading = false;
            if (error.error) this.notification.error(error.error['message'], 'Error');
        })
    }

    public declineTemplate()
    {
        this.loading = true;
        this.adminConsoleService.declineTemplate(this.template.id).subscribe((res: any) =>
        {
            this.loading = false;
            this.notification.success(res['message'], 'Success');
            this.dialogRef.close();
        }, (error) =>
        {
            this.loading = false;
            if (error.error) this.notification.error(error.error['message'], 'Error');
        })
    }

    public toggleLike()
    {
        if (!this.template) return;

        this.templateService.changeLikeCount(this.template.id).subscribe((template: Template) =>
        {
            this.template.likeCount = template.likeCount;
            this.template.isLiked = template.isLiked;
        });
    }

    public async copyShareableLinkToClipboard()
    {
        await navigator.clipboard.writeText(this.template.shareableLink.toString()).then(() =>
        {
            this.notification.success("shareable link is copied to the clipboard...", 'Success');
        }, (error) =>
        {
            this.notification.error("shareable link is not copied...", 'Error');
        });
    }

    public async copyTagsToClipboard()
    {
        let tagsStr = "";
        for (let i = 0; i < this.selectedTags.length; i++)
        {
            tagsStr += this.selectedTags[i];
            if (i != this.selectedTags.length - 1) tagsStr += ',';
        }

        await navigator.clipboard.writeText(tagsStr).then(() =>
        {
            this.notification.success("tags are copied to the clipboard...", 'Success');
        }, (error) =>
        {
            this.notification.error("tags are not copied...", 'Error');
        });
    }

    private setSpinnerPosition() // set loading spinner position
    {
        if (!this.dialogElement || !this.dialogElement.nativeElement || !this.dialogElement.nativeElement.getBoundingClientRect()) //set spinner position out of the view
        {
            this.spinnerPosition.top = 10000;
            this.spinnerPosition.left = 10000;

            return
        }

        let top = this.dialogElement.nativeElement.getBoundingClientRect().top;
        let left = this.dialogElement.nativeElement.getBoundingClientRect().left;
        let height = this.dialogElement.nativeElement.getBoundingClientRect().height;
        let width = this.dialogElement.nativeElement.getBoundingClientRect().width;

        this.spinnerPosition.top = top + height / 2 - 50;
        this.spinnerPosition.left = left + width / 2 - 50;
    }
}
