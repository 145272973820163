import {Component, OnInit} from '@angular/core';
import {UserStatsService} from "../../services/user-stats.service";

declare var download: any;

@Component({
	selector: 'app-thumbnail-download',
	templateUrl: './thumbnail-download.component.html',
	styleUrls: ['./thumbnail-download.component.scss']
})
export class ThumbnailDownloadComponent implements OnInit{

	public thumbnailData:string;
	public saveImageData:string;

    constructor(private userStatsService: UserStatsService)
    {}

	public ngOnInit() {
		this.thumbnailData = localStorage.getItem('thumbnailData');
		this.saveImageData = localStorage.getItem('saveImageData');
	}

	public download() {
		download(this.thumbnailData, "thumbnail.jpg", "image/jpg");
		this.userStatsService.saveDownloadedImage(this.saveImageData).subscribe();
	}

}
