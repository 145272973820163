import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
    selector: '[appMandatoryField]'
})
export class MandatoryFieldDirective implements OnInit {

    constructor(private renderer: Renderer2, private el: ElementRef) {}

    @Input()
    public conditional: boolean = false;

    ngOnInit()
    {
        if (!this.el || !this.el.nativeElement) return;
        this.addAsterisk(this.el.nativeElement);
    }

    private addAsterisk(element: Element)
    {

        element.classList.add("mandatory-field");

        let span = document.createElement("SPAN");
        span.className = this.conditional ? "conditional-mandatory-asterisk" : "mandatory-asterisk";
        span.innerText = "*";

        element.insertAdjacentElement('afterend', span);
    }
}
