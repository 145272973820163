import {Component, OnInit, ViewChild} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {User} from "../../../models/user";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {AdminConsoleService} from "../../../services/admin-console.service";
import {ToastrService} from "ngx-toastr";
import {LiveAnnouncer} from "@angular/cdk/a11y";

@Component({
    selector: 'app-manage-users',
    templateUrl: './manage-users.component.html',
    styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {

    @ViewChild('paginatorManageUsersTab', {static: false}) paginatorManageUsersTab: MatPaginator;
    @ViewChild('sortManageUsersTab', {static: false}) sortManageUsersTab: MatSort;

    displayedColumnsManageUsersTab: string[] = ['id', 'name', 'email', 'role', 'promoteDemoteDesigner'];

    dataSourceManageUsersTab: MatTableDataSource<User>;

    constructor(public authService: AuthService,
                public router: Router,
                public dialog: MatDialog,
                public adminConsoleService: AdminConsoleService,
                public notification: ToastrService,
                private liveAnnouncer: LiveAnnouncer)
    { }

    ngOnInit(): void
    {
        if (this.authService.getCurrentUser() && this.authService.getCurrentUserType() == 'ADMIN')
        {
            this.getAllUsers();
        }
        else
        {
            this.router.navigate(['']);
        }
    }

    private getAllUsers()
    {
        this.adminConsoleService.getAllUsers().subscribe((res: any[]) =>
        {
            res.forEach(obj  => {
                obj.role = obj.role.name;
            });
            this.dataSourceManageUsersTab = new MatTableDataSource<User>(res);
            this.dataSourceManageUsersTab.paginator = this.paginatorManageUsersTab;
            this.dataSourceManageUsersTab.sort = this.sortManageUsersTab;

        }, (error) =>
        {
            console.error(error);
        })
    }

    public toggleUserRole(event, user: User)
    {
        if (event.checked)
        {
            this.adminConsoleService.promoteUserToDesigner(user.id).subscribe((res: any) =>
            {
                this.notification.success(res['message'], 'Success');
                this.getAllUsers();
            }, (error) =>
            {
                if (error.error) this.notification.error(error.error['message'], 'Error');
            })
        }
        else
        {
            this.adminConsoleService.demoteUserToBronzeMember(user.id).subscribe((res: any) =>
            {
                this.notification.success(res['message'], 'Success');
                this.getAllUsers();
            }, (error) =>
            {
                if (error.error) this.notification.error(error.error['message'], 'Error');
            })
        }
    }

    public announceSortChangeForManageUsersTable(sortState: Sort)
    {
        if (sortState.direction)
        {
            this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        }
        else
        {
            this.liveAnnouncer.announce('Sorting cleared');
        }
    }
}
