import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {UserStatsCounts} from "../../../models/user-stats-counts";

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnChanges{

	@ViewChild('paginatorUserStatsCountTab', {static: false}) paginatorUserStatsCountTab: MatPaginator;
	@ViewChild('sortUserStatsCountTab', {static: false}) sortUserStatsCountTab: MatSort;

	@Input()
	userStatsCounts: UserStatsCounts[] = []

	displayedColumnsUserStatsCountTab: string[] = ['name', 'email', 'loginCount', 'downloadImageCount', 'keywordSearchCount'];
	dataSourceUserStatsCount: MatTableDataSource<UserStatsCounts>;

	constructor(private liveAnnouncerSortUserStatsCountTab: LiveAnnouncer)
	{ }

	ngOnChanges(changes: SimpleChanges) {
		this.dataSourceUserStatsCount = new MatTableDataSource<UserStatsCounts>(this.userStatsCounts);
		this.dataSourceUserStatsCount.paginator = this.paginatorUserStatsCountTab;
		this.dataSourceUserStatsCount.sort = this.sortUserStatsCountTab;
	}

	public announceSortChangeForUserStatsCountTable(sortState: Sort)
	{
		if (sortState.direction)
		{
			this.liveAnnouncerSortUserStatsCountTab.announce(`Sorted ${sortState.direction}ending`);
		}
		else
		{
			this.liveAnnouncerSortUserStatsCountTab.announce('Sorting cleared');
		}
	}
}
