import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserStatsComponent} from "./user-stats.component";
import {MaterialModule} from "../../material.module";
import {DownloadedImagePreviewComponent} from "./downloaded-thumbnails/downloaded-image-preview/downloaded-image-preview.component";
import {DownloadedThumbnailsComponent} from "./downloaded-thumbnails/downloaded-thumbnails.component";
import {UsersComponent} from "./users/users.component";



@NgModule({
  declarations: [
      UserStatsComponent,
      DownloadedImagePreviewComponent,
      DownloadedThumbnailsComponent,
      UsersComponent
  ],
  imports: [
    MaterialModule,
    CommonModule
  ]
})
export class UserStatsModule { }
