import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {ToolAndGridComponentService} from "../../../services/component-service/tool-and-grid-component.service";

@Component({
    selector: 'app-confirmation-box',
    templateUrl: './confirmation-box.component.html',
    styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit, AfterViewInit {

    title: string = "Are you sure?";
    yesBtnName: string = "Yes";
    noBtnName: string = "No";
    yesBtnVisible: boolean = true;
    noBtnVisible: boolean = true;

    constructor(public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
                public toolAndGridComponentService: ToolAndGridComponentService,
                @Inject(MAT_DIALOG_DATA) public data: any)
    {
    }

    ngOnInit(): void
    {
        if (!this.data) return;

        if (this.data['title'] != null) this.title = this.data['title'];
        if (this.data['yesBtnName'] != null) this.yesBtnName = this.data['yesBtnName'];
        if (this.data['yesBtnVisible'] != null) this.yesBtnVisible = this.data['yesBtnVisible'];
        if (this.data['noBtnName'] != null) this.noBtnName = this.data['noBtnName'];
        if (this.data['noBtnVisible'] != null) this.noBtnVisible = this.data['noBtnVisible'];
    }

    ngAfterViewInit()
    {
        if (this.toolAndGridComponentService.getIsDarkThemeEnable())
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.add("dark-theme");
        }
        else
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.remove("dark-theme");
        }
    }
}
