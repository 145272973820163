<div [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()" [appDivDisable]="drawingSectionDisabled">
    <div class="drawing-mode-div row drawing-mode-row">
        <div class="label col-4 d-flex align-items-center">
            <label>Mode</label>
        </div>
        <div class="col-8 d-flex justify-content-start align-items-center font-family-div">
            <select class="mode-select select-input" (change)="updateCanvas('drawing-styles')" [(ngModel)]="activeStyle.drawingMode">
                <option *ngFor="let mode of drawingModes" [value]="mode.value">{{ mode.text }}</option>
            </select>
        </div>
    </div>

    <div *ngIf="activeStyle.drawingMode == 'hline' || activeStyle.drawingMode == 'vline'" class="drawing-line-width-div row drawing-line-width-row">
        <div class="label col-4 d-flex align-items-center">
            <label>Stroke Width</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-slider thumbLabel [displayWith]="utilService.formatSliderValuePx" tickInterval="1" min="1" max="50" [(ngModel)]="activeStyle.drawingLineModeStrokeWidth" (change)="updateCanvas('drawing-styles')"></mat-slider>
        </div>
    </div>

    <div *ngIf="activeStyle.drawingMode == 'circle' || activeStyle.drawingMode == 'square' || activeStyle.drawingMode == 'diamond'" class="drawing-line-width-div row drawing-line-width-row">
        <div class="label col-4 d-flex align-items-center">
            <label *ngIf="activeStyle.drawingMode == 'square' || activeStyle.drawingMode == 'diamond'">Square Width</label>
            <label *ngIf="activeStyle.drawingMode == 'circle'">Circle Diameter</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-slider thumbLabel [displayWith]="utilService.formatSliderValuePx" tickInterval="1" min="1" max="100" [(ngModel)]="activeStyle.drawingCircleSquareDiamondModeSquareCircleWidth" (change)="updateCanvas('drawing-styles')"></mat-slider>
        </div>
    </div>

    <div *ngIf="activeStyle.drawingMode == 'hline' || activeStyle.drawingMode == 'vline' || activeStyle.drawingMode == 'circle' || activeStyle.drawingMode == 'square' || activeStyle.drawingMode == 'diamond'" class="drawing-line-width-div row drawing-line-width-row">
        <div class="label col-4 d-flex align-items-center">
            <label>Space Width</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-slider thumbLabel [displayWith]="utilService.formatSliderValuePx" tickInterval="1" min="0" max="50" [(ngModel)]="activeStyle.drawingLineSquareDiamondModeSpaceWidth" (change)="updateCanvas('drawing-styles')"></mat-slider>
        </div>
    </div>

    <div *ngIf="activeStyle.drawingMode == 'texture'" class="drawing-line-width-div row drawing-line-width-row">
        <div class="label col-4 d-flex align-items-center">
            <label>Image</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <div class="tool-btn upload d-flex justify-content-center">
                <label for="texture-image-file" class="upload-image-label">Upload Image</label>
                <input id="texture-image-file" type="file" accept="image/*" hidden (change)="addNewImageForTexture($event)" [(ngModel)]="activeStyle.drawingTextureModeImage"/>
            </div>
        </div>
    </div>

    <div *ngIf="activeStyle.drawingMode != 'eraser'" class="drawing-line-color-div row drawing-line-color-row">
        <div class="label col-4 d-flex align-items-center">
            <label>Line Color</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <input type="text" class="color-picker-input form-control" [(colorPicker)]="activeStyle.drawingLineColor" (colorPickerChange)="updateCanvas('drawing-styles')" [style.background]="activeStyle.drawingLineColor" [cpEyeDropper]="true" [cpUseRootViewContainer]="true" [cpDialogDisplay]="'popup'" [cpPosition]="'bottom'" [cpMaxPresetColorsLength]="12" [cpPresetColors]="['#ff0038', '#0022ff', '#00ff27', '#faf96d']" [cpAddColorButton]="true">
        </div>
    </div>

    <div class="drawing-line-width-div row drawing-line-width-row">
        <div class="label col-4 d-flex align-items-center">
            <label>Line Width</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-slider thumbLabel [displayWith]="utilService.formatSliderValuePx" tickInterval="1" min="1" max="100" [(ngModel)]="activeStyle.drawingLineWidth" (change)="updateCanvas('drawing-styles')"></mat-slider>
        </div>
    </div>

    <div *ngIf="activeStyle.drawingMode != 'eraser'" class="drawing-shadow-color-div row drawing-shadow-color-row">
        <div class="label col-4 d-flex align-items-center">
            <label>Shadow Color</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <input type="text" class="color-picker-input form-control" [(colorPicker)]="activeStyle.drawingShadowColor" (colorPickerOpen)="updateCanvas('drawing-styles')" [style.background]="activeStyle.drawingShadowColor" [cpEyeDropper]="true" [cpUseRootViewContainer]="true" [cpDialogDisplay]="'popup'" [cpPosition]="'bottom'" [cpMaxPresetColorsLength]="12" [cpPresetColors]="['#ff0038', '#0022ff', '#00ff27', '#fffe00']" [cpAddColorButton]="true">
        </div>
    </div>

    <div *ngIf="activeStyle.drawingMode != 'eraser'" class="drawing-shadow-width-div row drawing-shadow-width-row">
        <div class="label col-4 d-flex align-items-center">
            <label>Shadow Width</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-slider thumbLabel [displayWith]="utilService.formatSliderValuePx" tickInterval="1" min="0" max="100" [(ngModel)]="activeStyle.drawingShadowWidth" (change)="updateCanvas('drawing-styles')"></mat-slider>
        </div>
    </div>

    <div *ngIf="activeStyle.drawingMode != 'eraser'" class="drawing-shadow-offset-x-div row drawing-shadow-offset-x-row">
        <div class="label col-4 d-flex align-items-center">
            <label>Shadow Offset (X)</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-slider thumbLabel [displayWith]="utilService.formatSliderValuePx" tickInterval="1" min="0" max="100" [(ngModel)]="activeStyle.drawingShadowOffSetX" (change)="updateCanvas('drawing-styles')"></mat-slider>
        </div>
    </div>

    <div *ngIf="activeStyle.drawingMode != 'eraser'" class="drawing-shadow-offset-y-div row drawing-shadow-offset-y-row">
        <div class="label col-4 d-flex align-items-center">
            <label>Shadow Offset (Y)</label>
        </div>
        <div class="col-8 d-flex align-items-center">
            <mat-slider thumbLabel [displayWith]="utilService.formatSliderValuePx" tickInterval="1" min="0" max="100" [(ngModel)]="activeStyle.drawingShadowOffSetY" (change)="updateCanvas('drawing-styles')"></mat-slider>
        </div>
    </div>
</div>

