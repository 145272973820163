import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CanvasComponentService} from '../../../../services/component-service/canvas-component.service';
import {
    ADMIN,
    CONFIRMATION_DIALOG_HEIGHT,
    CONFIRMATION_DIALOG_WIDTH,
    DESIGNER,
    GOLD_MEMBER,
    SIGN_IN_DIALOG_HEIGHT,
    SIGN_IN_DIALOG_WIDTH,
    SILVER_MEMBER
} from '../../../../util/app-consts';
import {AuthService} from '../../../../services/auth.service';
import {TemplatePreviewDialogComponent} from '../../../common-components/template-preview-dialog/template-preview-dialog.component';
import {SavePublishTemplateComponent} from './save-publish-template/save-publish-template.component';
import {ActionComponentService} from '../../../../services/component-service/action-component.service';
import {TemplateService} from '../../../../services/temaplate.service';
import {ToolAndGridComponentService} from "../../../../services/component-service/tool-and-grid-component.service";
import {ConfirmationBoxComponent} from "../../../common-components/confirmation-box/confirmation-box.component";
import {ToastrService} from "ngx-toastr";
import {SignInDialogComponent} from "../../../sign-in/sign-in-dialog.component";

@Component({
    selector: 'app-actions',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

    constructor(public dialog: MatDialog,
                public canvasComponentService: CanvasComponentService,
                public authService: AuthService,
                public notification: ToastrService,
                public actionComponentService: ActionComponentService,
                public templateService: TemplateService,
                public toolAndGridComponentService: ToolAndGridComponentService)
    {
        this.actionComponentService.actionComponent = this;
    }

    ngOnInit()
    {
    }

    resetCanvas()
    {
        if (this.canvasComponentService.isCanvasDirty())
        {
            const initialState = {
                title: 'Canvas will be cleared after reset... Are you sure you want to reset the canvas?'
            };
            const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
                data: initialState,
                width: CONFIRMATION_DIALOG_WIDTH + 'px',
                height: CONFIRMATION_DIALOG_HEIGHT + 'px',
                maxWidth: CONFIRMATION_DIALOG_WIDTH,
                maxHeight: CONFIRMATION_DIALOG_HEIGHT
            });
            dialogRef.afterClosed().subscribe(result =>
            {
                if (result == "yes")
                {
                    this.templateService.setCurrentTemplate(null);
                    this.canvasComponentService.reset();
                }
            });
        }
        else
        {
            this.templateService.setCurrentTemplate(null);
            this.canvasComponentService.reset();
        }
    }

    public previewThumbnail()
    {
        let userType = this.authService.getCurrentUserType();
        let isPremiumUser = (userType == SILVER_MEMBER || userType == GOLD_MEMBER || userType == ADMIN || userType == DESIGNER);
        let dialogPreviewBase64Url = this.canvasComponentService.getThumbnailPreview();
        let template = this.templateService.getCurrentTemplate();

        let buttons = [];
        buttons.push("Save");
        buttons.push("Download");
        if (isPremiumUser) buttons.push("Publish");

        const initialState = {
            template: template,
            dialogPreviewBase64Url: dialogPreviewBase64Url,
            buttons: buttons
        };
        this.dialog.open(TemplatePreviewDialogComponent, {data: initialState});
    }

    public saveTemplate()
    {
        if (!this.authService.isUserLoggedIn())
        {
            this.notification.warning("Please sign in to perform this action...", 'Warning');
            this.dialog.open(SignInDialogComponent, {
                width: SIGN_IN_DIALOG_WIDTH + 'px',
                height: SIGN_IN_DIALOG_HEIGHT + 'px',
                maxWidth: SIGN_IN_DIALOG_WIDTH,
                maxHeight: SIGN_IN_DIALOG_HEIGHT
            })

            return;
        }

        const initialState = {
            dialogType: 'save'
        };
        this.dialog.open(SavePublishTemplateComponent, {data: initialState});
    }

    public publishTemplate()
    {
        if (!this.authService.isUserLoggedIn())
        {
            this.notification.warning("Please sign in to perform this action...", 'Warning');
            this.dialog.open(SignInDialogComponent, {
                width: SIGN_IN_DIALOG_WIDTH + 'px',
                height: SIGN_IN_DIALOG_HEIGHT + 'px',
                maxWidth: SIGN_IN_DIALOG_WIDTH,
                maxHeight: SIGN_IN_DIALOG_HEIGHT
            })

            return;
        }

        const initialState = {
            dialogType: 'publish'
        };
        this.dialog.open(SavePublishTemplateComponent, {data: initialState});
    }

    public downloadThumbnail()
    {
        if (!this.authService.isUserLoggedIn())
        {
            this.notification.warning("Please sign in to perform this action...", 'Warning');
            this.dialog.open(SignInDialogComponent, {
                width: SIGN_IN_DIALOG_WIDTH + 'px',
                height: SIGN_IN_DIALOG_HEIGHT + 'px',
                maxWidth: SIGN_IN_DIALOG_WIDTH,
                maxHeight: SIGN_IN_DIALOG_HEIGHT
            })

            return;
        }

        this.canvasComponentService.downloadThumbnail();

        let template = this.templateService.getCurrentTemplate();
        if (template && (template.isPublished || template.originalTemplate))
        {
            if (this.authService.getCurrentUser() == null) return;

            this.templateService.changeDownloadCount(template.id).subscribe();
        }
    }
}
