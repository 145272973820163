import 'hammerjs';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {Workbox} from 'workbox-window'

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production)
{
    enableProdMode();
}

async function loadServiceWorker()
{
    if ('serviceWorker' in navigator)
    {
        const workboxServiceWorker = new Workbox('/service-worker.js');
        await workboxServiceWorker.register();
    }
}

platformBrowserDynamic().bootstrapModule(AppModule).then(async () =>
{
    await loadServiceWorker();
}).catch((err) =>
{
    console.error(err);
});

