import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SearchEngineOptimizationService } from 'src/app/services/search-engine-optimization.service';

@Component({
    selector: "app-how-to-use",
    templateUrl: "./how-to-use.component.html",
    styleUrls: ["./how-to-use.component.scss"],
})
export class HowToUseComponent implements OnInit {

    constructor(
        public searchEngineOptimizationService: SearchEngineOptimizationService,
        public router: Router
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Scroll to the top of the page on navigation end
                window.scrollTo(0, 0);
            }
        });
    }


    public goToTool()
    {
        this.router.navigate(['/tool']);
    }

    ngOnInit(): void {
        this.searchEngineOptimizationService.updatePageTitle("How to use - ThumbzStudio");
        this.searchEngineOptimizationService.updateMetaDescription("Learn how to use ThumbzStudio, a creative tool by Zyntreck, to bring your visual ideas to life. Step-by-step guide for all users.");
    }

}
