import {Component, OnInit} from '@angular/core';
import {CanvasComponentService} from 'src/app/services/component-service/canvas-component.service';
import {UtilService} from 'src/app/services/util.service';
import {ImageComponentService} from 'src/app/services/component-service/image-component.service';
import {Style} from 'src/app/models/style';
import {StyleService} from 'src/app/services/style.service';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

    public imageSectionDisabled = true;
    public activeStyle: Style = null;
    private disableSaveCanvasState: boolean = false;

    constructor(public canvasComponentService: CanvasComponentService,
                public imageComponentService: ImageComponentService,
                public styleService: StyleService,
                public utilService: UtilService)
    {
        this.imageComponentService.imageComponent = this;
    }

    ngOnInit(): void
    {
        this.refreshStyles();
    }

    public setImageSectionDisabled(state): void
    {
        this.imageSectionDisabled = state;
    }

    public refreshStyles()
    {
        this.activeStyle = this.styleService.getActiveObjectStyle();
    }

    public applyImageFilters(filterType)
    {
        this.activeStyle.updateFilter(filterType);
        this.canvasComponentService.applyImageFilters(filterType);

        if (!this.disableSaveCanvasState) this.canvasComponentService.saveCanvasState();
    }

    public colorPickerOpen(): void
    {
        this.disableSaveCanvasState = true;
    }

    public colorPickerClose(): void
    {
        this.disableSaveCanvasState = false;
        this.canvasComponentService.saveCanvasState();
    }
}
