import {Filter} from "./filter";


export class Style {

    public id;
    public thumbnail;

    public static DEFAULT_FILL_COLOR = '#166dab';
    public static DEFAULT_GRADIENT_COLOR = {};
    public static DEFAULT_STROKE_COLOR = '#000000';
    public static DEFAULT_STROKE_SIZE = 5;
    public static DEFAULT_SHADOW_COLOR = '#1900ff';
    public static DEFAULT_SHADOW_DISTANCE = 10;
    public static DEFAULT_SHADOW_FEATHER = 10;
    public static DEFAULT_GLOW_COLOR = '#c9002c';
    public static DEFAULT_GLOW_FEATHER = 25;
    public static DEFAULT_DRAWING_MODE = 'Pencil';
    public static DEFAULT_DRAWING_LINE_MODE_STROKE_WIDTH = 5;
    public static DEFAULT_DRAWING_CIRCLE_SQUARE_DIAMOND_MODE_CIRCLE_SQUARE_WIDTH = 5;
    public static DEFAULT_DRAWING_LINE_SQUARE_DIAMOND_MODE_SPACE_WIDTH = 5;
    public static DEFAULT_DRAWING_LINE_COLOR = '#000000';
    public static DEFAULT_DRAWING_LINE_WIDTH = 1;
    public static DEFAULT_DRAWING_SHADOW_COLOR = '#1900ff';
    public static DEFAULT_DRAWING_SHADOW_WIDTH = 0;
    public static DEFAULT_DRAWING_SHADOW_OFFSET_X = 0;
    public static DEFAULT_DRAWING_SHADOW_OFFSET_Y = 0;
    public static DEFAULT_FONT_FAMILY = 'Times New Roman';
    public static DEFAULT_FONT_ALIGN = 'left';
    public static DEFAULT_FONT_SIZE = 100;
    public static DEFAULT_FONT_WEIGHT = 400;
    public static DEFAULT_TEXT_CHAR_SPACING = 1;

    public static FILTER_MAP = {
        "grayScale": 0, "invert": 1, "sepia": 2, "blackAndWhite": 3, "brownie": 4,
        "vintage": 5, "kodachrome": 6, "technicolor": 7, "polaroid": 8, "sharpen": 9,
        "emboss": 10, "gamma": 11, "brightness": 12, "contrast": 13, "saturation": 14,
        "hue": 15, "noise": 16, "pixelate": 17, "blur": 18, "blend-color": 19,
        "opacity": 20
    };

    public static FONT_LIST = ['Abril Fatface', 'Alfa Slab One', 'Amatic SC', 'Anton', 'Aradhana', 'Arial',
                               'Ballet', 'Bangers', 'BebasNeue', 'Bungee Inline', 'Bungee Shade', 'Bungee Spice',
                               'Cabin', 'Cabin Italic', 'Calibr', 'Caveat', 'Comfortaa', 'Courgette',
                               'Fredoka One', 'Indie Flower', 'JosefinSans Italic', 'Kanit ExtraBold', 'Kanit Medium',
                               'Koulen',
                               'League Gothic', 'Lobster', 'LuckiestGuy', 'Metal Mania', 'Mochiy PopPOne', 'Nabla',
                               'Noto Serif', 'Open Sans', 'Oswald', 'Poppins Black', 'Poppins Medium',
                               'Poppins SemiBold',
                               'Poppins Thin', 'Press Start', 'Quicksand', 'Qwigley', 'Raleway', 'Raleway Italic',
                               'Righteous', 'Roboto', 'Roboto Black', 'Roboto Light', 'Roboto Slab', 'Rubik Iso',
                               'Russo One', 'Sail', 'Satisfy', 'Secular One', 'Shadows Into Light', 'Sigmar One',
                               'Suez One', 'Times New Roman', 'Titillium Web Black', 'Titillium Web SemiBold', 'Ubuntu',
                               'Vast Shadow'];

    public static DRAWING_MODES = [
        {
            text: 'Pencil',
            value: 'Pencil'
        },
        {
            text: 'Patch',
            value: 'Circle'
        },
        {
            text: 'Spray',
            value: 'Spray'
        },
        {
            text: 'Horizontal Line',
            value: 'hline'
        },
        {
            text: 'Vertical Line',
            value: 'vline'
        },
        {
            text: 'Circle',
            value: 'circle'
        },
        {
            text: 'Square',
            value: 'square'
        },
        {
            text: 'Diamond',
            value: 'diamond'
        },
        {
            text: 'Eraser',
            value: 'eraser'
        },
        // {
        //     text: 'Texture',
        //     value: 'texture'
        // },
    ]

    public fillMethod: string;
    public fillColor: string;
    public gradientColor: any;
    public gradientType: string;
    public radialGradientRadius: number;
    public linearGradientAngle: number;

    public strokeEnabled: boolean;
    public strokeColor: string;
    public strokeSize: number;

    public shadowEnabled: boolean;
    public shadowColor: string;
    public shadowDistance: number;
    public shadowFeather: number;

    public glowEnabled: boolean;
    public glowColor: string;
    public glowFeather: number;

    public fontFamily: string;
    public textAlign: string;
    public textStyles: string[];
    public fontWeight: number;
    public textCharSpacing: number;
    public allCapital: boolean;
    public allSimple: boolean;
    public superscript: boolean;
    public subscript: boolean;

    public drawingMode: string;
    public drawingLineModeStrokeWidth: number;
    public drawingCircleSquareDiamondModeSquareCircleWidth: number;
    public drawingLineSquareDiamondModeSpaceWidth: number;
    public drawingTextureModeImage: string;
    public drawingLineColor: string;
    public drawingLineWidth: number;
    public drawingShadowColor: string;
    public drawingShadowWidth: number;
    public drawingShadowOffSetX: number;
    public drawingShadowOffSetY: number;

    public filters: Filter[];

    public grayScaleEnabled: boolean;
    public grayScaleMode: string;
    public invertEnabled: boolean;
    public sepiaEnabled: boolean;
    public blackAndWhiteEnabled: boolean;
    public brownieEnabled: boolean;
    public vintageEnabled: boolean;
    public kodachromeEnabled: boolean;
    public technicolorEnabled: boolean;
    public polaroidEnabled: boolean;
    public sharpenEnabled: boolean;
    public embossEnabled: boolean;

    public opacityEnabled: boolean;
    public opacityValue: number;
    public gammaEnabled: boolean;
    public gammaRedValue: number;
    public gammaGreenValue: number;
    public gammaBlueValue: number;
    public brightnessEnabled: boolean;
    public brightnessValue: number;
    public contrastEnabled: boolean;
    public contrastValue: number;
    public saturationEnabled: boolean;
    public saturationValue: number;
    public hueEnabled: boolean;
    public hueValue: number;
    public noiseEnabled: boolean;
    public noiseValue: number;
    public pixelateEnabled: boolean;
    public pixelateValue: number;
    public blurEnabled: boolean;
    public blurValue: number;
    public blendColorEnabled: boolean;
    public blendColorValue: string;
    public blendColorMode: string;
    public blendColorDistance: number;

    public constructor()
    {
        this.setDefaultValues();
    }

    public setDefaultValues()
    {
        this.fillMethod = 'fill';
        this.fillColor = Style.DEFAULT_FILL_COLOR;
        this.gradientColor = Style.DEFAULT_GRADIENT_COLOR;
        this.gradientType = 'linear';
        this.radialGradientRadius = 100;
        this.linearGradientAngle = 0;

        this.strokeEnabled = false;
        this.strokeColor = Style.DEFAULT_STROKE_COLOR;
        this.strokeSize = Style.DEFAULT_STROKE_SIZE;

        this.shadowEnabled = false;
        this.shadowColor = Style.DEFAULT_SHADOW_COLOR;
        this.shadowDistance = Style.DEFAULT_SHADOW_DISTANCE;
        this.shadowFeather = Style.DEFAULT_SHADOW_FEATHER;

        this.glowEnabled = false;
        this.glowColor = Style.DEFAULT_GLOW_COLOR;
        this.glowFeather = Style.DEFAULT_GLOW_FEATHER;

        this.drawingMode = Style.DEFAULT_DRAWING_MODE;
        this.drawingLineModeStrokeWidth = Style.DEFAULT_DRAWING_LINE_MODE_STROKE_WIDTH;
        this.drawingCircleSquareDiamondModeSquareCircleWidth = Style.DEFAULT_DRAWING_CIRCLE_SQUARE_DIAMOND_MODE_CIRCLE_SQUARE_WIDTH;
        this.drawingLineSquareDiamondModeSpaceWidth = Style.DEFAULT_DRAWING_LINE_SQUARE_DIAMOND_MODE_SPACE_WIDTH;
        this.drawingLineColor = Style.DEFAULT_DRAWING_LINE_COLOR;
        this.drawingLineWidth = Style.DEFAULT_DRAWING_LINE_WIDTH;
        this.drawingShadowColor = Style.DEFAULT_DRAWING_SHADOW_COLOR;
        this.drawingShadowWidth = Style.DEFAULT_DRAWING_SHADOW_WIDTH;
        this.drawingShadowOffSetX = Style.DEFAULT_DRAWING_SHADOW_OFFSET_X;
        this.drawingShadowOffSetY = Style.DEFAULT_DRAWING_SHADOW_OFFSET_Y;

        this.fontFamily = Style.DEFAULT_FONT_FAMILY;
        this.textAlign = Style.DEFAULT_FONT_ALIGN;
        this.textStyles = [];
        this.allCapital = false;
        this.allSimple = false;
        this.superscript = false;
        this.subscript = false;
        this.fontWeight = Style.DEFAULT_FONT_WEIGHT;
        this.textCharSpacing = Style.DEFAULT_TEXT_CHAR_SPACING;

        this.grayScaleEnabled = false;
        this.grayScaleMode = 'average'
        this.invertEnabled = false;
        this.sepiaEnabled = false;
        this.blackAndWhiteEnabled = false;
        this.brownieEnabled = false;
        this.vintageEnabled = false;
        this.kodachromeEnabled = false;
        this.technicolorEnabled = false;
        this.polaroidEnabled = false;
        this.sharpenEnabled = false;
        this.embossEnabled = false;

        this.opacityEnabled = false;
        this.opacityValue = 1;
        this.gammaEnabled = false;
        this.gammaRedValue = 0;
        this.gammaGreenValue = 0;
        this.gammaBlueValue = 0;
        this.brightnessEnabled = false;
        this.brightnessValue = 0;
        this.contrastEnabled = false;
        this.contrastValue = 0;
        this.saturationEnabled = false;
        this.saturationValue = 0;
        this.hueEnabled = false;
        this.hueValue = 0;
        this.noiseEnabled = false;
        this.noiseValue = 0;
        this.pixelateEnabled = false;
        this.pixelateValue = 1;
        this.blurEnabled = false;
        this.blurValue = 0;
        this.blendColorEnabled = false;
        this.blendColorValue = "#ffffff";
        this.blendColorMode = "multiply";
        this.blendColorDistance = 1;

        this.filters = [];
        this.initFilters();
    }

    public resetStroke()
    {
        this.strokeEnabled = false;
        this.strokeColor = Style.DEFAULT_STROKE_COLOR;
        this.strokeSize = Style.DEFAULT_STROKE_SIZE;
    }

    public resetShadow()
    {
        this.shadowEnabled = false;
        this.shadowColor = Style.DEFAULT_SHADOW_COLOR;
        this.shadowDistance = Style.DEFAULT_SHADOW_DISTANCE;
        this.shadowFeather = Style.DEFAULT_SHADOW_FEATHER;
    }

    public resetGlow()
    {
        this.glowEnabled = false;
        this.glowColor = Style.DEFAULT_GLOW_COLOR;
        this.glowFeather = Style.DEFAULT_GLOW_FEATHER;
    }

    private initFilters()
    {
        let grayScaleFilter = new Filter('grayScale', false);
        let invertFilter = new Filter('invert', false);
        let sepiaFilter = new Filter('sepia', false);
        let blackAndWhiteFilter = new Filter('blackAndWhite', false);
        let brownieFilter = new Filter('brownie', false);
        let vintageFilter = new Filter('vintage', false);
        let kodachromeFilter = new Filter('kodachrome', false);
        let technicolorFilter = new Filter('technicolor', false);
        let polaroidFilter = new Filter('polaroid', false);
        let sharpenFilter = new Filter('sharpen', false);
        let embossFilter = new Filter('emboss', false);

        let opacityFilter = new Filter('opacity', false);
        let gammaFilter = new Filter('gamma', false);
        let brightnessFilter = new Filter('brightness', false);
        let contrastFilter = new Filter('contrast', false);
        let saturationFilter = new Filter('saturation', false);
        let hueFilter = new Filter('hue', false);
        let noiseFilter = new Filter('noise', false);
        let pixelateFilter = new Filter('pixelate', false);
        let blurFilter = new Filter('blur', false);
        let blendColorFilter = new Filter('blend-color', false);

        this.filters[Style.FILTER_MAP['grayScale']] = grayScaleFilter;
        this.filters[Style.FILTER_MAP['invert']] = invertFilter;
        this.filters[Style.FILTER_MAP['sepia']] = sepiaFilter;
        this.filters[Style.FILTER_MAP['blackAndWhite']] = blackAndWhiteFilter;
        this.filters[Style.FILTER_MAP['brownie']] = brownieFilter;
        this.filters[Style.FILTER_MAP['vintage']] = vintageFilter;
        this.filters[Style.FILTER_MAP['kodachrome']] = kodachromeFilter;
        this.filters[Style.FILTER_MAP['technicolor']] = technicolorFilter;
        this.filters[Style.FILTER_MAP['polaroid']] = polaroidFilter;
        this.filters[Style.FILTER_MAP['sharpen']] = sharpenFilter;
        this.filters[Style.FILTER_MAP['emboss']] = embossFilter;

        this.filters[Style.FILTER_MAP['gamma']] = gammaFilter;
        this.filters[Style.FILTER_MAP['brightness']] = brightnessFilter;
        this.filters[Style.FILTER_MAP['contrast']] = contrastFilter;
        this.filters[Style.FILTER_MAP['saturation']] = saturationFilter;
        this.filters[Style.FILTER_MAP['hue']] = hueFilter;
        this.filters[Style.FILTER_MAP['noise']] = noiseFilter;
        this.filters[Style.FILTER_MAP['pixelate']] = pixelateFilter;
        this.filters[Style.FILTER_MAP['blur']] = blurFilter;
        this.filters[Style.FILTER_MAP['blend-color']] = blendColorFilter;
        this.filters[Style.FILTER_MAP['opacity']] = opacityFilter;
    }

    public updateFilter(filterType: string)
    {
        switch (filterType)
        {
            case 'grayScale':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.grayScaleEnabled;
                filter.properties['mode'] = this.grayScaleMode;
                break;
            }
            case 'invert':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.invertEnabled;
                break;
            }
            case 'sepia':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.sepiaEnabled;
                break;
            }
            case 'blackAndWhite':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.blackAndWhiteEnabled;
                break;
            }
            case 'brownie':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.brownieEnabled;
                break;
            }
            case 'vintage':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.vintageEnabled;
                break;
            }
            case 'kodachrome':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.kodachromeEnabled;
                break;
            }
            case 'technicolor':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.technicolorEnabled;
                break;
            }
            case 'polaroid':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.polaroidEnabled;
                break;
            }
            case 'sharpen':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.sharpenEnabled;
                break;
            }
            case 'emboss':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.embossEnabled;
                break;
            }
            case 'gamma':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.gammaEnabled;
                filter.properties['gamma'] = this.getGammaValues();
                break;
            }
            case 'brightness':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.brightnessEnabled;
                filter.properties['brightness'] = this.brightnessValue / 100;
                break;
            }
            case 'contrast':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.contrastEnabled;
                filter.properties['contrast'] = this.contrastValue / 100;
                break;
            }
            case 'saturation':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.saturationEnabled;
                filter.properties['saturation'] = this.saturationValue;
                break;
            }
            case 'hue':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.hueEnabled;
                filter.properties['rotation'] = this.hueValue / 100;
                break;
            }
            case 'noise':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.noiseEnabled;
                filter.properties['noise'] = this.noiseValue;
                break;
            }
            case 'pixelate':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.pixelateEnabled;
                filter.properties['blocksize'] = this.pixelateValue;
                break;
            }
            case 'blur':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.blurEnabled;
                filter.properties['blur'] = this.blurValue;
                break;
            }
            case 'blend-color':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.blendColorEnabled;
                filter.properties['color'] = this.blendColorValue;
                filter.properties['mode'] = this.blendColorMode;
                filter.properties['alpha'] = this.blendColorDistance;
                break;
            }
            case 'opacity':
            {
                let filter = this.filters[Style.FILTER_MAP[filterType]];
                filter.enabled = this.opacityEnabled;
                filter.properties['opacity'] = this.opacityValue;
                break;
            }
            case 'all':
            {
                this.updateFilter('grayScale');
                this.updateFilter('invert');
                this.updateFilter('sepia');
                this.updateFilter('blackAndWhite');
                this.updateFilter('brownie');
                this.updateFilter('vintage');
                this.updateFilter('kodachrome');
                this.updateFilter('technicolor');
                this.updateFilter('emboss');
                this.updateFilter('gamma');
                this.updateFilter('brightness');
                this.updateFilter('saturation');
                this.updateFilter('hue');
                this.updateFilter('noise');
                this.updateFilter('pixelate');
                this.updateFilter('blur');
                this.updateFilter('blend-color');
                this.updateFilter('opacity');
            }
            default:
                 // do nothing
        }
    }

    private getGammaValue(value: number): number
    {  // range - 0.01 to 2.2
        const minimum = 0.01;
        let range = 2.2 - 0.01
        let gammavalue = 1;
        if (value < 0)
        {
            gammavalue = range / 2 * ((value + 100) / 100) + minimum;
        }
        else
        {
            gammavalue = range / 2 * (value / 100) + minimum + range / 2;
        }
        return gammavalue;
    }

    private getGammaValues(): number[]
    {
        let red = this.getGammaValue(this.gammaRedValue);
        let green = this.getGammaValue(this.gammaGreenValue);
        let blue = this.getGammaValue(this.gammaBlueValue);

        let values = [red, green, blue];
        return values;

    }
}
