<div class="modal-header">
    <label class="modal-title pull-left">SELECT CANVAS SIZE</label>
    <button type="button" class="close pull-right" aria-label="Close" [disabled]="selectedCanvasTypeAndSize == null" (click)="dialogRef.close('cancel')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body row" appDivDisable>
    <div class="radio-group-div row">
        <mat-radio-group class="d-flex flex-column" [(ngModel)]="selectedCanvasTypeAndSize" (change)="validate()">
            <mat-radio-button *ngFor="let pageTypeAndSize of pageTypesAndSizes" [value]="pageTypeAndSize" class="pre-defined-size-radio">
                {{ pageTypeAndSize.text }}
            </mat-radio-button>
            <div class="d-flex justify-content-start align-items-center custom-input-div">
                <mat-radio-button [value]="customTypeAndSize" class="custom-size-radio">
                    Custom
                </mat-radio-button> &nbsp;&nbsp;
                <input type="text" class="form-control custom-size-input" placeholder="Width" (keyup)="validate()" [(ngModel)]="customTypeAndSize.size.width" appDecimal="0"/>&nbsp; px &nbsp;&nbsp; x &nbsp;&nbsp;
                <input type="text" class="form-control custom-size-input" placeholder="Height" (keyup)="validate()" [(ngModel)]="customTypeAndSize.size.height" appDecimal="0"/> &nbsp; px
            </div>
        </mat-radio-group>
    </div>
</div>
<div class="modal-footer">
    <mat-dialog-actions>
        <button class="btn btn-primary btn-sm btn-cancel" mat-button [disabled]="selectedCanvasTypeAndSize == null" [mat-dialog-close]="'cancel'">Cancel</button>
        <button class="btn btn-primary btn-sm btn-ok" [disabled]="loadBtnDisable" (click)="loadSizeAndRedrawCanvas()" mat-button>Load</button>
    </mat-dialog-actions>
</div>

