import {Component, OnInit} from '@angular/core';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {CanvasComponentService} from '../../../../services/component-service/canvas-component.service';
import {LayerComponentService} from '../../../../services/component-service/layer-component.service';
import {ToolAndGridComponentService} from "../../../../services/component-service/tool-and-grid-component.service";

@Component({
    selector: 'app-layers',
    templateUrl: './layers.component.html',
    styleUrls: ['./layers.component.scss']
})
export class LayersComponent implements OnInit {

    public layers = [];
    public selectedLayers = []

    constructor(public canvasComponentService: CanvasComponentService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public layerComponentService: LayerComponentService)
    {
        layerComponentService.layerComponent = this;
    }

    ngOnInit()
    {
        this.refreshLayers();
    }

    public addLayerData(object, name, type): void
    {
        if (!object || !name || !type) return;

        let imagePath;

        if (type == "shape")
        {
            imagePath = "assets/layer-thumbnails/shape.svg";
        }
        else if (type == "text")
        {
            imagePath = "assets/layer-thumbnails/text.svg";
        }
        else if (type == "image")
        {
            imagePath = "assets/layer-thumbnails/image.svg";
        }
        else if (type == "group")
        {
            imagePath = "assets/layer-thumbnails/group.svg";
        }
        else if (type == "drawing")
        {
            imagePath = "assets/layer-thumbnails/group.svg";
        }
        else
        {
            imagePath = "assets/layer-thumbnails/unknown.png";
        }

        object.image = imagePath;
        object.text = name;

        this.refreshLayers();
    }

    public makeTextEditable(layer, state): void
    {
        if (!layer) return;
        if (!state && layer['isTextEditable'])
        {
            this.canvasComponentService.saveCanvasState();
        }
        layer['isTextEditable'] = state;

        if (state)
        {
            this.canvasComponentService.disableCanvasKeyboardListener();
        }
    }

    public selectLayer(index: number, clearAndSelect: boolean = true): void
    {
        if (clearAndSelect)
        {
            this.selectedLayers = [];
        }
        this.selectedLayers.push(index);

        this.canvasComponentService.enableCanvasKeyboardListener();
    }

    public drop(event: CdkDragDrop<string[]>): void
    {
        if (!event) return;
        this.canvasComponentService.changeObjectOrder(event.previousIndex, event.currentIndex);
        this.selectedLayers = [];
        this.refreshLayers();

        this.canvasComponentService.saveCanvasState();
    }

    moveUpLayer(index)
    {
        if (index < 1) return;
        this.canvasComponentService.changeObjectOrder(index, index - 1);
        this.refreshLayers();

        this.canvasComponentService.saveCanvasState();
    }

    moveDownLayer(index)
    {
        if (index >= this.layers.length - 1) return;
        this.canvasComponentService.changeObjectOrder(index, index + 1);
        this.refreshLayers();

        this.canvasComponentService.saveCanvasState();
    }

    public refreshLayers(): void
    {
        this.layers = this.canvasComponentService.objectList();
    }

    public duplicateLayer(index)
    {
        if (index < 0) return;
        this.canvasComponentService.createObjectCopy(index);
        this.refreshLayers();

        this.canvasComponentService.saveCanvasState();
    }

    public deleteLayer(index)
    {
        if (index < 0) return;
        this.canvasComponentService.deleteObject(index);
        this.refreshLayers();

        this.canvasComponentService.saveCanvasState();
    }

    public lockObject(object, state)
    {
        if (!object) return;
        this.canvasComponentService.lockObject(object, state);
        this.refreshLayers();

        this.canvasComponentService.saveCanvasState();
    }

    public toggleGroup(layer, event)
    {
        if (!event || !event.target) return;

        let targetClasses = event.target.classList;
        if (targetClasses.contains("layer-btn") || targetClasses.contains("layer-image")) return;

        layer.groupExapnded = !layer.groupExapnded;
    }
}
