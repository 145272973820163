<div id="canvas-parent" #canvasParent (window:resize)="onResize()" [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <div class="canvas-area" #canvasArea [ngStyle]="{'height.px':  (9 / 16) * (canvasParent?.getBoundingClientRect()?.width + 5), 'width.px': canvasParent?.getBoundingClientRect()?.width}">
        <div class="canvas d-flex justify-content-start align-items-start" [ngClass]="{'justify-content-center':canvasJustifyContentCenter, 'align-items-center':canvasAlignItemsCenter}" [ngStyle]="{'height.px': canvasArea?.getBoundingClientRect()?.height-5, 'width.px': canvasArea?.getBoundingClientRect()?.width-8}">
            <canvas id="canvas" #htmlCanvas [ngStyle]="{'height.px': canvasArea?.getBoundingClientRect()?.height-5, 'width.px': canvasArea?.getBoundingClientRect()?.width-4}"></canvas>
        </div>
    </div>
</div>
<div class="canvas-scalar d-flex justify-content-center align-items-baseline">
    <span class="scale-min">x 1 &nbsp;&nbsp;</span>
    <mat-slider class="example-margin" (change)="scaleCanvas()" [max]="10" [min]="0.8" [step]="0.01" [(ngModel)]="canvasScaleFactor"></mat-slider>
    <span class="scale-max">&nbsp;&nbsp; x 10</span>
</div>

<div style="visibility: hidden; position: fixed" #objectContextMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="objectContextMenu" [style.left]="objectContextMenuPosition.x" [style.top]="objectContextMenuPosition.y"></div>
<mat-menu #objectContextMenu="matMenu">
    <ng-template matMenuContent let-object="object">
        <button mat-menu-item (click)="pasteFromClipboard()">Paste from Clipboard</button>
        <mat-divider></mat-divider>
        <button mat-menu-item *ngIf="!object.edit && (object.type == 'polygon' || object.type == 'rect')" (click)="edit(object)">Edit</button>
        <button mat-menu-item *ngIf="object.edit && (object.type == 'polygon' || object.type == 'rect')" (click)="accept(object)">Accept</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="bringForward(object)">Bring Forward</button>
        <button mat-menu-item (click)="bringToFront(object)">Bring to Front</button>
        <button mat-menu-item (click)="sendBackward(object)">Send Backward</button>
        <button mat-menu-item (click)="sendToBack(object)">Send to Back</button>
        <mat-divider></mat-divider>
        <button mat-menu-item [disabled]="selectionType != 'multiple'" (click)="groupObjects(object)">Group</button>
        <button mat-menu-item [disabled]="selectionType != 'group'" (click)="unGroupObjects(object)">Ungroup</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="copyStyles(object)">Copy Styles</button>
        <button mat-menu-item [disabled]="copiedStyle==null" (click)="pasteStyles(object)">Paste Styles</button>
        <mat-divider *ngIf="isImageSelected"></mat-divider>
        <button mat-menu-item *ngIf="isImageSelected" (click)="addClippingMask(object)">Add Clipping Mask</button>
    </ng-template>
</mat-menu>

<div style="visibility: hidden; position: fixed" #canvasContextMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="canvasContextMenu" [style.left]="canvasContextMenuPosition.x" [style.top]="canvasContextMenuPosition.y"></div>
<mat-menu #canvasContextMenu="matMenu">
    <ng-template matMenuContent>
        <button mat-menu-item (click)="pasteFromClipboard()">Paste from Clipboard</button>
    </ng-template>
</mat-menu>
