import {Injectable} from '@angular/core';
import {LayerComponentService} from './layer-component.service';
import {CanvasComponent} from "../../pages/tool-and-grid/tool/canvas/canvas.component";
import {Style} from 'src/app/models/style';
import {SearchEngineOptimizationService} from "../search-engine-optimization.service";

@Injectable({
    providedIn: 'root'
})
export class CanvasComponentService {

    public canvasComponent: CanvasComponent;

    constructor(public layerService: LayerComponentService,
                public searchEngineOptimizationService: SearchEngineOptimizationService)
    { }

    public setCanvasTypeAndSize(type, size)
    {
        if (!this.canvasComponent) return;

        this.canvasComponent.setCanvasTypeAndSize(type, size);
    }

    public resizeCanvas()
    {
        this.canvasComponent.onResize();
    }

    public addImage(url): void
    {
        if (!this.canvasComponent || !url) return;
        this.canvasComponent.addImage(url);
    }

    public addFigure(figure): void
    {
        if (!this.canvasComponent || !figure) return;
        this.canvasComponent.addFigure(figure);
    }

    public addText(textString): void
    {
        if (!this.canvasComponent || !textString) return;
        this.canvasComponent.addText(textString);
    }

    public setCanvasBackground(color): void
    {
        if (!this.canvasComponent || !color) return;
        this.canvasComponent.setCanvasBackGround(color);
    }

    public changeObjectOrder(previousIndex, currentIndex): void
    {
        if (!this.canvasComponent) return;
        this.canvasComponent.changeObjectOrder(this.convertIndex(previousIndex), this.convertIndex(currentIndex));
    }

    public clearAndSelect(object: any, clearAndSelect: boolean = true): void
    {
        if (!this.canvasComponent || !object) return;
        this.canvasComponent.clearAndSelect(object, clearAndSelect);
    }

    public createObjectCopy(index): void
    {
        if (!this.canvasComponent) return;
        this.canvasComponent.createObjectCopy(this.convertIndex(index));
    }

    public deleteObject(index): void
    {
        if (!this.canvasComponent) return;
        this.canvasComponent.deleteCurrentObjectWithPermission(this.convertIndex(index));
    }

    public lockObject(object, state): void
    {
        if (!this.canvasComponent || !object) return;
        this.canvasComponent.lockObject(object, state);
    }

    public objectList(): []
    {
        if (!this.canvasComponent || !this.canvasComponent.canvas) return [];
        return this.canvasComponent.canvas.getObjects();
    }

    public getActiveObject(): any
    {
        if (!this.canvasComponent || !this.canvasComponent.canvas) return null;
        return this.canvasComponent.canvas.getActiveObject();
    }

    public getActiveObjects(): any
    {
        if (!this.canvasComponent || !this.canvasComponent.canvas) return [];
        return this.canvasComponent.canvas.getActiveObjects();
    }

    public getActiveObjectStyle()
    {
        if (!this.canvasComponent) return new Style();
        return this.canvasComponent.getObjectStyle(this.getActiveObject());
    }

    public setStyleValuesForObject(type: string, object: any = null): void
    {
        if (!this.canvasComponent || !type) return;
        this.canvasComponent.setStyleValuesForObject(type, object);
    }

    public applyAllFilters(object = null): void
    {
        if (!this.canvasComponent) return;
        this.canvasComponent.applyAllFilters(object);
    }

    public setObjectStyle(style: Style, object: any = null)
    {
        if (!this.canvasComponent) return;
        this.canvasComponent.setObjectStyle(style, object);
    }

    public undo(): void
    {
        if (!this.canvasComponent) return;

        this.canvasComponent.undo();
        this.layerService.refreshLayers();
    }

    public redo(): void
    {
        if (!this.canvasComponent) return;

        this.canvasComponent.redo();
        this.layerService.refreshLayers();
    }

    public reset(): void
    {
        if (!this.canvasComponent) return;

        this.canvasComponent.reset();
        this.clearCanvasDraft();
    }

    public downloadThumbnail(): void
    {
        if (!this.canvasComponent) return;
        this.canvasComponent.downloadThumbnail();
    }

    public applyImageFilters(filterType: string)
    {
        this.canvasComponent.applyImageFilters(filterType);
    }

    public convertIndex(index): number
    {
        if (!this.canvasComponent || !this.canvasComponent.canvas) return -1;
        return this.canvasComponent.canvas.getObjects().length - index - 1;
    }

    public getThumbnailPreview(isLowQuality = false): string
    {
        if (!this.canvasComponent) return null;
        return this.canvasComponent.getThumbnailPreview(isLowQuality);
    }

    public isCanvasDirty()
    {
        if (!this.canvasComponent) return true;
        return this.canvasComponent.historyIndex > 0;
    }

    public saveCanvasState()
    {
        if (!this.canvasComponent) return;
        this.canvasComponent.saveCanvasState();
    }

    public enableCanvasKeyboardListener()
    {
        if (!this.canvasComponent) return;

        setTimeout(() =>
        {
            this.canvasComponent.enableCanvasKeyboardListener();
        }, 0)
    }

    public disableCanvasKeyboardListener()
    {
        if (!this.canvasComponent) return;

        setTimeout(() =>
        {
            this.canvasComponent.disableCanvasKeyboardListener();
        }, 0)
    }


    public toggleDrawingMode(state: boolean)
    {
        if (!this.canvasComponent) return;
        this.canvasComponent.toggleDrawingMode(state);
    }

    public createStyleThumbnail(style: Style)
    {
        if (!this.canvasComponent) return;
        return this.canvasComponent.createStyleThumbnail(style);
    }

    public getFabricTemplate()
    {
        if (!this.canvasComponent) return;
        return this.canvasComponent.getFabricTemplate();
    }

    public loadCanvasByFabricTemplate(fabricTemplate: any)
    {
        if (!this.canvasComponent) return;
        return this.canvasComponent.loadCanvasByFabricTemplate(fabricTemplate);
    }

    public async saveCanvasDraft()
    {
        const cache = await caches.open('canvas-draft');

        try
        {
            await cache.put('data', new Response(this.getFabricTemplate()));

            return true;
        }
        catch (error)
        {
            console.warn(error);

            return false;
        }
    }

    public async loadCanvasDraft()
    {
        const cache = await caches.open('canvas-draft');

        try
        {
            const matchOptions = {
                ignoreSearch: true,
                ignoreMethod: true
            }

            const response = await cache.match('data', matchOptions);

            if (response)
            {
                return await response.json();
            }
            else
            {
                return null;
            }
        }
        catch (error)
        {
            console.warn(error);

            return null;
        }
    }

    public async clearCanvasDraft()
    {
        const cache = await caches.open('canvas-draft');

        try
        {
            const deleteOptions = {
                ignoreSearch: true,
                ignoreMethod: true,
            }

            const result = await cache.delete('data', deleteOptions);

            return result;
        }
        catch (error)
        {
            console.warn(error);

            return false;
        }
    }

    public getCanvasWidth()
    {
        if (!this.canvasComponent || !this.canvasComponent.canvasSize) return 0;
        return this.canvasComponent.canvasSize.width;
    }

    public getCanvasHeight()
    {
        if (!this.canvasComponent || !this.canvasComponent.canvasSize) return 0;
        return this.canvasComponent.canvasSize.height;
    }

    public getCanvasType()
    {
        if (!this.canvasComponent || !this.canvasComponent.canvasType) return "";
        return this.canvasComponent.canvasType;
    }
}
