import {Injectable} from '@angular/core';
import {ToolAndGridComponent} from '../../pages/tool-and-grid/tool-and-grid.component';
import {LocalStorageService} from "../local-storage.service";

@Injectable({
    providedIn: 'root'
})
export class ToolAndGridComponentService {

    public toolAndGridComponent: ToolAndGridComponent;
    private isDarkThemeEnable = false;

    public isProgressSpinnerVisible: boolean = false;
    public progressSpinnerPosition = {top: 0, left: 0};
    public progressSpinnerText = "";
    public progressSpinnerMode: any = "indeterminate";
    public progressSpinnerValue: number = 0;
    public downloadedSizeToShowInProgressSpinner: string = "0.00";
    public totalSizeToDownloadToShowInProgressSpinner: any = null;

    constructor(public localStorageService: LocalStorageService) { }

    public refreshAllTemplates()
    {
        this.refreshAllGeneralTemplates();
        this.refreshAllPublishedTemplates();
        this.refreshAllSavedTemplates();
        this.refreshAllPurchasedTemplates();
        this.refreshAllFavouriteTemplates();
    }

    public refreshAllGeneralTemplates()
    {
        if (!this.toolAndGridComponent) return;
        this.toolAndGridComponent.getAllGeneralTemplates();
    }

    public refreshAllPublishedTemplates()
    {
        if (!this.toolAndGridComponent) return;
        this.toolAndGridComponent.getAllPublishedTemplates();
    }

    public refreshAllSavedTemplates()
    {
        if (!this.toolAndGridComponent) return;
        this.toolAndGridComponent.getAllSavedTemplates();
    }

    public refreshAllPurchasedTemplates()
    {
        if (!this.toolAndGridComponent) return;
        this.toolAndGridComponent.getAllPurchasedTemplates();
    }

    public refreshAllFavouriteTemplates()
    {
        if (!this.toolAndGridComponent) return;
        this.toolAndGridComponent.getAllFavouriteTemplates();
    }

    public getAllGeneralTemplates()
    {
        if (!this.toolAndGridComponent) return;
        return this.toolAndGridComponent.generalTemplates;
    }

    public getAllSavedTemplates()
    {
        if (!this.toolAndGridComponent) return;
        return this.toolAndGridComponent.savedTemplates;
    }

    public getAllPurchasedTemplates()
    {
        if (!this.toolAndGridComponent) return;
        return this.toolAndGridComponent.purchasedTemplates;
    }

    public getAllFavouriteTemplates()
    {
        if (!this.toolAndGridComponent) return;
        return this.toolAndGridComponent.favouriteTemplates;
    }

    getIsDarkThemeEnable(): boolean
    {
        return this.isDarkThemeEnable;
    }

    setIsDarkThemeEnable(value: boolean)
    {
        this.isDarkThemeEnable = value;
        this.localStorageService.saveTheme(this.isDarkThemeEnable ? "dark" : "light");
    }
}
