export class Filter {

    public filterType: string;
    public enabled: boolean;
    public properties: {};

    public constructor(filterType: string, enabled: boolean)
    {
        this.filterType = filterType;
        this.enabled = enabled;
        this.properties = {};
    };
}
