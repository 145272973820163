<div [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <div class="tool" (window:resize)="toolResize()">
        <div class="row tool-inner-div">
            <div class="col-12 canvas" [ngClass]="{'left-side-lg':!isWindowSmall}" id="left-side">
                <div class="row app-canvas-section section" id="canvas">
                    <app-canvas *ngIf="showCanvas" (onCanvasSizeChanged)="canvasSizeChanged($event)"></app-canvas>
                </div>
                <div class="row" id="actions">
                    <div class="col-12 col-lg-5">
                        <div class="app-actions-section section">
                            <app-actions *ngIf="showCanvas"></app-actions>
                        </div>
                    </div>
                    <div class="col-12 col-lg-7">
                        <div class="app-addnew-section section">
                            <app-addnew *ngIf="showCanvas"></app-addnew>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" [style.height.px]="rightHeight" [ngClass]="{'right-side-lg':!isWindowSmall}" id="right-side">
                <div class="row app-styles-section section">
                    <app-styles *ngIf="showCanvas"></app-styles>
                </div>
                <div class="row app-layers-section section" id="layers">
                    <app-layers *ngIf="showCanvas"></app-layers>
                </div>
            </div>
        </div>
    </div>
</div>


