<div class="date-range-filter-div">
    <button class="btn dropdown-toggle date-range-select-drop-down-btn" type="button" data-toggle="dropdown">
        Filter By: {{ selectedDateRange.label }}
    </button>
    <div class="dropdown-menu date-range-select-dropdown-menu">
        <div *ngFor="let dateRange of dateRanges; let i = index" class="date-range-select-drop-down-option" (click)="dateRangeChanged(dateRange)" [ngClass]="{'date-range-select-option-selected':selectedDateRange==dateRange}">
            <label class="form-check-label date-range-select-option-label"> {{ dateRange.label }}</label>
        </div>
    </div>
</div>

<mat-tab-group>
    <mat-tab label="Users">
        <app-users [userStatsCounts]="userStatsCounts"></app-users>
    </mat-tab>

    <mat-tab label="Downloaded Thumbnails">
        <app-downloaded-thumbnails [downloadedImages]="downloadedImages"></app-downloaded-thumbnails>
    </mat-tab>
</mat-tab-group>
