import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {ToolAndGridComponentService} from "../../../services/component-service/tool-and-grid-component.service";

@Component({
    selector: 'app-input-box',
    templateUrl: './input-box.component.html',
    styleUrls: ['./input-box.component.scss']
})
export class InputBoxComponent implements OnInit, AfterViewInit {

    title: string = "";

    yesBtnName: string = "Yes";
    yesBtnVisible: boolean = true;

    noBtnName: string = "No";
    noBtnVisible: boolean = true;

    textInputVisible: boolean = true;
    selectDropDownVisible: boolean = true;

    textLabel: string = "";
    selectLabel: string = "";
    selectOptions: any[] = [];

    textValue: string = "";
    selectValue: any = null;

    constructor(public dialogRef: MatDialogRef<InputBoxComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public toolAndGridComponentService: ToolAndGridComponentService)
    { }

    ngOnInit(): void
    {
        if (!this.data) return;

        if (this.data['title'] != null) this.title = this.data['title'];

        if (this.data['yesBtnName'] != null) this.yesBtnName = this.data['yesBtnName'];
        if (this.data['yesBtnVisible'] != null) this.yesBtnVisible = this.data['yesBtnVisible'];
        if (this.data['noBtnName'] != null) this.noBtnName = this.data['noBtnName'];
        if (this.data['noBtnVisible'] != null) this.noBtnVisible = this.data['noBtnVisible'];

        if (this.data['textInputVisible'] != null) this.textInputVisible = this.data['textInputVisible'];
        if (this.data['selectDropDownVisible'] != null) this.selectDropDownVisible = this.data['selectDropDownVisible'];

        if (this.data['textLabel'] != null) this.textLabel = this.data['textLabel'];
        if (this.data['selectLabel'] != null) this.selectLabel = this.data['selectLabel'];
        if (this.data['selectOptions'] != null) this.selectOptions = this.data['selectOptions'];
        if (this.data['selectValue'] != null) this.selectValue = this.data['selectValue'];
    }

    ngAfterViewInit()
    {
        if (this.toolAndGridComponentService.getIsDarkThemeEnable())
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.add("dark-theme");
        }
        else
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.remove("dark-theme");
        }
    }

    public dropDownSelectionChanged(option)
    {
        this.selectValue = option;
    }

    public submit()
    {
        this.dialogRef.close({
            textValue: this.textValue,
            selectValue: this.selectValue
        })
    }

}
