import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TemplateService} from '../../../../../services/temaplate.service';
import {CanvasComponentService} from '../../../../../services/component-service/canvas-component.service';
import {Template} from '../../../../../models/template';
import {ToastrService} from 'ngx-toastr';
import {ToolAndGridComponentService} from '../../../../../services/component-service/tool-and-grid-component.service';
import {AuthService} from "../../../../../services/auth.service";

@Component({
    selector: 'app-save-publish-template',
    templateUrl: './save-publish-template.component.html',
    styleUrls: ['./save-publish-template.component.scss']
})
export class SavePublishTemplateComponent implements OnInit, AfterViewInit {

    @ViewChild('dialog', {static: false}) dialogElement: ElementRef;

    public title: string = '';
    public dialogType: string = '';
    public template: Template;
    public dialogPreviewBase64Url: string = '';
    public gridPreviewBase64Url: string = '';
    public shareableLink: string = '';
    public templateCategories: any[] = [];
    public templateCategoryMap: Map<string, {}> = new Map();
    public selectedTags: string[] = [];
    public tagText: string = '';
    public loading: boolean = false;
    public spinnerPosition = {top: 0, left: 0};

    @ViewChild('categoryDropDownButton', {static: false}) categoryDropDownButton: ElementRef;
    public categoryDropDownMenuWidth: number = 0;

    public saveAndPublishForm = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.maxLength(250)]),
        category: new FormControl('', Validators.required),
        description: new FormControl('', Validators.maxLength(500)),
        isPremium: new FormControl(false),
        price: new FormControl({value: 0, disabled: true}, [Validators.max(1000)]),
    });

    constructor(public dialogRef: MatDialogRef<SavePublishTemplateComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public notification: ToastrService,
                public templateService: TemplateService,
                public authService: AuthService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public canvasComponentService: CanvasComponentService)
    {
        this.getTemplateCategories();
    }

    ngOnInit()
    {
        if (!this.data) return;

        this.template = this.templateService.getCurrentTemplate();

        if (this.data['dialogType'])
        {
            this.dialogType = this.data['dialogType'];
            this.title = this.dialogType == 'publish' ? 'PUBLISH TEMPLATE' : 'SAVE TEMPLATE';
            this.dialogPreviewBase64Url = this.canvasComponentService.getThumbnailPreview();

            this.loadTemplate();

            if (this.template && (!this.shareableLink || this.shareableLink == ""))
            {
                if (this.template.shareableLink)
                {
                    this.shareableLink = this.template.shareableLink;
                }
                else
                {
                    this.getShareableLink();
                }
            }
        }
    }

    ngAfterViewInit()
    {
        setTimeout(() =>
        {
            this.calculateCategoryDropDownMenuWidth();
            this.setSpinnerPosition();
        }, 0);

        if (this.toolAndGridComponentService.getIsDarkThemeEnable())
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.add("dark-theme");
        }
        else
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.remove("dark-theme");
        }
    }

    loadTemplate() // load template data to the dialog
    {
        if (!this.template) return;

        this.saveAndPublishForm.patchValue(JSON.parse(JSON.stringify(this.template)));

        if (this.template.isPremium)
        {
            this.saveAndPublishForm.controls['price'].enable();
        }

        if (this.template.tags && this.template.tags != "")
        {
            this.selectedTags = JSON.parse(this.template.tags);
        }
    }

    public categoryChanged(newCategory)
    {
        this.saveAndPublishForm.controls['category'].patchValue(newCategory.name);
    }

    public addTag()
    {
        if (!this.tagText || this.tagText.length == 0 || this.selectedTags.length >= 20) return;

        if (this.selectedTags.length == 0) // add default tags according to current canvas type
        {
            let currentCanvasTypeAndSize = this.templateService.getCurrentCanvasTypeAndSize();
            let currentCanvasType = currentCanvasTypeAndSize['type'];
            let defaultTagsStr = currentCanvasType + '-templates-designs';
            let defaultTags = defaultTagsStr.split('-');

            for (let defaultTag of defaultTags)
            {
                this.selectedTags.push(defaultTag);
            }
        }

        if (this.tagText.includes(',')) // add comma separated tags
        {
            let tagsArray = this.tagText.split(',');
            for (let tag of tagsArray)
            {
                let trimmedTag = tag.trim();
                if (trimmedTag == '') continue;

                this.selectedTags.push(trimmedTag);
            }
        }
        else
        {
            let trimmedTagText = this.tagText.trim();
            this.selectedTags.push(trimmedTagText);
        }

        this.tagText = '';
    }

    public removeTag(index)
    {
        this.selectedTags.splice(index, 1);
    }

    public saveTemplate()
    {
        let data: any = this.saveAndPublishForm.getRawValue();

        let template = this.template ? this.template : new Template();

        template.name = data.name;
        template.category = this.getTemplateCategoryByName(data.category);
        template.tags = JSON.stringify(this.selectedTags);
        template.description = data.description;
        template.fabricTemplate = this.canvasComponentService.getFabricTemplate();
        template.shareableLink = this.shareableLink;
        template.dialogPreviewBase64Url = this.dialogPreviewBase64Url;
        template.gridPreviewBase64Url = this.canvasComponentService.getThumbnailPreview(true);
        template.canvasWidth = this.canvasComponentService.getCanvasWidth();
        template.canvasHeight = this.canvasComponentService.getCanvasHeight();
        template.canvasType = this.canvasComponentService.getCanvasType();

        this.loading = true;
        this.templateService.saveTemplate(template).subscribe((res: Template) =>
        {
            if (res)
            {
                this.templateService.setCurrentTemplate(res);

                this.dialogRef.close();
                this.toolAndGridComponentService.refreshAllTemplates();
                this.notification.success("Template Saved Successfully...", 'Success');

                this.canvasComponentService.clearCanvasDraft();
            }
            this.loading = false;
        }, error =>
        {
            this.loading = false;

            if (!navigator.onLine)
            {
                this.notification.warning("Data will be synced when you back online...", "Warning");
                return;
            }

            if (error.error) this.notification.error(error.error['message'], 'Error');

        });
    }

    public publishTemplate()
    {
        let data: any = this.saveAndPublishForm.getRawValue();

        let template = this.template ? this.template : new Template();

        template.name = data.name;
        template.category = this.getTemplateCategoryByName(data.category);
        template.tags = JSON.stringify(this.selectedTags);
        template.description = data.description;
        template.isPremium = data.isPremium;
        template.price = data.price;
        template.fabricTemplate = this.canvasComponentService.getFabricTemplate();
        template.shareableLink = this.shareableLink;
        template.dialogPreviewBase64Url = this.dialogPreviewBase64Url;
        template.gridPreviewBase64Url = this.canvasComponentService.getThumbnailPreview(true);
        template.canvasWidth = this.canvasComponentService.getCanvasWidth();
        template.canvasHeight = this.canvasComponentService.getCanvasHeight();
        template.canvasType = this.canvasComponentService.getCanvasType();

        this.loading = true;
        this.templateService.publishTemplate(template).subscribe((res: Template) =>
        {
            if (res)
            {
                this.templateService.setCurrentTemplate(res);

                this.dialogRef.close();
                this.toolAndGridComponentService.refreshAllTemplates();
                this.notification.success("Template Published Successfully... " + (res.isApproved ? "" : "Wait for the Approval..."), 'Success');

                this.canvasComponentService.clearCanvasDraft();
            }
            this.loading = false;
        }, (error =>
        {
            this.loading = false;

            if (!navigator.onLine)
            {
                this.notification.warning("Data will be synced when you back online...", "Warning");
                return;
            }

            if (error.error) this.notification.error(error.error['message'], 'Error');
        }));
    }

    private getTemplateCategories()
    {
        this.templateService.getTemplateCategories().subscribe((res: any[]) =>
        {
            this.templateCategories = res;
            Object.keys(res).forEach(key =>
            {
                this.templateCategoryMap[res[key].name] = res[key];
            })
        });
    }

    private getTemplateCategoryByName(name: string)
    {
        return this.templateCategoryMap[name];
    }

    private getShareableLink()
    {
        if (!this.template) return;
        this.templateService.getShareableCodeForTemplate(this.template.id).subscribe((res: string) =>
        {
            this.shareableLink = res['message'];
        })
    }

    public isPremiumCheckBoxChange()
    {
        let state = this.saveAndPublishForm.controls['isPremium'].value;

        if (state)
        {
            this.saveAndPublishForm.controls['price'].enable();
        }
        else
        {
            this.saveAndPublishForm.controls['price'].disable();
        }

        this.saveAndPublishForm.controls['price'].patchValue(0);
    }

    private calculateCategoryDropDownMenuWidth()
    {
        if (!this.categoryDropDownButton || !this.categoryDropDownButton.nativeElement) return;
        if (!this.categoryDropDownButton.nativeElement.getBoundingClientRect()) return;

        this.categoryDropDownMenuWidth = this.categoryDropDownButton.nativeElement.getBoundingClientRect().width;
    }

    private setSpinnerPosition() // set loading spinner position
    {
        if (!this.dialogElement || !this.dialogElement.nativeElement || !this.dialogElement.nativeElement.getBoundingClientRect()) //set spinner position out of the view
        {
            this.spinnerPosition.top = 10000;
            this.spinnerPosition.left = 10000;

            return
        }

        let top = this.dialogElement.nativeElement.getBoundingClientRect().top;
        let left = this.dialogElement.nativeElement.getBoundingClientRect().left;
        let height = this.dialogElement.nativeElement.getBoundingClientRect().height;
        let width = this.dialogElement.nativeElement.getBoundingClientRect().width;

        this.spinnerPosition.top = top + height / 2 - 50;
        this.spinnerPosition.left = left + width / 2 - 50;
    }
}
