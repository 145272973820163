import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu'
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {MatLegacyPaginatorModule as MatPaginatorModule} from "@angular/material/legacy-paginator";
import {MatSortModule} from "@angular/material/sort";

// const EXPANSION_PANEL_ANIMATION_TIMING = '0ms cubic-bezier(0.4,0.0,0.2,1)';
// MatExpansionPanel['decorators'][0].args[0].animations = [
//     trigger('bodyExpansion', [
//         state('collapsed, void', style({ height: '0px', visibility: 'hidden' })),
//         state('expanded', style({ height: '*', visibility: 'visible' })),
//         transition('expanded <=> collapsed, void => collapsed',
//                 animate(EXPANSION_PANEL_ANIMATION_TIMING)),
//       ])];

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    exports: [
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSliderModule,
        MatFormFieldModule,
        MatButtonToggleModule,
        DragDropModule,
        MatExpansionModule,
        MatInputModule,
        MatDialogModule,
        MatTooltipModule,
        MatMenuModule,
        MatDividerModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
    ]
})
export class MaterialModule {}
