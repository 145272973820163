import {Component, OnInit} from '@angular/core';
import {ToolAndGridComponentService} from "../../../../../services/component-service/tool-and-grid-component.service";
import {UtilService} from "../../../../../services/util.service";
import {Style} from "../../../../../models/style";
import {StyleService} from "../../../../../services/style.service";
import {CanvasComponentService} from "../../../../../services/component-service/canvas-component.service";
import {DrawingComponentService} from "../../../../../services/component-service/drawing-component.service";

@Component({
    selector: 'app-drawing',
    templateUrl: './drawing.component.html',
    styleUrls: ['./drawing.component.scss']
})
export class DrawingComponent implements OnInit {

    public drawingSectionDisabled = true;
    public drawingModes: any[] = [];
    public activeStyle: Style = null;

    constructor(public drawingComponentService: DrawingComponentService,
                public canvasComponentService: CanvasComponentService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public utilService: UtilService,
                public styleService: StyleService)
    {
        drawingComponentService.drawingComponent = this;
    }

    ngOnInit()
    {
        this.getDrawingModes();
        this.refreshStyles();
    }

    public refreshStyles()
    {
        this.activeStyle = this.styleService.getActiveObjectStyle();
    }

    public updateCanvas(styleType)
    {
        this.canvasComponentService.setStyleValuesForObject(styleType);
    }

    public addNewImageForTexture(event): void
    {
        if (event && event.target && event.target.files && event.target.files[0])
        {
            const reader = new FileReader();
            reader.onload = (readerEvent) =>
            {
                this.activeStyle.drawingTextureModeImage = readerEvent.target['result'].toString();
                this.updateCanvas('drawing-styles');
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    private getDrawingModes()
    {
        this.drawingModes = Style.DRAWING_MODES;
    }

    public setDrawingSectionDisabled(state): void
    {
        this.drawingSectionDisabled = state;
    }

}
