import {Injectable} from '@angular/core';
import {DrawingComponent} from "../../pages/tool-and-grid/tool/styles/drawing/drawing.component";

@Injectable({
    providedIn: 'root'
})
export class DrawingComponentService {

    public drawingComponent: DrawingComponent;

    constructor() { }

    public setDrawingSectionDisabled(state): void
    {
        if (!this.drawingComponent) return;
        this.drawingComponent.setDrawingSectionDisabled(state);
    }

    public refreshStyles()
    {
        if (!this.drawingComponent) return;
        this.drawingComponent.refreshStyles();
    }
}
