<div [appDivDisable]="toolAndGridComponentService.isProgressSpinnerVisible == true">
    <div #tool [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
        <section id="tool" [ngStyle]="{'padding-top': authService.isUserLoggedIn() == false && notificationService.showMessageBar ? '100px' : '80px'}" class="d-flex flex-column justify-content-end align-items-center">
            <button [ngStyle]="{'top.px': authService.isUserLoggedIn() == false && notificationService.showMessageBar ? '120' : '100'}" class="select-size-btn" mat-fab color="primary" (click)="openSelectCanvasSizeComponent()">
                <mat-icon>aspect_ratio</mat-icon>
            </button>
            <app-tool (onCanvasSizeChanged)="canvasSizeChanged($event)"></app-tool>
        </section>
    </div>

    <main id="main" class="main-grid">
        <section class="main-grid-section">
            <div class="container">
                <div class="grid-header" data-aos="fade-in">
                    <div class="tab-changer d-flex justify-content-center align-items-center">
                        <ul class="template-item-main-filters">
                            <li [ngClass]="{'filter-active':currentTab == 'General'}" (click)="gridTabChanged('General')">General</li>
                            <li [ngClass]="{'filter-active':currentTab == 'Published'}" (click)="gridTabChanged('Published')">Published</li>
                            <li [ngClass]="{'filter-active':currentTab == 'Saved'}" (click)="gridTabChanged('Saved')">Saved</li>
                            <!--                        <li [ngClass]="{'filter-active':currentTab == 'Purchased'}" (click)="gridTabChanged('Purchased')">Purchased</li>-->
                            <li [ngClass]="{'filter-active':currentTab == 'Favourite'}" (click)="gridTabChanged('Favourite')">Favourite</li>
                        </ul>
                        <button (click)="refreshAllTemplates()" class="btn btn-primary page-refresh-btn" mat-icon-button matTooltip="refresh grid" [matTooltipPosition]="'above'">
                            <mat-icon>autorenew</mat-icon>
                        </button>
                    </div>
                    <div class="filter-div-marker" #filter_div_marker></div>
                </div>
                <mat-progress-spinner *ngIf="!gridVisible && !toolAndGridComponentService.isProgressSpinnerVisible" class="progress-spinner" [color]="'accent'" [mode]="'indeterminate'"></mat-progress-spinner>
                <div class="grid-data">
                    <div *ngIf="gridVisible" class="template-grid" data-aos="fade-in">
                        <div *ngIf="currentTab=='General'" class="grid-tab row d-flex flex-wrap">
                            <div *ngFor="let generalTemplate of generalTemplates" class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2 template-grid-item">
                                <app-grid-cell [template]="generalTemplate" [type]="'general'" (click)="showPreview(generalTemplate)"></app-grid-cell>
                            </div>
                        </div>

                        <div *ngIf="currentTab=='Published'" class="grid-tab row d-flex flex-wrap">
                            <div *ngFor="let publishedTemplate of publishedTemplates" class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2 template-grid-item">
                                <app-grid-cell [template]="publishedTemplate" [type]="'published'" (click)="showPreview(publishedTemplate)"></app-grid-cell>
                            </div>
                        </div>

                        <div *ngIf="currentTab=='Saved'" class="grid-tab row d-flex flex-wrap">
                            <div *ngFor="let savedTemplate of savedTemplates" class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2 template-grid-item">
                                <app-grid-cell [template]="savedTemplate" [type]="'saved'" (click)="showPreview(savedTemplate)"></app-grid-cell>
                            </div>
                        </div>

                        <!--                    <div *ngIf="currentTab=='Purchased'" class="grid-tab row d-flex flex-wrap" data-aos="fade-in">-->
                        <!--                        <div *ngFor="let purchasedTemplate of purchasedTemplates" class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2 template-grid-item">-->
                        <!--                            <app-grid-cell [template]="purchasedTemplate" [type]="'purchased'" (click)="showPreview(purchasedTemplate)"></app-grid-cell>-->
                        <!--                        </div>-->
                        <!--                    </div>-->

                        <div *ngIf="currentTab=='Favourite'" class="grid-tab row d-flex flex-wrap">
                            <div *ngFor="let favouriteTemplate of favouriteTemplates" class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2 template-grid-item">
                                <app-grid-cell [template]="favouriteTemplate" [type]="'favourite'" (click)="showPreview(favouriteTemplate)"></app-grid-cell>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="tabsPageCountMap[currentTab] > 1" class="pagination-div d-flex justify-content-start" data-aos="fade-in">
                <div class="paginate-btn" (click)="gotoFirst()" [ngClass]="{'paginate-btn-disabled':tabsCurrentPageMap[currentTab] == 1}" [ngStyle]="{'border-top-left-radius.px': 5, 'border-bottom-left-radius.px': 5}">
                    <span><<</span>
                </div>
                <div class="paginate-btn" (click)="gotoPrevious()" [ngClass]="{'paginate-btn-disabled':tabsCurrentPageMap[currentTab] == 1}">
                    <span><</span>
                </div>
                <div *ngFor="let n of getPaginationSequence(); let i = index;">
                    <div class="paginate-btn" (click)="gotoPage(n)" [ngClass]="{'paginate-btn-selected':tabsCurrentPageMap[currentTab] == n}">
                        <span>{{ n }}</span>
                    </div>
                </div>
                <div class="paginate-btn" (click)="gotoNext()" [ngClass]="{'paginate-btn-disabled':tabsCurrentPageMap[currentTab] == tabsPageCountMap[currentTab]}">
                    <span>></span>
                </div>
                <div class="paginate-btn" (click)="gotoLast()" [ngClass]="{'paginate-btn-disabled':tabsCurrentPageMap[currentTab] == tabsPageCountMap[currentTab]}" [ngStyle]="{'border-top-right-radius.px': 5, 'border-bottom-right-radius.px': 5}">
                    <span>>></span>
                </div>

                <div class="page-size-div d-flex justify-content-start">
                    <input [(ngModel)]="tabsPageSizeInputValueMap[currentTab]" class="page-size-input form-control" placeholder="Page Size">
                    <button [disabled]="!tabsPageSizeInputValueMap[currentTab]" (click)="changePageSize()" class="btn btn-primary page-size-save-btn" mat-icon-button matTooltip="goto page" [matTooltipPosition]="'above'">
                        <mat-icon>check</mat-icon>
                    </button>
                </div>
            </div>

        </section>
    </main>

    <button (click)="gotoTop()" class="back-to-top">
        <mat-icon>arrow_upward</mat-icon>
    </button>

    <div class="grid-filter-div row" [ngStyle]="{'top.px': this.gridFilterPosition.top + (authService.isUserLoggedIn() == false && notificationService.showMessageBar ? 20 : 0), 'left.px': this.gridFilterPosition.left, 'width.px': this.gridFilterPosition.width}" data-aos="fade-in">
        <div class="grid-filter-btn col-12 col-lg-2 p-0">
            <button class="btn dropdown-toggle category-select-drop-down-btn" type="button" data-toggle="dropdown">
                CATEGORY
            </button>
            <div class="dropdown-menu category-select-dropdown-menu">
                <div *ngFor="let category of templateCategories; let i = index" class="category-select-drop-down-option d-flex justify-content-between align-items-center" [ngClass]="{'category-option-selected':category['selected']}">
                    <input class="category-select-option-checkbox" type="checkbox" [checked]="category['selected']" value="option.value" (change)="categoryChanged(category)"> &nbsp;&nbsp;&nbsp;
                    <label class="form-check-label category-select-option-label" (click)="categoryChanged(category)"> {{ category.name }} </label>
                </div>
            </div>
        </div>
        <div class="grid-filter-btn col-9 col-lg-3 p-0">
            <button class="btn dropdown-toggle sort-select-drop-down-btn" type="button" data-toggle="dropdown">
                SORT BY: {{ selectedSortType?.text }}
            </button>
            <div class="dropdown-menu sort-select-dropdown-menu">
                <div *ngFor="let sortType of sortTypes; let i = index" class="sort-select-drop-down-option" (click)="sortChanged(sortType)" [ngClass]="{'sort-by-option-selected':selectedSortType==sortType}">
                    <label class="form-check-label sort-select-option-label"> {{ sortType.text }}</label>
                </div>
            </div>
        </div>
        <div class="grid-filter-btn grid-sort-direction-toggle-btn-div col-3 col-lg-1">
            <mat-button-toggle [checked]="selectedSortDirection=='DESC'" (change)="toggleSortDirection($event.source['_checked'])" class="btn btn-primary grid-sort-direction-toggle-btn d-flex justify-content-center" mat-icon-button matTooltip="toggle sort-direction" [matTooltipPosition]="'above'">
                <mat-icon>swap_vert</mat-icon>
            </mat-button-toggle>
        </div>
        <div class="col-12 col-lg-2"></div>
        <div class="grid-filter-btn d-flex justify-content-end col-12 col-lg-4 p-0">
            <label class="grid-search-label">SEARCH</label>
            <div class="input-group">
                <input class="grid-search-input form-control" type="text" [(ngModel)]="searchString" (keyup)="searchInGrid()">
                <div class="input-group-append">
                    <button class="btn grid-search-clear-btn" type="button" (click)="clearSearchString()" matTooltip="clear text" [matTooltipPosition]="'above'">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>

<div *ngIf="toolAndGridComponentService.isProgressSpinnerVisible" class="fabric-template-progress-spinner d-flex justify-content-center align-items-end" [ngStyle]="{'top.px': toolAndGridComponentService.progressSpinnerPosition.top, 'left.px': toolAndGridComponentService.progressSpinnerPosition.left}">
    <mat-progress-spinner class="progress-spinner" [color]="'accent'" [mode]="toolAndGridComponentService.progressSpinnerMode" [value]="toolAndGridComponentService.progressSpinnerValue"></mat-progress-spinner>
    <span>{{ toolAndGridComponentService.progressSpinnerText }} &nbsp;&nbsp;</span>
    <span *ngIf="toolAndGridComponentService.totalSizeToDownloadToShowInProgressSpinner && toolAndGridComponentService.downloadedSizeToShowInProgressSpinner != toolAndGridComponentService.totalSizeToDownloadToShowInProgressSpinner">{{ toolAndGridComponentService.downloadedSizeToShowInProgressSpinner }} MB / {{ toolAndGridComponentService.totalSizeToDownloadToShowInProgressSpinner }} MB</span>
</div>
