<div class="tool-section addnew-section" [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <div class="header">
        <label class="header-text">ADD NEW</label>
    </div>
    <div class="add-new d-flex flex-column tool-section-overlay" id="add-new">
        <div class="add-image d-flex justify-content-between">
            <div class="tool-btn upload d-flex justify-content-center align-items-center">
                <mat-icon svgIcon="svg-icon-add-image" class="upload-image-icon" aria-hidden="false"></mat-icon>
                <label for="file" class="upload-image-label">Upload Image</label>
                <input id="file" type="file" accept="image/*" hidden (change)="addNewImage($event)" [(ngModel)]="selectedFileforUpload"/>
            </div>

            <div class="input-group from-url d-flex justify-content-center">
                <input class="from-url-input form-control" type="text" placeholder="Paste Image URL here..." [(ngModel)]="imageUrl">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary tool-btn add-image-btn" type="button" (click)="addImageFromUrl()" matTooltip="add" [matTooltipPosition]="'above'">Add</button>
                </div>
            </div>
        </div>

        <div #add_buttons_group class="add-btn-group d-flex justify-content-between">
            <button class="tool-btn" color="primary" mat-stroked-button (click)="showAddNewText()">
                <mat-icon svgIcon="svg-icon-add-text" aria-hidden="false"></mat-icon>
                Text
            </button>
            <!--            <button class="tool-btn" color="primary" mat-stroked-button (click)="showClipArts()">-->
            <!--                <mat-icon svgIcon="svg-icon-add-clipart" aria-hidden="false"></mat-icon>Clip-Art-->
            <!--            </button>-->
            <button class="tool-btn" color="primary" mat-stroked-button (click)="showShapes()">
                <mat-icon svgIcon="svg-icon-add-shape" aria-hidden="false"></mat-icon>
                Shape
            </button>
            <button class="tool-btn" [ngClass]="{'drawing-mode-enabled':isDrawingModeEnabled}" color="primary" mat-stroked-button (click)="toggleDrawingMode()">
                <mat-icon svgIcon="svg-icon-add-shape" aria-hidden="false"></mat-icon>
                Drawing
            </button>
        </div>

        <div *ngIf="isShowClipArts || isShowShapes" class="clip-art-and-shape-div" [ngStyle]="{'top.px': this.clipArtAndShapeDivPosition.top , 'left.px': this.clipArtAndShapeDivPosition.left, 'width.px': this.clipArtAndShapeDivPosition.width}">
            <button type="button" class="clip-art-and-shape-div-close-btn close pull-right" aria-label="Close" (click)="isShowClipArts=false; isShowShapes=false">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="clipart-div d-flex flex-wrap" *ngIf="isShowClipArts">
                <div *ngIf="authService.getCurrentUserType()=='ADMIN'" class="add-clipart-div d-flex justify-content-center">
                    <button class="tool-btn add-clipart-btn" (click)="showFileOpenDialog()" mat-icon-button matTooltip="Add new Clip-Art" [matTooltipPosition]="'above'">
                        <mat-icon>add</mat-icon>
                    </button>
                    <input id="font-upload" #clip_art_upload type="file" accept=".png" hidden (change)="addNewClipArt($event)"/>
                </div>
                <div class="clipart-image" *ngFor="let clipart of clipArts; index as i" (click)="selectedClipArt=clipart; addNewItem()">
                    <img src="{{clipart.url}}" class="img-fluid" alt="" matTooltip="{{clipart.fileName}}" [matTooltipPosition]="'above'">
                </div>
            </div>
            <div class="shape-div d-flex justify-content-around" *ngIf="isShowShapes">
                <div class="shape-image" *ngFor="let shape of shapes; index as i" (click)="selectedShape=shape; addNewItem()">
                    <img src="{{shape.image}}" class="img-fluid" alt="" matTooltip="{{shape.name}}" [matTooltipPosition]="'above'">
                </div>
            </div>
        </div>

        <div class="bgcolor d-flex justify-content-between align-items-center">
            <label class="fill-label">Background Color</label>
            <input type="text" class="fill-input form-control" [(colorPicker)]="backgroundColor" (colorPickerClose)="colorPickerClose()" (colorPickerChange)="bgColorChange()" [style.background]="backgroundColor" [cpEyeDropper]="true" [cpUseRootViewContainer]="true" [cpDialogDisplay]="'popup'" [cpPosition]="'bottom'" [cpMaxPresetColorsLength]="12" [cpPresetColors]="['#ff0038', '#0022ff', '#00ff27', '#fffe00']" [cpAddColorButton]="true">
        </div>
    </div>
</div>

