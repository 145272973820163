import {Injectable} from '@angular/core';
import {ActionsComponent} from "../../pages/tool-and-grid/tool/actions/actions.component";

@Injectable({
    providedIn: 'root'
})
export class ActionComponentService {

    public actionComponent: ActionsComponent;

    constructor() { }

    public saveTemplate()
    {
        if (!this.actionComponent) return;
        this.actionComponent.saveTemplate();
    }

    public publishTemplate()
    {
        if (!this.actionComponent) return;
        this.actionComponent.publishTemplate();
    }

    // public shareTemplate(template){
    //   if(!this.actionComponent) return;
    //   this.actionComponent.shareTemplate(template);
    // }
}
