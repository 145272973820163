import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ManageUsersComponent} from "./manage-users/manage-users.component";
import {AdminConsoleComponent} from "./admin-console.component";
import {MaterialModule} from "../../material.module";
import {ApproveTemplatesComponent} from './approve-templates/approve-templates.component';


@NgModule({
    declarations: [
        AdminConsoleComponent,
        ManageUsersComponent,
        ApproveTemplatesComponent
    ],
    exports: [
        AdminConsoleComponent
    ],
    imports: [
        MaterialModule,
        CommonModule
    ]
})
export class AdminConsoleModule {}
