<div>
    <div class="color-matrix-filters">
        <div class="color-matrix-scroll-area">
            <div class="color-matrix-opt-row row d-flex justify-content-between">
                <div class="item-div row col-12 col-sm-6" [appDivDisable]="imageSectionDisabled">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('grayScale')" [(ngModel)]="activeStyle.grayScaleEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Gray scale</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6 d-flex align-items-center" [appDivDisable]="!activeStyle.grayScaleEnabled || imageSectionDisabled">
                    <select class="font-select select-input" (change)="applyImageFilters('grayScale')" [(ngModel)]="activeStyle.grayScaleMode">
                        <option value="average">Average</option>
                        <option value="luminosity">Luminosity</option>
                        <option value="lightness">Lightness</option>
                    </select>
                </div>
            </div>

            <div class="color-matrix-opt-row row d-flex justify-content-between" [appDivDisable]="imageSectionDisabled">
                <div class="item-div row col-12 col-sm-6">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('invert')" [(ngModel)]="activeStyle.invertEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Invert</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('sepia')" [(ngModel)]="activeStyle.sepiaEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Sepia</label>
                    </div>
                </div>
            </div>

            <div class="color-matrix-opt-row row d-flex justify-content-between" [appDivDisable]="imageSectionDisabled">
                <div class="item-div row col-12 col-sm-6">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('blackAndWhite')" [(ngModel)]="activeStyle.blackAndWhiteEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Black/White</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('brownie')" [(ngModel)]="activeStyle.brownieEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Brownie</label>
                    </div>
                </div>
            </div>

            <div class="color-matrix-opt-row row d-flex justify-content-between" [appDivDisable]="imageSectionDisabled">
                <div class="item-div row col-12 col-sm-6">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('vintage')" [(ngModel)]="activeStyle.vintageEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Vintage</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('kodachrome')" [(ngModel)]="activeStyle.kodachromeEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Kodachrome</label>
                    </div>
                </div>
            </div>

            <div class="color-matrix-opt-row row d-flex justify-content-between" [appDivDisable]="imageSectionDisabled">
                <div class="item-div row col-12 col-sm-6">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('technicolor')" [(ngModel)]="activeStyle.technicolorEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Technicolor</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('polaroid')" [(ngModel)]="activeStyle.polaroidEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Polaroid</label>
                    </div>
                </div>
            </div>

            <div class="color-change-opt-row row d-flex justify-content-between" [appDivDisable]="imageSectionDisabled">
                <div class="item-div row col-12 col-sm-6">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('sharpen')" [(ngModel)]="activeStyle.sharpenEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Sharpen</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('emboss')" [(ngModel)]="activeStyle.embossEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Emboss</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="color-change-filters">
        <div class="color-change-scroll-area">

            <div class="color-change-opt-row row d-flex justify-content-between" [appDivDisable]="imageSectionDisabled">
                <div class="item-div row col-12 col-sm-6">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('gamma')" [(ngModel)]="activeStyle.gammaEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Gamma</label>
                    </div>
                </div>
            </div>

            <div class="group-row color-change-opt-row row d-flex justify-content-between" [appDivDisable]="!activeStyle.gammaEnabled || imageSectionDisabled">
                <div class="col-1"></div>
                <div class="item-div row col-4 d-flex align-items-center">
                    <div class="color-change-label">
                        <label class="item-label">Red</label>
                    </div>
                </div>
                <div class="item-div row col-6 d-flex align-items-center">
                    <mat-slider thumbLabel step="1" min="-100" max="100" [displayWith]="utilService.formatSliderValue100" [(ngModel)]="activeStyle.gammaRedValue" (change)="applyImageFilters('gamma')"></mat-slider>
                </div>
            </div>

            <div class="group-row color-change-opt-row row d-flex justify-content-between" [appDivDisable]="!activeStyle.gammaEnabled || imageSectionDisabled">
                <div class="col-1"></div>
                <div class="item-div row col-4 d-flex align-items-center">
                    <div class="color-change-label">
                        <label class="item-label">Green</label>
                    </div>
                </div>
                <div class="item-div row col-6 d-flex align-items-center">
                    <mat-slider thumbLabel step="1" min="-100" max="100" [displayWith]="utilService.formatSliderValue100" [(ngModel)]="activeStyle.gammaGreenValue" (change)="applyImageFilters('gamma')"></mat-slider>
                </div>
            </div>

            <div class="group-row color-change-opt-row row d-flex justify-content-between" [appDivDisable]="!activeStyle.gammaEnabled || imageSectionDisabled">
                <div class="col-1"></div>
                <div class="item-div row col-4 d-flex align-items-center">
                    <div class="color-change-label">
                        <label class="item-label">Blue</label>
                    </div>
                </div>
                <div class="item-div row col-6 d-flex align-items-center">
                    <mat-slider thumbLabel step="1" min="-100" max="100" [displayWith]="utilService.formatSliderValue100" [(ngModel)]="activeStyle.gammaBlueValue" (change)="applyImageFilters('gamma')"></mat-slider>
                </div>
            </div>

            <div class="color-change-opt-row row d-flex justify-content-between">
                <div class="item-div row col-12 col-sm-6" [appDivDisable]="imageSectionDisabled">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('opacity')" [(ngModel)]="activeStyle.opacityEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Opacity</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6 d-flex align-items-center" [appDivDisable]="!activeStyle.opacityEnabled || imageSectionDisabled">
                    <mat-slider thumbLabel step="0.01" min="0.01" max="1" [(ngModel)]="activeStyle.opacityValue" (change)="applyImageFilters('opacity')"></mat-slider>
                </div>
            </div>

            <div class="color-change-opt-row row d-flex justify-content-between">
                <div class="item-div row col-12 col-sm-6" [appDivDisable]="imageSectionDisabled">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('brightness')" [(ngModel)]="activeStyle.brightnessEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Brigtness</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6 d-flex align-items-center" [appDivDisable]="!activeStyle.brightnessEnabled || imageSectionDisabled">
                    <mat-slider thumbLabel step="1" min="-100" max="100" [displayWith]="utilService.formatSliderValue100" [(ngModel)]="activeStyle.brightnessValue" (change)="applyImageFilters('brightness')"></mat-slider>
                </div>
            </div>

            <div class="color-change-opt-row row d-flex justify-content-between">
                <div class="item-div row col-12 col-sm-6" [appDivDisable]="imageSectionDisabled">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('contrast')" [(ngModel)]="activeStyle.contrastEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Contrast</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6 d-flex align-items-center" [appDivDisable]="!activeStyle.contrastEnabled || imageSectionDisabled">
                    <mat-slider thumbLabel step="1" min="-100" max="100" [displayWith]="utilService.formatSliderValue100" [(ngModel)]="activeStyle.contrastValue" (change)="applyImageFilters('contrast')"></mat-slider>
                </div>
            </div>

            <div class="color-change-opt-row row d-flex justify-content-between">
                <div class="item-div row col-12 col-sm-6" [appDivDisable]="imageSectionDisabled">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('saturation')" [(ngModel)]="activeStyle.saturationEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Saturation</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6 d-flex align-items-center" [appDivDisable]="!activeStyle.saturationEnabled || imageSectionDisabled">
                    <mat-slider thumbLabel step="1" min="1" max="100" [displayWith]="utilService.formatSliderValue100" [(ngModel)]="activeStyle.saturationValue" (change)="applyImageFilters('saturation')"></mat-slider>
                </div>
            </div>

            <div class="color-change-opt-row row d-flex justify-content-between">
                <div class="item-div row col-12 col-sm-6" [appDivDisable]="imageSectionDisabled">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('hue')" [(ngModel)]="activeStyle.hueEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Hue</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6 d-flex align-items-center" [appDivDisable]="!activeStyle.hueEnabled || imageSectionDisabled">
                    <mat-slider thumbLabel step="1" min="-100" max="100" [displayWith]="utilService.formatSliderValue100" [(ngModel)]="activeStyle.hueValue" (change)="applyImageFilters('hue')"></mat-slider>
                </div>
            </div>

            <div class="color-change-opt-row row d-flex justify-content-between">
                <div class="item-div row col-12 col-sm-6" [appDivDisable]="imageSectionDisabled">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('noise')" [(ngModel)]="activeStyle.noiseEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Noise</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6 d-flex align-items-center" [appDivDisable]="!activeStyle.noiseEnabled || imageSectionDisabled">
                    <mat-slider thumbLabel step="10" min="1" max="1000" [(ngModel)]="activeStyle.noiseValue" (change)="applyImageFilters('noise')"></mat-slider>
                </div>
            </div>

            <div class="color-change-opt-row row d-flex justify-content-between">
                <div class="item-div row col-12 col-sm-6" [appDivDisable]="imageSectionDisabled">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('pixelate')" [(ngModel)]="activeStyle.pixelateEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Pixelate</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6 d-flex align-items-center" [appDivDisable]="!activeStyle.pixelateEnabled || imageSectionDisabled">
                    <mat-slider thumbLabel step="1" min="1" max="50" [displayWith]="utilService.formatSliderValuePx" [(ngModel)]="activeStyle.pixelateValue" (change)="applyImageFilters('pixelate')"></mat-slider>
                </div>
            </div>

            <div class="color-change-opt-row row d-flex justify-content-between">
                <div class="item-div row col-12 col-sm-6" [appDivDisable]="imageSectionDisabled">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('blur')" [(ngModel)]="activeStyle.blurEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Blur</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6 d-flex align-items-center" [appDivDisable]="!activeStyle.blurEnabled || imageSectionDisabled">
                    <mat-slider thumbLabel step="0.01" min="0" max="1" [(ngModel)]="activeStyle.blurValue" (change)="applyImageFilters('blur')"></mat-slider>
                </div>
            </div>

            <div class="color-change-opt-row row d-flex justify-content-between">
                <div class="item-div row col-12 col-sm-6" [appDivDisable]="imageSectionDisabled">
                    <div class="item-checkbox col-3 d-flex align-items-center">
                        <mat-checkbox (change)="applyImageFilters('blend-color')" [(ngModel)]="activeStyle.blendColorEnabled"></mat-checkbox>
                    </div>
                    <div class="item-label col-9 d-flex align-items-center">
                        <label>Blend Color</label>
                    </div>
                </div>
                <div class="item-div row col-12 col-sm-6 d-flex align-items-center" [appDivDisable]="!activeStyle.blendColorEnabled || imageSectionDisabled">
                    <input type="text" class="color-picker-input" [(colorPicker)]="activeStyle.blendColorValue" (colorPickerOpen)="colorPickerOpen()" (colorPickerClose)="colorPickerClose()" (colorPickerChange)="applyImageFilters('blend-color')" [style.background]="activeStyle.blendColorValue" [cpEyeDropper]="true" [cpUseRootViewContainer]="true" [cpDialogDisplay]="'popup'" [cpPosition]="'bottom'" [cpMaxPresetColorsLength]="12" [cpPresetColors]="['#ff0038', '#0022ff', '#00ff27', '#fffe00']" [cpAddColorButton]="true">
                </div>
            </div>

            <div class="color-change-opt-row row d-flex justify-content-between" [appDivDisable]="!activeStyle.blendColorEnabled || imageSectionDisabled">
                <div class="col-1"></div>
                <div class="item-div row col-4 d-flex align-items-center">
                    <div class="color-change-label">
                        <label class="item-label">Mode</label>
                    </div>
                </div>
                <div class="item-div row col-6 d-flex align-items-center">
                    <select class="font-select select-input" (change)="applyImageFilters('blend-color')" [(ngModel)]="activeStyle.blendColorMode">
                        <option value="add">Add</option>
                        <option value="diff">Diff</option>
                        <option value="subtract">Subtract</option>
                        <option value="multiply">Multiply</option>
                        <option value="screen">Screen</option>
                        <option value="lighten">Lighten</option>
                        <option value="darken">Darken</option>
                        <option value="overlay">Overlay</option>
                        <option value="exclusion">Exclusion</option>
                        <option value="tint">Tint</option>
                    </select>
                </div>
            </div>
            <div class="color-change-opt-row row d-flex justify-content-between" [appDivDisable]="!activeStyle.blendColorEnabled || imageSectionDisabled">
                <div class="col-1"></div>
                <div class="item-div row col-4 d-flex align-items-center">
                    <div class="color-change-label">
                        <label class="item-label">Opacity</label>
                    </div>
                </div>
                <div class="item-div row col-6 d-flex align-items-center">
                    <mat-slider thumbLabel step="0.01" min="0" max="1" [(ngModel)]="activeStyle.blendColorDistance" (change)="applyImageFilters('blend-color')"></mat-slider>
                </div>
            </div>
        </div>
    </div>
</div>
