import {Component, Inject, OnInit} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {UserStatsDownloadedImage} from "../../../../models/user-stats-downloaded-image";

@Component({
    selector: 'app-downloaded-image-preview',
    templateUrl: './downloaded-image-preview.component.html',
    styleUrls: ['./downloaded-image-preview.component.scss']
})
export class DownloadedImagePreviewComponent implements OnInit {

    public downloadedImage: UserStatsDownloadedImage;

    constructor(public dialogRef: MatDialogRef<DownloadedImagePreviewComponent>,
                @Inject(MAT_DIALOG_DATA) public data: UserStatsDownloadedImage)
    {}

    public ngOnInit(): void
    {
        if (!this.data) return;
        this.downloadedImage = this.data;
    }

    public convertDateToLocalDate(date: string)
    {
        return new Date(date).toLocaleDateString()
    }

}
