import {environment} from '../../environments/environment';

export const ADMIN = "ADMIN";
export const BRONZE_MEMBER = "BRONZE";
export const SILVER_MEMBER = "SILVER";
export const GOLD_MEMBER = "GOLD";
export const PLATINUM_MEMBER = "PLATINUM";
export const DESIGNER = "DESIGNER";

export const KEY_RETURN = "Enter";
export const KEY_CTRL = "Control";
export const KEY_SHIFT = "Shift";
export const KEY_DELETE = "Delete";
export const KEY_BACKSPACE = "Backspace";

export const MESSAGE_BAR_MESSAGE_ASK_TO_LOGIN = "Please login to enable more features and save the template...";
export const MESSAGE_BAR_MESSAGE_NETWORK_DISCONNECTED = "You are offline... Still, you can use the app... But, your data will be synced when you are back online...";
export const MESSAGE_BAR_MESSAGE_NETWORK_CONNECTED = "You are online...";

export const MESSAGE_BAR_TYPE_WARNING_MESSAGES = "warning";
export const MESSAGE_BAR_TYPE_ERROR_MESSAGES = "error";
export const MESSAGE_BAR_TYPE_SUCCESS_MESSAGES = "success";

export const SIGN_IN_DIALOG_WIDTH = 450;
export const SIGN_IN_DIALOG_HEIGHT = 700;
export const CONFIRMATION_DIALOG_WIDTH = 700;
export const CONFIRMATION_DIALOG_HEIGHT = 200;
export const INPUT_DIALOG_WIDTH = 700;
export const INPUT_DIALOG_HEIGHT = 300;
export const DIALOG_XL_WIDTH_PERCENTAGE = '99%';
export const DIALOG_XL_HEIGHT_PERCENTAGE = '99%';

export const UNAUTHORIZED_PATHS = [
    environment.endpoint + 'template/save_template',
    environment.endpoint + 'template/remove_template',
    environment.endpoint + 'template/publish_template',
    environment.endpoint + 'template/unpublish_template',
    environment.endpoint + 'template/create_duplicate',
    environment.endpoint + 'template/add_to_favourite',
    environment.endpoint + 'template/remove_from_favourite',
    environment.endpoint + 'template/change_like_count',

    environment.endpoint + 'clip_art/save',
    environment.endpoint + 'clip_art/delete',

    environment.endpoint + 'style/save',
    environment.endpoint + 'style/delete',

    environment.endpoint + 'fonts/upload',
]

export const GRID_SORT_TYPES = [
    {
        text: 'NAME',
        value: 'name'
    },
    {
        text: 'CREATED DATE',
        value: 'created_date'
    },
    {
        text: 'DOWNLOADS',
        value: 'download_count'
    },
    {
        text: 'LIKES',
        value: 'like_count'
    },
    {
        text: 'VIEWS',
        value: 'view_count'
    }
]

export const CANVAS_TYPES_AND_SIZES = [
    {
        text: 'Youtube Thumbnail (1280px x 720px)',
        type: 'youtube-thumbnail',
        size: {
            width: 1280,
            height: 720
        },
    },
    {
        text: 'Facebook Thumbnail / Facebook Profile Picture (1080px x 1080px)',
        type: 'facebook-thumbnail-profile',
        size: {
            width: 1080,
            height: 1080
        },
    },
    {
        text: 'Facebook Status / Facebook Story (1080px x 1920px)',
        type: 'facebook-status-story',
        size: {
            width: 1080,
            height: 1920
        },
    },
    {
        text: 'Facebook Cover (1200px x 675px)',
        type: 'facebook-cover',
        size: {
            width: 1200,
            height: 675
        },
    },
]

