<div class="about-us container">
    <div class="text-center">
        <h1>Meet the Visionaries Behind ThumbzStudio</h1>
        <h2 class="text-muted">Unveiling the Team at Zyntreck</h2>
      </div>
    
      <div class="mt-5">
        <p>Welcome to the heart of ThumbzStudio, where creativity meets innovation! Our team at Zyntreck is a collective force of professional graphic designers, UI/UX experts, and seasoned web developers. Dive into the world of ThumbzStudio, a creative endeavor proudly nurtured under the umbrella of Zyntreck.</p>
      </div>
    
      <div class="mt-5">
        <h3>About Us</h3>
        <p>At Zyntreck, we believe in the power of visual storytelling. Our team comprises passionate individuals who bring a diverse range of skills and expertise to the table. From crafting eye-catching graphics to designing seamless user experiences and developing robust web solutions, we are united by a shared commitment to pushing the boundaries of creativity.</p>
      </div>
    
      <div class="row mt-4">
        <div class="col-md-4">
          <h4>Professional Graphic Designers</h4>
          <p>Our graphic designers are the artists behind the visuals that captivate and communicate. With a keen eye for detail, a flair for creativity, and a commitment to excellence, they transform ideas into stunning designs that leave a lasting impact.</p>
        </div>
    
        <div class="col-md-4">
          <h4>UI/UX Designers</h4>
          <p>User experience is at the forefront of our design philosophy. Our UI/UX designers are dedicated to creating interfaces that not only look beautiful but also provide an intuitive and delightful user journey. Every click, swipe, and interaction is carefully considered to enhance the overall experience.</p>
        </div>
    
        <div class="col-md-4">
          <h4>Experienced Web Developers</h4>
          <p>The backbone of ThumbzStudio's functionality lies in the expertise of our web developers. They translate creative designs into functional and dynamic web solutions. From ensuring seamless navigation to implementing cutting-edge features, our developers bring the magic to ThumbzStudio's digital canvas.</p>
        </div>
      </div>
    
      <div class="mt-5">
        <h3>ThumbzStudio: A Creative Venture by Zyntreck</h3>
        <p>ThumbzStudio is not just a design tool; it's a testament to the collaborative spirit and creative energy that define Zyntreck. As a property of Zyntreck, ThumbzStudio inherits a legacy of innovation and a commitment to providing users with tools that empower their creativity.</p>
      </div>
    
      <div class="row mt-4">
        <div class="col-md-6">
          <h4>Our Vision</h4>
          <p>At Zyntreck, our vision is to redefine the landscape of digital design. ThumbzStudio is an embodiment of this vision—a platform that empowers individuals to unleash their creativity and turn ideas into visually stunning realities. We believe in democratizing design, making it accessible to everyone.</p>
        </div>
    
        <div class="col-md-6">
          <h4>Our Mission</h4>
          <p>Our mission is to create tools that inspire and elevate. Through ThumbzStudio, we aim to break down the barriers to creativity, providing a space where individuals, businesses, and creators of all kinds can express themselves visually. ThumbzStudio is more than a tool; it's a catalyst for creative journeys.</p>
        </div>
      </div>
    
      <div class="mt-5">
        <h4>Conclusion</h4>
        <p>As you explore ThumbzStudio, remember that it's not just a product; it's a reflection of the passion, dedication, and expertise of the team at Zyntreck. We invite you to join us on this creative journey, where design knows no bounds, and innovation is a constant companion. Welcome to the ThumbzStudio family—a community where creativity thrives, and visions come to life.</p>
      </div>
  </div>
  