<div class="terms-of-use container">
  <div class="mb-4"><br /></div>

  <h1 class="mb-4">Terms of Use</h1>

  <p>
    Zyntreck (Pvt) Ltd. (“Zyntreck,” “we”, “our” or “us”) provides this
    website(“thumbzstudio.com”) and certain online and offline products and
    services (collectively, the“Platform”). These Terms of Service(“Agreement”)
    govern your (“Customer,” “you,” or “your”) access to and use or attempted
    use of the Platform. Your access to or use or attempted use of the Platform
    shall be deemed to constitute your consent to be bound by this Agreement and
    shall be enforceable in the same way as if you had signed this Agreement. If
    you do not agree with the terms of this Agreement, you may not use the
    Platform.
  </p>

  <h2 class="mt-5">Permitted use & licence</h2>
  <p>
    Subject to your payment of all fees and full compliance with this Agreement,
    Zyntreck hereby grants you a limited, revocable, non-exclusive,
    non-transferable, non-sub licensable right and licence during the term to
    access and use the portions of the Platform. You may only use the Platform
    for your internal business purposes, and not for the benefit of any other
    person or entity.
  </p>

  <h2 class="mt-5">Updates</h2>
  <p>
    Zyntreck may modify or update the Platform at any time, in its discretion.
    We reserve the right at any time and from time to time to modify or
    discontinue, temporarily or permanently, any part of the Service with or
    without notice. All updates provided to customers generally shall also be
    made available to you and shall be subject to this Agreement and any
    additional terms provided to you at the time of the update’s release. After
    the effective date of such update, we shall bear no obligation to run,
    provide or support legacy versions of the Platform.
  </p>

  <h2 class="mt-5">Access & accounts</h2>
  <p>
    Zyntreck may update, refresh or change the manner of accessing the Platform
    at its discretion. Zyntreck shall provide you with non-transferable access
    credentials for the Platform.
  </p>

  <p>You agree not to,</p>
  <ul>
    <li>
      Misrepresent or mask identities when using the Platform or seeking access
      credentials.
    </li>
    <li>
      Select or use as a username a name subject to any rights of a person or
      entity without appropriate authorization.
    </li>
    <li>
      Select or use, as your username, a name that is offensive, vulgar or
      obscene.
    </li>
    <li>Exceed any access permitted by Zyntrck.</li>
  </ul>

  <p>You agree to,</p>
  <ul>
    <li>
      Immediately notify Zyntreck of any unauthorised use of your password or
      account or any other breach of security.
    </li>
    <li>Ensure that you exit your account at the end of each session.</li>
    <li>
      Only your employees and contractors (“Personnel”) may use the Platform.
    </li>
    <li>
      You shall require your Personnel to comply with all applicable laws and
      the use restrictions set out in the Agreement or otherwise prescribed by
      Zyntreck.
    </li>
    <li>
      You represent and warrant that your Personnel have the capacity and
      authority to enter into this Agreement.
    </li>
    <li>
      You will be fully responsible for any acts or omissions of your Personnel,
      whether authorised or unauthorised.
    </li>
  </ul>

  <h2 class="mt-5">Registration</h2>
  <p>
    As part of the registration and account creation process, you will provide
    Zyntreck with certain registration information, all of which must be
    accurate, truthful, and updated. In consideration for your use of the
    Platform, you represent and warrant that (i) you are over the age of
    eighteen (18) and not barred from receiving the Platform under the laws of
    your country or any other applicable jurisdiction, (ii) your use of the
    Platform does not violate any applicable law or regulation, and (iii) you
    access the Platform through one or more humans rather than through automated
    means. Zyntreck reserves the right to deny the creation of or suspend your
    account based on our inability to verify the authenticity of your
    registration information or for any violation of this Section.
  </p>

  <h2 class="mt-5">Subscription fees</h2>
  <p>
    In consideration for the provision of the Service, the Customer shall pay us
    the applicable fees per the purchased Subscription. Customer hereby
    authorises us, either directly or through our payment processing service or
    our affiliates, to charge such Subscription Fees via Customer’s selected
    payment method, upon the due date. This subscription fee for the current
    term is non-cancellable once paid and non-refundable. We reserve the right
    to change the Subscription Fees at any time, upon notice to Customer if such
    change may affect Customer’s existing subscriptions upon renewal. In the
    event of failure to collect the Fees owed by Customer, we may, at our sole
    discretion (but shall not be obligated to) retry to collect at a later time,
    and/or suspend or cancel the Account, without notice. Please note that if
    you downgrade your Platform level or otherwise remove any paid component or
    feature, you may cause the loss of Content or features for your account.
    Zyntreck does not accept any liability for such loss.
  </p>

  <h2 class="mt-5">Renewals</h2>
  <p>
    Your Platform subscription will be automatically renewed at the end of your
    subscription period and will automatically renew for successive
    subscriptions of the same length, without prior notice to you, unless and
    until you cancel your membership, or we terminate it. You must cancel your
    subscription thirty (30) days before it renews to avoid the next period’s
    subscription fees.
  </p>

  <h2 class="mt-5">Ownership & license.</h2>
  <p>
    As between you and us, you are the owner of all rights, titles and interests
    in and to your Content. By uploading, posting, emailing, transmitting,
    submitting or otherwise making available Content to or through the Platform,
    you grant Zyntreck the irrevocable, non-exclusive, worldwide, transferable,
    royalty-free, fully paid up right and license during the term to use the
    Content to provide the Platform to you or as otherwise contemplated by this
    Agreement. “Content” means any information, data, code, video, images, text,
    documents or other materials of any type that you upload, post, email,
    transmit, submit or otherwise make available to or through the Platform,
    regardless of whether it is publicly posted or privately transmitted.
  </p>

  <h2 class="mt-5">
    Third-party services, integration services, independent relationships
  </h2>
  <p>
    You acknowledge and agree that regardless of the manner in which such Third
    Party Services may be offered to you, we merely act as an intermediary
    platform between you and such Third Party Services, and we do not, in any
    way, endorse any such Third Party Services, or shall be in any way
    responsible or liable concerning any such Third Party Services. Your
    relationship with such Third Party Services and any terms governing your
    payment for (if any), and use of, such Third Party Services, including
    without limitation, the collection, processing and use of your data by such
    Third Party Services, are subject to a separate contractual arrangement
    between you and the provider of a Third Party Service (the “Third Party
    Agreement”). We are not a party to, or responsible, in any manner, for the
    compliance by you or by the provider of the Third Party Service with the
    Third Party Agreement.
  </p>
  <p>
    Through the Service, you and any other Authorized User within the Account,
    may enable integration of your Account with Third Party Services, which will
    allow an exchange, transmission, modification or removal of data between us
    and the Third Party Service, including without limitation, the Customer
    Data, the scope of which is determined by the applicable actions set by such
    integration. You hereby acknowledge that any access, collection,
    transmission, processing, storage or any other use of data, including the
    Customer Data, by a Third Party Service, is governed by the Third Party
    Agreement, including any applicable privacy policy, and Zyntreck is not
    responsible for any access, collection, transmission, processing, storage or
    any other use of data, including the Customer Data, by the Third Party
    Service or for such Third Party Service privacy and security actions,
    inactions or general practices.
  </p>
  <p>
    By integrating and/or using the Third Party Services, you acknowledge and
    agree that you are solely responsible for your compliance with applicable
    privacy restrictions, laws and regulations, including your use of the Third
    Party Service and other data activities you may conduct or may permit third
    parties, including the Third Party Service, to conduct the activities and
    use of the data by you and any other Users within the Account, may result in
    a modification and/or removal of data, either in the Account (i.e. Customer
    Data) and in the integrated Third Party Service. We shall have no obligation
    of any kind, for any such modification and/or removal of data, either in the
    Account with us and/or the integrated Third Party Service.
  </p>

  <h2 class="mt-5">Links</h2>
  <p>
    The Platform and/or any Third Party Services may contain links to
    third-party websites that are not owned or controlled by us. You acknowledge
    that we have no control over and assume no responsibility for the content,
    privacy policies, or practices of, any third-party websites.
  </p>

  <h2 class="mt-5">Our intellectual property</h2>
  <p>
    The Service and Site, inclusive of materials, such as software, application
    programming interface, design, text, editorial materials, informational
    text, photographs, illustrations, audio clips, video clips, artwork and
    other graphic materials, names, logos, trademarks and services marks
    (excluding Customer Data), any and all related or underlying technology and
    any modifications, enhancements or derivative works of the foregoing
    (collectively, “thumbzstudio.com Materials”), are the property of Zyntreck
    and its licensors and may be protected by applicable copyright or other
    intellectual property laws and treaties. As between you and us, Zyntreck
    retains all rights, titles and interests, including all intellectual
    property rights, in and to the thumbzstudio.com Materials.
  </p>

  <h2 class="mt-5">Prohibited content & behaviours</h2>
  <p>You agree to not use the Platform to:</p>
  <ul>
    <li>
      monitor its performance, availability, or functionality, or for any other
      benchmarking or competitive purposes;
    </li>
    <li>
      upload, post, email, transmit, submit or otherwise make available any
      Content that is harmful, threatening, abusive, harassing, tortuous,
      defamatory, vulgar, obscene, libellous, hateful, or racially, ethnically
      or otherwise objectionable harm minors in any way;
    </li>
    <li>
      impersonate any person or entity, or falsely state or otherwise
      misrepresent your affiliation with a person or entity;
    </li>
    <li>
      disguise the origin of any Content transmitted through the Platform;
    </li>
    <li>
      upload, post, email, transmit or otherwise make available any unsolicited
      or unauthorized advertising, promotional materials, “junk mail”, “spam”,
      or any other form of solicitation;
    </li>
    <li>
      act in a manner that negatively affects other users’ ability to use the
      Platform;
    </li>
    <li>
      interfere with or disrupt the Platform or servers or networks connected to
      the Platform, or disobey any requirements, procedures, policies or
      regulations of networks connected to the Platform;
    </li>
    <li>violate any applicable local, state, national or international law;</li>
    <li>
      provide material support or resources (or conceal or disguise the nature,
      location, source, or ownership of material support or resources) to any
      organization(s) designated by any jurisdiction.
    </li>
    <li>
      attempt to override or circumvent any of the usage rules embedded into the
      Platform.
    </li>
  </ul>

  <h2 class="mt-5">Content and Designs</h2>
  <p>
    You represent and warrant that you own all rights, title, and interest in
    and to your User Content or that you have otherwise secured all necessary
    rights in your User Content as may be necessary to permit the access, use
    and distribution thereof as contemplated by these Terms. As between you and
    ThumbzStudio, you own all right, title and interest in and to your User
    Content. You grant ThumbzStudio a royalty-free and sublicensable license to
    display, host, copy, store and use your User Content solely to the extent
    necessary to provide the Service to you. To the extent you include User
    Content in a Design that you’ve shared with others, you grant ThumbzStudio a
    perpetual, royalty-free, sublicensable, license to display, host, copy,
    store and use your User Content to the extent necessary to continue to make
    that Design available.
  </p>
  <p>
    When you upload content to ThumbzStudio, you’re guaranteeing that you have
    the rights to it. We never obtain any ownership over your content, but we do
    need you to give us certain rights to store it and have it ready for you to
    use in your designs
  </p>

  <h3 class="mt-4">​​Use of Templates</h3>
  <p>
    Users have access to both free and premium templates provided by
    ThumbzStudio for their design needs. These templates are created using
    copyright-free open-source fonts and copyright-free images, ensuring
    compliance with all applicable intellectual property laws. Each template is
    carefully designed by the ThumbzStudio Design Team.
  </p>
  <p>
    Users are granted the flexibility to edit and customize the templates as
    they see fit, allowing them to tailor the designs to their specific
    requirements.
  </p>

  <h2 class="mt-5">License restrictions</h2>
  <p>You maynot</p>
  <ul>
    <li>
      Use any Zyntreck Confidential Information to create any services, product,
      software, documentation, or data that is competitive with, substantially
      similar to, or confusingly similar to any aspect of the Platform.
    </li>
    <li>
      Use, modify, display, perform, copy, disclose, or create derivative works
      of the Platform.
    </li>
    <li>
      Reverse engineer, decompile, disassemble, mimic, screen-scrape, frame, or
      mirror the Platform, or use any other means to attempt to discover their
      source code.
    </li>
    <li>
      Benchmark, encumber, distribute, sublicense, assign, share, sell, rent,
      lease, pledge, or otherwise transfer the Platform to any third party.
    </li>
    <li>
      Access via automated or unauthorized means, interfere with, disrupt, or
      attempt to monitor, override access, or circumvent security measures for,
      the Platform or related systems, including via robots, spiders, and other
      electronic methods.
    </li>
    <li>
      Use the Platform in violation of applicable law, rule, or regulation,
      including without limitation laws, rules, and regulations concerning
      privacy and data security.
    </li>
    <li>
      Use the Platform in any manner that intentionally damages, disables,
      overburdens, or impairs any the Platform or interferes with any other
      party’s use of the Platform, including without limitation by using or
      launching any automated system that sends more request messages to servers
      in a given period of time than a human can reasonably produce in the same
      period by using a conventional browser.
    </li>
    <li>
      Attempt to gain unauthorized access to the Platform, including without
      limitation by breaching, disabling, tampering with, or developing or using
      (or attempting to do the same) any workaround for the Platform or any
      security measure related thereto.
    </li>
    <li>
      Set, read, write, modify, or delete any cookie on a Zyntreck-owned or
      operated web domain, obscure, remove, or alter any proprietary rights or
      other notices on the Platform.
    </li>
  </ul>
  <p>
    Notwithstanding anything to the contrary herein, Zyntreck may, in its sole
    discretion, immediately revoke the grant of rights contemplated in the
    previous paragraph if you breach the restrictions in this Section or create
    other security or legal concerns. You hereby agree that Zyntreck will be
    entitled, in addition to any other remedies available to it at law or in
    equity, to injunctive relief to prevent the breach or threatened breach of
    your obligations under this Section, without any requirement to demonstrate
    irreparable harm or post a bond.
  </p>

  <p>
    NOTWITHSTANDING ANYTHING IN THESE TERMS OR ELSEWHERE TO THE CONTRARY AND TO
    THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW:
  </p>
  <p>
    IN NO EVENT SHALL EITHER PARTY HERETO AND ITS AFFILIATES, SUBCONTRACTORS,
    AGENTS AND VENDORS (INCLUDING, THE THIRD-PARTY SERVICE PROVIDERS), BE LIABLE
    UNDER, OR OTHERWISE IN CONNECTION WITH THESE TERMS FOR (I) ANY INDIRECT,
    EXEMPLARY, SPECIAL, CONSEQUENTIAL, INCIDENTAL OR PUNITIVE DAMAGES; (II) ANY
    LOSS OF PROFITS, COSTS, ANTICIPATED SAVINGS; (III) ANY LOSS OF, OR DAMAGE TO
    DATA, USE, BUSINESS, REPUTATION, REVENUE OR GOODWILL; AND/OR (IV) THE
    FAILURE OF SECURITY MEASURES AND PROTECTIONS, WHETHER IN CONTRACT, TORT OR
    UNDER ANY OTHER THEORY OF LIABILITY OR OTHERWISE, AND WHETHER OR NOT SUCH
    PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE, AND
    EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
  </p>

  <h2 class="mt-5">Warranties</h2>
  <p>
    THE COMPANY DOES NOT PROVIDE ANY WARRANTIES AND REPRESENTATIONS REGARDING
    THE PLATFORM AND ITS SERVICES. THE COMPANY DISCLAIMS ALL WARRANTIES AND
    REPRESENTATIONS OF ANY KIND WITH REGARD TO THE USE OR INABILITY TO USE THE
    WEBSITE AND SERVICES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
    NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, FREEDOM FROM VIRUSES OR OTHER
    HARMFUL CODE, OR FITNESS FOR ANY PARTICULAR PURPOSE. THE COMPANY WILL NOT BE
    LIABLE FOR ANY DELAY, DIFFICULTY IN USE, INACCURACY OF INFORMATION, COMPUTER
    VIRUSES, MALICIOUS CODE OR OTHER DEFECTS IN THIS PLATFORM AND THE SERVICES.
  </p>

  <h2 class="mt-5">Termination</h2>
  <p>
    Zyntreck may terminate or suspend this Agreement immediately in the event of
    your breach or threatened breach of any condition, confidentiality or
    intellectual property provisions of this Agreement or in the event any fees
    come past due. Either party may terminate this agreement immediately for (i)
    material breach if such breach, to the extent curable, remains uncured ten
    (10) days after notice thereof; (ii) convenience on thirty (30) days advance
    written notice to the other party; or (iii) if a party becomes the subject
    of a petition in bankruptcy or any other proceeding relating to insolvency,
    liquidation or assignment for the benefit of creditors. For the avoidance of
    doubt, you understand that there will be no fees refunded for any partial
    terms in the event of termination of this Agreement.
  </p>
  <p>
    Upon termination or expiration of this Agreement, you will immediately pay
    all fees and other amounts due to Zyntreck and stop all use of the Platform.
    Further, all applicable licenses and access granted to you shall
    automatically terminate. Notwithstanding the foregoing, terms that by their
    nature should survive expiration or termination of this Agreement shall so
    survive.
  </p>

  <h2 class="mt-5">Indemnification</h2>
  <p>
    You agree to indemnify and hold the Company, its subsidiaries, affiliates,
    shareholders, officers, directors, agents, employees and representative and
    Company licensors, their subsidiaries, affiliates, shareholders, officers,
    directors, agents, employees and representatives harmless from any claims
    and demands, including reasonable attorneys’ fees, made by any third party
    arising from or relating to,
  </p>
  <ul>
    <li>Your use of and access to the Platform.</li>
    <li>
      Content you submit, post, transmit, or otherwise make available via the
      Website and the Service.
    </li>
    <li>
      Your violation of these Terms & Conditions of Use or the Company’s Privacy
      Policy. This indemnification obligation will survive the termination of
      your account or these Terms & Conditions of Use.
    </li>
  </ul>

  <h2 class="mt-5">Waiver and severability of terms</h2>
  <p>
    The failure of Zyntreck to exercise or enforce any right or provision of the
    Agreement shall not constitute a waiver of such right or provision. If any
    provision of the Agreement is found by a court of competent jurisdiction to
    be invalid under applicable law, the parties nevertheless agree that the
    court should endeavour to give effect to the parties’ intentions to the
    greatest extent possible as reflected in the provision, and the other
    provisions of the Agreement shall remain in full effect thumbzstudio.com is
    owned by Zyntreck (Pvt) Ltd.
  </p>
</div>
