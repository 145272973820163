<div class="template" (mouseenter)="showTemplateItemCountDetails = true;" (mouseleave)="showTemplateItemCountDetails = false;">
    <div *ngIf="type == 'general'" [ngStyle]="{'opacity': showTemplateItemCountDetails ? 1 : 0}" class="template-item-count-details d-flex justify-content-between">
        <div>
            <mat-chip class="d-flex align-items-center justify-content-center">
                <mat-icon class="download">download</mat-icon>
                {{ template.downloadCount| number:'1.0-0' }}
            </mat-chip>
            <mat-chip class="d-flex align-items-center justify-content-center">
                <mat-icon class="visibility">visibility</mat-icon>
                {{ template.viewCount| number:'1.0-1' }}
            </mat-chip>
            <mat-chip class="d-flex align-items-center justify-content-center">
                <mat-icon class="thumb_up">thumb_up</mat-icon>
                {{ template.likeCount| number:'1.0-1' }}
            </mat-chip>
        </div>
    </div>
    <div class="template-item-default-image d-flex justify-content-center">
        <div class="template-item-image">
            <img [src]="template.gridPreviewUrl" class="img-fluid" [alt]="template.description"></div>
    </div>
    <div class="template-item-info">
        <h4>{{ template.name }}</h4>
    </div>
    <div *ngIf="(type == 'general') || (type == 'purchased') || (type == 'favourite')" class="price-div">
        <span class="price-span">{{ template.price == 0 ? "Free" : template.price + '$' }}</span>
    </div>
    <div *ngIf="type == 'published'" class="publish-status-div">
        <span class="publish-status-span">{{ template.isApproved ? "Approved" : 'Pending Approve' }}</span>
    </div>
</div>

