import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CanvasComponentService} from '../../services/component-service/canvas-component.service';
import {TemplateService} from "../../services/temaplate.service";
import {ToolAndGridComponentService} from "../../services/component-service/tool-and-grid-component.service";
import {LocalStorageService} from "../../services/local-storage.service";
import {ToolComponentService} from "../../services/component-service/tool-component.service";
import {ConfirmationBoxComponent} from "../common-components/confirmation-box/confirmation-box.component";
import {CANVAS_TYPES_AND_SIZES, CONFIRMATION_DIALOG_HEIGHT, CONFIRMATION_DIALOG_WIDTH} from "../../util/app-consts";
import {SearchEngineOptimizationService} from "../../services/search-engine-optimization.service";

@Component({
    selector: 'app-select-canvas-size',
    templateUrl: './select-canvas-size.component.html',
    styleUrls: ['./select-canvas-size.component.scss']
})
export class SelectCanvasSizeComponent implements OnInit, AfterViewInit {

    selectedCanvasTypeAndSize: any;
    pageTypesAndSizes: any = [];
    customTypeAndSize;
    loadBtnDisable = false;

    constructor(public dialogRef: MatDialogRef<SelectCanvasSizeComponent>,
                public dialog: MatDialog,
                public canvasComponentService: CanvasComponentService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public searchEngineOptimizationService: SearchEngineOptimizationService,
                public templateService: TemplateService,
                public localStorageService: LocalStorageService,
                public toolComponentService: ToolComponentService)
    {
    }

    ngOnInit(): void
    {
        this.initPageSizes();

        let selectedCanvasTypeAndSize = this.templateService.getCurrentCanvasTypeAndSize(); // sets by query params

        if (selectedCanvasTypeAndSize == null)
        {
            selectedCanvasTypeAndSize = this.localStorageService.loadCanvasTypeAndSize();
        }

        if (selectedCanvasTypeAndSize != null)
        {
            if (selectedCanvasTypeAndSize['type'] == this.customTypeAndSize['type']) //custom size is not in the pageSizes array
            {
                this.selectedCanvasTypeAndSize = this.customTypeAndSize;
            }
            else
            {
                for (let pageTypeAndSize of this.pageTypesAndSizes)
                {
                    if (pageTypeAndSize['type'] == selectedCanvasTypeAndSize['type'])
                    {
                        this.selectedCanvasTypeAndSize = pageTypeAndSize;
                        break;
                    }
                }
                if (this.selectedCanvasTypeAndSize == null)
                {
                    this.selectedCanvasTypeAndSize = this.pageTypesAndSizes[0];
                }
            }
        }
        else
        {
            this.selectedCanvasTypeAndSize = this.pageTypesAndSizes[0];
        }
    }

    ngAfterViewInit()
    {
        if (this.toolAndGridComponentService.getIsDarkThemeEnable())
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.add("dark-theme");
        }
        else
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.remove("dark-theme");
        }
    }

    public loadSizeAndRedrawCanvas()
    {
        if (this.canvasComponentService.isCanvasDirty())
        {
            const initialState = {
                title: 'Canvas will be reset when the canvas is resized... Are you sure?'
            };
            const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
                data: initialState,
                width: CONFIRMATION_DIALOG_WIDTH + 'px',
                height: CONFIRMATION_DIALOG_HEIGHT + 'px',
                maxWidth: CONFIRMATION_DIALOG_WIDTH,
                maxHeight: CONFIRMATION_DIALOG_HEIGHT
            });
            dialogRef.afterClosed().subscribe(result =>
            {
                if (result == "yes")
                {
                    this.doResizeAndRedrawCanvas();
                }
                else
                {
                    this.dialogRef.close();
                }
            });
        }
        else
        {
            this.doResizeAndRedrawCanvas();
        }
    }

    private doResizeAndRedrawCanvas()
    {
        this.templateService.setCurrentCanvasTypeAndSize(this.selectedCanvasTypeAndSize);
        this.localStorageService.saveCanvasTypeAndSize(this.selectedCanvasTypeAndSize);

        this.dialogRef.close('load');

        setTimeout(() =>
        {
            this.toolComponentService.redrawCanvas(this.selectedCanvasTypeAndSize['type'], this.selectedCanvasTypeAndSize['size']);
        }, 1000);
    }

    public validate()
    {
        this.loadBtnDisable = (this.selectedCanvasTypeAndSize == this.customTypeAndSize) && (!this.customTypeAndSize.size.width || !this.customTypeAndSize.size.height)
    }

    private initPageSizes()
    {
        this.pageTypesAndSizes = CANVAS_TYPES_AND_SIZES;

        this.customTypeAndSize = {
            text: 'Custom Size',
            type: 'custom',
            size: {
                width: null,
                height: null
            },
        }
    }

}
