import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {SignInDialogComponent} from "../pages/sign-in/sign-in-dialog.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {SIGN_IN_DIALOG_HEIGHT, SIGN_IN_DIALOG_WIDTH} from "../util/app-consts";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    constructor(public authService: AuthService,
                public notification: ToastrService,
                public dialog: MatDialog)
    {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if (!this.authService.isUserLoggedIn() && this.authService.isUnAuthorizedUrl(request.url))
        {
            this.notification.warning("Please sign in to perform this action...", 'Warning');
            this.dialog.open(SignInDialogComponent, {
                width: SIGN_IN_DIALOG_WIDTH + 'px',
                height: SIGN_IN_DIALOG_HEIGHT + 'px',
                maxWidth: SIGN_IN_DIALOG_WIDTH,
                maxHeight: SIGN_IN_DIALOG_HEIGHT
            })

            return next.handle(null);
        }

        let token = this.authService.getAuthToken();
        const authRequest = request.clone({
            headers: request.headers.set('Authorization-Token', "Bearer/" + token)
        });

        return next.handle(authRequest);
    }
}
