import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PricingDialogComponent} from './pages/pricing/pricing-dialog.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SignInDialogComponent} from './pages/sign-in/sign-in-dialog.component';
import {SelectCanvasSizeComponent} from './pages/select-canvas-size/select-canvas-size.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {AuthorizationInterceptor} from './interceptors/authorization.interceptor';
import {environment} from '../environments/environment';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {HttpProgressInterceptor} from "./interceptors/http-progress.interceptor";
import {CacheManagementInterceptor} from "./interceptors/cache-mangement.interceptor";
import {WelcomeComponent} from "./pages/welcome/welcome.component";
import {AdminConsoleModule} from "./pages/admin-console/admin-console.module";
import {CommonComponentsModule} from "./pages/common-components/common-components.module";
import {DirectivesModule} from "./directives/directives.module";
import {ToolAndGridModule} from "./pages/tool-and-grid/tool-and-grid.module";
import {TemplatePreviewComponent} from "./pages/template-preview/template-preview.component";
import {ThumbnailDownloadComponent} from "./pages/thumbnail-download/thumbnail-download.component";
import {TemplatesComponent} from "./pages/templates/templates.component";
import {UserStatsModule} from "./pages/user-stats/user-stats.module";
import {TermsOfUseComponent} from "./pages/terms-of-use/terms-of-use.component";
import {PrivacyPolicyComponent} from "./pages/privacy-policy/privacy-policy.component";
import {AboutUsComponent} from "./pages/about-us/about-us.component";
import {HowToUseComponent} from "./pages/how-to-use/how-to-use.component";

@NgModule({
	declarations: [
		AppComponent,
		WelcomeComponent,
		SignInDialogComponent,
		SelectCanvasSizeComponent,
		PricingDialogComponent,
        TemplatePreviewComponent,
        ThumbnailDownloadComponent,
        TemplatesComponent,
		TermsOfUseComponent,
		PrivacyPolicyComponent,
		AboutUsComponent,
		HowToUseComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		MaterialModule,
		FormsModule,
		HttpClientModule,
		DirectivesModule,
		CommonComponentsModule,
		AdminConsoleModule,
		UserStatsModule,
		ToolAndGridModule,
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-right'
		}),
		NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics_measurementID),
		NgxGoogleAnalyticsRouterModule
	],
	providers: [
		{provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: CacheManagementInterceptor, multi: true},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
