import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {TemplateService} from "../services/temaplate.service";
import {ToolAndGridComponentService} from "../services/component-service/tool-and-grid-component.service";

@Injectable()
export class HttpProgressInterceptor implements HttpInterceptor {

    constructor(public toolAndGridComponentService: ToolAndGridComponentService,
                public templateService: TemplateService)
    {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if (request.reportProgress)
        {
            // only intercept when the request is configured to report its progress
            return next.handle(request).pipe(tap((event: HttpEvent<any>) =>
                {
                    if (event.type === HttpEventType.DownloadProgress)
                    {
                        this.toolAndGridComponentService.totalSizeToDownloadToShowInProgressSpinner = (event.total / 1000000).toFixed(2);
                        this.toolAndGridComponentService.downloadedSizeToShowInProgressSpinner = (event.loaded / 1000000).toFixed(2);

                        this.toolAndGridComponentService.progressSpinnerValue = Math.round(event.loaded / event.total * 100); // display & update spinner progress
                    }
                    else if (event.type === HttpEventType.Response)
                    {
                        this.toolAndGridComponentService.progressSpinnerValue = 100;

                        if (event.url && event.url.endsWith('.thumbaa'))
                        {
                            this.toolAndGridComponentService.progressSpinnerText = "Processing the Template";
                            this.toolAndGridComponentService.progressSpinnerMode = "indeterminate";
                        }
                    }
                }, error =>
                {
                    this.toolAndGridComponentService.progressSpinnerValue = 0
                })
            );
        }
        else
        {
            return next.handle(request);
        }
    }
}
