import {Injectable} from '@angular/core';
import {ToolComponent} from "../../pages/tool-and-grid/tool/tool.component";

@Injectable({
    providedIn: 'root'
})
export class ToolComponentService {

    public toolComponent: ToolComponent;

    constructor() { }

    public redrawCanvas(canvasType, canvasSize)
    {
        this.toolComponent?.redrawCanvas(canvasType, canvasSize);
    }
}
