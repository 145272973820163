import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToolAndGridComponent} from "./tool-and-grid.component";
import {MaterialModule} from "../../material.module";
import {GridCellComponent} from "./grid-cell/grid-cell.component";
import {DirectivesModule} from "../../directives/directives.module";
import {ToolComponent} from "./tool/tool.component";
import {CanvasComponent} from "./tool/canvas/canvas.component";
import {StylesComponent} from "./tool/styles/styles.component";
import {AddNewComponent} from "./tool/addnew/add-new.component";
import {FontsComponent} from "./tool/styles/fonts/fonts.component";
import {LayersComponent} from "./tool/layers/layers.component";
import {ActionsComponent} from "./tool/actions/actions.component";
import {ImageComponent} from "./tool/styles/image/image.component";
import {DrawingComponent} from "./tool/styles/drawing/drawing.component";
import {SavePublishTemplateComponent} from "./tool/actions/save-publish-template/save-publish-template.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ColorPickerModule} from "ngx-color-picker";


@NgModule({
    declarations: [
        ToolAndGridComponent,
        GridCellComponent,
        ToolComponent,
        CanvasComponent,
        StylesComponent,
        AddNewComponent,
        FontsComponent,
        LayersComponent,
        ActionsComponent,
        ImageComponent,
        DrawingComponent,
        SavePublishTemplateComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ColorPickerModule,
        DirectivesModule,
        MaterialModule
    ]
})
export class ToolAndGridModule {}
