import {Injectable} from '@angular/core';
import {StylesComponent} from '../../pages/tool-and-grid/tool/styles/styles.component';

@Injectable({
    providedIn: 'root'
})
export class StyleComponentService {

    public styleComponent: StylesComponent;

    constructor() { }

    public setFillSectionDisabled(state): void
    {
        if (!this.styleComponent) return;
        this.styleComponent.setFillSectionDisabled(state);
    }

    public setOutlineSectionDisabled(state): void
    {
        if (!this.styleComponent) return;
        this.styleComponent.setOutlineSectionDisabled(state);
    }

    public setShadowSectionDisabled(state): void
    {
        if (!this.styleComponent) return;
        this.styleComponent.setShadowSectionDisabled(state);
    }

    public setGlowSectionDisabled(state): void
    {
        if (!this.styleComponent) return;
        this.styleComponent.setGlowSectionDisabled(state);
    }

    public refreshStyles()
    {
        if (!this.styleComponent) return;
        this.styleComponent.refreshStyles();
    }
}
