<div class="content">
    <div class="canvas-type-select-btn-div col-lg-8 col-xl-4 p-0">
        <button class="btn dropdown-toggle canvas-type-select-drop-down-btn" type="button" data-toggle="dropdown">
            {{ selectedCanvasType?.text }}
        </button>
        <div class="dropdown-menu canvas-type-select-dropdown-menu">
            <div *ngFor="let canvasType of canvasTypes; let i = index" class="canvas-type-select-drop-down-option" (click)="canvasTypeChanged(canvasType)" [ngClass]="{'canvas-type-by-option-selected':selectedCanvasType==canvasType}">
                <label class="form-check-label canvas-type-select-option-label"> {{ canvasType.text }}</label>
            </div>
        </div>
    </div>

    <div class="templates-div row">
        <div *ngFor="let template of templates" class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2 template-item" data-aos="fade-in">
            <img [src]=template.gridPreviewUrl (click)="showPreview(template)">
        </div>
        <div *ngIf="templates.length  > 0" class="load-more-btn-div">
            <button class="btn btn-primary btn-ok load-more-btn" mat-button (click)="loadMore()">Load More...</button>
        </div>
    </div>

    <div *ngIf="isProgressSpinnerVisible" class="template-progress-spinner d-flex justify-content-center align-items-end">
        <mat-progress-spinner class="progress-spinner" [color]="'accent'" [mode]="'indeterminate'"></mat-progress-spinner>
        <span>Loading...</span>
    </div>
</div>
