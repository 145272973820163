import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ToolAndGridComponent} from './pages/tool-and-grid/tool-and-grid.component';
import {AdminConsoleComponent} from "./pages/admin-console/admin-console.component";
import {WelcomeComponent} from "./pages/welcome/welcome.component";
import {TemplatePreviewComponent} from "./pages/template-preview/template-preview.component";
import {UserStatsComponent} from "./pages/user-stats/user-stats.component";
import {ThumbnailDownloadComponent} from "./pages/thumbnail-download/thumbnail-download.component";
import {TemplatesComponent} from "./pages/templates/templates.component";
import {PrivacyPolicyComponent} from "./pages/privacy-policy/privacy-policy.component";
import {TermsOfUseComponent} from "./pages/terms-of-use/terms-of-use.component";
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HowToUseComponent } from './pages/how-to-use/how-to-use.component';

const routes: Routes = [
	{path: '', component: WelcomeComponent},
	{path: 'tool', component: ToolAndGridComponent},
	{path: 'admin-console', component: AdminConsoleComponent},
	{path: 'user-stats', component: UserStatsComponent},
	{path: 'templates', component: TemplatesComponent},
	{path: 'template/:shareable-code', component: ToolAndGridComponent},
	{path: 'template-preview/:shareable-code', component: TemplatePreviewComponent},
	{path: 'thumbnail-download', component: ThumbnailDownloadComponent},
	{path: 'privacy-policy', component: PrivacyPolicyComponent},
	{path: 'terms-of-use', component: TermsOfUseComponent},
	{path: 'how-to-use', component: HowToUseComponent },
	{path: 'about-us', component: AboutUsComponent },
	{path: '**', redirectTo: ''}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
