import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CanvasComponentService} from '../../../services/component-service/canvas-component.service';
import {ToolAndGridComponentService} from "../../../services/component-service/tool-and-grid-component.service";
import {ToolComponentService} from "../../../services/component-service/tool-component.service";

@Component({
    selector: 'app-tool',
    templateUrl: './tool.component.html',
    styleUrls: ['./tool.component.scss']
})
export class ToolComponent implements OnInit, AfterViewInit {

    public rightHeight: number;
    public isWindowSmall: boolean = false;
    public showCanvas: boolean = true;

    @Output("onCanvasSizeChanged") private canvasSizeChangeEvent = new EventEmitter();

    constructor(public toolComponentService: ToolComponentService,
                public canvasComponentService: CanvasComponentService,
                public toolAndGridComponentService: ToolAndGridComponentService)
    {
        this.toolComponentService.toolComponent = this;
    }

    ngOnInit(): void
    {
        this.isWindowSmall = false;
    }

    ngAfterViewInit()
    {
        setTimeout(() =>
        {
            this.toolResize();
        }, 0);
    }

    public toolResize()
    {
        let canvasHeight = document.getElementById('canvas').getBoundingClientRect().height;
        let actionsHeight = document.getElementById('actions').getBoundingClientRect().height;
        this.rightHeight = canvasHeight + actionsHeight + 5;

        this.isWindowSmall = window.innerWidth < 992;
    }

    public canvasSizeChanged(event)
    {
        this.canvasSizeChangeEvent.emit(event);
    }

    public redrawCanvas(canvasType, canvasSize)
    {
        this.showCanvas = false;
        setTimeout(() =>
        {
            this.showCanvas = true;
            setTimeout(() =>
            {
                this.canvasComponentService.setCanvasTypeAndSize(canvasType, canvasSize);
            }, 0);

            this.canvasComponentService.clearCanvasDraft();
        })
    }
}
