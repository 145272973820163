import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CanvasComponentService} from './component-service/canvas-component.service';
import {Style} from '../models/style';
import {FontComponentService} from "./component-service/font-component.service";
import {StyleComponentService} from "./component-service/style-component.service";
import {ImageComponentService} from "./component-service/image-component.service";
import {DrawingComponentService} from "./component-service/drawing-component.service";

declare var FontFaceObserver: any;

@Injectable({
    providedIn: 'root'
})
export class StyleService {

    public static currentStyle: Style = new Style();

    constructor(public http: HttpClient,
                public canvasService: CanvasComponentService,
                public fontComponentService: FontComponentService,
                public styleComponentService: StyleComponentService,
                public imageComponentService: ImageComponentService,
                public drawingComponentService: DrawingComponentService)
    {
    }

    public saveStyle(style: any)
    {
        return this.http.post(environment.endpoint + "style/save", style);
    }

    public deleteStyle(style: any)
    {
        return this.http.post(environment.endpoint + "style/delete", style.id);
    }

    public getAllPublicStyles()
    {
        return this.http.get(environment.endpoint + "style/all_public");
    }

    public getAllSavedStyles()
    {
        return this.http.get(environment.endpoint + "style/all_saved");
    }

    public refreshAlComponentCurrentStyles()
    {
        this.styleComponentService.refreshStyles();
        this.imageComponentService.refreshStyles();
        this.fontComponentService.refreshStyles();
        this.drawingComponentService.refreshStyles();
    }

    public getActiveObjectStyle()
    {

        if (this.canvasService && this.canvasService.getActiveObject())
        {
            let activeObjectStyle = this.canvasService.getActiveObjectStyle();
            this.setCurrentStyle(activeObjectStyle);
        }
        else
        {
            this.setCurrentStyle(new Style());
        }

        return this.getCurrentStyle();
    }

    public getFontURL(data)
    {
        return this.http.post(environment.endpoint + "fonts/upload", data);
    }

    public loadFonts()
    {
        let fonts = Style.FONT_LIST;
        let observers = [];
        let loadedFonts = [];
        let loadFailedFonts = [];
        for (let font of fonts)
        {
            let obs = new FontFaceObserver(font);
            obs.load().then((res) =>
            {
                loadedFonts.push(font);
            }, (error) =>
            {
                loadFailedFonts.push(font);
            })
            observers.push(obs);
        }

        return Promise.all(observers);
    }

    public setCurrentStyle(style: Style)
    {
        StyleService.currentStyle = style;
    }

    public getCurrentStyle()
    {
        return StyleService.currentStyle;
    }
}
