import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SearchEngineOptimizationService } from "src/app/services/search-engine-optimization.service";

@Component({
  selector: "app-terms-of-use",
  templateUrl: "./terms-of-use.component.html",
  styleUrls: ["./terms-of-use.component.scss"],
})
export class TermsOfUseComponent implements OnInit {

  constructor(
    public searchEngineOptimizationService: SearchEngineOptimizationService,
    public router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Scroll to the top of the page on navigation end
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
    this.searchEngineOptimizationService.updatePageTitle("Terms of Use - ThumbzStudio");
    this.searchEngineOptimizationService.updateMetaDescription("Terms of Use for ThumbzStudio, outlining the rules and regulations for using our services.");
  }

}
