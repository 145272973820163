export class User {

    public id;
    public name;
    public firstName;
    public lastName;
    public email;
    public profilePictureUrl;
    public role;
    public joinedDate;
    public loginCount;

    public constructor(id: any, name: any, firstName: any, lastName: any, email: any, profilePictureUrl: any, role: any, joinedDate: any, loginCount:any)
    {
        this.id = id;
        this.name = name;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.profilePictureUrl = profilePictureUrl;
        this.role = role;
        this.joinedDate = joinedDate;
        this.loginCount = loginCount;
    }

    public equals(other: User): boolean
    {
        return other != null && this.id == other.id && this.name == other.name && this.firstName == other.firstName && this.lastName == other.lastName &&
            this.email == other.email && this.profilePictureUrl == other.profilePictureUrl && this.role == other.role && this.joinedDate == other.joinedDate && this.loginCount  ==  other.loginCount;
    }
}
