<div *ngIf="template">
    <div class="image-div">
        <img [src]="template?.dialogPreviewUrl" alt="preview"/>
        <div class="edit-template-btn-div">
            <button class="btn btn-primary btn-ok edit-template-btn" mat-button (click)="GotoTool()">Edit Template Using Tool</button>
        </div>
    </div>
    <div class="info-div row">
        <div class="info-key col-lg-2 col-12">
            <span> Name: </span>
        </div>
        <div class="info-value col-lg-10 col-12">
            <span> {{ template?.name }} </span>
        </div>

        <div class="info-key col-lg-2 col-12">
            <span> Category: </span>
        </div>
        <div class="info-value col-lg-10 col-12">
            <span> {{ template?.category }} </span>
        </div>

        <div class="info-key col-lg-2 col-12">
            <span> Type: </span>
        </div>
        <div class="info-value col-lg-10 col-12">
            <span> {{ templateType }}</span>
        </div>

        <div class="info-key col-lg-2 col-12">
            <span> Description: </span>
        </div>
        <div class="info-value col-lg-10 col-12">
            <span> {{ template?.description }} </span>
        </div>

        <div class="info-key col-lg-2 col-12">
            <span> Tags: </span>
        </div>
        <div class="info-value col-lg-10 col-12">
            <span> {{ this.templateTags }} </span>
        </div>
    </div>
</div>

