<div class="privacy-policy container">

  <h1 class="mb-4">Privacy Policy</h1>
  <p>
    Zyntreck (Pvt) Ltd. (“Zyntreck”, “we”, “our” or “us”) is concerned about
    your privacy and wants you to be familiar with how we collect, use and
    disclose your information. This Privacy Policy explains our online
    information practices applicable to the website available under the domain
    name “thumbzstudio.com” (the “Site”), downloadable software, mobile
    applications, and other services (the “Platform”) provided by us and on
    which a link to this Privacy Policy is displayed, and all other
    communications with individuals through written or oral means, such as email
    or phone (collectively, together with the Site, Platform, our “Service”).
  </p>

  <p>
    While privacy laws vary between jurisdictions, we are committed to
    protecting personal data in accordance with this Privacy Policy, customary
    and reasonable industry standards, and such appropriate lawful mechanisms
    and contractual terms requiring adequate data protection, regardless of any
    lesser legal requirements that may apply in the jurisdiction to which such
    data is transferred.
  </p>

  <p>
    This Privacy Policy is incorporated into and forms part of our Terms of Use
    which outline the terms and conditions you agree to when accessing and using
    the Site, Platform and Services. By providing Personal Information to us or
    by using our Site, Platform or Services, you agree and consent to the
    collection, use and disclosure of your information as outlined in this
    Privacy Policy. We may revise this Privacy Policy at any time by updating
    this posting. Use of the Site, Platform or Services after such revisions are
    posted will signify your agreement to the revised Privacy Policy. You should
    visit this page periodically to review this Privacy Policy and any
    revisions. Your continued use of the Site, Platform and Services following
    the posting of any changes to this Privacy Policy means you accept such
    changes.
  </p>

  <h2 class="mt-5">Definitions</h2>

  <ul class="list-unstyled">
    <li>
      <strong>"Cookies"</strong> are small pieces of data stored on a User’s
      device.
    </li>
    <li>
      <strong>"Controller, Customer, or You"</strong> means the natural or legal
      person, public authority, agency or other body which, alone or jointly
      with others, determines the purposes and means of the Processing of
      Personal Data.
    </li>
    <li>
      <strong>"User"</strong> means an account owner, employer, employee,
      resource, agent, or representative of a Customer, who primarily uses the
      restricted areas of the Site/ Platform to access the Service in such
      capacity. The User corresponds to the Data Subject, who is the subject of
      Personal Data.
    </li>
    <li>
      <strong>"Visitor"</strong> means an individual other than a User, who uses
      the public area but has no access to the restricted areas of the
      Site/Platform or Service. The Visitor corresponds to the Data Subject, who
      is the subject of Personal Data.
    </li>
    <li>
      <strong>"Public Area"</strong> means the area of the Site that can be
      accessed both by Users and Visitors, without needing to log in.
    </li>
    <li>
      <strong>"Restricted Area"</strong> means the area of the Site that can be
      accessed only by Users, and where access requires logging in.
    </li>
  </ul>

  <h2 class="mt-5">What information do we collect?</h2>

  <h3 class="mt-4">Information Provided by the User or Visitor.</h3>
  <p>
    While using our Service, as a User or as a Visitor, you may provide, and we
    may collect Personal Data. Personally identifiable information may include,
    but is not limited to: e-mail addresses of those who communicate with us via
    e-mail, e-mail addresses of those who sign up to the Service, e-mail
    addresses of those who are signed up for the service by the account owner,
    e-mail addresses of those who are invited to the service, e-mail addresses
    of those who signup to any subscription feed, any mailing address, mobile
    phone numbers, and credit cards or other billing information.
  </p>

  <p>
    Personal Data also includes other information such as name, email, phone
    number, position, workplace, photo of yourself or your colleagues or
    employees, login credentials, contractual and billing details, and any other
    information submitted by Users, Visitors or otherwise available to us when
    they signup or login to the”Site”, “Platform” (either directly or through
    their social media or organizational Sign-On account), when creating their
    individual profile (“User Profile”), or by updating their account or when
    any such information is linked to information that identifies a specific
    individual.
  </p>

  <h3 class="mt-4">Information Provided via Integrated Services.</h3>
  <p>
    You may be given the option to access or register for the Service through
    the use of your user names and passwords for certain services provided by
    third parties (each, an”Integrated Service”), such as through the use of
    your Google account, or otherwise have the option to authorize an Integrated
    Service to provide Personal Data or other information to us such as a third
    party integration to import your data from a third party source outside of
    the Service. By authorizing us to connect with an Integrated Service, you
    authorize us to access and store your name, email address(es), date of
    birth, gender, current city, profile picture URL, and other information that
    the Integrated Service makes available to us, and to use and disclose it in
    accordance with this Policy. You should check your privacy settings on each
    Integrated Service to understand what information that Integrated Service
    makes available to us, and make changes as appropriate. Please review each
    Integrated Service’s terms of use and privacy policies carefully before
    using their services and connecting to our Service. The information we
    collect is used to improve the content of our Service and the quality of our
    service.
  </p>

  <h3 class="mt-4">We use the information we collect:</h3>

  <ul>
    <li>To provide and maintain our Service</li>
    <li>To notify you about changes to our Service</li>
    <li>
      To allow you to participate in interactive features of our Service when
      you choose to do so
    </li>
    <li>To provide customer support</li>
    <li>
      To gather analysis or valuable information so that we can improve our
      Service
    </li>
    <li>To monitor the usage of our Service</li>
    <li>To detect, prevent and address technical issues</li>
    <li>
      To provide you with news, special offers and general information about
      other goods, services and events which we offer that are similar to those
      that you have already purchased or enquired about unless you have opted
      not to receive such information
    </li>
  </ul>

  <h2 class="mt-5">Business Operations.</h2>
  <p>
    We use the information – other than Customer Data – to operate, maintain,
    enhance and provide all features of the Service, to provide the services and
    information that you request, to respond to comments and questions and to
    provide support to Users of the Service. We process Customer Data solely in
    accordance with the directions provided by the applicable Customer or User.
  </p>

  <h2 class="mt-5">The Accuracy And Retention Of Personal Information.</h2>
  <p>
    We endeavour to ensure that any Personal Information in our possession is as
    accurate, current and complete as necessary for the purposes for which we
    use and or disclose such Personal Information. If we become aware that your
    Personal Information in our possession is inaccurate, out-of-date or
    incomplete, we will endeavour to revise such Personal Information
    accordingly.
  </p>
  <p>
    We will retain your Personal Information only for so long as it is required
    for the purposes it was collected or to comply with applicable laws. This
    period may extend beyond the end of your relationship with us, but it will
    be only for so long as it is reasonably necessary for us to have sufficient
    information to respond to any issues that may arise after the end of your
    relationship with us or as required by applicable laws. When your Personal
    Information is no longer required, we will destroy, delete or convert it
    into an anonymous form.
  </p>

  <h2 class="mt-5">User communications</h2>
  <p>
    The Site or Services may provide features that permit you to share
    information with other persons or services. You should be aware that when
    you voluntarily disclose your Personal or any other Information using any of
    these features, such Information may be collected, used or disclosed by such
    persons or services without restriction. This may result in unsolicited
    messages from third parties, identity theft and other harm to you. Any
    information that you disclose to such persons is entirely at your own risk.
    Zyntreck assumes no responsibility or liability as to any harm that you may
    suffer as a result of such activities.
  </p>

  <h2 class="mt-5">Links to other websites</h2>
  <p>
    Our services may contain links to other websites not controlled or operated
    by Zyntreck. These links do not imply that we endorse these third-party
    sites. We recommend reviewing those sites directly for information on their
    privacy policies. We do not share your personal information with those
    websites and are not responsible for their privacy practices. Any
    information you provide on third party sites or services is provided
    directly to the operators of such services and is subject to those
    operators’ policies, if any, governing privacy and security, even if
    accessed through the Service.
  </p>

  <p>
    We are not responsible for the content, privacy and security practices and
    policies of third-party sites or services to which links or access are
    provided through the Service.
  </p>

  <h2 class="mt-5">Cookies and tracking technologies</h2>
  <p>
    Our Site and Services utilize “cookies” and other technologies for us to
    provide and monitor our Services and Site, to ensure that they perform
    properly, to analyze our performance and marketing activities, and to
    personalize your experience. Such cookies and similar files may also be
    temporarily placed on your device. The next time you visit the Site, your
    device will remember useful information such as preferences, visited pages
    or logging-in options.
  </p>

  <h2 class="mt-5">Service providers and data disclosure</h2>
  <p>
    We engage selected third-party companies and individuals as “Service
    Providers”, to perform services on our behalf or complementary to our own.
    These include providers of Third Party Services such as Cloud Service
    Providers, email delivery services digital behavioral analytics services,
    and help desk software providers. They may have access to personal data,
    depending on each of their specific roles and purposes in facilitating and
    enhancing our Services or other activities.
  </p>
  <p>
    We use Google Analytics to collect site usage information and to measure and
    evaluate access, traffic on pages viewed on-site, operating system, IP
    address and cookies and how Users interact with and use the Platform and its
    various features. Google uses the data collected to track and monitor the
    use of our Service. This data may be shared with other Google services.
  </p>

  <h2 class="mt-5">Security and safeguarding methods</h2>
  <p>
    In order to protect your data held with us, we use (SHA 256 with RSA
    encryption and TLS1.2) industry-standard physical, procedural and technical
    security measures, including encryption as appropriate.
  </p>
  <p>
    Despite these efforts, please note that no data transmission or storage
    efforts can be guaranteed to be 100% secure. As a result, we cannot
    guarantee or warrant the security of any information you disclose or
    transmit to us. Our team is trained on the importance of Privacy and Data
    Protection and will adhere to our internal policies.
  </p>

  <h2 class="mt-5">
    thumbzstudio.com and the General Data Protection Regulation (GDPR)
  </h2>
  <p>
    The GDPR distinguishes between two main types of roles regarding the
    processing of personal data: “Data Controller” and “Data Processor”. A data
    controller determines the purposes and ways that personal data is processed,
    while a data processor is a party that processes data on behalf of the
    controller.
  </p>
  <p>
    The GDPR distinguishes between two main types of roles regarding the
    processing of personal data: “Data Controller” and “Data Processor”. A data
    controller determines the purposes and ways that personal data is processed,
    while a data processor is a party that processes data on behalf of the
    controller.
  </p>
  <p>
    Our Privacy Policy complies to meet the standards and requirements of the
    GDPR. Accountability and governance measures are in place to ensure that we
    understand and adequately disseminate and evidence our obligations and
    responsibilities; with a dedicated focus on privacy by design and the rights
    of individuals.
  </p>

  <h2 class="mt-5">How we are fully compliant with GDPR</h2>
  <ul>
    <li>
      <strong>Data Retention & Erasure</strong> – Our retention policy ensures
      that we meet the 'data minimization' and 'storage limitation' principles,
      and that personal information is stored, archived, and destroyed
      compliantly and ethically. We have dedicated erasure procedures in place
      to meet the 'Right to Erasure' obligation and are aware of when this and
      other data subjects' rights apply, along with any exemptions, response
      time frames, and notification responsibilities.
    </li>
    <li>
      <strong>Data Breaches</strong> – Our breach procedures ensure that we have
      safeguards and measures in place to identify, assess, investigate, and
      report any personal data breach at the earliest possible time. Our
      procedures are robust and have been disseminated to all employees, making
      them aware of the reporting lines and steps to follow.
    </li>
    <li>
      <strong>International Data Transfers & Third-Party Disclosures</strong> –
      Where Zyntreck stores or transfers personal information outside the EU, we
      have robust procedures and safeguarding measures in place to secure,
      encrypt, and maintain the integrity of the data. We carry out strict due
      diligence checks with all recipients of personal data to assess and verify
      that they have appropriate safeguards in place to protect the information,
      ensure enforceable data subject rights, and have effective legal remedies
      for data subjects where applicable.
    </li>
    <li>
      <strong>Privacy Policy</strong> – Our Privacy Policy complies with the
      GDPR, ensuring that all individuals whose personal information we process
      have been informed of why we need it, how it is used, what their rights
      are, to whom the information is disclosed, and what safeguarding measures
      are in place to protect their information.
    </li>
    <li>
      <strong>Obtaining Consent</strong> – Our consent mechanisms for obtaining
      personal data ensure that individuals understand what they are providing,
      why and how we use it, and give clear, defined ways to consent to us
      processing their information. We have developed stringent processes for
      recording consent, making sure that we can evidence an affirmative opt-in,
      along with time and date records; and an easy-to-see and accessible way to
      withdraw consent at any time.
    </li>
    <li>
      <strong>Direct Marketing</strong> – Processes for direct marketing,
      including clear opt-in mechanisms for marketing subscriptions; a clear
      notice and method for opting out and providing unsubscribe features on all
      subsequent marketing materials comply with the regulations.
    </li>
    <li>
      <strong>Processor Agreements</strong> – Where we use any third party to
      process personal information on our behalf (i.e., Infrastructure,
      development, payments, etc.), we use due diligence procedures for ensuring
      that they (as well as we) meet and understand their/our GDPR obligations.
      These measures include initial and ongoing reviews of the service
      provided, the necessity of the processing activity, the technical and
      organizational measures in place, and compliance with the GDPR.
    </li>
  </ul>

  <h2 class="mt-5">Consent</h2>
  <p>
    Unless the law permits, we will not collect, use or disclose your
    information for any purpose without obtaining your consent. However, we may
    seek consent to use and disclose Personal Information after it has been
    collected in those cases where we wish to use the Personal Information for a
    new or different purpose.
  </p>
  <p>
    BY PROVIDING PERSONAL INFORMATION TO US, YOU AGREE AND CONSENT THAT WE MAY
    COLLECT, USE AND DISCLOSE SUCH PERSONAL INFORMATION IN ACCORDANCE WITH THIS
    PRIVACY POLICY.
  </p>
  <p>
    In most cases, and subject to legal and contractual restrictions, you are
    free to refuse or withdraw your consent at any time upon reasonable advance
    notice. It should be noted that there may be certain portions of the Site,
    Platform or Services that will only be made available to you if you provide
    specific Personal Information to us. Consequently, if you choose not to
    provide us with any required Personal Information or withdraw any consent
    that you have provided, we may not be able to make such portions of the
    Site, Platform or Services available to you.
  </p>
</div>
