<div [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <div class="title-div row">
        <div class="title-content d-flex flex-column justify-content-center col-12 col-lg-6">
            <div class="title-content-h1">
                <h1> Jumpstart Your Designs </h1>
            </div>
            <br>
            <div class="title-bullets d-flex flex-wrap">
                <div class="title-bullet-item d-flex flex-row align-items-start justify-content-center">
                    <img src="../../../assets/images/welcome-page-title-bullet.png">
                    <p>Tools</p>
                </div>
                <div class="title-bullet-item d-flex flex-row align-items-start justify-content-center">
                    <img src="../../../assets/images/welcome-page-title-bullet.png">
                    <p>Tips</p>
                </div>
                <div class="title-bullet-item d-flex flex-row align-items-start justify-content-center">
                    <img src="../../../assets/images/welcome-page-title-bullet.png">
                    <p>Tutorials</p>
                </div>
                <div class="title-bullet-item d-flex flex-row align-items-start">
                    <img src="../../../assets/images/welcome-page-title-bullet.png">
                    <p>Templates</p>
                </div>
            </div>
            <button class="btn btn-primary btn-ok title-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
        </div>
        <div class="title-image d-flex justify-content-center align-items-center col-12 col-lg-6 ">
            <img src="../../../assets/gif/welcome-page-main.gif"/>
        </div>
    </div>
    <div class="content-1-div row">
        <div class="left-div col-12 col-lg-6">
            <mat-tab-group (selectedTabChange)="content1TabChange($event)">
                <mat-tab label="Templates">
                    <div class="tab-content-div d-flex flex-column">
                        <h1>Unique YouTube Thumbnail Templates!</h1>
                        <p>Experience stunning visuals at ThumbzStudio. Our expert designers create unique YouTube thumbnails to make your videos truly stand out</p>
                        <h3>REPLACES: Canva | Photoshop | Snappa</h3>
                        <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                    </div>
                </mat-tab>
                <mat-tab label="Editor">
                    <div class="tab-content-div d-flex flex-column">
                        <h1>Effortless Inbuilt Image Editing Power!</h1>
                        <p>Unlock effortless thumbnail enhancements with ThumbzStudio's inbuilt image editing power. Elevate your visuals like a pro in seconds.</p>
                        <h3>REPLACES: Canva | Photoshop | Snappa</h3>
                        <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                    </div>
                </mat-tab>
                <mat-tab label="Share">
                    <div class="tab-content-div d-flex flex-column">
                        <h1>Share and Collaborate with Ease!</h1>
                        <p>Create, share, and collaborate effortlessly. Share templates via links for easy editing and collaboration with ThumbzStudio</p>
                        <h3>REPLACES: Canva | Photoshop | Snappa</h3>
                        <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="right-div p-0 col-12 col-lg-6">
            <div class="right-div-content d-flex align-items-end justify-content-center">
                <img src="{{content1TabImage}}"/>
            </div>
        </div>
    </div>

    <div class="content-2-div">
        <div class="content-div">
            <mat-tab-group>
                <mat-tab label="Save Time!">
                    <h1>Design YouTube Thumbnails in Minutes!</h1>
                    <div class="content-2-image-div">
                        <img src="../../../assets/images/welcome-page-save-time.jpg"/>
                    </div>
                    <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                </mat-tab>
                <mat-tab label="Customize!">
                    <h1>Craft Any Canvas Size Imaginable!</h1>
                    <div class="content-2-image-div">
                        <img src="../../../assets/images/welcome-page-customize.jpg"/>
                    </div>
                    <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                </mat-tab>
                <mat-tab label="Inspire!">
                    <h1>Unleash Limitless Creativity with Colors!</h1>
                    <div class="content-2-image-div">
                        <img src="../../../assets/images/welcome-page-inspire.jpg"/>
                    </div>
                    <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

    <div class="content-3-div">
        <div class="content-div d-flex flex-column align-items-center">
            <h1>For any Category</h1>
            <mat-tab-group (selectedTabChange)="templateTabChanged($event)">
                <mat-tab label="Automotive">
                    <div class="row">
                        <div class="left-div col-12 col-lg-4">
                            <h3>Rev Up!</h3>
                            <p>
                                Drive success with stunning automotive visuals. ThumbzStudio's templates make your car content stand out on YouTube. </p>
                            <h3>Auto Design Power</h3>
                            <p>
                                Create captivating auto-themed designs effortlessly. Customize and brand your automotive content with ThumbzStudio's creative templates. </p>
                            <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                        </div>
                        <div class="right-div col-12 col-lg-8 p-0">
                            <div class="templates-row-1 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[0]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[0])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[1]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[1])">
                                </div>
                            </div>
                            <div class="templates-row-2 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[2]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[2])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[3]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[3])">
                                </div>
                            </div>
                            <div class="button-div d-flex justify-content-center">
                                <button mat-icon-button aria-label="Load previous template set" (click)="loadPreviousTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-backward" aria-hidden="false"></mat-icon>
                                </button>
                                <mat-button-toggle value="left" aria-label="Templates toggle auto refresh" [checked]="isTemplateRefreshEnabled" (change)="toggleTemplateAutoRefresh()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-auto-refresh" aria-hidden="false"></mat-icon>
                                </mat-button-toggle>
                                <button mat-icon-button aria-label="Load next template set" (click)="loadNextTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-forward" aria-hidden="false"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Business">
                    <div class="row">
                        <div class="left-div col-12 col-sm-4">
                            <h3>Business Brilliance</h3>
                            <p>
                                Elevate your brand with professional designs. Craft engaging visuals for your business videos using ThumbzStudio's templates. </p>
                            <h3>Corporate Creativity</h3>
                            <p>
                                Unleash corporate creativity. ThumbzStudio offers tailored templates for your business content, ensuring a strong visual presence. </p>
                            <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                        </div>
                        <div class="right-div col-12 col-sm-8 p-0">
                            <div class="templates-row-1 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[0]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[0])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[1]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[1])">
                                </div>
                            </div>
                            <div class="templates-row-2 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[2]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[2])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[3]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[3])">
                                </div>
                            </div>
                            <div class="button-div d-flex justify-content-center">
                                <button mat-icon-button aria-label="Load previous template set" (click)="loadPreviousTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-backward" aria-hidden="false"></mat-icon>
                                </button>
                                <mat-button-toggle value="left" aria-label="Templates toggle auto refresh" [checked]="isTemplateRefreshEnabled" (change)="toggleTemplateAutoRefresh()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-auto-refresh" aria-hidden="false"></mat-icon>
                                </mat-button-toggle>
                                <button mat-icon-button aria-label="Load next template set" (click)="loadNextTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-forward" aria-hidden="false"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Education">
                    <div class="row">
                        <div class="left-div col-12 col-sm-4">
                            <h3>Learn & Shine</h3>
                            <p>
                                Educate with style. Design compelling educational content with ThumbzStudio's templates, making learning visually engaging. </p>
                            <h3>Teaching Excellence</h3>
                            <p>
                                Inspire learners with captivating visuals. Create stunning educational graphics effortlessly using ThumbzStudio. </p>
                            <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                        </div>
                        <div class="right-div col-12 col-sm-8 p-0">
                            <div class="templates-row-1 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[0]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[0])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[1]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[1])">
                                </div>
                            </div>
                            <div class="templates-row-2 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[2]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[2])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[3]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[3])">
                                </div>
                            </div>
                            <div class="button-div d-flex justify-content-center">
                                <button mat-icon-button aria-label="Load previous template set" (click)="loadPreviousTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-backward" aria-hidden="false"></mat-icon>
                                </button>
                                <mat-button-toggle value="left" aria-label="Templates toggle auto refresh" [checked]="isTemplateRefreshEnabled" (change)="toggleTemplateAutoRefresh()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-auto-refresh" aria-hidden="false"></mat-icon>
                                </mat-button-toggle>
                                <button mat-icon-button aria-label="Load next template set" (click)="loadNextTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-forward" aria-hidden="false"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Health">
                    <div class="row">
                        <div class="left-div col-12 col-sm-4">
                            <h3>Wellness Wonders</h3>
                            <p>
                                Promote health content beautifully. Craft eye-catching wellness visuals with ThumbzStudio's templates, inspiring a healthier lifestyle. </p>
                            <h3>Healthy Design Magic</h3>
                            <p>
                                Design health and fitness content effortlessly. ThumbzStudio offers templates to make your wellness videos visually appealing. </p>
                            <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                        </div>
                        <div class="right-div col-12 col-sm-8 p-0">
                            <div class="templates-row-1 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[0]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[0])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[1]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[1])">
                                </div>
                            </div>
                            <div class="templates-row-2 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[2]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[2])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[3]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[3])">
                                </div>
                            </div>
                            <div class="button-div d-flex justify-content-center">
                                <button mat-icon-button aria-label="Load previous template set" (click)="loadPreviousTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-backward" aria-hidden="false"></mat-icon>
                                </button>
                                <mat-button-toggle value="left" aria-label="Templates toggle auto refresh" [checked]="isTemplateRefreshEnabled" (change)="toggleTemplateAutoRefresh()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-auto-refresh" aria-hidden="false"></mat-icon>
                                </mat-button-toggle>
                                <button mat-icon-button aria-label="Load next template set" (click)="loadNextTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-forward" aria-hidden="false"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Life Style">
                    <div class="row">
                        <div class="left-div col-12 col-sm-4">
                            <h3>Live in Style</h3>
                            <p>
                                Capture the essence of lifestyle. Create stunning visuals for your content with ThumbzStudio's lifestyle templates. </p>
                            <h3>Style Matters</h3>
                            <p>
                                Style your lifestyle content flawlessly. Design beautiful visuals with ThumbzStudio, enhancing your lifestyle brand. </p>
                            <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                        </div>
                        <div class="right-div col-12 col-sm-8 p-0">
                            <div class="templates-row-1 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[0]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[0])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[1]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[1])">
                                </div>
                            </div>
                            <div class="templates-row-2 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[2]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[2])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[3]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[3])">
                                </div>
                            </div>
                            <div class="button-div d-flex justify-content-center">
                                <button mat-icon-button aria-label="Load previous template set" (click)="loadPreviousTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-backward" aria-hidden="false"></mat-icon>
                                </button>
                                <mat-button-toggle value="left" aria-label="Templates toggle auto refresh" [checked]="isTemplateRefreshEnabled" (change)="toggleTemplateAutoRefresh()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-auto-refresh" aria-hidden="false"></mat-icon>
                                </mat-button-toggle>
                                <button mat-icon-button aria-label="Load next template set" (click)="loadNextTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-forward" aria-hidden="false"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Marketing">
                    <div class="row">
                        <div class="left-div col-12 col-sm-4">
                            <h3>Marketing Marvels</h3>
                            <p>
                                Elevate marketing visuals effortlessly. ThumbzStudio's templates empower you to create compelling marketing content. </p>
                            <h3>Marketing Magic</h3>
                            <p>
                                Transform marketing materials with creative flair. Craft stunning marketing graphics using ThumbzStudio's templates. </p>
                            <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                        </div>
                        <div class="right-div col-12 col-sm-8 p-0">
                            <div class="templates-row-1 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[0]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[0])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[1]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[1])">
                                </div>
                            </div>
                            <div class="templates-row-2 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[2]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[2])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[3]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[3])">
                                </div>
                            </div>
                            <div class="button-div d-flex justify-content-center">
                                <button mat-icon-button aria-label="Load previous template set" (click)="loadPreviousTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-backward" aria-hidden="false"></mat-icon>
                                </button>
                                <mat-button-toggle value="left" aria-label="Templates toggle auto refresh" [checked]="isTemplateRefreshEnabled" (change)="toggleTemplateAutoRefresh()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-auto-refresh" aria-hidden="false"></mat-icon>
                                </mat-button-toggle>
                                <button mat-icon-button aria-label="Load next template set" (click)="loadNextTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-forward" aria-hidden="false"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Music">
                    <div class="row">
                        <div class="left-div col-12 col-sm-4">
                            <h3>Music Vibes</h3>
                            <p>
                                Visualize your music with style. Create striking music visuals using ThumbzStudio's templates, enhancing your sound. </p>
                            <h3>Musical Magic</h3>
                            <p>
                                Design music content effortlessly. ThumbzStudio offers templates to elevate your music visuals and captivate your audience. </p>
                            <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                        </div>
                        <div class="right-div col-12 col-sm-8 p-0">
                            <div class="templates-row-1 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[0]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[0])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[1]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[1])">
                                </div>
                            </div>
                            <div class="templates-row-2 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[2]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[2])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[3]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[3])">
                                </div>
                            </div>
                            <div class="button-div d-flex justify-content-center">
                                <button mat-icon-button aria-label="Load previous template set" (click)="loadPreviousTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-backward" aria-hidden="false"></mat-icon>
                                </button>
                                <mat-button-toggle value="left" aria-label="Templates toggle auto refresh" [checked]="isTemplateRefreshEnabled" (change)="toggleTemplateAutoRefresh()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-auto-refresh" aria-hidden="false"></mat-icon>
                                </mat-button-toggle>
                                <button mat-icon-button aria-label="Load next template set" (click)="loadNextTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-forward" aria-hidden="false"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Nature">
                    <div class="row">
                        <div class="left-div col-12 col-sm-4">
                            <h3>Nature's Beauty</h3>
                            <p>
                                Celebrate nature's wonders visually. Design captivating nature content with ThumbzStudio's templates, bringing the outdoors to life. </p>
                            <h3>Natural Elegance</h3>
                            <p>
                                Capture the essence of nature. Create stunning visuals for your nature content with ThumbzStudio's templates. </p>
                            <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                        </div>
                        <div class="right-div col-12 col-sm-8 p-0">
                            <div class="templates-row-1 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[0]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[0])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[1]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[1])">
                                </div>
                            </div>
                            <div class="templates-row-2 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[2]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[2])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[3]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[3])">
                                </div>
                            </div>
                            <div class="button-div d-flex justify-content-center">
                                <button mat-icon-button aria-label="Load previous template set" (click)="loadPreviousTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-backward" aria-hidden="false"></mat-icon>
                                </button>
                                <mat-button-toggle value="left" aria-label="Templates toggle auto refresh" [checked]="isTemplateRefreshEnabled" (change)="toggleTemplateAutoRefresh()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-auto-refresh" aria-hidden="false"></mat-icon>
                                </mat-button-toggle>
                                <button mat-icon-button aria-label="Load next template set" (click)="loadNextTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-forward" aria-hidden="false"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Sport And Fitness">
                    <div class="row">
                        <div class="left-div col-12 col-sm-4">
                            <h3>Sports Glory</h3>
                            <p>
                                Enhance sports content beautifully. Craft compelling visuals for your athletic videos with ThumbzStudio's templates. </p>
                            <h3>Sporting Excellence</h3>
                            <p>
                                Level up your sports visuals. Design captivating athletic graphics using ThumbzStudio's templates. </p>
                            <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                        </div>
                        <div class="right-div col-12 col-sm-8 p-0">
                            <div class="templates-row-1 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[0]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[0])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[1]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[1])">
                                </div>
                            </div>
                            <div class="templates-row-2 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[2]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[2])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[3]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[3])">
                                </div>
                            </div>
                            <div class="button-div d-flex justify-content-center">
                                <button mat-icon-button aria-label="Load previous template set" (click)="loadPreviousTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-backward" aria-hidden="false"></mat-icon>
                                </button>
                                <mat-button-toggle value="left" aria-label="Templates toggle auto refresh" [checked]="isTemplateRefreshEnabled" (change)="toggleTemplateAutoRefresh()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-auto-refresh" aria-hidden="false"></mat-icon>
                                </mat-button-toggle>
                                <button mat-icon-button aria-label="Load next template set" (click)="loadNextTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-forward" aria-hidden="false"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Tech">
                    <div class="row">
                        <div class="left-div col-12 col-sm-4">
                            <h3>Tech Innovate</h3>
                            <p>
                                Visualize tech with creativity. Create striking tech visuals using ThumbzStudio's templates, making your content futuristic. </p>
                            <h3>Tech Design Power</h3>
                            <p>
                                Craft tech content effortlessly. ThumbzStudio offers templates to make your tech visuals stand out in the digital world. </p>
                            <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
                        </div>
                        <div class="right-div col-12 col-sm-8 p-0">
                            <div class="templates-row-1 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[0]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[0])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[1]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[1])">
                                </div>
                            </div>
                            <div class="templates-row-2 row">
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[2]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[2])">
                                </div>
                                <div class="image-div col-6">
                                    <img src="{{activeTemplateSet.templates[3]?.gridPreviewUrl}}" (click)="navigateToTemplate(activeTemplateSet.templates[3])">
                                </div>
                            </div>
                            <div class="button-div d-flex justify-content-center">
                                <button mat-icon-button aria-label="Load previous template set" (click)="loadPreviousTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-backward" aria-hidden="false"></mat-icon>
                                </button>
                                <mat-button-toggle value="left" aria-label="Templates toggle auto refresh" [checked]="isTemplateRefreshEnabled" (change)="toggleTemplateAutoRefresh()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-auto-refresh" aria-hidden="false"></mat-icon>
                                </mat-button-toggle>
                                <button mat-icon-button aria-label="Load next template set" (click)="loadNextTemplateSet()">
                                    <mat-icon svgIcon="svg-icon-welcome-page-templates-forward" aria-hidden="false"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

    <div class="content-real-time-support-div row">
        <div class="left-div col-12 col-lg-6">
            <div class="icon-div">
                <img src="../../../assets/images/welcome-page-real-time-support-icon.png">
            </div>
            <h1>24/7 Real-Time support.</h1>
            <p>ClickUp has the highest rated client support in software. We're here 24 hours a day, every day of the week, including holidays.</p>
            <div class="h3-div d-flex flex-wrap">
                <h3>Email Support</h3>
                <h3>Frequent Webinars</h3>
            </div>
            <button class="btn btn-primary btn-ok tab-btn-get-start" mat-button (click)="navigateToTool()">Get Started</button>
        </div>
        <div class="right-div p-0 col-12 col-lg-6">
            <div class="right-div-content">
                <img src="../../../assets/images/welcome-page-real-time-support-image.png">
            </div>
        </div>
    </div>

<!--    <div class="footer-links-div">-->
<!--        <div class="upper-content-div d-flex align-items-center flex-column justify-content-center">-->
<!--            <h1>One place for all the Designs</h1>-->
<!--            <p>Design all in one place: Templates, Graphics, Collaboration & more.</p>-->
<!--        </div>-->
<!--        <div class="lower-content-div d-flex justify-content-between">-->
<!--            <div class="link-content-div d-flex flex-column justify-content-around">-->
<!--                <h5>ThumbzStudio</h5>-->
<!--                <h6>Downloads</h6>-->
<!--                <h6>About Us</h6>-->
<!--                <h6>Product Roadmap</h6>-->
<!--                <h6>Clients</h6>-->
<!--                <h6>Brand</h6>-->
<!--                <h6>Designers</h6>-->
<!--                <h6>Affiliate & Referrals</h6>-->
<!--                <h6>Reviews</h6>-->
<!--            </div>-->
<!--            <div class="link-content-div d-flex flex-column justify-content-around">-->
<!--                <h5>Resources</h5>-->
<!--                <h6>Help Docs</h6>-->
<!--                <h6>On-demand Demo</h6>-->
<!--                <h6>Webinars</h6>-->
<!--                <h6>Contact Us</h6>-->
<!--                <h6>Import</h6>-->
<!--                <h6>Consultants</h6>-->
<!--                <h6>Partners</h6>-->
<!--                <h6>Events</h6>-->
<!--            </div>-->
<!--            <div class="link-content-div d-flex flex-column justify-content-around">-->
<!--                <h5>Features</h5>-->
<!--                <h6>Templates</h6>-->
<!--                <h6>Pre Styles</h6>-->
<!--                <h6>Shareable Link</h6>-->
<!--                <h6>Image Editor</h6>-->
<!--                <h6>Save & Download</h6>-->
<!--                <h6>Offline Availability</h6>-->
<!--                <h6>Free To Use</h6>-->
<!--                <h6>Custom Sizes</h6>-->
<!--            </div>-->
<!--            <div class="link-content-div d-flex flex-column justify-content-around">-->
<!--                <h5>Compare</h5>-->
<!--                <h6>vs Photoshop</h6>-->
<!--                <h6>vs Canva</h6>-->
<!--                <h6>vs Snappa</h6>-->
<!--                <h6>vs Adobe Spark</h6>-->
<!--                <h6>vs Picmaker</h6>-->
<!--                <h6>vs Stencil</h6>-->
<!--                <h6>vs Fotor</h6>-->
<!--                <h6>vs Coreldraw</h6>-->
<!--            </div>-->
<!--            <div class="link-content-div d-flex flex-column justify-content-around">-->
<!--                <h5>Blog</h5>-->
<!--                <h6>Help Docs</h6>-->
<!--                <h6>On-demand Demo</h6>-->
<!--                <h6>Webinars</h6>-->
<!--                <h6>Contact Us</h6>-->
<!--                <h6>Import</h6>-->
<!--                <h6>Consultants</h6>-->
<!--                <h6>Partners</h6>-->
<!--                <h6>Partners</h6>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="image-div">-->
<!--            <img src="../../../assets/images/welcome-footer-links-background.png">-->
<!--        </div>-->
<!--    </div>-->
</div>

