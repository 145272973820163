<div #dialog [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <div class="modal-header">
        <label class="modal-title pull-left">PREVIEW</label>
        <button type="button" class="close pull-right" aria-label="Close" (click)="dialogRef.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body row">
        <div class="preview col-12 col-md-6">
            <img [src]="dialogPreviewBase64Url" [alt]="template.description"/>
            <div *ngIf="template && (template.isPublished || template.isSaved)" class="row shareable-link-div d-flex justify-content-start">
                <span class="col-12 col-xl-2 shareable-link-lbl">Shareable Link:&nbsp; </span>
                <a class="shareable-link col-11 col-xl-9" href="{{template.shareableLink}}"><span>{{ template.shareableLink }}</span></a>
                <mat-chip class="col-1 col-xl-1 d-flex align-items-center" (click)="copyShareableLinkToClipboard()">
                    <mat-icon>content_copy</mat-icon>
                </mat-chip>
            </div>
            <div *ngIf="!template" class="row shareable-link-div d-flex justify-content-start">
                <span class="col-sm-12 shareable-link-lbl">Shareable Link:&nbsp;~save template to generate the shareable link~</span>
            </div>
        </div>
        <div class="details col-12 col-md-6">
            <form class="preview-form" [formGroup]="previewForm">
                <div class="form-group row">
                    <label class="col-3 col-form-label name-label-div">Name:</label>
                    <div class="name-input-div col-9 d-flex justify-content-start">
                        <input type="text" class="name-input form-control" formControlName="name">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-3 col-form-label category-label-div">Category:</label>
                    <div class="col-9 category-dropdown-div d-flex justify-content-start">
                        <input type="text" class="name-input form-control" formControlName="category">
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-3 col-form-label tags-label-div">Tags:</label>
                    <div class="col-9 tags-input-div">
                        <div class="form-group row tags-array-div d-flex align-content-start flex-wrap" matTooltip="click to copy tags to the clipboard" [matTooltipPosition]="'above'" (click)="copyTagsToClipboard()">
                            <div *ngFor="let tag of selectedTags; let index = index" class="tag-item-div">
                                <span class="tag-item-span">{{ tag }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-3 col-form-label description-label-div">Description:</label>
                    <div class="col-9 description-textarea-div">
                        <textarea class="form-control description-textarea" formControlName="description"></textarea>
                    </div>
                </div>

                <div class="form-group row" *ngIf="template && template.isPublished">
                    <label class="col-3 col-form-label price-label">Price:</label>
                    <div class="col-9 price-input-div d-flex justify-content-start">
                        <input type="text" class="form-control price-input" formControlName="price">
                        <label class="col-3 col-form-label usd-label">USD</label>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-3"></div>
                    <div class="col-9-flex justify-content-start premium-chk-div">
                        <input class="premium-chk form-check-input" type="checkbox" formControlName="isPremium" id="premium-chk">
                        <label class="premium-chk-lbl col-form-label form-check-label" for="premium-chk"> Premium </label>
                    </div>
                </div>
            </form>
            <div class="created-date-download-view-like-count-div d-flex justify-content-between" *ngIf="template && template.isPublished && template.isApproved && template.downloadCount >= 0 && template.viewCount >= 0 && template.likeCount >=0">
                <div class="created-date-div d-flex justify-content-start align-items-center">
                    <span>Created Date:&nbsp;&nbsp;{{ template.createdDate | date }}</span>
                </div>
                <div class="download-view-like-count-chip-div d-flex justify-content-between">
                    <mat-chip class="d-flex align-items-center view-count">
                        {{ template.viewCount| number:'1.0-1' }}&nbsp;&nbsp;<mat-icon>visibility</mat-icon>
                    </mat-chip>
                    <mat-chip class="d-flex align-items-center download-count">
                        {{ template.downloadCount| number:'1.0-0' }}&nbsp;&nbsp;<mat-icon>download</mat-icon>
                    </mat-chip>
                    <mat-chip class="d-flex align-items-center like-count" (click)="toggleLike()">
                        {{ template.likeCount| number:'1.0-1' }}&nbsp;&nbsp;<mat-icon>thumb_up</mat-icon>
                    </mat-chip>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" [appDivDisable]="spinnerPosition.top == 0 || spinnerPosition.left == 0">
        <mat-dialog-actions>
            <button class="btn btn-primary btn-sm btn-cancel" mat-button [mat-dialog-close]="'cancel'">Cancel</button>
            <button class="btn btn-primary btn-sm add-to-favourite-btn btn-ok" *ngIf="showAddToFavouriteBtn" (click)="addToFavourite()" mat-button>Add to Favourites</button>
            <button class="btn btn-primary btn-sm remove-from-favourite-btn btn-ok" *ngIf="showRemoveFromFavouriteBtn" (click)="removeFromFavourite()" mat-button>Remove from Favourites</button>
            <button class="btn btn-primary btn-sm btn-ok" *ngIf="showSaveBtn" (click)="save()" mat-button>Save</button>
            <button class="btn btn-primary btn-sm remove-from-save-btn btn-ok" *ngIf="showRemoveFromSaveBtn" (click)="removeFromSave()" mat-button>Remove from Save Templates</button>
            <button class="btn btn-primary btn-sm btn-ok" *ngIf="showPublishBtn" (click)="publish()" mat-button>Publish</button>
            <button class="btn btn-primary btn-sm btn-ok" *ngIf="showUnPublishBtn" (click)="unpublish()" mat-button>Unpublish</button>
            <button class="btn btn-primary btn-sm btn-ok" *ngIf="showDownloadBtn" (click)="download()" mat-button>Download</button>
            <button class="btn btn-primary btn-sm create-duplicate-btn btn-ok" *ngIf="showCreateDuplicateBtn" (click)="createDuplicate()" mat-button>Create Duplicate</button>
            <button class="btn btn-primary btn-sm btn-ok" *ngIf="showLoadBtn" (click)="loadTemplate()" mat-button>Load</button>
            <button class="btn btn-primary btn-sm btn-ok" *ngIf="authService.getCurrentUserType() == 'ADMIN' && showApproveBtn" (click)="approveTemplate()" mat-button>Approve</button>
            <button class="btn btn-primary btn-sm btn-ok" *ngIf="authService.getCurrentUserType() == 'ADMIN' && showDeclineBtn" (click)="declineTemplate()" mat-button>Decline</button>
        </mat-dialog-actions>
    </div>
</div>

<div *ngIf="loading" class="progress-spinner" [ngStyle]="{'top.px': spinnerPosition.top, 'left.px': spinnerPosition.left}">
    <mat-progress-spinner class="progress-spinner" [color]="'accent'" [mode]="'indeterminate'"></mat-progress-spinner>
</div>
