import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {StyleComponentService} from '../../../../services/component-service/style-component.service';
import {CanvasComponentService} from '../../../../services/component-service/canvas-component.service';
import {UtilService} from '../../../../services/util.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {StyleService} from '../../../../services/style.service';
import {ToastrService} from 'ngx-toastr';
import {Style} from 'src/app/models/style';
import {ToolAndGridComponentService} from "../../../../services/component-service/tool-and-grid-component.service";
import {AuthService} from "../../../../services/auth.service";
import {MatLegacyMenuTrigger as MatMenuTrigger} from "@angular/material/legacy-menu";
import {ImageComponentService} from "../../../../services/component-service/image-component.service";
import {FontComponentService} from "../../../../services/component-service/font-component.service";
import {DrawingComponentService} from "../../../../services/component-service/drawing-component.service";

declare var Grapick: any;

@Component({
    selector: 'app-styles',
    templateUrl: './styles.component.html',
    styleUrls: ['./styles.component.scss'],

})
export class StylesComponent implements OnInit, AfterViewInit {

    @ViewChild(MatMenuTrigger) preStyleContextMenu: MatMenuTrigger;

    public changingGradientProgrammatically = false;
    public imageTabOpened = false;
    public fillTabOpened = false;
    public outlineTabOpened = false;
    public shadowTabOpened = false;
    public glowTabOpened = false;
    public fontTabOpened = false;
    public drawingTabOpened = false;
    public preStylesTabOpened = false;

    public fillSectionDisabled = true;
    public outlineSectionDisabled = true;
    public shadowSectionDisabled = true;
    public glowSectionDisabled = true;

    public publicStyles: Style[];
    public savedStyles: Style[];

    public activeStyle: Style = null;
    public gradientPicker: any;
    private disableSaveCanvasState: boolean = false;

    private defaultHandler = null;
    private gradientPickerElement: ElementRef;

    public preStyleContextMenuPosition = {x: '0px', y: '0px'};

    @ViewChild('gradient_picker', {static: false}) set content(content: ElementRef)
    {
        if (content)
        {
            this.gradientPickerElement = content;
        }
    }

    constructor(public canvasComponentService: CanvasComponentService,
                public styleComponentService: StyleComponentService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public imageComponentService: ImageComponentService,
                public fontComponentService: FontComponentService,
                public drawingComponentService: DrawingComponentService,
                public authService: AuthService,
                public styleService: StyleService,
                public dialog: MatDialog,
                public notification: ToastrService,
                public utilService: UtilService)
    {
        styleComponentService.styleComponent = this;
    }


    ngOnInit()
    {
        this.refreshStyles();
        this.getAllPublicStyles();
        this.getAllSavedStyles();
    }

    ngAfterViewInit()
    {
        this.createGradient();
    }

    public setFillSectionDisabled(state): void
    {
        this.fillSectionDisabled = state;
    }

    public setOutlineSectionDisabled(state): void
    {
        this.outlineSectionDisabled = state;
    }

    public setShadowSectionDisabled(state): void
    {
        this.shadowSectionDisabled = state;
    }

    public setGlowSectionDisabled(state): void
    {
        this.glowSectionDisabled = state;
    }

    public refreshStyles()
    {
        this.activeStyle = this.styleService.getActiveObjectStyle();
        this.updateGradientColorsOnUI();
    }

    public setValuesForActiveObject(styleType)
    {
        this.canvasComponentService.setStyleValuesForObject(styleType);
    }

    public updateCanvas(styleType)
    {
        this.setValuesForActiveObject(styleType);
        if (!this.disableSaveCanvasState) this.canvasComponentService.saveCanvasState();
    }

    public saveCurrentStyle()
    {
        let thumbnail = this.canvasComponentService.createStyleThumbnail(this.activeStyle);

        let style: any = JSON.parse(JSON.stringify(this.activeStyle));

        style['thumbnail'] = thumbnail;
        style['gradientColor'] = JSON.stringify(this.activeStyle['gradientColor']);
        style['textStyles'] = JSON.stringify(this.activeStyle['textStyles']);

        this.styleService.saveStyle(style).subscribe((res) =>
        {
            if (res)
            {
                this.notification.success(res['message'], 'Success');
                this.getAllSavedStyles();
                this.getAllPublicStyles();
            }
        }, (error) =>
        {
            if (!navigator.onLine)
            {
                this.notification.warning("Data will be synced when you back online...", "Warning");
                return;
            }

            if (error.error) this.notification.error(error.error['message'], "Error");
        });

        this.styleService.setCurrentStyle(this.activeStyle);
    }

    public deleteStyle(style: Style)
    {
        this.styleService.deleteStyle(style).subscribe((res) =>
        {
            if (res)
            {
                this.notification.success(res['message'], 'Success');
                this.getAllSavedStyles();
                this.getAllPublicStyles();
            }
        }, (error) =>
        {
            if (!navigator.onLine)
            {
                this.notification.warning("Data will be synced when you back online...", "Warning");
                return;
            }

            if (error.error) this.notification.error(error.error['message'], "Error");
        });
    }

    public loadStyle(styleToLoad)
    {
        if (this.styleService.getCurrentStyle())
        {
            styleToLoad.fontFamily = this.styleService.getCurrentStyle().fontFamily;
            styleToLoad.textAlign = this.styleService.getCurrentStyle().textAlign;
            styleToLoad.textStyles = this.styleService.getCurrentStyle().textStyles;
            styleToLoad.textCharSpacing = this.styleService.getCurrentStyle().textCharSpacing;
            styleToLoad.allCapital = this.styleService.getCurrentStyle().allCapital;
            styleToLoad.allSimple = this.styleService.getCurrentStyle().allSimple;
            styleToLoad.superscript = this.styleService.getCurrentStyle().superscript;
            styleToLoad.subscript = this.styleService.getCurrentStyle().subscript;
        }

        this.activeStyle = new Style();
        Object.assign(this.activeStyle, styleToLoad);
        this.activeStyle.updateFilter('all');

        this.styleService.setCurrentStyle(this.activeStyle);
        this.canvasComponentService.setObjectStyle(this.activeStyle);

        this.canvasComponentService.setStyleValuesForObject('all');
        this.canvasComponentService.applyAllFilters();

        this.refreshStyles();
        this.imageComponentService.refreshStyles();
        this.fontComponentService.refreshStyles();
        this.drawingComponentService.refreshStyles();

        this.canvasComponentService.saveCanvasState();
    }

    public getAllPublicStyles()
    {
        this.styleService.getAllPublicStyles().subscribe((res: any) =>
        {
            if (res == null) return;

            this.publicStyles = [];
            Object.keys(res).forEach(key =>
            {
                try
                {
                    res[key].gradientColor = JSON.parse(res[key].gradientColor);
                }
                catch (e)
                {
                    res[key].gradientColor = Style.DEFAULT_GRADIENT_COLOR;
                }

                try
                {
                    res[key].textStyles = JSON.parse(res[key].textStyles);
                }
                catch (e)
                {
                    res[key].textStyles = [];
                }

                let style: Style = res[key];
                this.publicStyles.push(style);
            });
        });
    }

    public getAllSavedStyles()
    {
        if (this.authService.isUserLoggedIn() == false) return;

        this.styleService.getAllSavedStyles().subscribe((res: Style[]) =>
        {
            if (res == null) return;

            this.savedStyles = [];
            Object.keys(res).forEach(key =>
            {
                try
                {
                    res[key].gradientColor = JSON.parse(res[key].gradientColor);
                }
                catch (e)
                {
                    res[key].gradientColor = Style.DEFAULT_GRADIENT_COLOR;
                }

                try
                {
                    res[key].textStyles = JSON.parse(res[key].textStyles);
                }
                catch (e)
                {
                    res[key].textStyles = [];
                }

                let style: Style = res[key];
                this.savedStyles.push(style);
            });
        });
    }

    public strokeEnable(checked): void
    {
        this.activeStyle.strokeEnabled = checked;
        if (!checked) this.activeStyle.resetStroke();

        this.setValuesForActiveObject("stroke-color");
        this.setValuesForActiveObject("stroke-size");

        this.canvasComponentService.saveCanvasState();
    }

    public shadowEnable(checked): void
    {
        this.activeStyle.shadowEnabled = checked;
        if (!checked) this.activeStyle.resetShadow();

        this.setValuesForActiveObject("shadow-color");
        this.setValuesForActiveObject("shadow-distance");
        this.setValuesForActiveObject("shadow-feather");

        this.canvasComponentService.saveCanvasState();
    }

    public glowEnable(checked): void
    {
        this.activeStyle.glowEnabled = checked;
        if (!checked) this.activeStyle.resetGlow();

        this.setValuesForActiveObject("glow-color");
        this.setValuesForActiveObject("glow-feather");

        this.canvasComponentService.saveCanvasState();
    }

    public colorPickerOpen(): void
    {
        this.disableSaveCanvasState = true;
    }

    public colorPickerClose(): void
    {
        this.disableSaveCanvasState = false;
        this.canvasComponentService.saveCanvasState();
    }

    private updateGradientColorsOnUI(): void
    {
        if (!this.gradientPicker) return;
        let colorStops = this.activeStyle.gradientColor;

        this.changingGradientProgrammatically = true;
        this.gradientPicker.clear();
        for (let i = 0; i < colorStops.length; i++)
        {
            this.gradientPicker.addHandler(colorStops[i].offset * 100, colorStops[i].color);
        }
        this.changingGradientProgrammatically = false;
    }

    public createGradient(): void
    {
        if (!this.gradientPickerElement || !this.gradientPickerElement.nativeElement) return;

        this.gradientPicker = new Grapick({el: this.gradientPickerElement.nativeElement});

        this.gradientPicker.addHandler(0, 'rgb(255,0,0)');
        this.gradientPicker.addHandler(100, 'rgb(0,0,255)');
        Style.DEFAULT_GRADIENT_COLOR = this.parseGradient(this.gradientPicker.getSafeValue());
        this.activeStyle.gradientColor = Style.DEFAULT_GRADIENT_COLOR
        this.updateGradientColorsOnUI();

        this.gradientPicker.on('change', () =>
        {
            if (this.changingGradientProgrammatically) return;

            this.activeStyle.gradientColor = this.parseGradient(this.gradientPicker.getSafeValue());
            this.setValuesForActiveObject("gradient");

            if (!this.disableSaveCanvasState) this.canvasComponentService.saveCanvasState();
        });

        this.gradientPicker.on('handler:drag:start', () =>
        {
            this.disableSaveCanvasState = true;
        });

        this.gradientPicker.on('handler:drag:end', () =>
        {
            this.disableSaveCanvasState = false;
            this.canvasComponentService.saveCanvasState();
        });

        this.gradientPicker.on('handler:remove', (event) =>
        {
            let handlers = this.gradientPicker.getHandlers();
            let handlerCount = handlers.length;

            if (!this.changingGradientProgrammatically)
            {
                if (handlerCount == 1)
                {
                    this.defaultHandler = handlers[0];
                }
                else if (handlerCount == 0 && this.defaultHandler)
                {
                    this.gradientPicker.addHandler(this.defaultHandler['position'], this.defaultHandler['color']);
                }
            }
        });

    }

    private parseGradient(gradient): {}
    {
        if (!gradient) return;

        gradient = gradient.substring(30, gradient.length - 1).split("%");
        let clearChar = [",", " "];
        let colorStops = [];
        for (let i = 0; i < gradient.length; i++)
        {
            if (gradient[i] != "")
            {
                for (let j = 0; j < 2; j++)
                {
                    if (clearChar.includes(gradient[i][0]))
                    {
                        gradient[i] = gradient[i].substring(1);
                    }
                }
                colorStops.push(gradient[i]);
            }
        }
        let jsonColorStops = "[";
        for (let i = 0; i < colorStops.length; i++)
        {
            let jsonColorStop = "{";
            let colorSplit = colorStops[i].split(" ");
            let location = colorSplit[colorSplit.length - 1];
            let color = "";

            colorSplit.pop();
            for (let j = 0; j < colorSplit.length; j++) color += colorSplit[j];

            location = Number(location) / 100;
            location = Math.round((location + Number.EPSILON) * 100) / 100;

            jsonColorStop += "\"color\": \"" + this.rgbToHex(color) + "\", \"offset\": " + location + "},"
            jsonColorStops += jsonColorStop;
        }
        jsonColorStops = jsonColorStops.substring(0, jsonColorStops.length - 1);
        jsonColorStops += "]";
        jsonColorStops = JSON.parse(jsonColorStops);
        return jsonColorStops;
    }

    private rgbToHex(rgbString): string
    {
        if (!rgbString) return '';
        let rgbVals = rgbString.substring(4, rgbString.length - 1).replace(/ /g, '').split(',');
        return "#" + this.componentToHex(Number(rgbVals[0])) + this.componentToHex(Number(rgbVals[1])) + this.componentToHex(Number(rgbVals[2]));
    }

    private componentToHex(c): string
    {
        let hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    public openPreStyleContextMenu(event, preStyle)
    {
        this.preStyleContextMenuPosition.x = event.clientX + 'px';
        this.preStyleContextMenuPosition.y = event.clientY + 'px';

        this.preStyleContextMenu.menuData = {'object': preStyle};
        this.preStyleContextMenu.openMenu();

        event.preventDefault();
        return;
    }
}
