<div class="tool-section" [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <div class="header">
        <label class="header-text">STYLES</label>
    </div>
    <div class="styles tool-section-overlay">
        <mat-accordion>
            <mat-expansion-panel [(expanded)]="preStylesTabOpened">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Pre Styles
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="tab pre-styles-tab">
                    <div [hidden]="!preStylesTabOpened">
                        <div class="saved-grid d-flex flex-wrap">
                            <div class="saved-item" *ngFor="let savedStyle of savedStyles" (click)="loadStyle(savedStyle)" (contextmenu)="openPreStyleContextMenu($event, savedStyle)">
                                <img class="saved-item-image" [src]="savedStyle.thumbnail"/>
                            </div>
                            <div style="visibility: hidden; position: fixed" [style.left]="preStyleContextMenuPosition.x" [style.top]="preStyleContextMenuPosition.y" [matMenuTriggerFor]="preStyleContextMenu"></div>
                            <mat-menu #preStyleContextMenu="matMenu">
                                <ng-template matMenuContent let-object="object">
                                    <button mat-menu-item (click)="deleteStyle(object)">Delete</button>
                                </ng-template>
                            </mat-menu>
                        </div>
                        <hr *ngIf="savedStyles && savedStyles.length > 0">
                        <div class="public-grid d-flex flex-wrap">
                            <div class="public-item" *ngFor="let publicStyle of publicStyles" (click)="loadStyle(publicStyle)">
                                <img class="public-item-image" [src]="publicStyle.thumbnail"/>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between btn-group">
                            <button class="save-style-btn tool-btn" color="primary" mat-stroked-button (click)="saveCurrentStyle()">Save Style</button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [(expanded)]="imageTabOpened">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Image Colors
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="tab image-tab">
                    <div [hidden]="!imageTabOpened">
                        <app-image></app-image>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [(expanded)]="fillTabOpened">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Fill
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="tab fill-tab">
                    <div [hidden]="!fillTabOpened">
                        <div class="row fill-row">
                            <div class="col-5 col-sm-2 d-flex align-items-start" [appDivDisable]="fillSectionDisabled">
                                <mat-radio-button value="fill" (change)="activeStyle.fillMethod='fill'; updateCanvas('fill')" [checked]="activeStyle.fillMethod=='fill'"></mat-radio-button>
                            </div>
                            <div class="col-7 col-sm-3 d-flex align-items-start" [appDivDisable]="activeStyle.fillMethod!='fill' || fillSectionDisabled">
                                <label>Fill</label>
                            </div>
                            <div class="col-12 col-sm-7 d-flex align-items-start" [appDivDisable]="activeStyle.fillMethod!='fill' || fillSectionDisabled">
                                <input type="text" class="color-picker-input form-control" [(colorPicker)]="activeStyle.fillColor" (colorPickerOpen)="colorPickerOpen()" (colorPickerClose)="colorPickerClose()" (colorPickerChange)="updateCanvas('fill')" [style.background]="activeStyle.fillColor" [cpEyeDropper]="true" [cpUseRootViewContainer]="true" [cpDialogDisplay]="'popup'" [cpPosition]="'bottom'" [cpMaxPresetColorsLength]="12" [cpPresetColors]="['#ff0038', '#0022ff', '#00ff27', '#fffe00']" [cpAddColorButton]="true">
                            </div>
                        </div>
                        <div class="row">
                            <div [appDivDisable]="fillSectionDisabled" class="col-5 col-sm-2 d-flex align-items-start">
                                <mat-radio-button (change)="activeStyle.fillMethod='gradient'; updateCanvas('gradient')" [checked]="activeStyle.fillMethod=='gradient'" value="gradient"></mat-radio-button>
                            </div>
                            <div [appDivDisable]="activeStyle.fillMethod != 'gradient' || fillSectionDisabled" class="col-7 col-sm-3 d-flex align-items-start">
                                <label>Gradient</label>
                            </div>
                            <div [appDivDisable]="activeStyle.fillMethod != 'gradient' || fillSectionDisabled" class="col-12 col-sm-7">
                                <div #gradient_picker class="gradient"></div>
                            </div>
                        </div>
                        <div class="row gradient-prop-row">
                            <div [appDivDisable]="activeStyle.fillMethod != 'gradient' || fillSectionDisabled" class="col-12 col-sm-6 d-flex align-items-center gradient-prop-cell">
                                <select class="gradient-style-select select-input" (change)="updateCanvas('gradient')" [(ngModel)]="activeStyle.gradientType">
                                    <option value="linear">Linear</option>
                                    <option value="radial">Radial</option>
                                </select>
                            </div>
                            <div [appDivDisable]="activeStyle.fillMethod != 'gradient' || fillSectionDisabled" class="col-12 col-sm-6 d-flex align-items-center gradient-prop-cell">
                                <mat-slider *ngIf="activeStyle.gradientType == 'linear'" thumbLabel [displayWith]="utilService.formatSliderValueDeg" tickInterval="1" min="0" max="360" step="1" [(ngModel)]="activeStyle.linearGradientAngle" (change)="updateCanvas('gradient')"></mat-slider>
                                <mat-slider *ngIf="activeStyle.gradientType == 'radial'" thumbLabel [displayWith]="utilService.formatSliderValue100" tickInterval="1" min="1" max="100" step="1" [(ngModel)]="activeStyle.radialGradientRadius" (change)="updateCanvas('gradient')"></mat-slider>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [(expanded)]="outlineTabOpened">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Outline
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="tab outline-tab">
                    <div [hidden]="!outlineTabOpened">
                        <div class="row" [appDivDisable]="outlineSectionDisabled">
                            <div class="col-5 d-flex align-items-center">
                                <label>Stroke Enable</label>
                            </div>
                            <div class="col-5 d-flex align-items-center">
                                <mat-checkbox (change)="strokeEnable($event.checked)" [checked]="activeStyle.strokeEnabled"></mat-checkbox>
                            </div>
                        </div>
                        <div [appDivDisable]="!activeStyle.strokeEnabled || outlineSectionDisabled">
                            <div class="row">
                                <div class="col-5 d-flex align-items-center">
                                    <label>Color</label>
                                </div>
                                <div class="col-7 d-flex align-items-center">
                                    <input type="text" class="color-picker-input form-control" [(colorPicker)]="activeStyle.strokeColor" (colorPickerOpen)="colorPickerOpen()" (colorPickerClose)="colorPickerClose()" (colorPickerChange)="updateCanvas('stroke-color')" [style.background]="activeStyle.strokeColor" [cpEyeDropper]="true" [cpUseRootViewContainer]="true" [cpDialogDisplay]="'popup'" [cpPosition]="'bottom'" [cpMaxPresetColorsLength]="12" [cpPresetColors]="['#ff0038', '#0022ff', '#00ff27', '#fffe00']" [cpAddColorButton]="true">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 d-flex align-items-center">
                                    <label>Size</label>
                                </div>
                                <div class="col-7 d-flex align-items-center">
                                    <mat-slider thumbLabel [displayWith]="utilService.formatSliderValuePx" tickInterval="1" min="1" max="50" step="1" [(ngModel)]="activeStyle.strokeSize" (change)="updateCanvas('stroke-size')"></mat-slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [(expanded)]="shadowTabOpened">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Shadow
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="tab shadow-tab">
                    <div [hidden]="!shadowTabOpened">
                        <div class="row" [appDivDisable]="shadowSectionDisabled">
                            <div class="col-5">
                                <label>Shadow Enable</label>
                            </div>
                            <div class="col-7">
                                <mat-checkbox (change)="shadowEnable($event.checked)" [checked]="activeStyle.shadowEnabled"></mat-checkbox>
                            </div>
                        </div>
                        <div [appDivDisable]="!activeStyle.shadowEnabled  || shadowSectionDisabled">
                            <div class="row">
                                <div class="col-5 d-flex align-items-center">
                                    <label>Color</label>
                                </div>
                                <div class="col-7 d-flex align-items-center">
                                    <input type="text" class="color-picker-input form-control" [(colorPicker)]="activeStyle.shadowColor" (colorPickerOpen)="colorPickerOpen()" (colorPickerClose)="colorPickerClose()" (colorPickerChange)="updateCanvas('shadow-color')" [style.background]="activeStyle.shadowColor" [cpEyeDropper]="true" [cpUseRootViewContainer]="true" [cpDialogDisplay]="'popup'" [cpPosition]="'bottom'" [cpMaxPresetColorsLength]="12" [cpPresetColors]="['#ff0038', '#0022ff', '#00ff27', '#fffe00']" [cpAddColorButton]="true">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 d-flex align-items-center">
                                    <label>Distance</label>
                                </div>
                                <div class="col-7 d-flex align-items-center">
                                    <mat-slider thumbLabel [displayWith]="utilService.formatSliderValue100" tickInterval="1" min="1" max="100" [(ngModel)]="activeStyle.shadowDistance" (change)="updateCanvas('shadow-distance')"></mat-slider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 d-flex align-items-center">
                                    <label>Feather</label>
                                </div>
                                <div class="col-7">
                                    <mat-slider thumbLabel [displayWith]="utilService.formatSliderValue100" tickInterval="1" min="1" [max]="canvasComponentService.getActiveObject()?.type == 'image' ? 250 : 50" step="0.1" [(ngModel)]="activeStyle.shadowFeather" (change)="updateCanvas('shadow-feather')"></mat-slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [(expanded)]="glowTabOpened">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Glow
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="tab glow-tab">
                    <div [hidden]="!glowTabOpened">
                        <div class="row" [appDivDisable]="glowSectionDisabled">
                            <div class="col-5 d-flex align-items-center">
                                <label>Glow Enable</label>
                            </div>
                            <div class="col-7 d-flex align-items-center">
                                <mat-checkbox (change)="glowEnable($event.checked)" [checked]="activeStyle.glowEnabled"></mat-checkbox>
                            </div>
                        </div>
                        <div [appDivDisable]="!activeStyle.glowEnabled || glowSectionDisabled">
                            <div class="row">
                                <div class="col-5 d-flex align-items-center">
                                    <label>Color</label>
                                </div>
                                <div class="col-7 d-flex align-items-center">
                                    <input type="text" class="color-picker-input form-control" [(colorPicker)]="activeStyle.glowColor" (colorPickerOpen)="colorPickerOpen()" (colorPickerClose)="colorPickerClose()" (colorPickerChange)="updateCanvas('glow-color')" [style.background]="activeStyle.glowColor" [cpEyeDropper]="true" [cpUseRootViewContainer]="true" [cpDialogDisplay]="'popup'" [cpPosition]="'bottom'" [cpMaxPresetColorsLength]="12" [cpPresetColors]="['#ff0038', '#0022ff', '#00ff27', '#fffe00']" [cpAddColorButton]="true">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 d-flex align-items-center">
                                    <label>Feather</label>
                                </div>
                                <div class="col-7 d-flex align-items-center">
                                    <mat-slider thumbLabel [displayWith]="utilService.formatSliderValue100" tickInterval="1" min="1" [max]="canvasComponentService.getActiveObject()?.type == 'image' ? 250 : 50" [(ngModel)]="activeStyle.glowFeather" (change)="updateCanvas('glow-feather')"></mat-slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [(expanded)]="fontTabOpened">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Font
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="tab glow-tab">
                    <div [hidden]="!fontTabOpened">
                        <app-fonts></app-fonts>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [(expanded)]="drawingTabOpened">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Drawing
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="tab glow-tab">
                    <div [hidden]="!drawingTabOpened">
                        <app-drawing></app-drawing>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
