import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SearchEngineOptimizationService } from "src/app/services/search-engine-optimization.service";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    public searchEngineOptimizationService: SearchEngineOptimizationService,
    public router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Scroll to the top of the page on navigation end
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
    this.searchEngineOptimizationService.updatePageTitle("Privacy Policy - ThumbzStudio");
    this.searchEngineOptimizationService.updateMetaDescription("ThumbzStudio Privacy Policy detailing how we collect, use, and protect your personal information.");
  }

}
