import {Injectable, Renderer2} from '@angular/core';
import {environment} from "../../environments/environment";
import {Meta, Title} from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class SearchEngineOptimizationService {

    private renderer: Renderer2;

    constructor(private title: Title,
                private meta: Meta)
    { }

    public initService(renderer: Renderer2)
    {
        if (renderer == null) return;

        this.renderer = renderer;

        this.addDefaultMetaTags();
        // this.injectWebSiteTypeStructuredDataToAddSiteName();
    }

    public updatePageTitle(titleText: string)
    {
        this.title.setTitle(titleText);
    }

    public updateMetaDescription(description: string)
    {
        this.meta.updateTag({name: 'description', content: description});
    }

    public updateKeywords(keywords: string){
        this.meta.updateTag({name: 'keywords', content: keywords});
    }

    // public injectImageTypeStructuredDataToAddGridGeneralTemplates(templates: Template[])
    // {
    //     let script = this.renderer.createElement('script');
    //     script.type = 'application/ld+json';
    //
    //     let imageTypeStructuredDataArray = [];
    //
    //     for (let template of templates)
    //     {
    //         let imageTypeStructuredData = {
    //             "@context": "https://schema.org/",
    //             "@type": "ImageObject",
    //             "contentUrl": template.gridPreviewUrl,
    //             "creditText": "Thumbz Studio",
    //             "acquireLicensePage": template.shareableLink
    //         }
    //
    //         imageTypeStructuredDataArray.push(imageTypeStructuredData);
    //     }
    //
    //     script.text = JSON.stringify(imageTypeStructuredDataArray);
    //
    //     this.renderer.appendChild(document.head, script);
    // }

    private addDefaultMetaTags()
    {
        // this.meta.addTag({name: 'viewport', content: 'width=device-width, initial-scale=1'});
        // this.meta.addTag({name: 'robots', content: 'max-snippet:-1, max-image-preview:large, max-video-preview:-1'});
        // this.meta.addTag({name: 'google', content: 'nopagereadaloud'});

        if (environment.env != 'prod')
        {
            this.meta.addTag({name: 'robots', content: 'noindex'});
        }
        else {
            this.meta.addTag({name: 'robots', content: 'index, follow'});
            this.meta.addTag({name: 'keywords', content: 'ThumbzStudio, Thumbnails, YouTube, Free, Edit'});
        }
    }

    // private createCanonicalURL(url:string)
    // {
    //     const canonicalUrl: HTMLLinkElement = this.renderer.createElement('link');
    //
    //     canonicalUrl.setAttribute('rel', 'canonical');
    //     canonicalUrl.setAttribute('href', environment.baseUrl);
    //
    //     this.renderer.appendChild(document.head, canonicalUrl);
    // }

    // private injectWebSiteTypeStructuredDataToAddSiteName()
    // {
    //     let script = this.renderer.createElement('script');
    //     script.type = 'application/ld+json';
    //
    //     let webSiteTypeStructuredData = {
    //         "@context": "https://schema.org",
    //         "@type": "WebSite",
    //         "name": "Thumbz Studio",
    //         "url": environment.baseUrl
    //     }
    //
    //     script.text = JSON.stringify(webSiteTypeStructuredData);
    //
    //     this.renderer.appendChild(document.head, script);
    // }
}
