import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {fabric} from 'fabric';
import {v4 as uuidv4} from 'uuid';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacyMenuTrigger as MatMenuTrigger} from '@angular/material/legacy-menu';
import {ConfirmationBoxComponent} from '../../../common-components/confirmation-box/confirmation-box.component';
import {CanvasComponentService} from '../../../../services/component-service/canvas-component.service';
import {LayerComponentService} from '../../../../services/component-service/layer-component.service';
import {FontComponentService} from '../../../../services/component-service/font-component.service';
import {StyleComponentService} from '../../../../services/component-service/style-component.service';
import {ImageComponentService} from '../../../../services/component-service/image-component.service';
import {Style} from 'src/app/models/style';
import {Filter} from 'src/app/models/filter';
import {ToastrService} from 'ngx-toastr';
import {
    CONFIRMATION_DIALOG_HEIGHT,
    CONFIRMATION_DIALOG_WIDTH,
    INPUT_DIALOG_HEIGHT,
    INPUT_DIALOG_WIDTH
} from "../../../../util/app-consts";
import {StyleService} from "../../../../services/style.service";
import {ToolAndGridComponentService} from "../../../../services/component-service/tool-and-grid-component.service";
import {DrawingComponentService} from "../../../../services/component-service/drawing-component.service";
import {InputBoxComponent} from "../../../common-components/input-box/input-box.component";
import {NotificationService} from "../../../../services/notification.service";
import {TemplateService} from "../../../../services/temaplate.service";
import {UserStatsService} from "../../../../services/user-stats.service";
import {environment} from "../../../../../environments/environment";

declare var initAligningGuidelines: any;
declare var initCenteringGuidelines: any;
declare var FontFaceObserver: any;


@Component({
    selector: 'app-canvas',
    templateUrl: './canvas.component.html',
    styleUrls: ['./canvas.component.scss']
})

export class CanvasComponent {

    public OBJECT_CONTROL_COLOR = 'rgba(4,0,181,0.35)';
    public DEFAULT_OBJECT_CONTROL_SIZE = 35;
    public DEFAULT_OBJECT_WIDTH_HEIGHT = 100;
    public DEFAULT_OBJECT_LEFT = 100;
    public DEFAULT_OBJECT_TOP = 100;
    public DEFAULT_FILL_COLOR = '#166dab';
    public DEFAULT_STROKE_COLOR = '#000000';
    public DEFAULT_ARROW_MOVE_STEP = 1;
    public DEFAULT_MAX_UNDO_COUNT = 20;

    @ViewChild('htmlCanvas', {static: false}) htmlCanvas: ElementRef;
    @ViewChild('canvasArea', {static: false}) canvasArea: ElementRef;
    @ViewChild('objectContextMenuTrigger') objectContextMenu: MatMenuTrigger;
    @ViewChild('canvasContextMenuTrigger') canvasContextMenu: MatMenuTrigger;

    @Output("onCanvasSizeChanged") private canvasSizeChangeEvent = new EventEmitter();

    public canvas: fabric.Canvas;

    public objectContextMenuPosition = {x: '0px', y: '0px'};
    public canvasContextMenuPosition = {x: '0px', y: '0px'};
    public objectControlSize = this.DEFAULT_OBJECT_CONTROL_SIZE;
    public canvasHistory = [];
    private layerCount = 0;
    public historyIndex = 0;
    public copiedStyle: Style = null;
    public isImageSelected = false;
    public selectionType = "none"; // none, single, multi, group
    private selectedObjects: any[] = [];
    private canvasKeyboardListenerIsOn = false;
    public canvasType = 'youtube-thumbnail';
    public canvasJustifyContentCenter = true;
    public canvasAlignItemsCenter = true;
    public canvasScaleFactor = 1;
    public initialCanvasHeight;
    public initialCanvasWidth;
    private loadingStylesProgrammatically: boolean = false;
    private waitingForUserResponseToDoClipping: boolean = false;

    public canvasSize: any = null;

    constructor(public canvasComponentService: CanvasComponentService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public layerComponentService: LayerComponentService,
                public fontComponentService: FontComponentService,
                public styleComponentService: StyleComponentService,
                public imageComponentService: ImageComponentService,
                public drawingComponentService: DrawingComponentService,
                public notificationService: NotificationService,
                public templateService:TemplateService,
                public userStatsService:UserStatsService,
                public styleService: StyleService,
                public dialog: MatDialog,
                public notification: ToastrService)
    {
        canvasComponentService.canvasComponent = this;
    }


    // ********************  Canvas Operations ********************

    public setCanvasTypeAndSize(canvasType, canvasSize)
    {
        this.canvasType = canvasType;
        this.canvasSize = canvasSize;

        this.styleService.loadFonts().then((res) =>
        {
            this.createCanvas();
        }, (error) =>
        {
            this.createCanvas();
        });
    }

    public scaleCanvas()
    {
        this.objectControlSize = this.DEFAULT_OBJECT_CONTROL_SIZE / this.canvasScaleFactor;

        this.canvas.setDimensions({
            width: (this.initialCanvasWidth * this.canvasScaleFactor) + 'px',
            height: (this.initialCanvasHeight * this.canvasScaleFactor) + 'px'
        }, {cssOnly: true});

        this.canvas.requestRenderAll();

        this.canvasJustifyContentCenter = this.canvasArea?.nativeElement?.getBoundingClientRect()?.width > this.htmlCanvas?.nativeElement?.getBoundingClientRect()?.width;
        this.canvasAlignItemsCenter = this.canvasArea?.nativeElement?.getBoundingClientRect()?.height > this.htmlCanvas?.nativeElement?.getBoundingClientRect()?.height;
    }

    private createCanvas()
    {
        if (!this.htmlCanvas || !this.htmlCanvas.nativeElement || !this.canvasSize) return;

        this.DEFAULT_OBJECT_LEFT = this.canvasSize.width / 2;
        this.DEFAULT_OBJECT_TOP = this.canvasSize.height / 2;
        this.DEFAULT_OBJECT_WIDTH_HEIGHT = Math.min(this.canvasSize.width, this.canvasSize.height) / 5;
        this.DEFAULT_OBJECT_CONTROL_SIZE = Math.min(this.canvasSize.width, this.canvasSize.height) / 20;
        this.objectControlSize = this.DEFAULT_OBJECT_CONTROL_SIZE > 10 ? this.DEFAULT_OBJECT_CONTROL_SIZE : 10;

        // setup the canvas
        this.canvas = new fabric.Canvas(this.htmlCanvas.nativeElement, {
            hoverCursor: 'pointer',
            selection: true,
            selectionBorderColor: 'blue',
            fireRightClick: true,
            fireMiddleClick: true,
            stopContextMenu: true,
            originX: "left",
            originY: "top"
        });

        this.subscribeMouseEvents();
        this.subscribeKeyBoardEvents();

        this.canvas.preserveObjectStacking = true;
        this.canvas.setWidth(this.canvasSize.width);
        this.canvas.setHeight(this.canvasSize.height);

        setTimeout(() =>
        {
            this.onResize();
        }, 0);

        initAligningGuidelines(this.canvas);
        initCenteringGuidelines(this.canvas);

        this.canvasHistory = [];

        let canvasJson = this.canvas.toJSON();
        this.canvasHistory.push(canvasJson);
    }

    private subscribeMouseEvents()
    {
        this.canvas.on({
            'mouse:down': (e) =>
            {
                if (!this.canvas || !e || !e.e || e.button != 3) return;

                let event = e.e;
                if (e.target == null) // canvas context menu
                {
                    this.canvasContextMenuPosition.x = event.clientX + 'px';
                    this.canvasContextMenuPosition.y = event.clientY + 'px';
                    this.canvasContextMenu.openMenu();
                }
                else // object context menu
                {
                    this.objectContextMenuPosition.x = event.clientX + 'px';
                    this.objectContextMenuPosition.y = event.clientY + 'px';
                    this.objectContextMenu.menuData = {'object': e.target};
                    this.objectContextMenu.openMenu();
                }

                event.preventDefault();
            },
            'mouse:up': (e) =>
            {
                if (!this.canvas || !this.canvas.isDrawingMode) return;

                let style: Style = this.styleService.getCurrentStyle();
                if (style.drawingMode != 'eraser')
                {
                    this.layerComponentService.addLayerData(e.currentTarget, "drawing " + this.layerCount++, "drawing");
                }
                this.saveCanvasState();
            },
            'object:modified': (e) =>
            {
                this.saveCanvasState();
            },
            'selection:created': (e) =>
            {
                this.selectionCreteOrUpdated(e);
            },
            'selection:updated': (e) =>
            {
                this.selectionCreteOrUpdated(e);
            },
            'selection:cleared': (e) =>
            {
                this.layerComponentService.selectLayer(-1);
                this.imageComponentService.setImageSectionDisabled(true);
                this.styleComponentService.setFillSectionDisabled(true);
                this.styleComponentService.setOutlineSectionDisabled(true);
                this.styleComponentService.setShadowSectionDisabled(true);
                this.styleComponentService.setGlowSectionDisabled(true);
                this.fontComponentService.setFontSectionDisabled(true);

                this.isImageSelected = false;
                let mouseEvent = e.e;
                if (mouseEvent && !mouseEvent.ctrlKey && !mouseEvent.metaKey)
                {
                    this.selectionType = 'none';
                    this.selectedObjects = [];
                }

                this.styleService.refreshAlComponentCurrentStyles();
            }
        });

        // block default context menu
        window.addEventListener("contextmenu", (event) =>
        {
            if (this.canvasContextMenu.menuOpen || this.objectContextMenu.menuOpen)
            {
                event.preventDefault();
                event.stopPropagation();
            }
        });

        // enable/disable CanvasKeyboardListener according to mouse click target
        document.addEventListener("click", (event) =>
        {
            if (!this.canvasArea || !this.canvasArea.nativeElement) return;

            if (this.canvasArea.nativeElement.contains(event.target))
            {
                this.enableCanvasKeyboardListener();
            }
            else
            {
                this.disableCanvasKeyboardListener();
            }
        });
    }

    private selectionCreteOrUpdated(event)
    {
        if (!event || !event.selected || event.selected.length == 0) return;

        let mouseEvent = event.e;
        let selectedObject = this.canvas.getActiveObject();

        if (this.selectedObjects.length > 0 && selectedObject && mouseEvent && (mouseEvent.ctrlKey || mouseEvent.metaKey))
        {
            this.multiSelect(selectedObject);
        }
        else
        {
            this.onObjectSelection(selectedObject);
        }

        this.canvas.renderAll();

        setTimeout(() =>
        {
            this.styleService.refreshAlComponentCurrentStyles();
        }, 0)
    }

    public enableCanvasKeyboardListener()
    {
        this.canvasKeyboardListenerIsOn = true;
    }

    public disableCanvasKeyboardListener()
    {
        this.canvasKeyboardListenerIsOn = false;
    }

    private subscribeKeyBoardEvents()
    {
        window.addEventListener("keydown", (event) =>
        {
            if (!this.canvasKeyboardListenerIsOn || !this.canvas || !this.canvas.getActiveObject()) return;

            let key = event.key;

            if (key === 'ArrowLeft') // handle Left key
            {
                this.moveSelected("LEFT", event.repeat);
                event.preventDefault();
            }
            else if (key === 'ArrowUp') // handle Up key
            {
                this.moveSelected("UP", event.repeat);
                event.preventDefault();
            }
            else if (key === 'ArrowRight') // handle Right key
            {
                this.moveSelected("RIGHT", event.repeat);
                event.preventDefault();
            }
            else if (key === 'ArrowDown') // handle Down key
            {
                this.moveSelected("DOWN", event.repeat);
                event.preventDefault();
            }
            else if (key === 'Delete' || key === 'Backspace') // handle Delete key
            {
                let activeObject = this.canvas.getActiveObject();
                if (activeObject.type == 'i-text' && activeObject.isEditing) return;

                this.deleteCurrentObjectWithPermission(-1);
                event.preventDefault();
            }
            else
            {

            } //do nothing
        }, false);

        window.addEventListener("paste", (event: any) =>
        {
            if (!this.canvasKeyboardListenerIsOn) return;

            this.pasteFromClipboard();
        }, false);
    }

    public async pasteFromClipboard()
    {
        const clipBoardData = await navigator.clipboard.read();
        if (!clipBoardData) return;

        let foundObjectForPaste = false;
        for (const clipBoardDataItem of clipBoardData)
        {
            if (clipBoardDataItem.types.includes('image/png')) // paste image from clipboard
            {
                const imageFile = await clipBoardDataItem.getType('image/png');
                if (imageFile == null) return;

                const reader = new FileReader();
                reader.onload = (readerEvent) =>
                {
                    this.canvasComponentService.addImage(readerEvent.target['result']);
                    foundObjectForPaste = true;
                    // saves canvas state inside addImage
                };
                reader.readAsDataURL(imageFile);
                break;
            }
            else if (clipBoardDataItem.types.includes('text/plain')) // paste image from clipboard
            {
                const text = await clipBoardDataItem.getType('text/plain');
                if (text == null) return;

                const reader = new FileReader();
                reader.onload = (readerEvent) =>
                {
                    let base64EncodedString: string = readerEvent.target['result'].toString();
                    let decodedString: string = atob(base64EncodedString.slice(23));

                    this.addText(decodedString);
                    this.saveCanvasState();

                    foundObjectForPaste = true;
                };
                reader.readAsDataURL(text);
                break;
            }

            if (foundObjectForPaste == false)
            {
                this.notification.warning("A type that supports pasting was not found in the clipboard...", 'Warning');
            }
        }
    }

    public onResize(): void
    {
        if (!this.canvasArea || !this.canvasArea.nativeElement || !this.canvas || !this.canvasSize) return;

        let width = this.canvasArea.nativeElement.getBoundingClientRect().width;
        let height = (this.canvasSize.height / this.canvasSize.width) * width;

        if ((height + 5) > this.canvasArea.nativeElement.getBoundingClientRect().height)
        {
            height = this.canvasArea.nativeElement.getBoundingClientRect().height - 5;
            width = (this.canvasSize.width / this.canvasSize.height) * height;
        }

        this.initialCanvasWidth = width;
        this.initialCanvasHeight = height;
        this.canvas.setDimensions({width: width + 'px', height: height + 'px'}, {cssOnly: true});
        this.canvas.requestRenderAll();

        this.canvasSizeChangeEvent.emit({height: height, width: width});
    }

    private onObjectSelection(selectedObject): void
    {
        if (!selectedObject || !this.canvas || !this.canvas.getObjects() || this.canvas.getObjects().length == 0) return;

        this.selectedObjects = [];
        if (selectedObject.type == 'activeSelection')
        {
            this.selectionType = selectedObject.grouped ? 'group' : 'multiple';

            this.selectedObjects = selectedObject.getObjects();

            let allObjects = [];
            let found = false;
            this.getAllObjectsOfGroupOrSelection(selectedObject, allObjects);

            for (let obj of allObjects)
            {
                if (obj.type == 'image' && obj.clipPath && allObjects.indexOf(obj.clipPath) < 0)
                {
                    this.selectedObjects.push(obj.clipPath);
                    found = true;
                }
                if (obj.clippedObject && allObjects.indexOf(obj.clippedObject) < 0)
                {
                    this.selectedObjects.push(obj.clippedObject);
                    found = true;
                }
            }
            if (found)
            {
                this.multiSelect(null);
                return;
            }
        }
        else
        {
            if (selectedObject.type == 'group')
            {
                this.selectionType = 'group';

                this.selectedObjects.push(selectedObject);

                let allObjects = [];
                let found = false;
                this.getAllObjectsOfGroupOrSelection(selectedObject, allObjects);

                for (let obj of allObjects)
                {
                    if (obj.type == 'image' && obj.clipPath)
                    {
                        this.selectedObjects.push(obj.clipPath);
                        found = true;
                    }
                }
                if (found)
                {
                    this.multiSelect(null, true);
                    this.selectionType = 'group';

                    return;
                }
            }
            else
            {
                this.selectionType = 'single';
                if (selectedObject.type == "image")
                {
                    this.isImageSelected = true;
                    if (selectedObject.clipPath && selectedObject.grouped)
                    {
                        this.selectedObjects.push(selectedObject.clipPath);
                        this.multiSelect(selectedObject, true);

                        return;
                    }
                }
                else
                {
                    this.isImageSelected = false;
                }

                this.selectedObjects.push(selectedObject);
            }
        }
        selectedObject.hasRotatingPoint = true;
        selectedObject.cornerSize = this.objectControlSize;
        selectedObject.transparentCorners = false;
        selectedObject.cornerColor = this.OBJECT_CONTROL_COLOR;

        selectedObject.setControlVisible('mtr', true);
        selectedObject.setControlVisible('mlr', true);
        selectedObject.setControlVisible('mbr', true);
        selectedObject.setControlVisible('mrr', true);

        this.imageComponentService.setImageSectionDisabled(true);
        this.styleComponentService.setFillSectionDisabled(true);
        this.styleComponentService.setOutlineSectionDisabled(true);
        this.styleComponentService.setShadowSectionDisabled(true);
        this.styleComponentService.setGlowSectionDisabled(true);
        this.fontComponentService.setFontSectionDisabled(true);

        let indexesToSelect = [];
        for (let object of this.selectedObjects)
        {
            if (!object) return;

            let objIndex = this.canvas.getObjects().indexOf(object);
            if (objIndex < 0) return;

            indexesToSelect.push(this.canvasComponentService.convertIndex(objIndex));

            switch (object.type)
            {
                case 'rect':
                case 'circle':
                case 'polygon':
                {
                    this.styleComponentService.setFillSectionDisabled(false);
                    this.styleComponentService.setOutlineSectionDisabled(false);
                    this.styleComponentService.setShadowSectionDisabled(false);
                    this.styleComponentService.setGlowSectionDisabled(false);
                    break;
                }
                case 'line':
                {
                    this.styleComponentService.setOutlineSectionDisabled(false);
                    this.styleComponentService.setShadowSectionDisabled(false);
                    this.styleComponentService.setGlowSectionDisabled(false);
                    break;
                }
                case 'i-text':
                {
                    this.styleComponentService.setFillSectionDisabled(false);
                    this.styleComponentService.setOutlineSectionDisabled(false);
                    this.styleComponentService.setShadowSectionDisabled(false);
                    this.styleComponentService.setGlowSectionDisabled(false);
                    this.fontComponentService.setFontSectionDisabled(false);
                    break;
                }
                case 'image':
                {
                    this.imageComponentService.setImageSectionDisabled(false);
                    this.styleComponentService.setOutlineSectionDisabled(false);
                    this.styleComponentService.setShadowSectionDisabled(false);
                    this.styleComponentService.setGlowSectionDisabled(false);
                    break;
                }
            }
        }

        this.layerComponentService.selectLayers(indexesToSelect);
    }

    public saveCanvasState(): void
    {
        if (!this.canvas) return;

        this.canvasHistory = this.canvasHistory.slice(0, this.historyIndex + 1);
        let canvasJson = this.canvas.toJSON();

        if (canvasJson.objects && canvasJson.objects.length > 0)
        {
            for (let i = 0; i < canvasJson.objects.length; i++)
            {
                this.addAllExtraAttributesToJson(this.canvas._objects[i], canvasJson.objects[i], canvasJson);
            }
        }

        this.canvasHistory.push(canvasJson);
        this.historyIndex++;

        if (this.canvasHistory.length > this.DEFAULT_MAX_UNDO_COUNT)
        {
            this.canvasHistory = this.canvasHistory.slice(-this.DEFAULT_MAX_UNDO_COUNT);
            this.historyIndex = this.canvasHistory.length - 1;
        }

        this.canvasComponentService.saveCanvasDraft().then((res) =>
        {
            // do nothing
        }).catch((error) =>
        {
            this.notification.warning("Canvas Draft not saved... Please save and reload the page to prevent data lose in page reloads...", "Warning");
        })
    }

    private loadCanvasDataByCanvasHistory()
    {
        let canvasJson = this.canvasHistory[this.historyIndex];
        if (!canvasJson) return;

        this.loadCanvasData(canvasJson).then((loaded: boolean) =>
        {
            if (loaded)
            {
                this.notification.success("Action completed...", 'Success');
            }
        });
    }

    private loadCanvasData(canvasJson, isLoadingNewTemplate = false): Promise<any>
    {
        if (isLoadingNewTemplate)
        {
            this.canvas.clear();

            this.historyIndex = 0;
            this.canvasHistory = [];

            let canvasJson = this.canvas.toJSON();
            this.canvasHistory.push(canvasJson);
        }

        const promise = new Promise<boolean>((resolve, reject) =>
        {
            this.canvas.loadFromJSON(canvasJson, () =>
            {
                this.canvas.renderAll.bind(this.canvas);

                for (let i = 0; i < this.canvas._objects.length; i++)
                {
                    let result = [];
                    this.getAllObjectsOfGroupOrSelection(this.canvas._objects[i], result);
                    for (let j = 0; j < result.length; j++)
                    {
                        if (result[j].clipPath)
                        {
                            for (let k = 0; k < this.canvas._objects.length; k++)
                            {
                                if (result[j].clipPath.id == this.canvas._objects[k].id)
                                {
                                    result[j].clipPath = this.canvas._objects[k];
                                    this.canvas._objects[k].clippedObject = result[j];
                                }
                            }
                        }
                    }
                }

                this.canvas.discardActiveObject().requestRenderAll();
                this.layerComponentService.refreshLayers();

                if (isLoadingNewTemplate)
                {
                    this.saveCanvasState();
                }

                setTimeout(() =>
                {
                    resolve(true);
                }, 0)
            }, (o, object) =>
            {
                let result = [];
                this.getAllObjectsOfGroupOrSelection(object, result);
                result.forEach((obj) =>
                {
                    if (!obj || !obj.customStyleStr) return;

                    if (obj.type == 'rect' || obj.type == 'triangle' || obj.type == 'circle' || obj.type == 'i-text' || obj.type == 'polygon')
                    {
                        obj.absolutePositioned = true;
                    }

                    if (obj.type == 'i-text')
                    {
                        obj.styles = new fabric.IText('').styles;
                    }

                    obj.customStyles = new Style();
                    let customStyleJson = JSON.parse(obj.customStyleStr);

                    this.copyFillAttributes(customStyleJson, obj.customStyles);
                    this.copyStrokeAttributes(customStyleJson, obj.customStyles);
                    this.copyShadowAttributes(customStyleJson, obj.customStyles);
                    this.copyGlowAttributes(customStyleJson, obj.customStyles);
                    this.copyFontAttributes(customStyleJson, obj.customStyles);
                    this.copyFilterAttributes(customStyleJson, obj.customStyles);
                    obj.customStyles.filters = customStyleJson.filters;

                    this.applyAllFilters(obj);
                    this.setStyleValuesForObject('all', obj);
                });
            });
        });

        return promise;
    }

    public undo(): void
    {
        if (this.canvasHistory.length == this.DEFAULT_MAX_UNDO_COUNT && this.historyIndex == 0)
        {
            this.notification.warning("The maximum number of steps that can be undone is " + this.DEFAULT_MAX_UNDO_COUNT, 'Warning');
            return;
        }
        else if (!this.canvas || this.historyIndex == 0) return;

        this.historyIndex--;
        this.loadCanvasDataByCanvasHistory();
    }

    public redo(): void
    {
        if (!this.canvas || this.historyIndex == this.canvasHistory.length - 1) return;

        this.historyIndex++;
        this.loadCanvasDataByCanvasHistory();
    }

    public reset()
    {
        this.canvas.clear();

        this.historyIndex = 0;
        this.canvasHistory = [];

        let canvasJson = this.canvas.toJSON();
        this.canvasHistory.push(canvasJson);

        this.layerComponentService.refreshLayers();
    }

    public downloadThumbnail(): void
    {
        if (!this.canvas) return;

        let thumbnailData = this.canvas.toDataURL({
            format: 'jpeg',
            multiplier: 1.0,
            quality: 1.0
        });

        let saveImageData = this.canvas.toDataURL({
            format: 'webp',
            multiplier: 1.0,
            quality: 0.5
        });

        localStorage.setItem('thumbnailData', thumbnailData);
        localStorage.setItem('saveImageData', saveImageData);

        window.open(environment.baseUrl + 'thumbnail-download', "_blank");
    }

    public getThumbnailPreview(isLowQuality = false): string
    {
        if (!this.canvas) return;

        let thumbnailData = this.canvas.toDataURL({
            format: 'webp',
            multiplier: isLowQuality ? 0.5 : 1.0,
            quality: 0.5
        });
        return thumbnailData;
    }

    public getFabricTemplate()
    {
        if (!this.canvas) return;

        let canvasJson = this.canvas.toJSON();
        if (canvasJson.objects && canvasJson.objects.length > 0)
        {
            for (let i = 0; i < canvasJson.objects.length; i++)
            {
                this.addAllExtraAttributesToJson(this.canvas._objects[i], canvasJson.objects[i], canvasJson);
            }
        }

        return JSON.stringify(canvasJson);
    }

    public loadCanvasByFabricTemplate(canvasJson: any): Promise<any>
    {
        if (!this.canvas) return;
        return this.loadCanvasData(canvasJson, true);
    }

    public toggleDrawingMode(state: boolean)
    {
        if (!this.canvas) return;
        this.canvas.isDrawingMode = state;
        this.drawingComponentService.setDrawingSectionDisabled(!state);
    }


    // ********************  Object Operations ********************

    private getActiveStyle(object: any, styleName: string): any
    {
        if (!this.canvas || !object) return null;

        if (object.getSelectionStyles && object.isEditing)
        {
            return (object.getSelectionStyles()[styleName] || null);
        }
        else
        {
            return (object[styleName] || null);
        }
    }

    private setActiveStyle(object: any, styleName: string, value: any): void
    {
        if (!this.canvas || !object) return;

        if (object.setSelectionStyles && object.isEditing)
        {
            const style = {};
            style[styleName] = value;

            if (typeof value === 'string')
            {
                if (value.includes('underline'))
                {
                    object.setSelectionStyles({underline: true});
                }
                else
                {
                    object.setSelectionStyles({underline: false});
                }

                if (value.includes('overline'))
                {
                    object.setSelectionStyles({overline: true});
                }
                else
                {
                    object.setSelectionStyles({overline: false});
                }

                if (value.includes('linethrough'))
                {
                    object.setSelectionStyles({linethrough: true});
                }
                else
                {
                    object.setSelectionStyles({linethrough: false});
                }
            }

            object.setSelectionStyles(style);
            object.setCoords();

        }
        else
        {
            if (typeof value === 'string')
            {
                if (value.includes('underline'))
                {
                    object.set('underline', true);
                }
                else
                {
                    object.set('underline', false);
                }

                if (value.includes('overline'))
                {
                    object.set('overline', true);
                }
                else
                {
                    object.set('overline', false);
                }

                if (value.includes('linethrough'))
                {
                    object.set('linethrough', true);
                }
                else
                {
                    object.set('linethrough', false);
                }
            }
            object.set(styleName, value);
        }
        object.setCoords();
    }

    private getActiveProp(object: any, name: string): string
    {
        if (!this.canvas || !object) return '';

        return object[name] || '';
    }

    private setActiveProp(object: any, name: string, value: any): void
    {
        if (!this.canvas || !object) return;

        object.set(name, value).setCoords();
    }

    private addAllExtraAttributesToJson(fabricObject: any, jsonObject: any, fullJsonObject: any)
    {
        if (!fabricObject || !jsonObject) return;
        if (fabricObject.type != jsonObject.type)
        { //TODO
            alert('canvas component: line 483');
            return;
        }

        if (fabricObject.type == "group" || fabricObject.type == "activeSelection")
        {
            let children = fabricObject.getObjects();
            let jsonChildren = jsonObject.objects;
            for (let i = 0; i < children.length; i++)
            {
                jsonObject["image"] = fabricObject["image"];
                jsonObject["text"] = fabricObject["text"];
                jsonObject["grouped"] = fabricObject["grouped"];
                this.addAllExtraAttributesToJson(children[i], jsonChildren[i], fullJsonObject);
            }
        }
        else
        {
            jsonObject["id"] = fabricObject["id"];
            jsonObject["text"] = fabricObject["text"];
            jsonObject["image"] = fabricObject["image"];
            jsonObject["grouped"] = fabricObject["grouped"];
            jsonObject["excludeFromLayers"] = fabricObject["excludeFromLayers"];

            if (fabricObject["customStyles"])
            {
                jsonObject["customStyleStr"] = JSON.stringify(fabricObject["customStyles"]);
            }

            if (fabricObject.type == 'image' && fabricObject.clipPath != null)
            {
                let clipPathIndex = this.canvas._objects.indexOf(fabricObject.clipPath);
                let clipPathJson = fullJsonObject.objects[clipPathIndex];

                jsonObject["clipPath"] = clipPathJson;
            }
        }
    }

    private getAllObjectsOfGroupOrSelection(object: any, result: any[])
    { // returns all object by traversing nested groups and selections
        if (!object) return;

        if (object.type == "group" || object.type == "activeSelection")
        {
            object.forEachObject((obj) =>
            {
                this.getAllObjectsOfGroupOrSelection(obj, result);
            })
        }
        else
        {
            result.push(object);
        }
    }

    public getObjectStyle(object: any) // return first style object of first fabric object by traversing nested groups and selections
    {
        if (!this.canvas || !object) return new Style();

        if (object.type == 'activeSelection' || object.type == 'group')
        {
            return this.getObjectStyle(object._objects[0]);
        }
        else
        {
            return object['customStyles'] ? object['customStyles'] : new Style();
        }
    }

    public setObjectStyle(style: Style, object: any = null)
    {
        if (!this.canvas) return;

        if (!object) object = this.canvas.getActiveObject();
        if (object.type == 'activeSelection' || object.type == 'group')
        {
            this.setObjectStyle(style, object._objects[0]);
        }
        else
        {
            object['customStyles'] = style;
        }
    }

    public clearAndSelect(object: fabric.Object, clearAndSelect: boolean = true)
    {
        if (clearAndSelect)
        {
            this.selectItem(object);
        }
        else
        {
            this.multiSelect(object)
        }
    }

    private multiSelect(selectedObject: fabric.Object, grouped: boolean = false)
    {
        if (!this.canvas || this.selectedObjects.indexOf(selectedObject) >= 0) return;

        this.canvas.discardActiveObject().requestRenderAll();

        if (selectedObject) this.selectedObjects.push(selectedObject);

        let selection = new fabric.ActiveSelection(this.selectedObjects, {
            canvas: this.canvas,
        });
        selection.grouped = grouped;

        this.canvas.setActiveObject(selection);
        this.canvas.requestRenderAll();
    }

    private selectItem(obj): void
    {
        if (!this.canvas) return;

        this.canvas.discardActiveObject().requestRenderAll();

        if (!obj || !obj.selectable) return;
        this.canvas.setActiveObject(obj);
        this.canvas.requestRenderAll();
    }

    private moveSelected(direction, moveFast = false)
    {
        if (!this.canvas) return;

        let activeObject = this.canvas.getActiveObject();
        if (activeObject)
        {
            switch (direction)
            {
                case "LEFT":
                    activeObject.left = activeObject.left - this.DEFAULT_ARROW_MOVE_STEP * (moveFast ? 5 : 1);
                    break;
                case "UP":
                    activeObject.top = activeObject.top - this.DEFAULT_ARROW_MOVE_STEP * (moveFast ? 5 : 1);
                    break;
                case "RIGHT":
                    activeObject.left = activeObject.left + this.DEFAULT_ARROW_MOVE_STEP * (moveFast ? 5 : 1);
                    break;
                case "DOWN":
                    activeObject.top = activeObject.top + this.DEFAULT_ARROW_MOVE_STEP * (moveFast ? 5 : 1);
                    break;
            }
            activeObject.setCoords();
            this.canvas.renderAll();
        }
        else
        {

        } // do nothing
    }

    public addClippingMask(image)
    {
        if (!image || !(image instanceof fabric.Image)) return;

        image.clipPath = null;

        let supportedTypes = ['rect', 'circle', 'i-text', 'polygon'];
        let canvasObjects = this.canvas.getObjects();
        let foundObjectForClip = false;
        for (let i = 0; i < canvasObjects.length; i++)
        {
            let targetObject = canvasObjects[i];
            if (!targetObject || supportedTypes.indexOf(targetObject.type) < 0) continue;

            if (image.intersectsWithObject(targetObject))
            {
                foundObjectForClip = true;

                setTimeout(() =>
                {
                    this.waitForUserResponseAndDoClipping(image, targetObject);
                }, i * 2000)
            }
        }
        if (!foundObjectForClip) this.notificationService.alert("Could not find an object for Clip. Please place a shape or text object under the image and try again...");
    }

    private waitForUserResponseAndDoClipping(image, targetObject)
    {
        if (this.waitingForUserResponseToDoClipping)
        {
            setTimeout(() =>
            {
                this.waitForUserResponseAndDoClipping(image, targetObject);
            }, 1000)
        }
        else
        {
            if (image.clipPath != null) return;  // user has approved an object for clipping and clipping has been completed

            this.askPermissionForClipObjectAndDoClipping(image, targetObject);
        }
    }

    private askPermissionForClipObjectAndDoClipping(image, targetObject)
    {
        this.waitingForUserResponseToDoClipping = true; // wait until user response from ConfirmationBox dialog

        const initialState = {
            title: 'Do you want to clip \'' + targetObject.text + '\' object as a mask to this image?'
        };
        const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
            data: initialState,
            width: CONFIRMATION_DIALOG_WIDTH + 'px',
            height: CONFIRMATION_DIALOG_HEIGHT + 'px',
            maxWidth: CONFIRMATION_DIALOG_WIDTH,
            maxHeight: CONFIRMATION_DIALOG_HEIGHT
        });
        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == "yes")
            {
                try
                {
                    let canvasObjects = this.canvas.getObjects();
                    let imageIndex = canvasObjects.indexOf(image);
                    let targetIndex = canvasObjects.indexOf(targetObject);
                    this.changeObjectOrder(targetIndex, targetIndex > imageIndex ? imageIndex : imageIndex - 1);

                    image.clipPath = targetObject;
                    targetObject.clippedObject = image;
                    this.canvas.discardActiveObject();
                    this.canvas.setActiveObject(image);

                    this.canvas.requestRenderAll();

                    this.saveCanvasState();
                }
                catch (e)
                {
                    console.warn(e.toString())
                }
            }
            this.waitingForUserResponseToDoClipping = false;
        });
    }

    public copyStyles(object: any)
    {
        if (!object) return;

        if (object.type == 'group' || object.type == 'activeSelection')
        {
            this.notificationService.alert("Can't copy styles from Group or Multiple Selection");
            return;
        }
        this.copiedStyle = object.customStyles;
        this.notification.success("styles copied", 'Success');
    }

    public pasteStyles(object: any)
    {
        if (!this.copiedStyle || !object || !this.canvas) return;

        this.canvas.discardActiveObject();
        this.canvas.setActiveObject(object);

        let result = [];
        this.getAllObjectsOfGroupOrSelection(object, result);
        result.forEach((object) =>
        {
            if (!object) return;

            if (object.customStyles.fontFamily != null) this.copiedStyle.fontFamily = object.customStyles.fontFamily;
            if (object.customStyles.textAlign != null) this.copiedStyle.textAlign = object.customStyles.textAlign;
            if (object.customStyles.textStyles != null) this.copiedStyle.textStyles = object.customStyles.textStyles;
            if (object.customStyles.textCharSpacing != null) this.copiedStyle.textCharSpacing = object.customStyles.textCharSpacing;
            if (object.customStyles.allCapital != null) this.copiedStyle.allCapital = object.customStyles.allCapital;
            if (object.customStyles.allSimple != null) this.copiedStyle.allSimple = object.customStyles.allSimple;
            if (object.customStyles.superscript != null) this.copiedStyle.superscript = object.customStyles.superscript;
            if (object.customStyles.subscript != null) this.copiedStyle.subscript = object.customStyles.subscript;

            Object.assign(object.customStyles, this.copiedStyle);
        });

        this.styleService.refreshAlComponentCurrentStyles();

        this.applyAllFilters();
        this.setStyleValuesForObject('all');

        this.saveCanvasState();
    }

    public groupObjects(activeSelection: any)
    {
        if (!activeSelection) return;

        let objects = [];
        this.getAllObjectsOfGroupOrSelection(activeSelection, objects);

        let objectsToSelect = activeSelection.getObjects();
        for (let object of objects)
        {
            if (object.type == "image" && object.clipPath)
            {
                object.clipPath.hasControls = false;
                object.clipPath.hasBorders = false;
                object.clipPath.excludeFromLayers = true;
                object.grouped = true;

                let index = objectsToSelect.indexOf(object.clipPath);
                if (index >= 0) objectsToSelect.splice(index, 1);
            }
        }

        if (objectsToSelect.length == 0)
        {
            return;
        }
        else if (objectsToSelect.length == 1)
        {
            this.selectItem(objectsToSelect[0]);
        }
        else
        {
            this.canvas.discardActiveObject().requestRenderAll();

            let newSelection = new fabric.ActiveSelection(objectsToSelect, {
                canvas: this.canvas
            });

            let group = newSelection.toGroup();
            if (!group) return;

            group.grouped = true;
            this.layerComponentService.addLayerData(group, "group " + this.layerCount++, "group");
            this.selectItem(group);
        }
        this.layerComponentService.refreshLayers();

        this.saveCanvasState();
    }

    public unGroupObjects(object)
    {
        if (!object) return;

        this.canvas.discardActiveObject().requestRenderAll();

        if (object.type == 'activeSelection' && object.grouped) //with clipped object
        {
            let objects = object.getObjects();
            for (let obj of objects)
            {
                if (obj.type == 'group')
                {
                    let selection = obj.toActiveSelection();
                    selection.set({
                        hasRotatingPoint: true,
                        cornerSize: this.objectControlSize,
                        transparentCorners: false,
                        cornerColor: this.OBJECT_CONTROL_COLOR
                    });

                    selection.grouped = false;

                    this.selectedObjects = selection.getObjects();
                    this.multiSelect(null);
                    break;
                }
                if (obj.type == 'image' && obj.clipPath)
                {
                    obj.clipPath.hasControls = true;
                    obj.clipPath.hasBorders = true;
                    obj.clipPath.excludeFromLayers = false;
                    obj.grouped = false;

                    this.selectedObjects = [];
                    this.selectedObjects.push(obj.clipPath);
                    this.multiSelect(obj);
                    break;
                }
            }
        }
        else if (object.type == 'group')
        {
            let selection = object.toActiveSelection();
            selection.set({
                hasRotatingPoint: true,
                cornerSize: this.objectControlSize,
                transparentCorners: false,
                cornerColor: this.OBJECT_CONTROL_COLOR
            });
            this.canvas.setActiveObject(selection);
            this.canvas.requestRenderAll();
        }
        else
        {
            return;
        }

        this.layerComponentService.refreshLayers();

        this.saveCanvasState();
    }

    public edit(object): void
    {
        object.hasBorders = false;

        if (object.type == 'rect')
        {
            let upSideArrowIcon = "data:image/svg+xml;base64,DQo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgIHZpZXdCb3g9Ii0xIC0xIDI5IDI5Ij4NCgk8cGF0aCBkPSJNMTMuNzQ4LDBDNi4xNTYsMCwwLDYuMTU1LDAsMTMuNzQ3czYuMTU2LDEzLjc0OCwxMy43NDgsMTMuNzQ4czEzLjc0Ni02LjE1NiwxMy43NDYtMTMuNzQ4UzIxLjM0LDAsMTMuNzQ4LDB6IE0xMy43NSw0Ljg2YzAuMTExLDAsMC4yMiwwLjA1NiwwLjI5MiwwLjE0M2w3Ljk3MiwxMi40ODNjMC4wODgsMC4xMTIsMC4xMDgsMC4yNjgsMC4wNDIsMC4zOTkgYy0wLjA2MiwwLjEyOC0wLjE5NCwwLjIxLTAuMzM3LDAuMjFINS43NzdjLTAuMTQ1LDAtMC4yNzctMC4wODItMC4zMzgtMC4yMWwtMC4wNC0wLjE2NmMwLTAuMDgyLDAuMDMxLTAuMTY2LDAuMDg1LTAuMjMzIGw3Ljk2OS0xMi40ODNDMTMuNTI1LDQuOTE2LDEzLjYzNSw0Ljg2LDEzLjc1LDQuODZ6Ii8+DQo8L3N2Zz4NCg0K";
            let downSideArrowIcon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSItMjggLTI4IDI4IDI4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCgk8ZyBpZD0iYzEyM19hcnJvdyIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwKSI+DQoJCTxwYXRoIGQ9Ik0xMy43NDgsMEM2LjE1NiwwLDAsNi4xNTUsMCwxMy43NDdzNi4xNTYsMTMuNzQ4LDEzLjc0OCwxMy43NDhzMTMuNzQ2LTYuMTU2LDEzLjc0Ni0xMy43NDhTMjEuMzQsMCwxMy43NDgsMHoNCgkJCSBNMTMuNzUsNC44NmMwLjExMSwwLDAuMjIsMC4wNTYsMC4yOTIsMC4xNDNsNy45NzIsMTIuNDgzYzAuMDg4LDAuMTEyLDAuMTA4LDAuMjY4LDAuMDQyLDAuMzk5DQoJCQljLTAuMDYyLDAuMTI4LTAuMTk0LDAuMjEtMC4zMzcsMC4yMUg1Ljc3N2MtMC4xNDUsMC0wLjI3Ny0wLjA4Mi0wLjMzOC0wLjIxbC0wLjA0LTAuMTY2YzAtMC4wODIsMC4wMzEtMC4xNjYsMC4wODUtMC4yMzMNCgkJCWw3Ljk2OS0xMi40ODNDMTMuNTI1LDQuOTE2LDEzLjYzNSw0Ljg2LDEzLjc1LDQuODZ6Ii8+DQoJPC9nPg0KPC9zdmc+DQo=";

            let upSideArrowImg = document.createElement('img');
            upSideArrowImg.src = upSideArrowIcon;

            let downSideArrowImg = document.createElement('img');
            downSideArrowImg.src = downSideArrowIcon;

            function increaseBoarderRadius(self)
            {
                return function increaseBoarderRadius(eventData, transform)
                {
                    let target = transform.target;
                    let canvas = target.canvas;
                    let currentRadius = target.rx;

                    if (currentRadius >= target.width / 2 && currentRadius >= target.height / 2)
                    {
                        self.notification.warning("The maximum value that can be applied to the radius is half from with and height", 'Warning');
                        return;
                    }

                    target.set({
                        rx: currentRadius + 1,
                        ry: currentRadius + 1,
                    })

                    canvas.requestRenderAll();
                }
            }


            function decreaseBoarderRadius(self)
            {
                return function decreaseBoarderRadius(eventData, transform)
                {
                    let target = transform.target;
                    let canvas = target.canvas;
                    let currentRadius = target.rx;

                    if (currentRadius == 0)
                    {
                        self.notification.warning("The minimum value that can be applied to the radius is 0", 'Warning');
                        return;
                    }

                    target.set({
                        rx: currentRadius - 1,
                        ry: currentRadius - 1,
                    })

                    canvas.requestRenderAll();
                }
            }


            function renderIcon(icon)
            {
                return function renderIcon(ctx, left, top, styleOverride, fabricObject)
                {
                    let size = this.cornerSize;
                    ctx.save();
                    ctx.translate(left, top);
                    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                    ctx.drawImage(icon, -size / 2, -size / 2, size, size);
                    ctx.restore();
                }
            }

            let increaseBoarderRadiusControl = new fabric.Control({
                x: 0.5,
                y: -0.5,
                offsetY: -16,
                offsetX: 16,
                cursorStyle: 'pointer',
                mouseUpHandler: increaseBoarderRadius(this),
                render: renderIcon(upSideArrowImg),
                cornerSize: 30
            });

            let decreaseBoarderRadiusControl = new fabric.Control({
                x: -0.5,
                y: -0.5,
                offsetY: -16,
                offsetX: -16,
                cursorStyle: 'pointer',
                mouseUpHandler: decreaseBoarderRadius(this),
                render: renderIcon(downSideArrowImg),
                cornerSize: 30
            });

            object.controls = [];
            object.controls.push(increaseBoarderRadiusControl);
            object.controls.push(decreaseBoarderRadiusControl);
        }
        else
        {
            // locate the controls. this function will be used both for drawing and for interaction.
            function polygonPositionHandler(dim, finalMatrix, fabricObject)
            {
                let x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x);
                let y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
                let multipliedTransformMatrices = fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix());

                return fabric.util.transformPoint({x: x, y: y}, multipliedTransformMatrices);
            }

            function getObjectSizeWithStroke(object)
            {
                let stroke = new fabric.Point(object.strokeUniform ? 1 / object.scaleX : 1, object.strokeUniform ? 1 / object.scaleY : 1).multiply(object.strokeWidth);
                return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
            }

            // this function will be called on every mouse move after a control has been clicked and is being dragged.
            // The function receive as argument the mouse event, the current transform object and the current position in canvas coordinate. transform.target is a reference to the current object being transformed,
            function actionHandlerPolygon(eventData, transform, x, y)
            {
                let polygon = transform.target;
                let currentControl = polygon.controls[polygon.__corner];
                let mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
                let polygonBaseSize = getObjectSizeWithStroke(polygon);
                let size = polygon._getTransformedDimensions(0, 0);
                let finalPointPosition = {
                    x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
                    y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
                };
                polygon.points[currentControl.pointIndex] = finalPointPosition;
                return true;
            }

            // keep the polygon in the same position when we change its width, height, top, left.
            function anchorWrapperPolygon(anchorIndex, actionHandler)
            {
                return (eventData, transform, x, y) =>
                {
                    let fabricObject = transform.target;
                    let absolutePoint = fabric.util.transformPoint({
                        x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
                        y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
                    }, fabricObject.calcTransformMatrix());
                    let actionPerformed = actionHandler(eventData, transform, x, y);
                    let newDim = fabricObject._setPositionDimensions({});
                    let polygonBaseSize = getObjectSizeWithStroke(fabricObject);
                    let newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x;
                    let newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
                    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
                    return actionPerformed;
                }
            }

            object.cornerStyle = 'circle';

            let lastControl = object.points.length - 1;
            object.controls = object.points.reduce(function (acc, point, index)
            {
                acc['p' + index] = new fabric.Control({
                    positionHandler: polygonPositionHandler,
                    actionHandler: anchorWrapperPolygon(index > 0 ? index - 1 : lastControl, actionHandlerPolygon),
                    actionName: 'modifyPolygon',
                    pointIndex: index
                });
                return acc;
            }, {});
        }

        this.canvas.requestRenderAll();

        setTimeout(() =>
        {
            object.edit = true;
        }, 1000)
    }

    public accept(object): void
    {
        object.hasBorders = true;
        object.cornerStyle = 'rect';
        object.controls = fabric.Object.prototype.controls;
        object.hasRotatingPoint = true;
        object.cornerSize = this.objectControlSize;
        object.transparentCorners = false;

        object.setControlVisible('mtr', true);
        object.setControlVisible('mlr', true);
        object.setControlVisible('mbr', true);
        object.setControlVisible('mrr', true);

        this.saveCanvasState();
        this.canvas.requestRenderAll();

        setTimeout(() =>
        {
            object.edit = false;
        }, 1000)
    }

    public bringForward(object): void
    {
        if (!this.canvas || !object) return;

        let objectCount = this.canvas.getObjects().length;
        let currentIndex = this.canvas.getObjects().indexOf(object);
        let newIndex = currentIndex + 1;
        if (currentIndex < 0 || newIndex >= objectCount) return;

        this.changeObjectOrder(currentIndex, newIndex);
        this.layerComponentService.refreshLayers();

        this.saveCanvasState();
    }

    public bringToFront(object): void
    {
        if (!this.canvas || !object) return;

        let objectCount = this.canvas.getObjects().length;
        let currentIndex = this.canvas.getObjects().indexOf(object);
        let newIndex = objectCount - 1;
        if (currentIndex < 0) return;

        this.changeObjectOrder(currentIndex, newIndex);
        this.layerComponentService.refreshLayers();

        this.saveCanvasState();
    }

    public sendBackward(object): void
    {
        if (!this.canvas || !object) return;

        let currentIndex = this.canvas.getObjects().indexOf(object);
        let newIndex = currentIndex - 1;
        if (currentIndex < 0 || newIndex < 0) return;

        this.changeObjectOrder(currentIndex, newIndex);
        this.layerComponentService.refreshLayers();

        this.saveCanvasState();
    }

    public sendToBack(object): void
    {
        if (!this.canvas || !object) return;

        let currentIndex = this.canvas.getObjects().indexOf(object);
        let newIndex = 0;
        if (currentIndex < 0) return;

        this.changeObjectOrder(currentIndex, newIndex);
        this.layerComponentService.refreshLayers();

        this.saveCanvasState();
    }

    public changeObjectOrder(previousIndex, currentIndex): void
    {
        if (!this.canvas) return;

        let objectCount = this.canvas.getObjects().length;
        if (objectCount == 0 || previousIndex >= objectCount || currentIndex >= objectCount) return;

        let object = this.canvas._objects[previousIndex];
        this.canvas.discardActiveObject().requestRenderAll();

        this.canvas._objects.splice(previousIndex, 1);
        this.canvas._objects.splice(currentIndex, 0, object);
        this.canvas.requestRenderAll();
    }

    public createObjectCopy(index): void
    {
        if (!this.canvas) return;

        let objectCount = this.canvas.getObjects().length;
        if (objectCount == 0 || index >= objectCount) return;

        this.canvas.discardActiveObject().requestRenderAll();

        let object = this.canvas._objects[index];

        object.clone((clonedObj) =>
        {
            clonedObj.set({
                left: object.left + 50,
                top: object.top + 50,
                evented: true,
            });

            clonedObj.id = uuidv4();
            clonedObj.text = object.text;
            clonedObj.image = object.image;
            clonedObj.customStyleStr = JSON.stringify(object.customStyles);

            clonedObj.customStyles = new Style();
            Object.assign(clonedObj.customStyles, object.customStyles);

            this.canvas.add(clonedObj);

            setTimeout(() =>
            {
                this.canvas.setActiveObject(clonedObj).requestRenderAll();
            }, 0)
        });
    }

    public deleteObjectByIndex(index): void
    {
        if (!this.canvas) return;

        let objectCount = this.canvas.getObjects().length;
        if (objectCount == 0 || index >= objectCount) return;

        this.canvas._objects.splice(index, 1);
        this.canvas.discardActiveObject().requestRenderAll();

        this.saveCanvasState();
    }

    public deleteCurrentObjectWithPermission(index): void
    {
        if (!this.canvas) return;

        const initialState = {
            title: 'Are you sure you want to delete this layer?'
        };
        const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
            data: initialState,
            width: CONFIRMATION_DIALOG_WIDTH + 'px',
            height: CONFIRMATION_DIALOG_HEIGHT + 'px',
            maxWidth: CONFIRMATION_DIALOG_WIDTH,
            maxHeight: CONFIRMATION_DIALOG_HEIGHT
        });
        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == "yes")
            {
                if (index < 0)
                {
                    this.deleteCurrentObject();
                }
                else
                {
                    this.deleteObjectByIndex(index);
                }
            }
        });
    }

    private deleteCurrentObject()
    {
        if (!this.canvas || !this.canvas.getActiveObject()) return;

        let activeObject = this.canvas.getActiveObject();

        if (activeObject.type == 'activeSelection')
        {
            activeObject.getObjects().forEach((object) =>
            {
                this.canvas.remove(object);
            });
        }
        else
        {
            this.canvas.remove(activeObject);
        }

        this.canvas.discardActiveObject().requestRenderAll();
        this.layerComponentService.refreshLayers();

        this.saveCanvasState();
    }

    public lockObject(object, state): void
    {
        if (!this.canvas || !object) return;

        this.canvas.discardActiveObject().requestRenderAll();
        object.set({selectable: state});
    }

    public setStyleValuesForObject(type: string, object: any = null): void
    {
        switch (type)
        {
            case 'family':
            {
                this.setFontFamily(object);
                break;
            }
            case 'align':
            {
                this.setTextAlign(object);
                break;
            }
            case 'styles':
            {
                this.setTextDecoration(object);
                break;
            }
            case 'all-capital':
            {
                this.setTextAllCapital(object);
                break;
            }
            case 'all-simple':
            {
                this.setTextAllSimple(object);
                break;
            }
            case 'superscript':
            {
                this.setTextSuperScript(object);
                break;
            }
            case 'subscript':
            {
                this.setTextSubScript(object);
                break;
            }
            case 'reset-other-styles':
            {
                this.resetOtherStyles(object);
                break;
            }
            case 'font-weight':
            {
                this.setFontWeight(object);
                break;
            }
            case 'char-spacing':
            {
                this.setCharSpacing(object);
                break;
            }
            case 'drawing-styles':
            {
                this.setDrawingStyles();
                break;
            }
            case 'fill':
            {
                this.setFill(object);
                break;
            }
            case 'gradient':
            {
                this.setGradient(object);
                break;
            }
            case 'stroke-color':
            case 'stroke-size':
            {
                this.setStroke(object);
                break;
            }
            case 'shadow-color':
            case 'shadow-distance':
            case 'shadow-feather':
            {
                this.setShadow(object);
                break;
            }
            case 'glow-color':
            case 'glow-feather':
            {
                this.setGlow(object);
                break;
            }
            case 'all':
            {
                this.loadingStylesProgrammatically = true;

                this.setFill(object);
                this.setGradient(object);
                this.setStroke(object);
                this.setShadow(object);
                this.setGlow(object);

                this.setFontFamily(object);
                this.setTextAlign(object);
                this.setTextAllCapital(object);
                this.setTextAllSimple(object);
                this.setTextSuperScript(object);
                this.setTextSubScript(object);
                this.setFontWeight(object);
                this.setCharSpacing(object);
                this.setTextDecoration(object);

                this.loadingStylesProgrammatically = false;
            }
            default:
                break;
        }
    }

    public applyAllFilters(object = null)
    {
        this.applyImageFilters('grayScale', object);
        this.applyImageFilters('invert', object);
        this.applyImageFilters('sepia', object);
        this.applyImageFilters('blackAndWhite', object);
        this.applyImageFilters('brownie', object);
        this.applyImageFilters('vintage', object);
        this.applyImageFilters('kodachrome', object);
        this.applyImageFilters('technicolor', object);
        this.applyImageFilters('polaroid', object);
        this.applyImageFilters('sharpen', object);
        this.applyImageFilters('emboss', object);
        this.applyImageFilters('opacity', object);
        this.applyImageFilters('gamma', object);
        this.applyImageFilters('brightness', object);
        this.applyImageFilters('contrast', object);
        this.applyImageFilters('saturation', object);
        this.applyImageFilters('hue', object);
        this.applyImageFilters('noise', object);
        this.applyImageFilters('pixelate', object);
        this.applyImageFilters('blur', object);
        this.applyImageFilters('blend-color', object);
    }

    public applyImageFilters(filterType: string, object = null)
    {
        if (!filterType) return;

        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || style.filters == null) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object || !(object instanceof fabric.Image)) continue;

            if (!object.customStyles) object.customStyles = new Style();
            object.customStyles.filters = style.filters;

            let filterIndex = Style.FILTER_MAP[filterType];
            if (filterIndex < 0) continue;

            let styleFilter: Filter = style.filters[filterIndex];
            if (!styleFilter) continue;

            object.customStyles.filters[filterIndex].filterType = styleFilter.filterType;
            object.customStyles.filters[filterIndex].enabled = styleFilter.enabled;
            object.customStyles.filters[filterIndex].properties = styleFilter.properties;
            this.copyFilterAttributes(style, object.customStyles);

            let enabled = styleFilter.enabled;
            let properties = styleFilter.properties;

            if (!enabled)
            {
                if (filterType == "opacity") object.opacity = 1;
                object.filters[filterIndex] = null;
            }
            else
            {
                let filter: any = null;
                switch (filterType)
                {
                    case "grayScale":
                    {
                        filter = new fabric.Image.filters.Grayscale();
                        break;
                    }
                    case "invert":
                    {
                        filter = new fabric.Image.filters.Invert();
                        break;
                    }
                    case "sepia":
                    {
                        filter = new fabric.Image.filters.Sepia();
                        break;
                    }
                    case "blackAndWhite":
                    {
                        filter = new fabric.Image.filters.BlackWhite();
                        break;
                    }
                    case "brownie":
                    {
                        filter = new fabric.Image.filters.Brownie();
                        break;
                    }
                    case "vintage":
                    {
                        filter = new fabric.Image.filters.Vintage();
                        break;
                    }
                    case "kodachrome":
                    {
                        filter = new fabric.Image.filters.Kodachrome();
                        break;
                    }
                    case "technicolor":
                    {
                        filter = new fabric.Image.filters.Technicolor();
                        break;
                    }
                    case "polaroid":
                    {
                        filter = new fabric.Image.filters.Polaroid();
                        break;
                    }
                    case "sharpen":
                    {
                        filter = new fabric.Image.filters.Convolute({matrix: [0, -1, 0, -1, 5, -1, 0, -1, 0]});
                        break;
                    }
                    case "emboss":
                    {
                        filter = new fabric.Image.filters.Convolute({matrix: [1, 1, 1, 1, 0.7, -1, -1, -1, -1]});
                        break;
                    }
                    case "opacity":
                    {
                        targetObject.opacity = properties['opacity'] && properties['opacity'] < 1 && properties['opacity'] >= 0 ? properties['opacity'] : 1;
                        this.canvas.renderAll();
                        return;
                    }
                    case "gamma":
                    {
                        filter = new fabric.Image.filters.Gamma({gamma: [1, 1, 1]});
                        break;
                    }
                    case "brightness":
                    {
                        filter = new fabric.Image.filters.Brightness({brightness: 0});
                        break;
                    }
                    case "contrast":
                    {
                        filter = new fabric.Image.filters.Contrast({contrast: 0});
                        break;
                    }
                    case "saturation":
                    {
                        filter = new fabric.Image.filters.Saturation({saturation: 0});
                        break;
                    }
                    case "hue":
                    {
                        filter = new fabric.Image.filters.HueRotation({rotation: 0});
                        break;
                    }
                    case "noise":
                    {
                        filter = new fabric.Image.filters.Noise({noise: 0});
                        break;
                    }
                    case "pixelate":
                    {
                        filter = new fabric.Image.filters.Pixelate({blocksize: 1});
                        break;
                    }
                    case "blur":
                    {
                        filter = new fabric.Image.filters.Blur({blur: 0});
                        break;
                    }
                    case "blend-color":
                    {
                        filter = new fabric.Image.filters.BlendColor({
                            color: '#ffffff',
                            mode: 'add',
                            alpha: 0
                        });
                        break;
                    }
                }

                if (Object.keys(properties).length > 0)
                {
                    const props = Object.keys(properties);
                    props.forEach((key, index) =>
                    {
                        if (object.filters[filterIndex] == null)
                        {
                            object.filters[filterIndex] = filter;
                        }
                        object.filters[filterIndex][key] = properties[key];
                    });
                }
                else
                {
                    object.filters[filterIndex] = filter;
                }
            }
            object.applyFilters();
        }

        setTimeout(() =>
        {
            this.canvas.renderAll();
        }, 0);
    }

    private copyFilterAttributes(src: any, dest: Style)
    {
        dest.grayScaleEnabled = src.grayScaleEnabled;
        dest.grayScaleMode = src.grayScaleMode;
        dest.invertEnabled = src.invertEnabled;
        dest.sepiaEnabled = src.sepiaEnabled;
        dest.blackAndWhiteEnabled = src.blackAndWhiteEnabled;
        dest.brownieEnabled = src.brownieEnabled;
        dest.vintageEnabled = src.vintageEnabled;
        dest.kodachromeEnabled = src.kodachromeEnabled;
        dest.technicolorEnabled = src.technicolorEnabled;
        dest.polaroidEnabled = src.polaroidEnabled;
        dest.sharpenEnabled = src.sharpenEnabled;
        dest.embossEnabled = src.embossEnabled;

        dest.opacityEnabled = src.opacityEnabled;
        dest.opacityValue = src.opacityValue;
        dest.gammaEnabled = src.gammaEnabled;
        dest.gammaRedValue = src.gammaRedValue;
        dest.gammaGreenValue = src.gammaGreenValue;
        dest.gammaBlueValue = src.gammaBlueValue;
        dest.brightnessEnabled = src.brightnessEnabled;
        dest.brightnessValue = src.brightnessValue;
        dest.contrastEnabled = src.contrastEnabled;
        dest.contrastValue = src.contrastValue;
        dest.saturationEnabled = src.saturationEnabled;
        dest.saturationValue = src.saturationValue;
        dest.hueEnabled = src.hueEnabled;
        dest.hueValue = src.hueValue;
        dest.noiseEnabled = src.noiseEnabled;
        dest.noiseValue = src.noiseValue;
        dest.pixelateEnabled = src.pixelateEnabled;
        dest.pixelateValue = src.pixelateValue;
        dest.blurEnabled = src.blurEnabled;
        dest.blurValue = src.blurValue;
        dest.blendColorEnabled = src.blendColorEnabled;
        dest.blendColorValue = src.blendColorValue;
        dest.blendColorMode = src.blendColorMode;
        dest.blendColorDistance = src.blendColorDistance;
    }

    private copyFillAttributes(src: any, dest: Style)
    {
        dest.fillMethod = src.fillMethod;
        dest.fillColor = src.fillColor;
        dest.gradientColor = src.gradientColor;
        dest.gradientType = src.gradientType;
        dest.radialGradientRadius = src.radialGradientRadius;
        dest.linearGradientAngle = src.linearGradientAngle;
    }

    private copyStrokeAttributes(src: any, dest: Style)
    {
        dest.strokeEnabled = src.strokeEnabled;
        dest.strokeColor = src.strokeColor;
        dest.strokeSize = src.strokeSize;
    }

    private copyShadowAttributes(src: any, dest: Style)
    {
        dest.shadowEnabled = src.shadowEnabled;
        dest.shadowColor = src.shadowColor;
        dest.shadowDistance = src.shadowDistance;
        dest.shadowFeather = src.shadowFeather;
    }

    private copyGlowAttributes(src: any, dest: Style)
    {
        dest.glowEnabled = src.glowEnabled;
        dest.glowColor = src.glowColor;
        dest.glowFeather = src.glowFeather;
    }

    private copyFontAttributes(src: any, dest: Style)
    {
        dest.fontFamily = src.fontFamily;
        dest.textAlign = src.textAlign;
        dest.textStyles = src.textStyles;
        dest.fontWeight = src.fontWeight;
        dest.textCharSpacing = src.textCharSpacing;
        dest.allCapital = src.allCapital;
        dest.allSimple = src.allSimple;
        dest.superscript = src.superscript;
        dest.subscript = src.subscript;
    }

    // ********************  Add Canvas Items and Background ********************

    public setCanvasBackGround(color): void
    {
        this.canvas.backgroundColor = color;
        this.canvas.requestRenderAll();
    }

    public addImage(url: any): Promise<any>
    {
        if (!url) return;

        this.canvas.discardActiveObject();
        const promise = new Promise<any>((resolve, reject) =>
        {
            if (url)
            {
                let style: Style = new Style();
                fabric.util.loadImage(url, (img) =>
                {
                    let image = new fabric.Image(img);
                    image.set({
                        id: uuidv4(),
                        left: this.DEFAULT_OBJECT_LEFT,
                        top: this.DEFAULT_OBJECT_TOP,
                        angle: 0,
                        padding: 10
                    });
                    image.scaleToWidth(600);

                    image.customStyles = style;
                    image.customStyleStr = JSON.stringify(style);

                    this.canvas.add(image);
                    this.layerComponentService.addLayerData(image, "Image " + this.layerCount++, "image");
                    this.selectItem(image);

                    this.saveCanvasState();

                    resolve(image);
                });
            }
            else
            {
                this.notificationService.alert("Please add a valid image URL/Path");
                resolve(null);
            }
        });
        return promise;
    }

    public addFigure(figure: string): fabric.Object
    {
        if (!figure || !this.canvas) return null;

        let style: Style = new Style();
        this.canvas.discardActiveObject();
        let item: any;
        switch (figure)
        {
            case 'square':
            {
                let points = [
                    {x: 50, y: 50},
                    {x: 50 + this.DEFAULT_OBJECT_WIDTH_HEIGHT, y: 50},
                    {x: 50 + this.DEFAULT_OBJECT_WIDTH_HEIGHT, y: 50 + this.DEFAULT_OBJECT_WIDTH_HEIGHT},
                    {x: 50, y: 50 + this.DEFAULT_OBJECT_WIDTH_HEIGHT}
                ]
                item = new fabric.Polygon(points, {
                    id: uuidv4(),
                    left: this.DEFAULT_OBJECT_LEFT,
                    top: this.DEFAULT_OBJECT_TOP,
                    fill: this.DEFAULT_FILL_COLOR,
                    objectCaching: false,
                    absolutePositioned: true
                });
                break;
            }
            case 'rectangle':
            {
                let points = [
                    {x: 50, y: 50},
                    {x: 50 + this.DEFAULT_OBJECT_WIDTH_HEIGHT * 2, y: 50},
                    {x: 50 + this.DEFAULT_OBJECT_WIDTH_HEIGHT * 2, y: 50 + this.DEFAULT_OBJECT_WIDTH_HEIGHT},
                    {x: 50, y: 50 + this.DEFAULT_OBJECT_WIDTH_HEIGHT}
                ]
                item = new fabric.Polygon(points, {
                    id: uuidv4(),
                    left: this.DEFAULT_OBJECT_LEFT,
                    top: this.DEFAULT_OBJECT_TOP,
                    fill: this.DEFAULT_FILL_COLOR,
                    objectCaching: false,
                    absolutePositioned: true
                });
                break;
            }
            case 'rounded rectangle':
            {
                item = new fabric.Rect({
                    id: uuidv4(),
                    width: this.DEFAULT_OBJECT_WIDTH_HEIGHT * 2,
                    height: this.DEFAULT_OBJECT_WIDTH_HEIGHT,
                    left: this.DEFAULT_OBJECT_LEFT,
                    top: this.DEFAULT_OBJECT_TOP,
                    fill: this.DEFAULT_FILL_COLOR,
                    rx: this.DEFAULT_OBJECT_WIDTH_HEIGHT / 4,
                    ry: this.DEFAULT_OBJECT_WIDTH_HEIGHT / 4,
                    objectCaching: false,
                    absolutePositioned: true
                });
                break;
            }
            case 'triangle':
            {
                let points = [
                    {x: 50, y: 50},
                    {x: 50 + this.DEFAULT_OBJECT_WIDTH_HEIGHT / 2, y: 50 + this.DEFAULT_OBJECT_WIDTH_HEIGHT},
                    {x: 50 - this.DEFAULT_OBJECT_WIDTH_HEIGHT / 2, y: 50 + this.DEFAULT_OBJECT_WIDTH_HEIGHT}
                ]
                item = new fabric.Polygon(points, {
                    id: uuidv4(),
                    left: this.DEFAULT_OBJECT_LEFT,
                    top: this.DEFAULT_OBJECT_TOP,
                    fill: this.DEFAULT_FILL_COLOR,
                    objectCaching: false,
                    absolutePositioned: true
                });
                break;
            }
            case 'circle':
            {
                item = new fabric.Circle({
                    id: uuidv4(),
                    radius: this.DEFAULT_OBJECT_WIDTH_HEIGHT,
                    left: this.DEFAULT_OBJECT_LEFT,
                    top: this.DEFAULT_OBJECT_TOP,
                    fill: this.DEFAULT_FILL_COLOR,
                    objectCaching: false,
                    absolutePositioned: true
                });
                break;
            }
            case 'line':
            {
                item = new fabric.Line([50, 100, 200, 200], {
                    id: uuidv4(),
                    left: this.DEFAULT_OBJECT_LEFT,
                    top: this.DEFAULT_OBJECT_TOP,
                    angle: 45,
                    stroke: this.DEFAULT_STROKE_COLOR
                });
                style.strokeEnabled = true;
                break;
            }
            case 'polygon':
            {
                const initialState = {
                    title: 'Number of sides for the Polygon',
                    yesBtnName: 'OK',
                    yesBtnVisible: true,
                    noBtnName: 'Cancel',
                    noBtnVisible: true,
                    textInputVisible: false,
                    selectDropDownVisible: true,
                    selectLabel: 'No of Sides',
                    selectOptions: [
                        {name: '3 Sides  (Triangle)', value: 3},
                        {name: '4 Sides  (Rectangle)', value: 4},
                        {name: '5 Sides  (Pentagon)', value: 5},
                        {name: '6 Sides  (Hexagon)', value: 6},
                        {name: '7 Sides  (Heptagon)', value: 7},
                        {name: '8 Sides  (Octagon)', value: 8},
                        {name: '9 Sides  (Nonagon)', value: 9},
                        {name: '10 Sides  (Decagon)', value: 10},
                        {name: '11 Sides  (Hendecagon)', value: 11},
                        {name: '12 Sides  (Dodecagon)', value: 12},
                        {name: '13 Sides  (Tridecagon)', value: 13},
                        {name: '14 Sides  (Tetradecagon)', value: 14},
                        {name: '15 Sides  (Pentadecagon)', value: 15},
                        {name: '16 Sides  (Hexadecagon)', value: 16},
                    ],
                    selectValue: {name: '6 Sides  (Hexagon)', value: 6},
                };
                const dialogRef = this.dialog.open(InputBoxComponent, {
                    data: initialState,
                    width: INPUT_DIALOG_WIDTH + 'px',
                    height: INPUT_DIALOG_HEIGHT + 'px',
                    maxWidth: INPUT_DIALOG_WIDTH,
                    maxHeight: INPUT_DIALOG_HEIGHT
                });
                dialogRef.afterClosed().subscribe(result =>
                {
                    if (result && result['selectValue'] && result['selectValue']['value'])
                    {
                        let noOfSides = result['selectValue']['value'];

                        let points = this.getPolygonCoordinates(noOfSides, 0, 0, 100);

                        item = new fabric.Polygon(points, {
                            id: uuidv4(),
                            left: this.DEFAULT_OBJECT_LEFT,
                            top: this.DEFAULT_OBJECT_TOP,
                            fill: this.DEFAULT_FILL_COLOR,
                            objectCaching: false,
                            absolutePositioned: true
                        });

                        item.customStyles = style;
                        item.customStyleStr = JSON.stringify(style);
                        item.edit = false;

                        this.canvas.add(item);
                        this.layerComponentService.addLayerData(item, figure + " " + this.layerCount++, "shape");

                        this.selectItem(item);
                        this.styleService.setCurrentStyle(style);

                        this.setStyleValuesForObject('all', item);

                        return item;
                    }
                });
                return;
            }
            case 'star':
            {
                let points = [
                    {x: 95, y: -10},
                    {x: 135, y: 5},
                    {x: 90, y: 25},
                    {x: 130, y: 65},
                    {x: 65, y: 55},
                    {x: 50, y: 100},
                    {x: 15, y: 65},
                    {x: -35, y: 100},
                    {x: -20, y: 45},
                    {x: -90, y: 50},
                    {x: -35, y: 10},
                    {x: -80, y: -15},
                    {x: -20, y: -20},
                    {x: -10, y: -70},
                    {x: 25, y: -35},
                    {x: 65, y: -80},
                    {x: 65, y: -30},
                    {x: 110, y: -50}
                ]
                item = new fabric.Polygon(points, {
                    id: uuidv4(),
                    left: this.DEFAULT_OBJECT_LEFT,
                    top: this.DEFAULT_OBJECT_TOP,
                    fill: this.DEFAULT_FILL_COLOR,
                    objectCaching: false,
                    absolutePositioned: true
                });
                break;
            }
            default:
                return null;
        }
        item.customStyles = style;
        item.customStyleStr = JSON.stringify(style);
        item.edit = false;

        this.canvas.add(item);
        this.layerComponentService.addLayerData(item, figure + " " + this.layerCount++, "shape");

        this.selectItem(item);
        this.styleService.setCurrentStyle(style);

        this.setStyleValuesForObject('all', item);

        return item;
    }

    private getPolygonCoordinates(sidesCount: number, centerX: number, centerY: number, radius: number)
    {
        let centerAngle = 2 * Math.PI / sidesCount;
        let initialAngle = (sidesCount % 2 == 0) ? (Math.PI / 2 - centerAngle / 2) : Math.PI / 2;

        let result: any[] = [];

        for (let i = 0; i < sidesCount; i++)
        {
            let angle = initialAngle + (i * centerAngle);
            let x = Math.round(centerX + radius * Math.cos(angle));
            let y = Math.round(centerY - radius * Math.sin(angle));
            result.push({x: x, y: y});
        }

        return result;
    }


    public addText(textString): fabric.IText
    {
        if (!textString || !this.canvas) return null;

        let style: Style = new Style();
        this.canvas.discardActiveObject();
        const text = new fabric.IText(textString, {
            id: uuidv4(),
            left: 100,
            top: 100,
            fontFamily: style.fontFamily,
            angle: 0,
            fontSize: Style.DEFAULT_FONT_SIZE,
            fill: style.fillColor,
            absolutePositioned: true,

        });
        text.customStyles = style;
        text.customStyleStr = JSON.stringify(style);

        this.canvas.add(text);
        this.layerComponentService.addLayerData(text, textString, "text");
        this.selectItem(text);

        return text;
    }

    // ********************  Drawing Styles ********************

    public setDrawingStyles(): void
    {
        let style: Style = this.styleService.getCurrentStyle();
        if (!style || style.drawingMode == null) return;

        if (style.drawingMode == 'vline')
        {
            let vLinePatternBrush = new fabric.PatternBrush(this.canvas);
            vLinePatternBrush.getPatternSrc = () =>
            {
                let patternCanvas = fabric.document.createElement('canvas');
                patternCanvas.width = style.drawingLineModeStrokeWidth + style.drawingLineSquareDiamondModeSpaceWidth;
                patternCanvas.height = style.drawingLineModeStrokeWidth + style.drawingLineSquareDiamondModeSpaceWidth;

                let ctx = patternCanvas.getContext('2d');
                ctx.strokeStyle = style.drawingLineColor;
                ctx.lineWidth = style.drawingLineModeStrokeWidth;
                ctx.beginPath();
                ctx.moveTo(style.drawingLineModeStrokeWidth, 0);
                ctx.lineTo(style.drawingLineModeStrokeWidth, style.drawingLineModeStrokeWidth + style.drawingLineSquareDiamondModeSpaceWidth);
                ctx.closePath();
                ctx.stroke();

                return patternCanvas;
            };

            this.canvas.freeDrawingBrush = vLinePatternBrush;
        }
        else if (style.drawingMode == 'hline')
        {
            let hLinePatternBrush = new fabric.PatternBrush(this.canvas);
            hLinePatternBrush.getPatternSrc = () =>
            {
                let patternCanvas = fabric.document.createElement('canvas');
                patternCanvas.width = style.drawingLineModeStrokeWidth + style.drawingLineSquareDiamondModeSpaceWidth;
                patternCanvas.height = style.drawingLineModeStrokeWidth + style.drawingLineSquareDiamondModeSpaceWidth;

                let ctx = patternCanvas.getContext('2d');
                ctx.strokeStyle = style.drawingLineColor;
                ctx.lineWidth = style.drawingLineModeStrokeWidth;
                ctx.beginPath();
                ctx.moveTo(0, style.drawingLineModeStrokeWidth);
                ctx.lineTo(style.drawingLineModeStrokeWidth + style.drawingLineSquareDiamondModeSpaceWidth, style.drawingLineModeStrokeWidth);
                ctx.closePath();
                ctx.stroke();

                return patternCanvas;
            };

            this.canvas.freeDrawingBrush = hLinePatternBrush;
        }
        else if (style.drawingMode == 'circle')
        {
            let circlePatternBrush = new fabric.PatternBrush(this.canvas);
            circlePatternBrush.getPatternSrc = () =>
            {
                let patternCanvas = fabric.document.createElement('canvas');
                let circle = new fabric.Circle({
                    radius: style.drawingCircleSquareDiamondModeSquareCircleWidth / 2,
                    fill: style.drawingLineColor
                });

                patternCanvas.width = circle.getBoundingRect().width + style.drawingLineSquareDiamondModeSpaceWidth;
                patternCanvas.height = circle.getBoundingRect().height + style.drawingLineSquareDiamondModeSpaceWidth;

                circle.set({
                    left: 0,
                    top: 0
                });

                let ctx = patternCanvas.getContext('2d');
                circle.render(ctx);

                return patternCanvas;
            };

            this.canvas.freeDrawingBrush = circlePatternBrush;
        }
        else if (style.drawingMode == 'square')
        {
            let squarePatternBrush = new fabric.PatternBrush(this.canvas);
            squarePatternBrush.getPatternSrc = () =>
            {
                let patternCanvas = fabric.document.createElement('canvas');
                patternCanvas.width = style.drawingCircleSquareDiamondModeSquareCircleWidth + style.drawingLineSquareDiamondModeSpaceWidth;
                patternCanvas.height = style.drawingCircleSquareDiamondModeSquareCircleWidth + style.drawingLineSquareDiamondModeSpaceWidth;

                let ctx = patternCanvas.getContext('2d');
                ctx.fillStyle = style.drawingLineColor;
                ctx.fillRect(0, 0, style.drawingCircleSquareDiamondModeSquareCircleWidth, style.drawingCircleSquareDiamondModeSquareCircleWidth);

                return patternCanvas;
            };

            this.canvas.freeDrawingBrush = squarePatternBrush;
        }
        else if (style.drawingMode == 'diamond')
        {
            let diamondPatternBrush = new fabric.PatternBrush(this.canvas);
            diamondPatternBrush.getPatternSrc = () =>
            {
                let patternCanvas = fabric.document.createElement('canvas');
                let rect = new fabric.Rect({
                    width: style.drawingCircleSquareDiamondModeSquareCircleWidth,
                    height: style.drawingCircleSquareDiamondModeSquareCircleWidth,
                    angle: 45,
                    fill: style.drawingLineColor
                });

                patternCanvas.width = rect.getBoundingRect().width + style.drawingLineSquareDiamondModeSpaceWidth;
                patternCanvas.height = rect.getBoundingRect().height + style.drawingLineSquareDiamondModeSpaceWidth;

                rect.set({
                    left: rect.getBoundingRect().width / 2,
                    top: 0
                });

                let ctx = patternCanvas.getContext('2d');
                rect.render(ctx);

                return patternCanvas;
            };

            this.canvas.freeDrawingBrush = diamondPatternBrush;
        }
        else if (style.drawingMode === 'texture')
        {
            // let textureImageBrush = new fabric.PatternBrush(this.canvas);
            // textureImageBrush.getPatternSrc = () =>
            // {
            //     let patternCanvas = fabric.document.createElement('canvas');
            //     let image = new fabric.Image({
            //         src: style.drawingTextureModeImage
            //     });
            //
            //     patternCanvas.width = image.getBoundingRect().width + style.drawingLineSquareDiamondModeSpaceWidth;
            //     patternCanvas.height = image.getBoundingRect().height + style.drawingLineSquareDiamondModeSpaceWidth;
            //
            //     image.set({
            //         left: 10,
            //         top: 15
            //     });
            //
            //     let ctx = patternCanvas.getContext('2d');
            //     image.render(ctx);
            //
            //     return patternCanvas;
            // };
            //
            // this.canvas.freeDrawingBrush = textureImageBrush;
        }
        else if (style.drawingMode === 'eraser')
        {
            this.canvas.freeDrawingBrush = new fabric.EraserBrush(this.canvas);
            this.canvas.freeDrawingBrush.width = style.drawingLineWidth;

            return;
        }
        else
        {
            this.canvas.freeDrawingBrush = new fabric[style.drawingMode + 'Brush'](this.canvas);
        }

        let brush = this.canvas.freeDrawingBrush;

        brush.color = style.drawingLineColor;
        brush.width = style.drawingLineWidth;

        brush.shadow = new fabric.Shadow({
            color: style.drawingShadowColor,
            blur: style.drawingShadowWidth,
            offsetX: style.drawingShadowOffSetX,
            offsetY: style.drawingShadowOffSetY,
            affectStroke: true,
        });

        if (brush.getPatternSrc)
        {
            brush.source = brush.getPatternSrc.call(brush);
        }
    }

    // ********************  Font Styles ********************

    private setFontFamily(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || style.fontFamily == null) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object || !(object instanceof fabric.IText)) continue;

            if (!object.customStyles) object.customStyles = new Style();
            object.customStyles.fontFamily = style.fontFamily;

            this.setActiveProp(object, 'fontFamily', style.fontFamily);
        }
        this.canvas.requestRenderAll();
    }

    private setTextAlign(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || style.textAlign == null) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object || !(object instanceof fabric.IText)) continue;

            if (!object.customStyles) object.customStyles = new Style();
            object.customStyles.textAlign = style.textAlign;

            this.setActiveProp(object, 'textAlign', style.textAlign);
        }
        this.canvas.requestRenderAll();
    }

    private setFontWeight(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || style.fontWeight == null) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object || !(object instanceof fabric.IText)) continue;

            if (!object.customStyles) object.customStyles = new Style();
            object.customStyles.fontWeight = style.fontWeight;

            this.setActiveStyle(object, 'fontWeight', style.fontWeight);
        }
        this.canvas.requestRenderAll();
    }

    private setCharSpacing(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || style.textCharSpacing == null) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object || !(object instanceof fabric.IText)) continue;

            if (!object.customStyles) object.customStyles = new Style();
            object.customStyles.textCharSpacing = style.textCharSpacing;

            this.setActiveStyle(object, 'charSpacing', style.textCharSpacing);
        }
        this.canvas.requestRenderAll();
    }

    private setTextDecoration(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || style.textStyles == null) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object || !(object instanceof fabric.IText)) continue;

            if (!object.customStyles) object.customStyles = new Style();

            object.customStyles.textStyles = style.textStyles;

            if (style.textStyles.indexOf('italic') > -1)
            {
                this.setActiveStyle(object, 'fontStyle', 'italic');
            }
            else
            {
                this.setActiveStyle(object, 'fontStyle', 'normal');
            }

            let textDecoration = '';
            if (style.textStyles.indexOf('underline') > -1)
            {
                let value = 'underline';
                textDecoration += ` ${value}`;
            }
            if (style.textStyles.indexOf('overline') > -1)
            {
                let value = 'overline';
                textDecoration += ` ${value}`;
            }
            if (style.textStyles.indexOf('linethrough') > -1)
            {
                let value = 'linethrough';
                textDecoration += ` ${value}`;
            }
            this.setActiveStyle(object, 'textDecoration', textDecoration);
        }
        this.canvas.requestRenderAll();
    }

    private setTextAllCapital(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object || !(object instanceof fabric.IText)) continue;

            if (!object.customStyles) object.customStyles = new Style();
            style.allCapital = false;
            object.customStyles.allCapital = style.allCapital;

            let text: string = object.text;
            if (object.isEditing)
            {
                let startStr = text.substring(0, object.selectionStart);
                let selectedStr = text.substring(object.selectionStart, object.selectionEnd);
                let endStr = text.substring(object.selectionEnd)

                let finalStr = startStr + selectedStr.toUpperCase() + endStr;
                object.text = finalStr;
            }
            else
            {
                if (!this.loadingStylesProgrammatically) this.notificationService.alert("Please select required text");
            }
        }
        this.canvas.requestRenderAll();
    }

    private setTextAllSimple(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object || !(object instanceof fabric.IText)) continue;

            if (!object.customStyles) object.customStyles = new Style();
            style.allSimple = false;
            object.customStyles.allSimple = style.allSimple;

            let text: string = object.text;
            if (object.isEditing)
            {
                let startStr = text.substring(0, object.selectionStart);
                let selectedStr = text.substring(object.selectionStart, object.selectionEnd);
                let endStr = text.substring(object.selectionEnd)

                let finalStr = startStr + selectedStr.toLowerCase() + endStr;
                object.text = finalStr;
            }
            else
            {
                if (!this.loadingStylesProgrammatically) this.notificationService.alert("Please select required text");
            }
        }
        this.canvas.requestRenderAll();
    }

    private setTextSuperScript(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || !style.superscript) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object || !(object instanceof fabric.IText)) continue;

            if (!object.customStyles) object.customStyles = new Style();
            style.superscript = false;
            object.customStyles.superscript = style.superscript;

            if (object.isEditing)
            {
                object.setSelectionStyles({
                    fontSize: Style.DEFAULT_FONT_SIZE,
                    deltaY: undefined,
                });
                object.setSuperscript();
            }
            else
            {
                if (!this.loadingStylesProgrammatically) this.notificationService.alert("Please select required text");
            }
        }
        this.canvas.requestRenderAll();
    }

    private setTextSubScript(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || !style.subscript) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object || !(object instanceof fabric.IText)) continue;

            if (!object.customStyles) object.customStyles = new Style();
            style.subscript = false;
            object.customStyles.subscript = style.subscript;

            if (object.isEditing)
            {
                object.setSelectionStyles({
                    fontSize: Style.DEFAULT_FONT_SIZE,
                    deltaY: undefined,
                });
                object.setSubscript();
            }
            else
            {
                if (!this.loadingStylesProgrammatically) this.notificationService.alert("Please select required text");
            }
        }
        this.canvas.requestRenderAll();
    }

    private resetOtherStyles(object = null)
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object || !(object instanceof fabric.IText)) continue;
            if (object.isEditing)
            {
                object.setSelectionStyles({
                    fontSize: Style.DEFAULT_FONT_SIZE,
                    deltaY: undefined,
                });
            }
            else
            {
                if (!this.loadingStylesProgrammatically) this.notificationService.alert("Please select required text");
            }
        }
        this.canvas.requestRenderAll();
    }


    // ********************  Fill, Stroke, Shadow, Glow Styles ********************

    private setFill(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || style.fillMethod != 'fill' || style.fillColor == null) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object) continue;

            if (!object.customStyles) object.customStyles = new Style();
            object.customStyles.fillMethod = style.fillMethod;
            object.customStyles.fillColor = style.fillColor;

            this.setActiveStyle(object, 'fill', style.fillColor);
            this.setTextDecoration();
        }
        this.canvas.requestRenderAll();
    }

    private setGradient(object = null): void
    {
        if (!this.canvas) return;

        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || style.fillMethod != 'gradient' || style.gradientColor == null) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object) continue;

            if (!object.customStyles) object.customStyles = new Style();
            object.customStyles.fillMethod = style.fillMethod;
            object.customStyles.gradientColor = style.gradientColor;
            object.customStyles.gradientType = style.gradientType;
            object.customStyles.linearGradientAngle = style.linearGradientAngle;
            object.customStyles.radialGradientRadius = style.radialGradientRadius;

            let x1 = 0;
            let y1 = 0;
            let x2 = object.width;
            let y2 = object.height;

            let ow = object.width;
            let oh = object.height;
            if (style.gradientType && style.gradientType == 'linear')
            {
                let angle = style.linearGradientAngle;
                let gradientOpts = null;
                if (angle != null)
                {
                    if (object instanceof fabric.Circle)
                    {
                        gradientOpts = {
                            start: this.angle2circle(angle, ow / 2),
                            end: this.angle2circle(angle + 180, ow / 2)
                        };
                    }
                    else
                    {
                        gradientOpts = {
                            start: this.angle2rect(angle, ow, oh),
                            end: this.angle2rect(angle + 180, ow, oh)
                        };
                    }

                    gradientOpts.end = {
                        x: ow - gradientOpts.start.x,
                        y: oh - gradientOpts.start.y
                    };

                    x1 = gradientOpts.start.x;
                    y1 = gradientOpts.start.y;
                    x2 = gradientOpts.end.x;
                    y2 = gradientOpts.end.y;
                }

                let gradient = new fabric.Gradient({
                    type: 'linear',
                    coords: {
                        x1: x1,
                        y1: y1,
                        x2: x2,
                        y2: y2
                    },
                    colorStops: style.gradientColor,
                });
                this.setActiveStyle(object, 'fill', gradient);
            }
            else if (style.gradientType && style.gradientType == 'radial')
            {
                let radius = style.radialGradientRadius ? style.radialGradientRadius : 100;
                let gradient = new fabric.Gradient({
                    type: 'radial',
                    coords: {
                        x1: ow / 2,
                        y1: oh / 2,
                        x2: ow / 2,
                        y2: oh / 2,
                        r1: ow / 2 * 20 / 100 * 1 / radius,
                        r2: 1.414 * ow / 2 * 1 / 100 * (20 * radius / 100 + 80),
                    },
                    colorStops: style.gradientColor,
                });
                this.setActiveStyle(object, 'fill', gradient);
            }

        }
        this.canvas.requestRenderAll();
    }

    public angle2rect(angle, sx, sy)
    {
        while (angle < 0) angle += 360;
        angle %= 360;

        let a = sy, b = a + sx, c = b + sy, // 3 first corners
            p                     = (sx + sy) * 2, // perimeter
            rp                    = p * 0.00277, // ratio between perimeter & 360
            pp                    = Math.round(((angle * rp) + (sy >> 1)) % p); // angle position on perimeter

        if (pp <= a) return {x: 0, y: sy - pp};
        if (pp <= b) return {y: 0, x: pp - a};
        if (pp <= c) return {x: sx, y: pp - b};
        return {y: sy, x: sx - (pp - c)};
    }

    public angle2circle(angle, radius)
    {
        let a = (angle + 180) * Math.PI / 180;
        return {
            x: Math.round((Math.cos(a) + 1) * radius),
            y: Math.round((Math.sin(a) + 1) * radius)
        };
    }

    private setStroke(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || style.strokeColor == null) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object) continue;

            if (!object.customStyles) object.customStyles = new Style();
            object.customStyles.strokeEnabled = style.strokeEnabled;
            object.customStyles.strokeColor = style.strokeColor;
            object.customStyles.strokeSize = style.strokeSize;

            if (object.type == 'i-text')
            {
                this.setActiveStyle(object, 'paintFirst', 'stroke');
                this.setActiveStyle(object, 'strokeUniform', 'true');
            }

            if (style.strokeEnabled)
            {
                this.setActiveStyle(object, 'stroke', style.strokeColor);
                this.setActiveStyle(object, 'strokeWidth', style.strokeSize);
            }
            else
            {
                this.setActiveStyle(object, 'stroke', null);
                this.setActiveStyle(object, 'strokeWidth', 0);
            }
            this.setTextDecoration();
        }
        this.canvas.requestRenderAll();
    }

    private setShadow(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || style.shadowColor == null) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object) continue;

            if (!object.customStyles) object.customStyles = new Style();
            object.customStyles.shadowEnabled = style.shadowEnabled;
            object.customStyles.shadowColor = style.shadowColor;
            object.customStyles.shadowFeather = style.shadowFeather;
            object.customStyles.shadowDistance = style.shadowDistance;

            let shadow = {
                affectStroke: true,
                color: style.shadowEnabled ? style.shadowColor : null,
                blur: style.shadowEnabled ? style.shadowFeather : 0,
                offsetX: style.shadowEnabled ? style.shadowDistance : 0,
                offsetY: style.shadowEnabled ? style.shadowDistance : 0
            }
            this.setActiveStyle(object, 'shadow', shadow);
            if (!style.shadowEnabled && style.glowEnabled) this.setGlow(object);
        }
        this.canvas.requestRenderAll();
    }

    private setGlow(object = null): void
    {
        let targetObject: fabric.Object = object ? object : this.canvas.getActiveObject();
        let style: Style = this.getObjectStyle(targetObject);
        if (!style || style.glowColor == null) return;

        let result = [];
        this.getAllObjectsOfGroupOrSelection(targetObject, result);
        for (let object of result)
        {
            if (!object) continue;

            if (!object.customStyles) object.customStyles = new Style();
            object.customStyles.glowEnabled = style.glowEnabled;
            object.customStyles.glowColor = style.glowColor;
            object.customStyles.glowFeather = style.glowFeather;

            let glow = {
                affectStroke: true,
                color: style.glowEnabled ? style.glowColor : null,
                blur: style.glowEnabled ? style.glowFeather : 0,
                offsetX: 0.1,
                offsetY: 0
            };
            this.setActiveStyle(object, 'shadow', glow);
            if (!style.glowEnabled && style.shadowEnabled) this.setShadow(object);
        }
        this.canvas.requestRenderAll();
    }

    // Overall Styles

    public createStyleThumbnail(style: Style)
    {

        let canvas = new fabric.Canvas();
        canvas.setWidth(50);
        canvas.setHeight(50);

        let item = new fabric.Rect({
            width: 30,
            height: 20,
            rx: 5,
            ry: 5,
            left: 25,
            top: 25,
            originX: "center",
            originY: "center"
        });

        item.customStyles = JSON.parse(JSON.stringify(style));
        item.customStyles.strokeSize = 3;
        item.customStyles.shadowDistance = 6;
        item.customStyles.shadowFeather = 6;
        item.customStyles.glowFeather = 15;

        this.setStyleValuesForObject('all', item);

        canvas.add(item);
        canvas.renderAll();

        let thumbnailData = canvas.toDataURL({
            format: 'jpeg',
            multiplier: 1.0,
            quality: 1.0
        });

        return thumbnailData;
    }
}
