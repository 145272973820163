import {Component, OnInit, ViewChild} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {Template} from "../../../models/template";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {AdminConsoleService} from "../../../services/admin-console.service";
import {ToastrService} from "ngx-toastr";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {TemplatePreviewDialogComponent} from "../../common-components/template-preview-dialog/template-preview-dialog.component";

@Component({
    selector: 'app-approve-templates',
    templateUrl: './approve-templates.component.html',
    styleUrls: ['./approve-templates.component.scss']
})
export class ApproveTemplatesComponent implements OnInit {

    @ViewChild('paginatorApproveTemplatesTab', {static: false}) paginatorApproveTemplatesTab: MatPaginator;
    @ViewChild('sortApproveTemplatesTab', {static: false}) sortApproveTemplatesTab: MatSort;

    displayedColumnsApproveTemplatesTab: string[] = ['preview', 'name', 'category', 'canvasType', 'isPremium', 'price',
                                                     'createdDate', 'view-template'];

    dataSourceApproveTemplatesTab: MatTableDataSource<Template>;

    constructor(public authService: AuthService,
                public router: Router,
                public dialog: MatDialog,
                public adminConsoleService: AdminConsoleService,
                public notification: ToastrService,
                private liveAnnouncer: LiveAnnouncer)
    { }

    ngOnInit(): void
    {
        if (this.authService.getCurrentUser() && this.authService.getCurrentUserType() == 'ADMIN')
        {
            this.getAllPublishedTemplatesToApprove();
        }
        else
        {
            this.router.navigate(['']);
        }
    }

    public viewTemplate(template: Template)
    {
        let buttons = [];
        buttons.push("Approve");
        buttons.push("Decline");

        const initialState = {
            template: template,
            dialogPreviewBase64Url: template.dialogPreviewUrl,
            buttons: buttons
        };
        let dialogRef = this.dialog.open(TemplatePreviewDialogComponent, {data: initialState});

        dialogRef.afterClosed().subscribe(() =>
        {
            this.getAllPublishedTemplatesToApprove();
        });
    }

    private getAllPublishedTemplatesToApprove()
    {
        this.adminConsoleService.getAllPublishedTemplatesToApprove().subscribe((res: any[]) =>
        {
            this.dataSourceApproveTemplatesTab = new MatTableDataSource<Template>(res);
            this.dataSourceApproveTemplatesTab.paginator = this.paginatorApproveTemplatesTab;
            this.dataSourceApproveTemplatesTab.sort = this.sortApproveTemplatesTab;

        }, (error) =>
        {
            console.error(error);
        })
    }

    public announceSortChangeForApproveTemplatesTable(sortState: Sort)
    {
        if (sortState.direction)
        {
            this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        }
        else
        {
            this.liveAnnouncer.announce('Sorting cleared');
        }
    }
}
