import {Component, OnInit} from '@angular/core';
import {ToolAndGridComponentService} from "../../services/component-service/tool-and-grid-component.service";
import {Template} from "../../models/template";
import {TemplateService} from "../../services/temaplate.service";
import {Router} from '@angular/router';
import {SearchEngineOptimizationService} from "../../services/search-engine-optimization.service";

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit{

    public templates: Map<string, {}> = new Map<string, {}>();
    public activeTemplateSet: { category: string, setIndex: number, templates: Template[] } = {
        category: "Automotive",
        setIndex: 0,
        templates: []
    };

    public isTemplateRefreshEnabled: boolean = false;
    public templateRefreshMaxSetCount = 5;
    public templateSetSize = 4;
    public content1TabImage = "../../../assets/gif/welcome-page-templates.gif";

    constructor(public toolAndGridComponentService: ToolAndGridComponentService,
                public templateService: TemplateService,
                public searchEngineOptimizationService: SearchEngineOptimizationService,
                public router: Router)
    {
        this.fillTemplateMap();
    }

    ngOnInit() {
        this.searchEngineOptimizationService.updatePageTitle('ThumbzStudio');
        this.searchEngineOptimizationService.updateMetaDescription("Experience stunning visuals at ThumbzStudio. Our expert designers create unique YouTube thumbnails to make your videos truly stand out...");
    }

    private fillTemplateMap()
    {
        this.templateService.getTemplateCategories().subscribe((res: any[]) =>
        {
            Object.keys(res).forEach(key =>
            {
                let categoryId = res[key].id;
                let categoryName = res[key].name;
                this.templateService.getWelcomePageTemplates(categoryId, 20).subscribe((res: Template[]) =>
                {
                    this.templates[categoryName] = {
                        templates: res,
                        category: categoryName
                    };
                    if (categoryName == 'Automotive')
                    {
                        this.activeTemplateSet.templates = this.templates[categoryName].templates.slice(0, 4);
                        this.activeTemplateSet.category = this.templates[categoryName].category;
                        this.activeTemplateSet.setIndex = 0;
                    }
                });
            });
            this.refreshTemplates();
        });
    }

    public templateTabChanged(event)
    {
        this.activeTemplateSet.templates = this.templates[event.tab.textLabel].templates.slice(0, 4);
        this.activeTemplateSet.category = this.templates[event.tab.textLabel].category;
        this.activeTemplateSet.setIndex = 0;
    }

    public content1TabChange(event)
    {
        if (event.tab.textLabel == 'Templates')
        {
            this.content1TabImage = "../../../assets/gif/welcome-page-templates.gif";
        }
        else if (event.tab.textLabel == 'Editor')
        {
            this.content1TabImage = "../../../assets/gif/welcome-page-editor.gif";
        }
        else if (event.tab.textLabel == 'Share')
        {
            this.content1TabImage = "../../../assets/gif/welcome-page-share.gif";
        }
    }

    public refreshTemplates()
    {
        if (!this.isTemplateRefreshEnabled) return;

        setTimeout(() =>
        {
            this.loadNextTemplateSet();
            this.refreshTemplates();
        }, 5000)
    }

    public toggleTemplateAutoRefresh()
    {
        this.isTemplateRefreshEnabled = !this.isTemplateRefreshEnabled;
        this.refreshTemplates();
    }

    public loadPreviousTemplateSet()
    {
        if (this.activeTemplateSet.setIndex <= 0) {
            this.activeTemplateSet.setIndex = this.templateRefreshMaxSetCount;
        }

        this.activeTemplateSet.setIndex = (this.activeTemplateSet.setIndex - 1) % this.templateRefreshMaxSetCount;
        let startIndex  = this.activeTemplateSet.setIndex * this.templateSetSize;
        this.activeTemplateSet.templates = this.templates[this.activeTemplateSet.category].templates.slice(startIndex, startIndex + this.templateSetSize);
    }

    public loadNextTemplateSet()
    {
        this.activeTemplateSet.setIndex = (this.activeTemplateSet.setIndex + 1) % this.templateRefreshMaxSetCount;
        let startIndex  = this.activeTemplateSet.setIndex * this.templateSetSize;
        this.activeTemplateSet.templates = this.templates[this.activeTemplateSet.category].templates.slice(startIndex, startIndex + this.templateSetSize);
    }

    public navigateToTemplate(template: Template)
    {
        window.location.href = template.shareableLink;
    }

    public navigateToTool()
    {
        this.router.navigate(['/tool']);
        window.scrollTo(0, 0);
    }
}
