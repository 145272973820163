<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSourceApproveTemplatesTab" matSort #sortApproveTemplatesTab="matSort" (matSortChange)="announceSortChangeForApproveTemplatesTable($event)">

        <ng-container matColumnDef="preview">
            <th mat-header-cell *matHeaderCellDef> Preview</th>
            <td class="preview-td" mat-cell *matCellDef="let template">
                <div class="template-image"><img [src]="template.gridPreviewUrl" class="img-fluid" alt=""></div>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"> Name</th>
            <td mat-cell *matCellDef="let template"> {{ template.name }}</td>
        </ng-container>

        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Category"> Category</th>
            <td mat-cell *matCellDef="let template"> {{ template.category }}</td>
        </ng-container>

        <ng-container matColumnDef="canvasType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Canvas Type"> Canvas Type</th>
            <td mat-cell *matCellDef="let template"> {{ template.canvasType }}</td>
        </ng-container>

        <ng-container matColumnDef="isPremium">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Is Premium"> Is Premium</th>
            <td mat-cell *matCellDef="let template"> {{ template.isPremium }}</td>
        </ng-container>

        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Price"> Price</th>
            <td mat-cell *matCellDef="let template"> {{ template.price }}</td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Created Date"> Created Date</th>
            <td mat-cell *matCellDef="let template"> {{ template.createdDate | date }}</td>
        </ng-container>

        <ng-container matColumnDef="view-template">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let template">
                <button class="view-template-btn" mat-flat-button color="primary" (click)="viewTemplate(template)">View Template</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsApproveTemplatesTab"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsApproveTemplatesTab;"></tr>
    </table>

    <mat-paginator #paginatorApproveTemplatesTab [pageSizeOptions]="[4, 5, 7, 10]" showFirstLastButtons></mat-paginator>
</div>
