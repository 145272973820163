<div class="how-to-use d-flex justify-content-center">
    <div class="content-div">
        <h1>How to use ThumbzStudio tool</h1>

        <div class="iframe-container">
            <iframe src="https://www.youtube.com/embed/jZ_JPJPRfYo" title="How to use ThumbzStudio tool to create a awesome youtube thumbnails" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

        <button class="btn btn-primary btn-ok how-to-use-btn" mat-button (click)="goToTool()">Use Tool</button>
    </div>
</div>
