<div class="modal-header" [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <label class="modal-title pull-left">{{ title }}</label>
    <button type="button" class="close pull-right" aria-label="Close" (click)="dialogRef.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body d-flex align-items-center row" [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <div class="login-btn-div">
        <div class="google-login-div">
            <button class="login-google-btn" color="primary" (click)="signInWithGoogle()">
                <mat-icon svgIcon="svg-icon-google-login" aria-hidden="false"></mat-icon>
                Sign in with Google
            </button>
        </div>
        <div class="facebook-login-div">
            <button class="login-facebook-btn" color="primary" (click)="signInWithFacebook()">
                <mat-icon svgIcon="svg-icon-facebook-login" aria-hidden="false"></mat-icon>
                Sign in with Facebook
            </button>
        </div>
    </div>
</div>

<div class="modal-footer" [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <mat-dialog-actions data-aos="fade-in" data-aos-delay="200">
        <button class="btn btn-primary btn-sm btn-cancel" mat-button [mat-dialog-close]="'cancel'">Cancel</button>
    </mat-dialog-actions>
</div>


