<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSourceManageUsersTab" matSort #sortManageUsersTab="matSort" (matSortChange)="announceSortChangeForManageUsersTable($event)">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID"> ID</th>
            <td mat-cell *matCellDef="let user"> {{ user.id }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"> Name</th>
            <td mat-cell *matCellDef="let user"> {{ user.name }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email"> Email</th>
            <td mat-cell *matCellDef="let user"> {{ user.email }}</td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Role"> Role</th>
            <td mat-cell *matCellDef="let user"> {{ user.role }}</td>
        </ng-container>

        <ng-container matColumnDef="promoteDemoteDesigner">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let user">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleUserRole($event, user)" [checked]="user.role == 'DESIGNER'" [disabled]="!(user.role == 'BRONZE' || user.role == 'DESIGNER')"></mat-checkbox>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsManageUsersTab"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsManageUsersTab;"></tr>
    </table>

    <mat-paginator #paginatorManageUsersTab [pageSizeOptions]="[25, 50, 100, 250, 500, 1000]" showFirstLastButtons></mat-paginator>
</div>
