<div [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <div class="modal-header">
        <label class="modal-title pull-left">{{ title }}</label>
        <button type="button" class="close pull-right" aria-label="Close" (click)="dialogRef.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group row" *ngIf="textInputVisible">
            <label class="col-sm-3 col-form-label text-label-div">{{ textLabel }}:</label>
            <div class="name-input-div col-sm-9 d-flex justify-content-start">
                <input type="text" class="name-input form-control" [(ngModel)]="textValue" appMandatoryField>
            </div>
        </div>
        <div class="form-group row" *ngIf="selectDropDownVisible && selectOptions && selectOptions.length > 0">
            <div class="col-sm-1"></div>
            <label class="col-sm-4 col-form-label select-label-div">{{ selectLabel }}:</label>
            <div class="col-sm-6 select-dropdown-div d-flex justify-content-start">
                <button class="btn dropdown-toggle select-dropdown-button form-control" type="button" data-toggle="dropdown" appMandatoryField>
                    {{ selectValue.name }}
                </button>
                <div class="dropdown-menu select-dropdown-menu">
                    <a *ngFor="let option of selectOptions" (click)="dropDownSelectionChanged(option)" [ngClass]="{'dropdown-item-selected': selectValue.value == option.value}" class="dropdown-item select-dropdown-item">{{ option.name }}</a>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <mat-dialog-actions>
            <button *ngIf="yesBtnVisible" [disabled]="textInputVisible && (textValue == null || textValue == '') || selectDropDownVisible && selectValue == null" class="btn btn-primary btn-sm btn-ok" mat-button (click)="submit()" cdkFocusInitial>{{ yesBtnName }}</button>
            <button *ngIf="noBtnVisible" class="btn btn-primary btn-sm btn-cancel" mat-button [mat-dialog-close]="'no'">{{ noBtnName }}</button>
        </mat-dialog-actions>
    </div>
</div>

