import {Injectable} from '@angular/core';
import {FontsComponent} from '../../pages/tool-and-grid/tool/styles/fonts/fonts.component';

@Injectable({
    providedIn: 'root'
})
export class FontComponentService {

    public fontComponent: FontsComponent;

    constructor() { }

    public setFontSectionDisabled(state): void
    {
        if (!this.fontComponent) return;
        this.fontComponent.setFontSectionDisabled(state);
    }

    public refreshStyles()
    {
        if (!this.fontComponent) return;
        this.fontComponent.refreshStyles();
    }
}
