import {Component, Input} from '@angular/core';
import {UserStatsDownloadedImage} from "../../../models/user-stats-downloaded-image";
import {DownloadedImagePreviewComponent} from "./downloaded-image-preview/downloaded-image-preview.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-downloaded-thumbnails',
    templateUrl: './downloaded-thumbnails.component.html',
    styleUrls: ['./downloaded-thumbnails.component.scss']
})
export class DownloadedThumbnailsComponent {

    @Input()
    downloadedImages: UserStatsDownloadedImage[] = [];

    constructor(public dialog: MatDialog)
    { }

    public showDownloadedImagePreview(downloadedImage: UserStatsDownloadedImage)
    {
        this.dialog.open(DownloadedImagePreviewComponent, {data: downloadedImage});
    }
}
