<div class="tool-section actions-section" [class.dark-theme]="toolAndGridComponentService.getIsDarkThemeEnable()">
    <div class="header">
        <label class="header-text">ACTIONS</label>
    </div>
    <div class="actions d-flex flex-column tool-section-overlay">
        <div class="icon-actions d-flex justify-content-between">
            <button (click)="resetCanvas()" aria-label="Reset" color="primary" mat-icon-button matTooltip="reset" [matTooltipPosition]="'above'">
                <mat-icon svgIcon="svg-icon-reset" aria-hidden="false"></mat-icon>
            </button>
            <button (click)="canvasComponentService.undo()" aria-label="Undo" color="primary" mat-icon-button matTooltip="undo" [matTooltipPosition]="'above'">
                <mat-icon svgIcon="svg-icon-undo" aria-hidden="false"></mat-icon>
            </button>
            <button (click)="canvasComponentService.redo()" aria-label="Redo" color="primary" mat-icon-button matTooltip="redo" [matTooltipPosition]="'above'">
                <mat-icon svgIcon="svg-icon-redo" aria-hidden="false"></mat-icon>
            </button>
        </div>
        <div class="icon-actions d-flex justify-content-between">
            <button class="tool-btn" color="primary" mat-stroked-button (click)="previewThumbnail()">
                <mat-icon svgIcon="svg-icon-preview" aria-hidden="false"></mat-icon>
                Preview
            </button>
            <button class="tool-btn" color="primary" mat-stroked-button (click)="downloadThumbnail()">
                <mat-icon svgIcon="svg-icon-download" aria-hidden="false"></mat-icon>
                Download
            </button>
        </div>
        <div class="icon-actions d-flex justify-content-between">
            <button class="tool-btn" color="primary" mat-stroked-button (click)="saveTemplate()" *ngIf="(templateService.getCurrentTemplate() == null) || (templateService.getCurrentTemplate()?.id != null && !templateService.getCurrentTemplate()?.isPublished) || (templateService.getCurrentTemplate()?.id != null && templateService.getCurrentTemplate()?.isPublished && templateService.getCurrentTemplate()?.ownerId != authService.getCurrentUser()?.id)">
                <mat-icon svgIcon="svg-icon-save" aria-hidden="false"></mat-icon>
                Save
            </button>
            <button class="tool-btn" color="primary" mat-stroked-button (click)="publishTemplate()" *ngIf="(templateService.getCurrentTemplate() == null) || (templateService.getCurrentTemplate()?.id != null && templateService.getCurrentTemplate()?.isSaved) || (templateService.getCurrentTemplate()?.id != null && templateService.getCurrentTemplate()?.isPublished && templateService.getCurrentTemplate()?.ownerId == authService.getCurrentUser()?.id)">
                <mat-icon svgIcon="svg-icon-publish" aria-hidden="false"></mat-icon>
                Publish
            </button>
        </div>
    </div>
</div>



