import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {environment} from "../../../environments/environment";
import {ToolAndGridComponentService} from "../../services/component-service/tool-and-grid-component.service";
import {CanvasComponentService} from "../../services/component-service/canvas-component.service";

@Component({
    selector: 'app-sign-in-dialog',
    templateUrl: './sign-in-dialog.component.html',
    styleUrls: ['./sign-in-dialog.component.scss']
})
export class SignInDialogComponent implements OnInit, AfterViewInit {

    title: string = "SIGN IN";

    constructor(public dialogRef: MatDialogRef<SignInDialogComponent>,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public canvasComponentService: CanvasComponentService,
                @Inject(MAT_DIALOG_DATA) public data: any)
    { }

    ngOnInit()
    {
        if (!this.data) return;
        if (this.data['title'] != null) this.title = this.data['title'];
    }

    ngAfterViewInit()
    {
        if (this.toolAndGridComponentService.getIsDarkThemeEnable())
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.add("dark-theme");
        }
        else
        {
            document?.getElementsByClassName('cdk-overlay-container')?.item(0)?.classList?.remove("dark-theme");
        }
    }

    public signInWithGoogle()
    {
        let url = environment.endpoint + "oauth2/authorize/google?redirect_uri=" + window.location.href;
        window.location.href = url;
    }

    public signInWithFacebook()
    {
        let url = environment.endpoint + "oauth2/authorize/facebook?redirect_uri=" + window.location.href;
        window.location.href = url;
    }
}
